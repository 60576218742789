import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { getEmployesId, reset } from "../redux/EmployesSlice";
import Loading from "src/components/Loading";
import EmployesInfoData from "../components/EmployesInfoData";
import { PageTemplate } from "src/layout/components/PageTemplate";

interface CustomizedState {
  id: string;
  forwardedFromInfo?: boolean;
}

const EmployesInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const { id } = state;

  const [data, setData] = useState([] as any);
  const [loading, setLoading] = useState(true);

  const {
    singleEmployee,
    isDeleteLoading,
    isSingleLoading,
    isSingleError,
    isDeleteError,
    isSingleSuccess,
    isDeleteSuccess,
    singleMessage,
    deleteMessage,
  } = useSelector((state: RootState) => state.employes);

  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSingleError) {
      enqueueSnackbar(singleMessage, { variant: "error" });
    }
    if (isSingleSuccess) {
      setData(singleEmployee);
      setLoading(false);
    }
    return () => {
      dispatch(reset());
    };
  }, [isSingleError, isSingleSuccess, singleMessage, dispatch]);

  useEffect(() => {
    if (isDeleteError) {
      enqueueSnackbar(deleteMessage, { variant: "error" });
    }
    if (isDeleteSuccess) {
      enqueueSnackbar(deleteMessage, { variant: "success" });
      navigate("/employees/employees-list");
    }
    return () => {
      dispatch(reset());
    };
  }, [isDeleteError, isDeleteSuccess, deleteMessage, dispatch]);

  useEffect(() => {
    const infoValue = {
      id: Number(id),
    };
    dispatch(getEmployesId(infoValue));
  }, []);

  return (
    <PageTemplate
      isTransparent
      crumbs={[
        {
          label: "employees",
          href: "/employees/employees-list",
          depth: -1,
        },
        {
          label: "employee information",
        },
      ]}
    >
      {loading ? (
        <Loading />
      ) : (
        <EmployesInfoData isDeleteLoading={isDeleteLoading} data={data} />
      )}
    </PageTemplate>
  );
};

export default EmployesInfo;
