export default {
  1: "#FFFFFF",
  20: "#F5F8FD",
  50: "#F0F2F7",
  100: "#EAECEF",
  200: "#C2CBD7",
  300: "#A9B6C6",
  400: "#B7BDC5",
  500: "#A1A8B2",
  600: "#707A8A",
  700: "#636A73",
  750: "#474D57",
  850: "#1E2329",
};
