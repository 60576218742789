import { styled } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";
import StyledTable from "src/components/Table";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import { GridColDef } from "@mui/x-data-grid";
import { Eye } from "iconsax-react";

import {
  deleteSaleOrderShipmentCarrier,
  getSaleOrderShipmentCarriers,
  addSaleOrderShipmentCarrier,
  reset as resetSaleOrderCarriers,
} from "src/features/saleOrders/redux/CarriersSlice";
import { StyledLoadingButton } from "src/components/LoadingButton";
import ConfirmModal from "src/components/Modal";
import { GrayButton } from "src/components/GrayButton";
import {
  exportSaleOrderContainers,
  getSaleOrderContainers,
  reset as resetSaleOrderContainers,
  editSaleOrderContainerNumber,
} from "src/features/saleOrders/redux/SaleOrdersSlice";

import { AddNewContainerModal } from "../modals/AddNewContainerModal";
import { EditContainerNumberModal } from "../modals/EditContainerNumberModal";

const filtersList = [
  {
    id: 1,
    label: "loading",
    title: "In Progress",
  },
  {
    id: 2,
    label: "dispatched,reached",
    title: "Completed",
  },
];

interface ShippingPortStuffedContainersListProps {
  saleOrderId: number;
  shipmentId: number;
  warehouseId: number;
  saleOrderName: string;
}

export const ShippingPortStuffedContainersList: FC<
  ShippingPortStuffedContainersListProps
> = (props) => {
  const { saleOrderId, saleOrderName, warehouseId, shipmentId } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("loading");
  const [containersData, setContainersData] = useState([] as any);
  const [containersMeta, setContainersMeta] = useState([] as any);
  const [containersLoading, setContainersLoading] = useState(true);

  const [isDeletetModalOpen, setIsDeletetModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [selectedName, setSelectedName] = useState("");

  const [isAddNewContainerModalOpen, setIsAddNewContainerModalOpen] =
    useState(false);
  const [isEditNumberModalOpen, setIsEditNumberModalOpen] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: "Container Number",
      flex: 1,
      minWidth: 140,
      filterable: false,
    },
    {
      field: "total_products",
      headerName: "Total Products",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "total_weight",
      headerName: "Total Products Weight",
      flex: 1,
      minWidth: 100,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.total_weight
            ? `${params.row?.total_weight * 1000} Kg`
            : 0}
        </Box>
      ),
    },
    // {
    //   field: "documents",
    //   headerName: "Documents",
    //   flex: 1,
    //   minWidth: 120,
    //   filterable: false,
    //   renderCell: (params) => (
    //     <Box padding={2} color={"#1E2329"}>
    //       {params.row?.full_weight ? params.row?.full_weight * 1000 : 0}
    //     </Box>
    //   ),
    // },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.2,
      minWidth: 120,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          flexDirection="row"
        >
          <IconButton
            onClick={() => {
              navigate(
                "/saleOrders/saleOrders-information/container-information",
                {
                  state: {
                    data: params?.row,
                    warehouseId: warehouseId,
                    saleOrderId: saleOrderId,
                    shipmentId: shipmentId,
                    saleOrderName: saleOrderName,
                  },
                }
              );
            }}
          >
            <Eye color={colors.gray[600]} size="24" />
          </IconButton>
          <IconButton
            onClick={() => {
              setIsEditNumberModalOpen(true);
              setSelectedId(Number(params?.id));
              setSelectedName(params?.row?.title);
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Edit.svg"}
              alt="edit"
            />
          </IconButton>
          <IconButton>
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Remove.svg"}
              alt="delete"
              onClick={() => {
                setSelectedId(Number(params?.id));
                setIsDeletetModalOpen(true);
              }}
            />
          </IconButton>
        </Box>
      ),
    },
  ];

  const {
    isExportContainersError,
    isEditContainerNumberError,
    isExportContainersLoading,
    isEditContainerNumberLoading,
    isExportContainersSuccess,
    isEditContainerNumberSuccess,
    exportContainersMessage,
    editContainerNumberMessage,
  } = useSelector((state: RootState) => state.saleOrders);

  const {
    shipmentCarriers,
    shipmentCarriersMeta,
    isGetShipmentCarriersError,
    isDeleteShipmentCarriersError,
    isAddShipmentCarriersError,
    isGetShipmentCarriersSuccess,
    isDeleteShipmentCarriersSuccess,
    isAddShipmentCarriersSuccess,
    isGetShipmentCarriersLoading,
    isDeleteShipmentCarriersLoading,
    isAddShipmentCarriersLoading,
    getShipmentCarriersMessage,
    deleteShipmentCarriersMessage,
    addShipmentCarriersMessage,
  } = useSelector((state: RootState) => state.carriers);

  useEffect(() => {
    if (isGetShipmentCarriersError) {
      enqueueSnackbar(getShipmentCarriersMessage, { variant: "error" });
    }
    if (isGetShipmentCarriersSuccess) {
      setContainersData(shipmentCarriers);
      setContainersMeta(shipmentCarriersMeta);
      setContainersLoading(false);
    }
    return () => {
      dispatch(resetSaleOrderCarriers());
    };
  }, [
    isGetShipmentCarriersError,
    isGetShipmentCarriersSuccess,
    getShipmentCarriersMessage,
    dispatch,
  ]);

  useEffect(() => {
    if (isEditContainerNumberError) {
      enqueueSnackbar(editContainerNumberMessage, { variant: "error" });
    }
    if (isEditContainerNumberSuccess) {
      enqueueSnackbar(editContainerNumberMessage, { variant: "success" });
      dispatch(
        getSaleOrderShipmentCarriers({
          saleOrderId: Number(saleOrderId),
          shipmentId: Number(shipmentId),
          filter: filter,
          page: containersData.length <= 1 ? (page > 1 ? page - 1 : 1) : page,
        })
      ).then(() => setIsEditNumberModalOpen(false));
    }
    return () => {
      dispatch(resetSaleOrderCarriers());
    };
  }, [
    isEditContainerNumberError,
    isEditContainerNumberSuccess,
    editContainerNumberMessage,
  ]);

  useEffect(() => {
    if (isAddShipmentCarriersError) {
      enqueueSnackbar(addShipmentCarriersMessage, { variant: "error" });
    }
    if (isAddShipmentCarriersSuccess) {
      enqueueSnackbar(addShipmentCarriersMessage, { variant: "success" });
      dispatch(
        getSaleOrderShipmentCarriers({
          saleOrderId: Number(saleOrderId),
          shipmentId: Number(shipmentId),
          filter: filter,
          page: containersData.length <= 1 ? (page > 1 ? page - 1 : 1) : page,
        })
      ).then(() => setIsAddNewContainerModalOpen(false));
    }
    return () => {
      dispatch(resetSaleOrderCarriers());
    };
  }, [
    isAddShipmentCarriersError,
    isAddShipmentCarriersSuccess,
    addShipmentCarriersMessage,
  ]);

  useEffect(() => {
    if (isDeleteShipmentCarriersError) {
      enqueueSnackbar(deleteShipmentCarriersMessage, { variant: "error" });
    }
    if (isDeleteShipmentCarriersSuccess) {
      enqueueSnackbar(deleteShipmentCarriersMessage, { variant: "success" });
      dispatch(
        getSaleOrderShipmentCarriers({
          saleOrderId: Number(saleOrderId),
          shipmentId: Number(shipmentId),
          filter: filter,
          page: containersData.length <= 1 ? (page > 1 ? page - 1 : 1) : page,
        })
      ).then(() => setIsDeletetModalOpen(false));

      if (containersData.length <= 1) {
        setPage(page > 1 ? page - 1 : 1);
      }
    }
    return () => {
      dispatch(resetSaleOrderCarriers());
    };
  }, [
    isDeleteShipmentCarriersError,
    isDeleteShipmentCarriersSuccess,
    deleteShipmentCarriersMessage,
  ]);

  useEffect(() => {
    if (isExportContainersError) {
      enqueueSnackbar(exportContainersMessage, { variant: "error" });
    }
  }, [
    isExportContainersError,
    isExportContainersLoading,
    exportContainersMessage,
    dispatch,
  ]);

  useEffect(() => {
    const infoValue = {
      saleOrderId: Number(saleOrderId),
      shipmentId: Number(shipmentId),
      page: page,
      filter: filter,
    };
    dispatch(getSaleOrderShipmentCarriers(infoValue));
  }, [page, filter]);

  const onDelete = (selected: any) => {
    const deleteValues = {
      saleOrderId: Number(saleOrderId),
      shipmentId: Number(shipmentId),
      carrierId: Number(selected),
    };
    dispatch(deleteSaleOrderShipmentCarrier(deleteValues));
  };

  const onAddNewContainer = (containerNumber: string) => {
    const AddValues = {
      saleOrderId: Number(saleOrderId),
      shipmentId: Number(shipmentId),
      truckRegNumber: containerNumber,
    };
    dispatch(addSaleOrderShipmentCarrier(AddValues));
  };

  const onEditContainerNumber = (containerNumber: string) => {
    const editValues = {
      saleOrderId: Number(saleOrderId),
      shipmentId: Number(shipmentId),
      carrierId: Number(selectedId),
      title: containerNumber,
    };
    dispatch(editSaleOrderContainerNumber(editValues));
  };

  return (
    <>
      <StyledHeader>
        <Filters>
          <FiltersLabel>Containers List </FiltersLabel>
          {filtersList.map((filterItem) =>
            filter === filterItem.label ? (
              <StyledAcitve key={filterItem.id}>
                <Typography
                  color={colors.secondary[700]}
                  fontSize={15}
                  fontWeight="700"
                >
                  {filterItem.title}
                </Typography>
                <FilterBadge>{containersData.length ?? 0}</FilterBadge>
              </StyledAcitve>
            ) : (
              <Typography
                key={filterItem.id}
                color={colors.gray[850]}
                fontSize={15}
                fontWeight="400"
                onClick={() => {
                  setContainersLoading(true);
                  setPage(1);
                  setFilter(filterItem.label);
                }}
                sx={{
                  cursor: "pointer",
                }}
              >
                {filterItem.title}
              </Typography>
            )
          )}
        </Filters>
        <Actions>
          <Box>
            <GrayButton
              variant="outlined"
              fullWidth
              loading={isExportContainersLoading}
              onClick={() => {
                dispatch(
                  exportSaleOrderContainers({
                    saleOrderId: Number(saleOrderId),
                  })
                ).then(() => resetSaleOrderContainers());
              }}
            >
              Export Containers Report
            </GrayButton>
          </Box>
          <Box>
            <StyledLoadingButton
              variant="contained"
              color="secondary"
              fullWidth
              loading={false}
              onClick={() => setIsAddNewContainerModalOpen(true)}
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.base.white}
              >
                Stuff New Container
              </Typography>
            </StyledLoadingButton>
          </Box>
        </Actions>
      </StyledHeader>
      <ListBoundary
        isEmpty={containersData.length === 0 || !containersData}
        isDataLoading={containersLoading}
        fallbackImageURL="/assets/images/emptyStates/Slaeorder-Port-recievedList EmptyState.svg"
        description="No Container added yet!"
      >
        <StyledTable
          disableColumnMenu
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableVirtualization
          disableSelectionOnClick
          loading={false}
          columns={columns}
          experimentalFeatures={{ newEditingApi: true }}
          rows={containersData}
          onPageChange={(e) => setPage(e + 1)}
          page={page - 1}
          paginationMode="server"
          rowCount={containersMeta?.pagination?.total_items}
        />
      </ListBoundary>

      <ConfirmModal
        title={"Remove Container"}
        iconName={"ModalRemove"}
        open={isDeletetModalOpen}
        isLoading={isGetShipmentCarriersLoading}
        onDismiss={() => setIsDeletetModalOpen(false)}
        onConfirm={() => onDelete(selectedId)}
        confrimText={"Remove"}
        cancelText={"Cancel"}
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to remove Container
          <b>{selectedName}</b> ?
        </Typography>
      </ConfirmModal>

      <AddNewContainerModal
        open={isAddNewContainerModalOpen}
        loading={isAddShipmentCarriersLoading}
        onAddVariant={(containerNumber) => onAddNewContainer(containerNumber)}
        onDismiss={() => setIsAddNewContainerModalOpen(false)}
      />
      <EditContainerNumberModal
        open={isEditNumberModalOpen}
        loading={isEditContainerNumberLoading}
        defaultNumber={selectedName}
        onAddVariant={(containerNumber) =>
          onEditContainerNumber(containerNumber)
        }
        onDismiss={() => setIsEditNumberModalOpen(false)}
      />
    </>
  );
};

const StyledHeader = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 58px;
  margin-bottom: 24px;
  ${(props) => props.theme.breakpoints.down("xl")} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Filters = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 32px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FiltersLabel = styled("span")`
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  white-space: nowrap;
  color: ${colors.midnight[850]};
  margin-right: 50px;
`;

const StyledAcitve = styled(Box)`
  background-color: ${colors.midnight[20]};
  border-radius: 4px;
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const FilterBadge = styled(Box)`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  padding: 3px 8px;
  width: auto;
  height: 24px;
  background-color: ${colors.secondary[700]};
  color: ${colors.base.white};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  & > div {
    width: 224px;
  }
  ${(props) => props.theme.breakpoints.down("xl")} {
    width: 100%;
    margin-top: 32px;
    flex-direction: column;
    align-items: flex-start;
    & > div {
      width: 100%;
    }
  }
`;
