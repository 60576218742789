import { styled } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";
import StyledTable from "src/components/Table";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import { GridColDef } from "@mui/x-data-grid";
import { Eye } from "iconsax-react";
import {
  deleteSaleOrderShipmentCarrier,
  deleteSaleOrderShipmentCarrierProduct,
  getSaleOrderShipmentCarrierProducts,
  addSaleOrderShipmentCarrierProduct,
  getSaleOrderShipmentCarriers,
  reset as resetSaleOrderCarriers,
} from "src/features/saleOrders/redux/CarriersSlice";
import { StyledLoadingButton } from "src/components/LoadingButton";
import ConfirmModal from "src/components/Modal";
import { AddNewProductModal } from "../modals/AddNewProductModal";
import {
  serachProducts,
  reset as resetProducts,
} from "src/features/products/redux/ProductsSlice";

interface SaleOrderOriginCarrierProductsListProps {
  id: number;
  warehouseId: number;
}

export const SaleOrderOriginCarrierProductsList: FC<
  SaleOrderOriginCarrierProductsListProps
> = (props) => {
  const { id, warehouseId } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("loading");
  const [products, setProductsData] = useState([] as any);
  const [productsMeta, setProductsMeta] = useState([] as any);
  const [trucksLoadind, setTrucksLoadind] = useState(true);
  const [searchedProductData, setSearchedProductData] = useState(null as any);

  const [isDeletetModalOpen, setIsDeletetModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const [isAddNewProductModalOpen, setIsAddNewProductModalOpen] =
    useState(false);

  const columns: GridColDef[] = [
    {
      field: "product",
      headerName: "Product",
      flex: 1,
      minWidth: 140,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.product?.title}
        </Box>
      ),
    },
    {
      field: "placement",
      headerName: "Placement",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "barcode",
      headerName: "Barcode",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "weight",
      headerName: "Weight",
      flex: 1,
      minWidth: 120,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.variants && params.row?.variants.length > 0
            ? params.row?.variants.find((v: any) => v.variant === "Weight") !=
              null
              ? `${
                  params.row?.variants.find((v: any) => v.variant === "Weight")
                    .value * 1000
                } Kg`
              : 0
            : 0}
        </Box>
      ),
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.2,
      minWidth: 120,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          flexDirection="row"
        >
          <IconButton>
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Remove.svg"}
              alt="delete"
              onClick={() => {
                setSelectedId(Number(params?.id));
                setIsDeletetModalOpen(true);
              }}
            />
          </IconButton>
        </Box>
      ),
    },
  ];

  const {
    shipmentCarrierProducts,
    shipmentCarrierProductsMeta,
    isGetShipmentCarrierProductsError,
    isDeleteShipmentCarrierProductError,
    isAddShipmentCarrierProductError,
    isGetShipmentCarrierProductsSuccess,
    isDeleteShipmentCarrierProductSuccess,
    isAddShipmentCarrierProductSuccess,
    isGetShipmentCarrierProductsLoading,
    isDeleteShipmentCarrierProductLoading,
    isAddShipmentCarrierProductLoading,
    getShipmentCarrierProductsMessage,
    deleteShipmentCarrierProductMessage,
    addShipmentCarrierProductMessage,
  } = useSelector((state: RootState) => state.carriers);

  const {
    isSearchLoading,
    isSearchSuccess,
    searchedProducts,
    message: searchedProductMessage,
    isError: isSearchError,
  } = useSelector((state: RootState) => state.products);

  useEffect(() => {
    if (isGetShipmentCarrierProductsError) {
      enqueueSnackbar(getShipmentCarrierProductsMessage, { variant: "error" });
    }
    if (isGetShipmentCarrierProductsSuccess) {
      setProductsData(shipmentCarrierProducts);
      setProductsMeta(shipmentCarrierProductsMeta);
      setTrucksLoadind(false);
    }
    return () => {
      dispatch(resetSaleOrderCarriers());
    };
  }, [
    isGetShipmentCarrierProductsError,
    isGetShipmentCarrierProductsSuccess,
    getShipmentCarrierProductsMessage,
    dispatch,
  ]);

  useEffect(() => {
    if (isSearchError) {
      enqueueSnackbar(searchedProductMessage, { variant: "error" });
    }
    if (isSearchSuccess) {
      setSearchedProductData(searchedProducts);
    }
    return () => {
      dispatch(resetProducts());
    };
  }, [isSearchError, isSearchSuccess, searchedProductMessage, dispatch]);

  useEffect(() => {
    if (isAddShipmentCarrierProductError) {
      enqueueSnackbar(addShipmentCarrierProductMessage, {
        variant: "error",
      });
    }
    if (isAddShipmentCarrierProductSuccess) {
      enqueueSnackbar(addShipmentCarrierProductMessage, {
        variant: "success",
      });
      dispatch(
        getSaleOrderShipmentCarrierProducts({
          carrierId: Number(id),
          page: page,
        })
      ).then(() => setIsAddNewProductModalOpen(false));
    }
    return () => {
      dispatch(resetSaleOrderCarriers());
    };
  }, [
    isAddShipmentCarrierProductError,
    isAddShipmentCarrierProductSuccess,
    addShipmentCarrierProductMessage,
  ]);

  useEffect(() => {
    if (isDeleteShipmentCarrierProductError) {
      enqueueSnackbar(deleteShipmentCarrierProductMessage, {
        variant: "error",
      });
    }
    if (isDeleteShipmentCarrierProductSuccess) {
      enqueueSnackbar(deleteShipmentCarrierProductMessage, {
        variant: "success",
      });
      dispatch(
        getSaleOrderShipmentCarrierProducts({
          carrierId: Number(id),
          page: products.length <= 1 ? (page > 1 ? page - 1 : 1) : page,
        })
      ).then(() => setIsDeletetModalOpen(false));

      if (products.length <= 1) {
        setPage(page > 1 ? page - 1 : 1);
      }
    }
    return () => {
      dispatch(resetSaleOrderCarriers());
    };
  }, [
    isDeleteShipmentCarrierProductError,
    isDeleteShipmentCarrierProductSuccess,
    deleteShipmentCarrierProductMessage,
  ]);

  useEffect(() => {
    const infoValue = {
      carrierId: Number(id),
      page: products.length <= 1 ? (page > 1 ? page - 1 : 1) : page,
    };
    dispatch(getSaleOrderShipmentCarrierProducts(infoValue));
  }, [page, filter]);

  useEffect(() => {
    const infoValue = {
      carrierId: Number(id),
      page: products.length <= 1 ? (page > 1 ? page - 1 : 1) : page,
    };
    dispatch(getSaleOrderShipmentCarrierProducts(infoValue));
  }, [page, filter]);

  const onDelete = (selected: any) => {
    const deleteValues = {
      carrierId: Number(id),
      productId: Number(selected),
    };
    dispatch(deleteSaleOrderShipmentCarrierProduct(deleteValues));
  };

  const onGetSearchedProduct = (barcode: string) => {
    resetProducts();
    const getValues = {
      warehouseId: Number(warehouseId),
      barcode: barcode,
    };
    dispatch(serachProducts(getValues));
  };

  const onAddNewProduct = (regNumber: string) => {
    const AddValues = {
      carrierId: Number(id),
      barcode: regNumber,
    };
    dispatch(addSaleOrderShipmentCarrierProduct(AddValues));
  };

  return (
    <>
      <StyledHeader>
        <Header>
          <HeaderLabel>Products List: </HeaderLabel>
          {productsMeta?.pagination?.total_items > 0 &&
          !isGetShipmentCarrierProductsLoading ? (
            <Infos>
              <SuccessInfo>
                Total Number{" "}
                <InfoBold>{productsMeta?.pagination?.total_items}</InfoBold> Pcs
              </SuccessInfo>
              <ErrorInfo>
                Total Weight{" "}
                <InfoBold>{productsMeta?.total_weight * 1000 ?? 0}</InfoBold> Kg
              </ErrorInfo>
            </Infos>
          ) : null}
        </Header>
        <SendButton>
          <StyledLoadingButton
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => setIsAddNewProductModalOpen(true)}
          >
            <Typography
              fontSize={16}
              fontWeight={500}
              color={colors.base.white}
            >
              Add New Product
            </Typography>
          </StyledLoadingButton>
        </SendButton>
      </StyledHeader>
      <ListBoundary
        isEmpty={products.length === 0 || !products}
        isDataLoading={trucksLoadind}
        fallbackImageURL="/assets/images/emptyStates/CarrierProducts-List EmptyState.svg"
        description="No product added yet!"
      >
        <StyledTable
          disableColumnMenu
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableVirtualization
          disableSelectionOnClick
          loading={false}
          columns={columns}
          experimentalFeatures={{ newEditingApi: true }}
          rows={products}
          onPageChange={(e) => setPage(e + 1)}
          page={page - 1}
          paginationMode="server"
          rowCount={productsMeta?.pagination?.total_items}
        />
      </ListBoundary>

      <ConfirmModal
        title={"Remove Product"}
        iconName={"ModalRemove"}
        open={isDeletetModalOpen}
        isLoading={
          isGetShipmentCarrierProductsLoading ||
          isDeleteShipmentCarrierProductLoading
        }
        onDismiss={() => setIsDeletetModalOpen(false)}
        onConfirm={() => onDelete(selectedId)}
        confrimText={"Remove"}
        cancelText={"Cancel"}
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to remove this Product ?
        </Typography>
      </ConfirmModal>

      <AddNewProductModal
        open={isAddNewProductModalOpen}
        dataLoading={isSearchLoading}
        loading={isAddShipmentCarrierProductLoading}
        resultProduct={searchedProducts ? searchedProducts[0] : null}
        onGetProductsData={(barcode) => onGetSearchedProduct(barcode)}
        onAddProduct={(regNumber) => onAddNewProduct(regNumber)}
        onDismiss={() => setIsAddNewProductModalOpen(false)}
      />
    </>
  );
};

const StyledHeader = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SendButton = styled(Box)`
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    margin-top: 24px;
  }
`;

const Header = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 32px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const HeaderLabel = styled("span")`
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  white-space: nowrap;
  color: ${colors.midnight[850]};
`;

const Infos = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
`;

const SuccessInfo = styled(Box)`
  background-color: ${colors.secondary[10]};
  color: ${colors.secondary[800]};
  border-radius: 50px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
`;

const ErrorInfo = styled(Box)`
  background-color: ${colors.error[10]};
  color: ${colors.error[600]};
  border-radius: 50px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
`;

const InfoBold = styled(Typography)`
  margin: 0 4px;
  font-size: 14px;
  font-weight: 700;
`;
