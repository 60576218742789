import { styled } from "@mui/material/styles";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import React, { ReactNode } from "react";
import { AppDispatch } from "src/store";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import colors from "src/theme/colors";
import { toggleSidebar } from "../redux/layoutStore";

type Props = {
  iconName?: string;
  name: string;
  route: string;
  navigation: string;
  expand?: boolean;
  children?: ReactNode;
};

const MenuItem = (props: Props) => {
  const { iconName, name, route, navigation, expand, children } = props;

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = React.useState(false);

  const onClickLink = () => {
    navigate(navigation);
    dispatch(toggleSidebar());
  };

  return (
    <>
      <StyledListItemButton
        onClick={() => (expand ? setOpen(!open) : onClickLink())}
        selected={
          route === "/" ? pathname === navigation : pathname.startsWith(route)
        }
        exapndable={expand}
      >
        <StyledListItemIcon>
          {iconName ? (
            <img
              src={process.env.PUBLIC_URL + `/assets/icons/${iconName}.svg`}
              style={{ width: 20, height: 20 }}
            />
          ) : null}
        </StyledListItemIcon>
        <StyledListItemText>
          <Typography fontSize={14} fontWeight={500} color={colors.gray[750]}>
            {name}
          </Typography>
        </StyledListItemText>
        {expand ? (
          open ? (
            <ArrowDown2 size="14" color={colors.gray[600]} />
          ) : (
            <ArrowUp2 size="14" color={colors.gray[600]} />
          )
        ) : null}
      </StyledListItemButton>
      {expand ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children}
          </List>
        </Collapse>
      ) : null}
    </>
  );
};

interface StyledProps {
  selected?: boolean;
  exapndable?: boolean;
}

const StyledListItemButton = styled(ListItemButton)<StyledProps>`
  height: 56px;
  &.Mui-selected {
    background-color: #f1fbff;
    border-radius: 4px;
    &:hover {
      background-color: #f1fbff;
    }
  }
  &:hover {
    background-color: #f1fbff67;
  }
`;

const StyledListItemText = styled(ListItemText)`
  padding-left: 18px;
  white-space: nowrap;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  padding-left: 0px;
  min-width: 24px;
`;

export default MenuItem;
