import { styled } from "@mui/material/styles";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import colors from "src/theme/colors";

interface GrayButtonProps extends LoadingButtonProps {
  useVariantHeight?: boolean;
}

export const GrayButton = (props: GrayButtonProps) => {
  return (
    <StyledButton
      {...props}
      useVariantHeight={props.size != null || props.useVariantHeight}
    />
  );
};

interface StyledProps {
  useVariantHeight?: boolean;
}

const StyledButton = styled(LoadingButton)<StyledProps>`
  height: ${({ useVariantHeight }) => (useVariantHeight ? "auto" : "48px")};
  color: ${colors.gray[800]};
  border-color: ${colors.gray[500]};
  background-color: transparent;
  &:hover {
    border-color: ${colors.gray[500]};
    background-color: ${colors.gray[200]};
  }
  &.MuiButton-contained {
    color: ${colors.gray[750]};
    border-color: ${colors.gray[100]};
    background-color: ${colors.gray[100]};
  }
  &.MuiLoadingButton-loading {
    color: ${colors.gray[400]};
    border-color: ${colors.gray[50]};
    background-color: ${colors.gray[50]};
  }
  & > .MuiLoadingButton-loadingIndicator {
    color: ${colors.gray[600]};
  }
`;
