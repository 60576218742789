import { Route, Routes } from "react-router-dom";
import LogsTable from "../pages/LogsTable";

export const LogsRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/logs-list" element={<LogsTable />} />
      </Routes>
    </>
  );
};
