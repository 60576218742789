import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import itemServices from "./ItemServices";
import { getData, deleteValue, getIdValue, postItem } from "./types";

const initialState = {
  isError: false,
  isDeleteError: false,
  isSuccess: false,
  isPatchSuccess: false,
  isDeleteSuccess: false,
  isLoading: true,
  isSingleLoading: true,
  isCreateLoading: false,
  isDeleteLoading: false,
  message: "",
  deleteMessage: "",
  items: [],
  itemsMeta: [],
  singleItem: [],
};

export const getItems = createAsyncThunk(
  "items",
  async (data: getData, thunkAPI) => {
    try {
      return await itemServices.getItems(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getItemsId = createAsyncThunk(
  "items/id",
  async (data: getIdValue, thunkAPI) => {
    try {
      return itemServices.getItemsId(data.id);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const postItems = createAsyncThunk(
  "items/post",
  async (data: any, thunkAPI) => {
    try {
      return await itemServices.postItems(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const patchItems = createAsyncThunk(
  "items/patch",
  async (data: any, thunkAPI) => {
    try {
      return await itemServices.patchItems(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteItems = createAsyncThunk(
  "items/delete",
  async (data: deleteValue, thunkAPI) => {
    try {
      return await itemServices.deleteItems(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const itemSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSingleLoading = true;
      state.isCreateLoading = false;
      state.isDeleteLoading = false;
      state.isSuccess = false;
      state.isPatchSuccess = false;
      state.isDeleteSuccess = false;
      state.isError = false;
      state.isDeleteError = false;
      state.message = "";
      state.deleteMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.items = action.payload.data.results;
        state.itemsMeta = action.payload.data.meta;
      })
      .addCase(getItems.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(postItems.pending, (state) => {
        state.isLoading = true;
        state.isCreateLoading = true;
      })
      .addCase(postItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(postItems.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreateLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(getItemsId.pending, (state) => {
        state.isSingleLoading = true;
      })
      .addCase(getItemsId.fulfilled, (state, action) => {
        state.isSingleLoading = false;
        state.isSuccess = true;
        state.singleItem = action.payload.data.results;
      })
      .addCase(getItemsId.rejected, (state, action) => {
        state.isSingleLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(patchItems.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(patchItems.fulfilled, (state, action) => {
        state.isCreateLoading = false;
        state.isPatchSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(patchItems.rejected, (state, action) => {
        state.isCreateLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(deleteItems.pending, (state) => {
        state.isDeleteLoading = true;
      })
      .addCase(deleteItems.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteSuccess = true;
        state.deleteMessage = `${action.payload.data.message}`;
      })
      .addCase(deleteItems.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.deleteMessage = `${action.payload}`;
      });
  },
});
export const { reset } = itemSlice.actions;
export default itemSlice.reducer;
