import { styled } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";
import StyledTable from "src/components/Table";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import { GridColDef } from "@mui/x-data-grid";
import {
  serachProducts,
  reset as resetProducts,
} from "src/features/products/redux/ProductsSlice";

interface ShippingPortRemainingProductsListProps {
  saleOrderId: number;
  shipmentId: number;
  warehouseId: number;
  saleOrderName: string;
}

export const ShippingPortRemainingProductsList: FC<
  ShippingPortRemainingProductsListProps
> = (props) => {
  const { saleOrderId, shipmentId, warehouseId, saleOrderName } = props;

  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(1);
  const [products, setProductsData] = useState([] as any);
  const [productsMeta, setProductsMeta] = useState([] as any);
  const [productsLoading, setProductsLoading] = useState(true);

  const columns: GridColDef[] = [
    {
      field: "product",
      headerName: "Product",
      flex: 1,
      minWidth: 140,
      filterable: false,
    },
    {
      field: "reg_number",
      headerName: "Truck Reg Number",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "internal_barcode",
      headerName: "Barcode",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "weight",
      headerName: "Weight",
      flex: 1,
      minWidth: 120,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.weight ? `${params.row?.weight * 1000} Kg` : 0}
        </Box>
      ),
    },
  ];

  const {
    isSearchLoading,
    isSearchSuccess,
    searchedProducts,
    searchedProductsMeta,
    message: searchedProductMessage,
    isError: isSearchError,
  } = useSelector((state: RootState) => state.products);

  useEffect(() => {
    if (isSearchError) {
      enqueueSnackbar(searchedProductMessage, { variant: "error" });
    }
    if (isSearchSuccess) {
      setProductsData(searchedProducts);
      setProductsMeta(searchedProductsMeta);
      setProductsLoading(false);
    }
    return () => {
      dispatch(resetProducts());
    };
  }, [isSearchError, isSearchSuccess, searchedProductMessage, dispatch]);

  useEffect(() => {
    const getValues = {
      warehouseId: Number(warehouseId),
      page: products.length <= 1 ? (page > 1 ? page - 1 : 1) : page,
    };
    dispatch(serachProducts(getValues));
  }, [page]);

  return (
    <>
      <StyledHeader>
        <Header>
          <HeaderLabel>Products List: </HeaderLabel>
          {productsMeta?.pagination?.total_items > 0 && !productsLoading ? (
            <Infos>
              <SuccessInfo>
                Total Number{" "}
                <InfoBold>{productsMeta?.pagination?.total_items}</InfoBold> Pcs
              </SuccessInfo>
              {/* <ErrorInfo>
                Total Weight{" "}
                <InfoBold>
                  {products.length > 0
                    ? products.reduce(
                        (accumulator: any, object: any) =>
                          accumulator + object.weight,
                        0
                      ) * 1000
                    : 0}
                </InfoBold>{" "}
                Kg
              </ErrorInfo> */}
            </Infos>
          ) : null}
        </Header>
      </StyledHeader>
      <ListBoundary
        isEmpty={products.length === 0 || !products}
        isDataLoading={productsLoading}
        fallbackImageURL="/assets/images/emptyStates/CarrierProducts-List EmptyState.svg"
        description="No product added yet!"
      >
        <StyledTable
          disableColumnMenu
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableVirtualization
          disableSelectionOnClick
          loading={false}
          columns={columns}
          experimentalFeatures={{ newEditingApi: true }}
          rows={products}
          onPageChange={(e) => setPage(e + 1)}
          page={page - 1}
          paginationMode="server"
          rowCount={productsMeta?.pagination?.total_items}
        />
      </ListBoundary>
    </>
  );
};

const StyledHeader = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 58px;
  margin-bottom: 24px;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SendButton = styled(Box)`
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    margin-top: 24px;
  }
`;

const Header = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 32px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const HeaderLabel = styled("span")`
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  white-space: nowrap;
  color: ${colors.midnight[850]};
`;

const Infos = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
`;

const SuccessInfo = styled(Box)`
  background-color: ${colors.secondary[10]};
  color: ${colors.secondary[800]};
  border-radius: 50px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
`;

const ErrorInfo = styled(Box)`
  background-color: ${colors.error[10]};
  color: ${colors.error[600]};
  border-radius: 50px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
`;

const InfoBold = styled(Typography)`
  margin: 0 4px;
  font-size: 14px;
  font-weight: 700;
`;
