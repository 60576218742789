import { Typography } from "@mui/material";
import React, { ReactNode } from "react";
import colors from "src/theme/colors";

type Props = {
  children: ReactNode;
  requried?: boolean;
  noPrefix?: boolean;
  noPB?: boolean;
};

const FormInputLable = (props: Props) => {
  const { children, requried, noPrefix, noPB } = props;

  return (
    <Typography
      fontSize={14}
      fontWeight={400}
      color={colors.gray[850]}
      pb={!noPB ? 4 : 0}
    >
      {requried && !noPrefix ? (
        <span style={{ color: colors.error[900] }}>*&nbsp;</span>
      ) : null}
      {children}
      {!requried && !noPrefix ? (
        <span style={{ color: colors.gray[600] }}>&nbsp;(optional)</span>
      ) : null}
    </Typography>
  );
};

export default FormInputLable;
