import { styled } from "@mui/material/styles";
import { DrawerProps, Drawer } from "@mui/material";
import colors from "../../theme/colors";
const CustomDrawer = styled(Drawer)`
  borderwidth: 0px;
`;
const DRAWER_WIDTH = 300;
export const StyledDrawer = (props: DrawerProps) => {
  return (
    <CustomDrawer
      {...props}
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          boxSizing: "border-box",
          bgcolor: "#ffffff",
          borderWidth: 0,
        },
        border: "none",
      }}
    />
  );
};
