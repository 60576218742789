/* eslint-disable react/no-unescaped-entities */
import { FC } from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormInputLable from "src/components/FormInputLabel";
import { StyledTextField } from "src/components/Input";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import * as Yup from "yup";
import {
  patchCustomer,
  reset as resetCustomers,
} from "../redux/CustomersSlice";
import { GrayButton } from "src/components/GrayButton";
import { useNavigate } from "react-router-dom";
import {
  FileUploader,
  FileUploaderType,
} from "src/components/FileUploader/FileUploader";
import { UploadedFileType } from "src/components/FileUploader/UploadZone/UploadZone";
import FormHeading from "src/components/FormHeading";
import { StyledTextFieldMulti } from "src/components/MultilineInput";
import {
  useUploadNewFile,
  UseUploadNewFilesType,
} from "src/components/FileUploader/useUploadNewFile";
import { PaperBox } from "src/components/Paper/Paper";

interface CustomerEditForm {
  customerData: {
    id: number;
    code: string;
    full_name: string;
    company_name: string;
    mobile_number: string;
    phone_number: string;
    email: string;
    postal_code: string;
    address: string;
    notes: string;
    documents?: UploadedFileType[];
  };
}

export const CustomersEditForm: FC<CustomerEditForm> = (props) => {
  const navigate = useNavigate();

  const [uploadFilesLoading, uploadNewFiles] = useUploadNewFile(
    UseUploadNewFilesType.Multiple
  );
  const [files, setFiles] = useState<UploadedFileType[]>([]);
  //formik---------------------------------
  const CustomersSchema = Yup.object().shape({
    code: Yup.string().required(),
    fullName: Yup.string(),
    companyName: Yup.string(),
    mobileNumber: Yup.string(),
    phoneNumber: Yup.string(),
    email: Yup.string(),
    postalCode: Yup.string(),
    address: Yup.string(),
    notes: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      code: props?.customerData?.code,
      fullName: props?.customerData?.full_name ?? "",
      companyName: props?.customerData?.company_name ?? "",
      mobileNumber: props?.customerData?.mobile_number ?? "",
      phoneNumber: props?.customerData?.phone_number ?? "",
      email: props?.customerData?.email ?? "",
      postalCode: props?.customerData?.postal_code ?? "",
      address: props?.customerData?.address ?? "",
      notes: props?.customerData?.notes ?? "",
    },
    validationSchema: CustomersSchema,

    onSubmit: async (values: any) => {
      const patchValues = {
        id: props?.customerData?.id,
        code: values.code,
        full_name: values.fullName,
        company_name: values.companyName,
        mobile_number: values.mobileNumber,
        phone_number: values.phoneNumber,
        email: values.email,
        postal_code: values.postalCode,
        address: values.address,
        notes: values.notes,
      };

      uploadNewFiles(
        () => dispatch(patchCustomer(patchValues)),
        `customers/${props?.customerData?.id}`,
        files
      );
    },
  });

  const { errors, touched, values, resetForm, handleSubmit, getFieldProps } =
    formik;

  //redux ---------------------------------
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const { isPatchLoading, isError, isSuccess, patchMessage } = useSelector(
    (state: RootState) => state.customers
  );
  //redux ---------------------------------

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(patchMessage, { variant: "error" });
    }
    if (isSuccess) {
      enqueueSnackbar(patchMessage, { variant: "success" });
    }
    return () => {
      dispatch(resetCustomers());
    };
  }, [isError, isSuccess, patchMessage, dispatch]);

  useEffect(() => {
    const documents = props?.customerData?.documents ?? [];
    setFiles(documents as any);
  }, [props]);

  const onCancel = () => {
    navigate("/customers/customers-list");
    resetForm();
  };

  return (
    <>
      <FormikProvider value={formik}>
        <FormContainer>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <PaperBox>
              <Box mb={12}>
                <FormHeading>Product Information</FormHeading>
              </Box>
              <Grid
                container
                rowSpacing={12}
                columnSpacing={{ sm: 0, md: 20 }}
                md={10}
                sm={12}
              >
                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <FormInputLable requried>Customer Code</FormInputLable>
                    <StyledTextField
                      placeholder="Enter customer display name"
                      fullWidth
                      {...getFieldProps("code")}
                      error={Boolean(touched.code && errors.code)}
                      helperText={touched.code && errors.code}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <FormInputLable noPrefix>Full name</FormInputLable>
                    <StyledTextField
                      placeholder="Enter customer full name"
                      fullWidth
                      {...getFieldProps("fullName")}
                      error={Boolean(touched.fullName && errors.fullName)}
                      helperText={touched.fullName && errors.fullName}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <FormInputLable noPrefix>Company Name</FormInputLable>
                    <StyledTextField
                      placeholder="Enter customer company name"
                      fullWidth
                      {...getFieldProps("companyName")}
                      error={Boolean(touched.companyName && errors.companyName)}
                      helperText={touched.companyName && errors.companyName}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <FormInputLable noPrefix>Mobile number</FormInputLable>
                    <StyledTextField
                      placeholder="Enter customer mobile Number"
                      fullWidth
                      {...getFieldProps("mobileNumber")}
                      error={Boolean(
                        touched.mobileNumber && errors.mobileNumber
                      )}
                      helperText={touched.mobileNumber && errors.mobileNumber}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <FormInputLable noPrefix>Phone Number</FormInputLable>
                    <StyledTextField
                      placeholder="Enter customer phone number"
                      fullWidth
                      {...getFieldProps("phoneNumber")}
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <FormInputLable noPrefix>Email Address</FormInputLable>
                    <StyledTextField
                      placeholder="Enter customer email address"
                      fullWidth
                      {...getFieldProps("email")}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <FormInputLable noPrefix>Address</FormInputLable>
                    <StyledTextFieldMulti
                      placeholder="Enter customer address"
                      fullWidth
                      multiline={true}
                      {...getFieldProps("address")}
                      error={Boolean(touched.address && errors.address)}
                      helperText={touched.address && errors.address}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <FormInputLable noPrefix>Postal Code</FormInputLable>
                    <StyledTextField
                      placeholder="Enter customer postal code"
                      fullWidth
                      {...getFieldProps("postalCode")}
                      error={Boolean(touched.postalCode && errors.postalCode)}
                      helperText={touched.postalCode && errors.postalCode}
                    />
                  </Box>
                </Grid>
              </Grid>
            </PaperBox>

            <PaperBox>
              <Box mb={12}>
                <FormHeading>Notes & Files</FormHeading>
              </Box>
              <Grid
                container
                rowSpacing={12}
                columnSpacing={{ sm: 0, md: 20 }}
                md={10}
                sm={12}
              >
                <Grid item md={6} sm={12} xs={12}>
                  <FormInputLable noPrefix>Notes</FormInputLable>
                  <StyledTextFieldMulti
                    placeholder="Write something..."
                    fullWidth
                    multiline={true}
                    {...getFieldProps("notes")}
                    error={Boolean(touched.notes && errors.notes)}
                    helperText={touched.notes && errors.notes}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <FormInputLable noPrefix>Files</FormInputLable>
                  <FileUploader
                    type={FileUploaderType.Multiple}
                    maxFiles={5}
                    files={files}
                    deleteEndPoint={`customers/${props.customerData?.id}`}
                    setFiles={setFiles}
                  />
                </Grid>
              </Grid>
            </PaperBox>

            <Actions width="100%">
              <Box>
                <GrayButton
                  variant="outlined"
                  fullWidth
                  disabled={isPatchLoading || uploadFilesLoading}
                  onClick={onCancel}
                >
                  Cancel
                </GrayButton>
              </Box>
              <Box marginLeft={12}>
                <StyledLoadingButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  loading={isPatchLoading || uploadFilesLoading}
                  type="submit"
                >
                  <Typography
                    fontSize={16}
                    fontWeight={500}
                    color={colors.gray[850]}
                  >
                    Edit Customer
                  </Typography>
                </StyledLoadingButton>
              </Box>
            </Actions>
          </Form>
        </FormContainer>
      </FormikProvider>
    </>
  );
};

const FormContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
  & > form {
    width: 100%;
  }
`;

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  & > div {
    width: 184px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    & > div {
      width: 142px;
    }
  }
`;
