import { styled } from "@mui/material/styles";
import { FormHelperText, TextField, TextFieldProps } from "@mui/material";
import colors from "../../theme/colors";

interface StyledTextFieldProps {
  height?: string;
}

export const StyledTextField = (
  props: StyledTextFieldProps & TextFieldProps
) => {
  const inputValue = props.value as any;

  return (
    <CustomTextField
      color="secondary"
      height={props.height}
      {...props}
      sx={{
        color: colors.gray[850],
      }}
      isEmpty={
        typeof inputValue === "object" ? inputValue?.length === 0 : !inputValue
      }
    >
      {props.children}
      <FormHelperText
        style={{ color: colors.error[700], fontSize: 13, fontWeight: 400 }}
      >
        {props.helperText}
      </FormHelperText>
    </CustomTextField>
  );
};

interface StyledProps {
  isEmpty?: boolean;
  height?: string;
}

const CustomTextField = styled(TextField)<StyledProps>`
  background: ${colors.base.white};
  border-radius: 5px;
  color: ${colors.gray[850]}!important;

  & .MuiInputBase-root {
    height: ${({ height }) => (height ? height : "auto")};
  }

  & .MuiInputBase-input {
    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: ${colors.gray[500]};
    }
  }

  & .MuiSelect-nativeInput {
    opacity: ${({ isEmpty }) => (isEmpty ? "0.5" : "0")};
    height: 100%;
    padding: 0 14px;
    font-weight: 400;
    font-size: 14px;
    &::placeholder {
      color: ${colors.gray[500]};
    }
  }
`;
