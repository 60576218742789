import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProductsServices from "./ProductsServices";

const initialState = {
  isError: false,
  isProductDetailsError: false,
  isProductsExportError: false,
  isVariantsError: false,
  isSingleProductError: false,
  isDeleteError: false,
  isSearchError: false,
  isSuccess: false,
  isSearchSuccess: false,
  isProductDetailsSuccess: false,
  isProductsExportSuccess: false,
  isSingleProductSuccess: false,
  isDeleteSuccess: false,
  isVariantsLoading: false,
  isLoading: true,
  isCreateLoading: false,
  isPatchLoading: false,
  isDeleteLoading: false,
  isSearchLoading: false,
  isProductDetailsLoading: true,
  isProductsExportLoading: false,
  isSingleProductLoading: true,
  message: "",
  searchProductsMessage: "",
  productDetailsMessage: "",
  productsExportMessage: "",
  singleProductMessage: "",
  variantsMessage: "",
  deleteMessage: "",
  products: [],
  productsMeta: [],
  variants: [],
  singleProduct: [],
  searchedProducts: [],
  searchedProductsMeta: [],
  productDetails: [],
  productDetailsMeta: [],
};

export const getProducts = createAsyncThunk(
  "products",
  async (data: any, thunkAPI) => {
    try {
      return await ProductsServices.getProducts(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const exportProducts = createAsyncThunk(
  "products/export",
  async (data: any, thunkAPI) => {
    try {
      return await ProductsServices.exportProducts(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getProduct = createAsyncThunk(
  "products/id",
  async (id: number, thunkAPI) => {
    try {
      return ProductsServices.getProduct(id);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const postProduct = createAsyncThunk(
  "products/post",
  async (data: any, thunkAPI) => {
    try {
      return await ProductsServices.postProduct(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const patchProduct = createAsyncThunk(
  "products/patch",
  async (data: any, thunkAPI) => {
    try {
      return await ProductsServices.patchProduct(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteProduct = createAsyncThunk(
  "products/delete",
  async (product_ids: number[], thunkAPI) => {
    try {
      return await ProductsServices.deleteProduct(product_ids);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const serachProducts = createAsyncThunk(
  "Warehouses/products-search",
  async (data: any, thunkAPI) => {
    try {
      return await ProductsServices.serachProducts(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const exportSerachProducts = createAsyncThunk(
  "Warehouses/export-products-search",
  async (data: any, thunkAPI) => {
    try {
      return await ProductsServices.exportSerachProducts(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getProductDetails = createAsyncThunk(
  "Warehouses/product-details",
  async (data: any, thunkAPI) => {
    try {
      return await ProductsServices.getProductDetails(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getIndexVariants = createAsyncThunk(
  "products/index-variants",
  async (data: any, thunkAPI) => {
    try {
      return await ProductsServices.getIndexVariants();
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isCreateLoading = false;
      state.isPatchLoading = false;
      state.isDeleteLoading = false;
      state.isSearchLoading = false;
      state.isVariantsLoading = false;
      state.isProductDetailsLoading = true;
      state.isProductsExportLoading = false;
      state.isSingleProductLoading = true;
      state.isSuccess = false;
      state.isSearchSuccess = false;
      state.isProductDetailsSuccess = false;
      state.isProductsExportSuccess = false;
      state.isSingleProductSuccess = false;
      state.isDeleteSuccess = false;
      state.isError = false;
      state.isProductDetailsError = false;
      state.isProductsExportError = false;
      state.isVariantsError = false;
      state.isSingleProductError = false;
      state.isDeleteError = false;
      state.isSearchError = false;
      state.message = "";
      state.searchProductsMessage = "";
      state.productDetailsMessage = "";
      state.productsExportMessage = "";
      state.variantsMessage = "";
      state.singleProductMessage = "";
      state.deleteMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.products = action.payload.data.results;
        state.productsMeta = action.payload.data.meta;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })
      .addCase(exportProducts.pending, (state) => {
        state.isProductsExportLoading = true;
      })
      .addCase(exportProducts.fulfilled, (state, action) => {
        state.isProductsExportLoading = false;
        state.isProductsExportSuccess = true;
        state.searchProductsMessage = `${action.payload.data.message}`;
      })
      .addCase(exportProducts.rejected, (state, action) => {
        state.isProductsExportLoading = false;
        state.isProductsExportError = true;
        state.isProductsExportSuccess = false;
        state.searchProductsMessage = `${action.payload}`;
      })

      .addCase(getProduct.pending, (state) => {
        state.isSingleProductLoading = true;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.isSingleProductLoading = false;
        state.isSingleProductSuccess = true;
        state.singleProduct = action.payload.data.results;
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.isSingleProductLoading = false;
        state.isSingleProductError = true;
        state.singleProductMessage = `${action.payload}`;
      })

      .addCase(postProduct.pending, (state) => {
        state.isLoading = true;
        state.isCreateLoading = true;
      })
      .addCase(postProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(postProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreateLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(patchProduct.pending, (state) => {
        state.isLoading = true;
        state.isPatchLoading = true;
      })
      .addCase(patchProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isPatchLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(patchProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isPatchLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(deleteProduct.pending, (state) => {
        state.isDeleteLoading = true;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteSuccess = true;
        state.deleteMessage = `${action.payload.data.message}`;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.deleteMessage = `${action.payload}`;
      })

      .addCase(serachProducts.pending, (state) => {
        state.isSearchLoading = true;
      })
      .addCase(serachProducts.fulfilled, (state, action) => {
        state.isSearchLoading = false;
        state.isSearchSuccess = true;
        state.searchedProducts = action.payload.data.results;
        state.searchedProductsMeta = action.payload.data.meta;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(serachProducts.rejected, (state, action) => {
        state.isSearchLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })
      .addCase(getProductDetails.pending, (state) => {
        state.isProductDetailsLoading = true;
      })
      .addCase(getProductDetails.fulfilled, (state, action) => {
        state.isProductDetailsLoading = false;
        state.isProductDetailsSuccess = true;
        state.productDetails = action.payload.data.results;
        state.productDetailsMeta = action.payload.data.meta;
        state.productDetailsMessage = `${action.payload.data.message}`;
      })
      .addCase(getProductDetails.rejected, (state, action) => {
        state.isProductDetailsLoading = false;
        state.isProductDetailsError = true;
        state.isProductDetailsSuccess = false;
        state.productDetailsMessage = `${action.payload}`;
      })

      .addCase(exportSerachProducts.pending, (state) => {
        state.isProductsExportLoading = true;
      })
      .addCase(exportSerachProducts.fulfilled, (state, action) => {
        state.isProductsExportLoading = false;
        state.isProductsExportSuccess = true;
        state.searchProductsMessage = `${action.payload.data.message}`;
      })
      .addCase(exportSerachProducts.rejected, (state, action) => {
        state.isProductsExportLoading = false;
        state.isProductsExportError = true;
        state.isProductsExportSuccess = false;
        state.searchProductsMessage = `${action.payload}`;
      })

      .addCase(getIndexVariants.pending, (state) => {
        state.isVariantsLoading = true;
      })
      .addCase(getIndexVariants.fulfilled, (state, action) => {
        state.isVariantsLoading = false;
        state.variants = action.payload.data.results;
        state.variantsMessage = `${action.payload.data.message}`;
      })
      .addCase(getIndexVariants.rejected, (state, action) => {
        state.isVariantsLoading = false;
        state.isVariantsError = true;
        state.variantsMessage = `${action.payload}`;
      });
  },
});

export const { reset } = productsSlice.actions;
export default productsSlice.reducer;
