import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import colors from "src/theme/colors";
import { IconButton, MenuItem, styled } from "@mui/material";
import { StyledButton } from "src/components/Button";
import FormInputLable from "src/components/FormInputLabel";
import { StyledTextField } from "src/components/Input";
import Loading from "src/components/Loading";
import { GrayButton } from "src/components/GrayButton";
import { StyledLoadingButton } from "src/components/LoadingButton";

type Props = {
  qualities: any;
  iconName: string;
  title: string;
  open: boolean;
  isLoading?: boolean;
  isButtonLoading: boolean;
  selected: any;
  onApprove: (quality: string) => void;
  onDismiss: () => void;
};

export const SetQualityResultModal = (props: Props) => {
  const {
    qualities,
    iconName,
    title,
    selected,
    open,
    isLoading,
    isButtonLoading,
    onApprove,
    onDismiss,
  } = props;

  const [selectedQuality, setSelectedQuality] = useState("");

  const onCancel = () => {
    setSelectedQuality("");
    onDismiss();
  };

  return (
    <div>
      <Modal open={open} onClose={onDismiss}>
        <Wrapper>
          <Content>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <Head>
                  <IconButton onClick={onDismiss}>
                    <img
                      src={process.env.PUBLIC_URL + `/assets/icons/close.svg`}
                      alt={"close"}
                    />
                  </IconButton>
                </Head>
                <StyledInner>
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/icons/${iconName}.svg`
                    }
                    alt={iconName}
                  />
                  <Box pt={10} pb={10}>
                    <Typography
                      fontSize={18}
                      fontWeight={700}
                      color={colors.gray[850]}
                    >
                      {title}
                    </Typography>
                  </Box>
                </StyledInner>
                <Typography
                  fontSize={15}
                  fontWeight={400}
                  color={colors.gray[850]}
                  textAlign="center"
                >
                  Enter the result of international inspection
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    ”{selected.title}”{" "}
                  </span>
                </Typography>
                <Box mt={20}>
                  <FormInputLable noPrefix>Quality Result</FormInputLable>
                  <StyledTextField
                    fullWidth
                    select
                    placeholder="Select Quality Result"
                    value={selectedQuality}
                    onChange={(e) => setSelectedQuality(e.target.value)}
                  >
                    {qualities?.map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.title}
                        </MenuItem>
                      );
                    })}
                  </StyledTextField>
                </Box>
                <Buttons>
                  <GrayButton
                    variant="outlined"
                    color="error"
                    fullWidth
                    disabled={isButtonLoading}
                    onClick={onCancel}
                  >
                    Cancel
                  </GrayButton>
                  <StyledLoadingButton
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!selectedQuality}
                    loading={isButtonLoading}
                    onClick={() => onApprove(selectedQuality)}
                  >
                    <Typography fontSize={15} fontWeight={500}>
                      Approve Inspection
                    </Typography>
                  </StyledLoadingButton>
                </Buttons>
              </>
            )}
          </Content>
        </Wrapper>
      </Modal>
    </div>
  );
};

const Wrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  min-width: 500px;
  max-width: 500px;
  height: fit-content;
  max-height: 90vh;
  overflow: auto;
  background-color: ${colors.base.white};
  border: 1px solid ${colors.gray[200]};
  border-radius: 5px;
  padding: 28px 32px;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 90%;
    min-width: auto;
  }
`;

const Content = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledInner = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Head = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Buttons = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  gap: 16px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column-reverse;
  }
`;
