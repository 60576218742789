import { useSnackbar } from "notistack";
import { useState } from "react";
import { axios } from "src/config/Axios";
import { UploadedFileType } from "./UploadZone/UploadZone";

export enum UseUploadNewFilesType {
  Multiple,
  Single,
}

export const useUploadNewFile = (
  type: UseUploadNewFilesType = UseUploadNewFilesType.Single
) => {
  const { enqueueSnackbar } = useSnackbar();
  const [uploadFilesLoading, setUploadFilesLoading] = useState(false);

  const uploadNewFiles = (
    fn: () => void,
    endPoint: string,
    files: UploadedFileType[]
  ) => {
    const filteredFiles = files.filter((file) => file.id == null);

    const formData = new FormData();
    if (filteredFiles.length > 0) {
      setUploadFilesLoading(true);

      if (type === UseUploadNewFilesType.Multiple) {
        filteredFiles.forEach((file, index) =>
          formData.append(`files[${index}]`, file as any, file.name)
        );
      } else {
        formData.append(`file`, files[0] as any, files[0].name);
      }

      axios
        .post(`/${endPoint}/documents`, formData)
        .then((res) => {
          if (res.data.message === "Successfully Created") {
            fn();
          } else {
            enqueueSnackbar("Failed to upload files, please try again", {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error?.response.data.message, { variant: "error" });
        })
        .finally(() => setUploadFilesLoading(false));
    } else {
      setUploadFilesLoading(false);
      fn();
    }
  };

  const result = [uploadFilesLoading, uploadNewFiles] as const;
  return result;
};
