import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import { FC, useEffect, useState } from "react";
import Loading from "src/components/Loading";
import colors from "src/theme/colors";

interface ListBoundaryProps {
  miniPage?: boolean;
  isEmpty?: boolean;
  isDataLoading?: boolean;
  fallbackImageURL: string;
  description: string;
  buttonLabel?: string;
  children: any;
  onClick?: () => void;
}

export const ListBoundary: FC<ListBoundaryProps> = (props) => {
  const {
    miniPage,
    isEmpty,
    isDataLoading,
    fallbackImageURL,
    description,
    buttonLabel,
    children,
    onClick,
  } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isDataLoading) {
      setLoading(false);
    }
    return () => {
      setLoading(true);
    };
  }, [isDataLoading, isEmpty]);

  if (loading) {
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    );
  } else {
    if (isEmpty) {
      return (
        <Wrapper miniPage={miniPage}>
          <Image>
            <img src={process.env.PUBLIC_URL + fallbackImageURL} />
          </Image>
          <Desc>{description}</Desc>
          {buttonLabel ? (
            <Button variant="contained" onClick={onClick}>
              {buttonLabel}
            </Button>
          ) : null}
        </Wrapper>
      );
    } else {
      return children;
    }
  }
};

interface StyledProps {
  miniPage?: boolean;
}

const Wrapper = styled.div<StyledProps>`
  width: 100%;
  height: ${({ miniPage }) =>
    miniPage ? "calc(100vh - 340px)" : "calc(100vh - 160px)"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Image = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Desc = muiStyled(Typography)({
  color: colors.gray[600],
  textAlign: "center",
  fontSize: "18px",
  margin: "40px 0 24px 0",
});
