import { axios } from "../../../config/Axios";

//customers get
const getCustomers = async (data: any) => {
  const response = await axios.get(
    `/customers?page=${data.page}${
      data?.search ? `&full_name=${data?.search}` : ""
    }`
  );
  return response;
};
//customers EXPORT
const exportCustomers = async () => {
  const response = await axios({
    url: `/customers?export_excel=true`,
    method: "get",
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${Date.now()}.xlsx`);
  document.body.appendChild(link);
  link.click();

  return response;
};
//customers get by id
const getCustomerId = async (id: number) => {
  const response = await axios.get(`/customers/${id}`);
  return response;
};
//customers create
const addCustomer = async (data: any) => {
  const response = await axios.post("/customers", data);
  return response;
};
//customers patch
const patchCustomer = async (data: any) => {
  const response = await axios.patch(`/customers/${data.id}`, data);
  return response;
};
//customers delete
const deleteCustomer = async (id: number) => {
  const response = await axios.delete(`/customers/${id}`);
  return response;
};

//contact get by id
const getContactId = async (customerId: number) => {
  const response = await axios.get(`/customers/${customerId}/contacts`);
  return response;
};
const deleteContact = async (contactDeleteData: any) => {
  const response = await axios.delete(
    `/customers/${contactDeleteData.customerId}/contacts/${contactDeleteData.contactId}`
  );
  return response;
};
//contact submit
const postContact = async (contactData: any) => {
  const response = await axios.post(
    `/customers/${contactData.customerId}/contacts`,
    contactData
  );
  return response;
};
const patchContact = async (contactPatchData: any) => {
  const response = await axios.patch(
    `/customers/${contactPatchData.customerId}/contacts/${contactPatchData.contactId}`,
    contactPatchData
  );
  return response;
};

const Customerservices = {
  getCustomers,
  exportCustomers,
  getCustomerId,
  addCustomer,
  patchCustomer,
  deleteCustomer,
  getContactId,
  deleteContact,
  postContact,
  patchContact,
};
export default Customerservices;
