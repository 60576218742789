import { Route, Routes } from "react-router-dom";
import OrderEdit from "../pages/OrderEdit";
import OrderInfo from "../pages/OrderInfo";
import OrderNew from "../pages/OrderNew";
import OrderTable from "../pages/OrderTable";

export const OrdersRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/orders-list" element={<OrderTable />} />
        <Route path="/order-new" element={<OrderNew />} />
        <Route path="/order-edit" element={<OrderEdit />} />
        <Route path="/order-information" element={<OrderInfo />} />
      </Routes>
    </>
  );
};
