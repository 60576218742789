import { useSnackbar } from "notistack";
import { useState } from "react";
import { axios } from "src/config/Axios";

export const useDeleteFile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const deleteFileFromApi = (
    fn: () => void,
    endPoint: string,
    fileId: string
  ) => {
    setLoading(true);

    axios
      .delete(`/${endPoint}/documents/${fileId}`)
      .then((res) => {
        if (res.data.message === "Success") {
          fn();
        } else {
          enqueueSnackbar("Failed to delete file, please try again", {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error?.response.data.message, { variant: "error" });
      })
      .finally(() => setLoading(false));
  };

  const result = [loading, deleteFileFromApi] as const;
  return result;
};
