import styled from "@emotion/styled";
import {
  Modal,
  Box,
  IconButton,
  Typography,
  Avatar,
  Button,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledTextField } from "src/components/Input";
import Loading from "src/components/Loading";
import { me } from "src/features/auth/redux/authSlice";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import { getChats, postChats, reset } from "../../redux/chatSlice";
import MessageLeft from "../MessageLeft";
import MessageRight from "../MessageRight";

type Props = {
  userId: string;
  name: string;
  avatar: string;
  open: boolean;
  onDismiss: (open: boolean) => void;
};

const Chat = (props: Props) => {
  const { userId, name, avatar, onDismiss, open } = props;
  const [Message, setMessage] = useState("");
  const [data, setData] = useState([] as any);
  const [User, setUser] = useState([] as any);
  const [show, setShow] = React.useState(false);
  const intervalRef = useRef<any>();
  const { chats, isLoading, isError, isSuccess, message } = useSelector(
    (state: RootState) => state.chat
  );
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      setData(chats);
      setUser(user);
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, isSuccess, message, dispatch]);

  useEffect(() => {
    if (userId && open) {
      const data = {
        userId: userId,
      };
      dispatch(me()).then(() => {
        intervalRef.current = setInterval(() => {
          dispatch(getChats(data));
        }, 5000);
      });
    } else {
      console.log("loading");
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [userId, open]);

  const onsubmit = async () => {
    const data = {
      userId: userId,
      message: Message,
    };
    await dispatch(postChats(data));
    const data2 = {
      userId: userId,
    };
    return await dispatch(getChats(data2)).then(() => setMessage(""));
  };

  const bottomRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [data]);

  React.useEffect(() => {
    setShow(open);
  }, [open]);

  return (
    <Modal
      open={show}
      onClose={() => onDismiss(show)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40%",
          minWidth: 300,
          height: "fit-content",
          bgcolor: colors.base.white,
          border: `1px solid ${colors.gray[200]}`,
          borderRadius: "5px",
        }}
      >
        <StyledOuter>
          <StyledOuter>
            <Avatar sx={{ height: 48, width: 48 }}>
              <img src={avatar} alt={name} style={{ height: 48, width: 48 }} />
            </Avatar>
            <Typography
              fontSize={14}
              fontWeight="500"
              color={colors.gray[850]}
              pl={4}
            >
              {name}
            </Typography>
          </StyledOuter>
          <IconButton onClick={() => onDismiss(show)}>
            <img
              src={process.env.PUBLIC_URL + `/assets/icons/close.svg`}
              alt={"close"}
            />
          </IconButton>
        </StyledOuter>
        <StyledInner>
          {isLoading && data?.length === 0 ? (
            <Loading />
          ) : (
            <>
              {data?.map((item: any) => {
                if (item?.user?.id === User?.id) {
                  return (
                    <MessageRight
                      message={item?.message}
                      key={item?.id}
                      time={item?.time}
                      date={item?.date}
                    />
                  );
                } else {
                  return (
                    <MessageLeft
                      message={item?.message}
                      key={item?.id}
                      time={item?.time}
                      date={item?.date}
                    />
                  );
                }
              })}
            </>
          )}
          <div ref={bottomRef} />
        </StyledInner>
        <StyledTextField
          fullWidth
          style={{
            backgroundColor: "#F8F8F8",
            padding: 10,
            height: "100% !important",
          }}
          value={Message}
          onChange={(e) => setMessage(e.target.value)}
          variant="standard"
          size="small"
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <Button
                variant="contained"
                size="small"
                color="secondary"
                onClick={() => onsubmit()}
              >
                send
              </Button>
            ),
          }}
        />
      </Box>
    </Modal>
  );
};

export default Chat;

const StyledOuter = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  background: #f8f8f8;
  border-bottom: 1px solid #eaecef;
  padding: 10px;
`;
const StyledInner = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 400px;
`;
