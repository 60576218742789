import {
  Box,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  styled,
  css,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { GridColDef } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { StyledButton } from "src/components/Button";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";
import StyledTable from "src/components/Table";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import {
  deleteTruck,
  getTrucks,
  reset,
} from "src/features/warehouses/redux/TruckSlice";
// import TruckSelectModal from "./modals/TruckSelectModal";
// import { WeightBridgeViewModal } from "./modals/WeightBridgeViewModal";
// import { DriverLicenceViewModal } from "./modals/DriverLicenceViewModal";
import { getVendors } from "src/features/vendors/redux/VendorSlice";
import ConfirmModal from "src/components/Modal";
import { PaperBox } from "src/components/Paper/Paper";

interface Props {
  id: string;
}

const ReceivedTrucks = (props: Props) => {
  const navigate = useNavigate();
  const { id } = props;

  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState([] as any);
  const [meta, setMeta] = useState([] as any);
  const [filterValue, setFilterValue] = useState([] as any);
  const [page, setPage] = useState(1);
  const [truckIds, setTruckIds] = useState([]);

  // view modals
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [isWeightModalOpen, setIsWeightModalOpen] = useState(false);
  const [isLicenceModalOpen, setIsLicenceModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  //redux -----------------------------------
  const { vendors } = useSelector((state: RootState) => state.vendor);

  const {
    Trucks,
    TrucksMeta,
    isLoading,
    isConfirmLoading,
    isDeleteLoading,
    isError,
    isConfirmError,
    isDeleteError,
    isSuccess,
    isConfirmSuccess,
    isDeleteSuccess,
    message,
    confirmMessage,
    deleteMessage,
  } = useSelector((state: RootState) => state.trucks);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      setData(Trucks);
      setMeta(TrucksMeta);
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, isSuccess, message, dispatch]);

  useEffect(() => {
    if (isConfirmError) {
      enqueueSnackbar(confirmMessage, { variant: "error" });
    }
    if (isConfirmSuccess) {
      enqueueSnackbar(confirmMessage, { variant: "success" });
    }
    return () => {
      dispatch(reset());
    };
  }, [isConfirmError, isConfirmSuccess, confirmMessage, dispatch]);

  useEffect(() => {
    if (isDeleteError) {
      enqueueSnackbar(deleteMessage, { variant: "error" });
    }
    if (isDeleteSuccess) {
      enqueueSnackbar(deleteMessage, { variant: "success" });
      dispatch(
        getTrucks({
          warehouse_id: Number(id),
          page: page,
        })
      );
      setIsDeleteModalOpen(false);
      setSelectedId(0);
    }
    return () => {
      dispatch(reset());
    };
  }, [isDeleteError, isDeleteSuccess, deleteMessage, dispatch]);

  useEffect(() => {
    const infoValue = {
      warehouse_id: Number(id),
      page: page,
    };
    dispatch(getTrucks(infoValue));
  }, [page]);

  useEffect(() => {
    dispatch(getVendors({ limit: 100, page: 1 }));
  }, []);

  const onDeleteTruck = (selected: number) => {
    dispatch(
      deleteTruck({
        warehouseId: Number(id),
        truckId: Number(selected),
      })
    );
  };

  const actionsColumn: GridColDef = {
    field: "Actions",
    headerName: "Actions",
    flex: 0.3,
    minWidth: 100,
    sortable: false,
    filterable: false,
    hideable: false,
    renderCell: (params) => (
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        flexDirection="row"
      >
        <IconButton
          aria-label="more"
          id="action-button"
          aria-controls={openMenu ? "action-menu" : undefined}
          aria-expanded={openMenu ? "true" : undefined}
          aria-haspopup="true"
          onClick={(e: any) => {
            setSelectedRowData(params?.row);
            handleClickMenu(e);
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="action-menu"
          MenuListProps={{
            "aria-labelledby": "action-button",
          }}
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleCloseMenu}
          PaperProps={{
            style: {
              width: "auto",
            },
          }}
        >
          <MenuItem
            key={1}
            onClick={() => {
              setIsWeightModalOpen(true);
              handleCloseMenu();
            }}
            sx={{ p: 6, color: colors.secondary[800] }}
          >
            Review Weighbridge
          </MenuItem>
          <MenuItem
            key={2}
            onClick={() => {
              setIsLicenceModalOpen(true);
              handleCloseMenu();
            }}
            sx={{ p: 6, color: colors.secondary[800] }}
          >
            Review Driver Licence
          </MenuItem>
        </Menu>
        {/* <IconButton
            onClick={() => {
              setSelectedId(Number(params?.id));
              setIsDeleteModalOpen(true);
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Remove.svg"}
              alt="delete"
            />
          </IconButton> */}
      </Box>
    ),
  };

  const baseColumns: GridColDef[] = [
    {
      field: "numberplate",
      headerName: "Truck Number",
      flex: 1,
      minWidth: 140,
      filterable: false,
    },
    {
      field: "vendor",
      headerName: "Vendor",
      flex: 0.6,
      minWidth: 100,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.vendor?.code}
        </Box>
      ),
    },
    {
      field: "full_weight",
      headerName: "Full Weight(Kg)",
      flex: 1,
      minWidth: 140,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.full_weight ? params.row?.full_weight * 1000 : 0}
        </Box>
      ),
    },
    {
      field: "empty_weight",
      headerName: "Empty Weight(Kg)",
      flex: 1,
      minWidth: 160,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.empty_weight ? params.row?.empty_weight * 1000 : 0}
        </Box>
      ),
    },
    {
      field: "net_weight",
      headerName: "Net Weight(Kg)",
      flex: 1,
      minWidth: 140,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.net_weight ? params.row?.net_weight * 1000 : 0}
        </Box>
      ),
    },

    {
      field: "order",
      headerName: "Purchase Order",
      flex: 1,
      minWidth: 140,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.order?.code}
        </Box>
      ),
    },
  ];

  const columns: GridColDef[] = baseColumns.concat(actionsColumn);

  //TruckSelectModal -----------------------
  const [open2, setOpen2] = useState(false);
  const handelOpen2 = (open: boolean) => {
    setOpen2(!open2);
  };
  const onClose2 = () => {
    setOpen2(false);
    const infoValue = {
      warehouse_id: Number(id),
      page: page,
    };
    dispatch(getTrucks(infoValue));
  };
  const onConfirm2 = (values: any) => {
    setTruckIds(values);
    onClose2();
    handelOpen3(open3);
  };

  //LocalInspectionModal ------------------
  const [open3, setOpen3] = useState(false);
  const handelOpen3 = (open: boolean) => {
    setOpen3(!open3);
  };
  const onClose3 = () => {
    setOpen3(false);
  };
  const onConfirm3 = (values: any) => {
    setFilterValue(values);
  };

  const onConfirmTruckComplete = () => {
    const infoValue = {
      warehouse_id: Number(id),
      page: page,
    };
    dispatch(getTrucks(infoValue));
    setIsWeightModalOpen(false);
  };

  return (
    <PaperBox>
      <ListBoundary
        isEmpty={data.length === 0 || !data}
        isDataLoading={isLoading}
        fallbackImageURL="/assets/images/emptyStates/Trucks-List EmptyState.svg"
        description="Wait for the truck to arrive at the warehouse."
      >
        <StyledTable
          disableColumnMenu
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableVirtualization
          disableSelectionOnClick
          loading={isLoading}
          columns={columns}
          experimentalFeatures={{ newEditingApi: true }}
          rows={data}
          onPageChange={(e) => setPage(e + 1)}
          page={page - 1}
          paginationMode="server"
          rowCount={meta?.pagination?.total_items}
        />
      </ListBoundary>

      {/* <WeightBridgeViewModal
        warehouseId={Number(id)}
        data={selectedRowData}
        vendors={vendors}
        open={isWeightModalOpen}
        loading={isConfirmLoading}
        onDismiss={() => setIsWeightModalOpen(false)}
        onConfirm={onConfirmTruckComplete}
      />
      <DriverLicenceViewModal
        imageUrl={selectedRowData?.driver_license}
        open={isLicenceModalOpen}
        onDismiss={() => setIsLicenceModalOpen(false)}
      />
      {open2 ? (
        <TruckSelectModal
          title={"Local Inspection Request"}
          iconName="Inspection"
          filterValue={filterValue}
          open={open2}
          onDismiss={() => onClose2()}
          onConfirm={(values: any) => onConfirm2(values)}
          confrimText={"Remove"}
          cancelText={"Cancel"}
          warehouseId={id}
        />
      ) : null} */}
      <ConfirmModal
        title={"Remove Truck"}
        iconName={"ModalRemove"}
        open={isDeleteModalOpen}
        isLoading={isDeleteLoading}
        onDismiss={() => setIsDeleteModalOpen(false)}
        onConfirm={() => onDeleteTruck(selectedId)}
        confrimText="Remove"
        cancelText="Cancel"
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to remove this Truck?
        </Typography>
      </ConfirmModal>
    </PaperBox>
  );
};

const MainHeader = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: column;
    align-items: normal;
  }
`;

const StyledHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.theme.breakpoints.down("md")} {
    margin-bottom: 16px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FiltersHead = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16px;
    margin-bottom: 16px;
    gap: 12px;
  }
`;

const UnconfFilter = styled(Box)`
  margin-left: 12px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-left: 0;
  }
`;

const StyledAcitve = styled(Box)`
  background-color: ${colors.gray[50]};
  border-radius: 4px;
  padding: 9px 24px;
`;

export default ReceivedTrucks;
