import base from "./base";
import gray from "./gray";
import error from "./error";
import primary from "./primary";
import secondary from "./secondary";
import success from "./success";
import midnight from "./midnight";

export default {
  base,
  gray,
  error,
  primary,
  secondary,
  success,
  midnight,
};
