import { PageTemplate } from "src/layout/components/PageTemplate";
import VendorNewForm from "../components/VendorNewForm";

const VendorNew = () => {
  return (
    <PageTemplate
      isTransparent
      crumbs={[
        {
          label: "vendors",
          href: "/vendors/vendors-list",
          depth: -1,
        },
        {
          label: "new vendor",
        },
      ]}
    >
      <VendorNewForm />
    </PageTemplate>
  );
};

export default VendorNew;
