import styled from "@emotion/styled";
import { styled as styledMui } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { PageTemplate } from "src/layout/components/PageTemplate";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import { EmployeeCardsBox } from "../components/infos/EmployeeCardsBox/EmployeeCardsBox";
import { InventoryCardsBox } from "../components/infos/InventoryCardsBox/InventoryCardsBox";
import { AddNewEmployeeModal } from "../components/modals/AddNewEmployeeModal";
import { WarehouseDetailsCard } from "../components/infos/WarehouseDetailsCard";
import ReceivedTrucks from "../components/ReceivedTrucks";
import {
  reset as resetWarehouseData,
  getWarehousesId,
  deleteSingleWarehouse,
  getWarehouseInventories,
} from "../redux/WarehouseSlice";
import {
  deleteWarehouseUsers,
  postWarehouseUsers,
  reset as resetWarehouseUsers,
} from "../redux/WarehouseUserSlice";
import ConfirmModal from "src/components/Modal";
import { Box, Typography } from "@mui/material";
import {
  FileUploaderViewer,
  FileUploaderViewerSingleFileType,
  FileUploaderViewerType,
} from "src/components/FileUploader/FileUploaderViewer/FileUploaderViewer";

interface CustomizedState {
  id: string;
  data: any;
}

const WarehouseInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state as CustomizedState;
  const { id, data: warehouseInfo } = state;

  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [warehouseData, setWarehouseData] = useState([] as any);
  const [warehouseInventoriesData, setWarehouseInventoriesData] = useState(
    null as any
  );
  const [selectedEmployeeCardId, setSelectedEmployeeCardId] =
    useState<string>("");

  const [isAddNewEmployeeModalOpen, setIsAddNewEmployeeModalOpen] =
    useState(false);
  const [isDeleteEmployeeModalOpen, setIsDeleteEmployeeModalOpen] =
    useState(false);

  //redux -----------------------------------
  const {
    singleWarehouse: singleWarehouseData,
    inventories,
    isSingleLoading: isWarehouseDataLoading,
    isDeleteLoading: isWarehouseDataDeleteLoading,
    isInventoriesLoading,
    isError: isWarehouseDataError,
    isInventoriesError,
    isSuccess: isWarehouseDataSuccess,
    isInventoriesSuccess,
    message: WarehouseDataMessage,
  } = useSelector((state: RootState) => state.warehouse);

  const {
    isPatchLoading: isWarehouseUsersPatchLoading,
    isError: isWarehouseUsersError,
    isSuccess: isWarehouseUsersSuccess,
    message: WarehouseUsersMessage,
  } = useSelector((state: RootState) => state.warehouseUsers);

  useEffect(() => {
    if (isWarehouseDataError) {
      enqueueSnackbar(WarehouseDataMessage, { variant: "error" });
    }
    if (isWarehouseDataSuccess) {
      setWarehouseData(singleWarehouseData);
    }
    return () => {
      dispatch(resetWarehouseData());
    };
  }, [
    isWarehouseDataError,
    isWarehouseDataSuccess,
    WarehouseDataMessage,
    isWarehouseDataLoading,
    dispatch,
  ]);

  useEffect(() => {
    if (isInventoriesError) {
      setWarehouseInventoriesData([]);
    }
    if (isInventoriesSuccess) {
      setWarehouseInventoriesData(inventories ?? []);
    }
  }, [
    isInventoriesLoading,
    isInventoriesError,
    isInventoriesSuccess,
    dispatch,
  ]);

  useEffect(() => {
    if (isWarehouseUsersError) {
      enqueueSnackbar(WarehouseUsersMessage, { variant: "error" });
    }
    if (isWarehouseUsersSuccess) {
      enqueueSnackbar(WarehouseUsersMessage, { variant: "success" });
    }
    return () => {
      dispatch(resetWarehouseUsers());
    };
  }, [
    isWarehouseUsersError,
    isWarehouseUsersSuccess,
    WarehouseUsersMessage,
    dispatch,
  ]);

  useEffect(() => {
    const getWarehouseInfo = {
      id: Number(id),
    };
    dispatch(getWarehousesId(getWarehouseInfo));
    dispatch(
      getWarehouseInventories({
        warehouseId: Number(id),
      })
    );

    return () => {
      dispatch(resetWarehouseData());
    };
  }, []);

  const onAssignNewEmployee = (employeeId: number) => {
    const postUserInfo = {
      warehouseId: Number(id),
      user_ids: [employeeId],
    };
    dispatch(postWarehouseUsers(postUserInfo))
      .then(() =>
        dispatch(
          getWarehousesId({
            id: Number(id),
          })
        )
      )
      .then(() => setIsAddNewEmployeeModalOpen(false));
  };

  const onUnAssignEmployee = () => {
    const selectedId = Number(selectedEmployeeCardId);
    const deleteUserInfo = {
      warehouseId: Number(id),
      user_ids: [selectedId],
    };

    dispatch(deleteWarehouseUsers(deleteUserInfo))
      .then(() =>
        dispatch(
          getWarehousesId({
            id: Number(id),
          })
        )
      )
      .then(() => setIsDeleteEmployeeModalOpen(false));
  };

  return (
    <>
      <PageTemplate
        isTransparent
        crumbs={[
          {
            label: "warehouses",
            href: "/warehouses/warehouse-list",
            depth: -1,
          },
          {
            label: warehouseInfo?.title,
          },
        ]}
      >
        <WarehouseDetailsCard
          title={warehouseInfo?.title}
          code={warehouseInfo?.code}
          phone={warehouseInfo?.phone}
          address={warehouseInfo?.address}
          onEditWarehouse={() =>
            navigate("/Warehouses/Warehouse-edit", {
              state: { data: warehouseData },
            })
          }
        />
        <CardsRow>
          <Inventories>
            <InventoryCardsBox
              loading={isInventoriesLoading}
              warehouseId={id}
              warehouseTitle={warehouseInfo?.title}
              inventories={warehouseInventoriesData}
            />
          </Inventories>
          <Employees>
            <EmployeeCardsBox
              loading={isWarehouseDataLoading}
              employees={warehouseData?.users}
              onAddNewEmployee={() => setIsAddNewEmployeeModalOpen(true)}
              // onRemoveEmployee={(employeeId: string) => {
              //   setSelectedEmployeeCardId(employeeId);
              //   setIsDeleteEmployeeModalOpen(true);
              // }}
              onRemoveEmployee={(employeeId: string) => {
                setSelectedEmployeeCardId(employeeId);
                setIsDeleteEmployeeModalOpen(false);
              }}
            />
          </Employees>
        </CardsRow>
        <StyledBack>
          <ReceivedTrucks id={id} />
        </StyledBack>
      </PageTemplate>

      <AddNewEmployeeModal
        buttonLoading={isWarehouseUsersPatchLoading}
        warehouseName={warehouseInfo?.title}
        open={isAddNewEmployeeModalOpen}
        onConfirm={(selectedEmployeeId: number) =>
          onAssignNewEmployee(selectedEmployeeId)
        }
        onDismiss={() => setIsAddNewEmployeeModalOpen(false)}
      />
      <ConfirmModal
        title="Remove Employee"
        iconName="ModalRemove"
        open={isDeleteEmployeeModalOpen}
        isLoading={isWarehouseUsersPatchLoading}
        onDismiss={() => setIsDeleteEmployeeModalOpen(false)}
        onConfirm={onUnAssignEmployee}
        confrimText="Remove"
        cancelText="Cancel"
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to remove this Employee?
        </Typography>
      </ConfirmModal>
    </>
  );
};

export default WarehouseInfo;

const StyledBack = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 4px;
  background-color: ${colors.base.white};
`;

const CardsRow = styledMui("div")(({ theme }) => ({
  width: "100%",
  marginBottom: "16px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
    "& > div": {
      width: "100%",
      "&:first-child": {
        marginBottom: "16px",
      },
    },
  },
}));

const Inventories = styled("div")`
  width: 58%;
  max-height: 450px;
  overflow: auto;
`;

const Employees = styled("div")`
  width: 41%;
  max-height: 450px;
  overflow: auto;
`;

const FileViewerWrapper = styled(Box)`
  width: 170px;
  height: 170px;
`;
