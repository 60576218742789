/* eslint-disable react/no-unescaped-entities */
import { FC } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormInputLable from "src/components/FormInputLabel";
import { StyledTextField } from "src/components/Input";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import * as Yup from "yup";
import { GrayButton } from "src/components/GrayButton";
import { useNavigate } from "react-router-dom";
import FormHeading from "src/components/FormHeading";
import { StyledTextFieldMulti } from "src/components/MultilineInput";
import {
  addSaleOrder,
  patchSaleOrder,
  reset as resetSaleOrders,
} from "../redux/SaleOrdersSlice";
import { StyledDatePicker } from "src/components/DatePicker/DatePicker";
import moment from "moment";
import { PaperBox } from "src/components/Paper/Paper";

interface SaleOrderData {
  name: string;
  code: string;
  date: Date;
  customer: {
    id: number;
    full_name: string;
    [key: string]: any;
  };
  related_sale_order: number;
  product: {
    id: number;
    title: string;
    [key: string]: any;
  };
  quality: {
    id: number;
    title: string;
    [key: string]: any;
  };
  quantity: string;
  origin_warehouse: {
    id: number;
    title: string;
    [key: string]: any;
  };
  port_of_loading: {
    id: number;
    title: string;
    [key: string]: any;
  };
  delivery_terms: string;
  delivery_packaging: string;
  allow_partial_shipment: number;
  [key: string]: any;
}

interface SaleOrdersEditFormProps {
  saleOrderData: SaleOrderData;
  customersData: any;
  ordersData: any;
  warehousesData: any;
  productsData: any;
}

export const SaleOrdersEditForm: FC<SaleOrdersEditFormProps> = (props) => {
  const {
    saleOrderData,
    customersData,
    ordersData,
    warehousesData,
    productsData,
  } = props;

  const navigate = useNavigate();

  const [qualities, setQualities] = useState([]);

  //formik---------------------------------
  const SaleOrdersSchema = Yup.object().shape({
    name: Yup.string().required(),
    id: Yup.string().required(),
    date: Yup.date().nullable(),
    customer: Yup.number().required(),
    order: Yup.number(),
    originWarehouse: Yup.number().required(),
    loadingPort: Yup.number().required(),
    product: Yup.number().required(),
    quantity: Yup.string().required(),
    quality: Yup.number().required(),
    terms: Yup.string(),
    packaging: Yup.string(),
    partialShipment: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      name: saleOrderData?.name as string,
      id: saleOrderData?.code?.substring(3) as string,
      date: saleOrderData?.date,
      customer: saleOrderData?.customer?.id,
      order: saleOrderData?.related_sale_order ?? "",
      originWarehouse: saleOrderData?.origin_warehouse?.id,
      loadingPort: saleOrderData?.port_of_loading?.id,
      product: saleOrderData?.product?.id,
      quantity: saleOrderData?.quantity,
      quality: saleOrderData?.quality?.id,
      terms: saleOrderData?.delivery_terms ?? "",
      packaging: saleOrderData?.delivery_packaging ?? "",
      partialShipment:
        saleOrderData?.allow_partial_shipment === 1 ? true : false,
    },
    validationSchema: SaleOrdersSchema,

    onSubmit: async (values: any) => {
      const patchValues = {
        id: Number(saleOrderData?.id),
        name: values?.name,
        date: values.date
          ? moment((values.date as any).$d).format("YYYY-MM-DD")
          : "",
        delivery_terms: values?.terms,
        delivery_packaging: values?.packaging,
        related_sale_order: values?.order,
      };

      dispatch(patchSaleOrder(patchValues));
    },
  });

  const { errors, touched, values, resetForm, handleSubmit, getFieldProps } =
    formik;

  //redux ---------------------------------
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const { isPatchLoading, isPatchError, isPatchSuccess, patchMessage } =
    useSelector((state: RootState) => state.saleOrders);
  //redux ---------------------------------

  useEffect(() => {
    if (isPatchError) {
      enqueueSnackbar(patchMessage, { variant: "error" });
    }
    if (isPatchSuccess) {
      enqueueSnackbar(patchMessage, { variant: "success" });
    }
    return () => {
      dispatch(resetSaleOrders());
    };
  }, [isPatchError, isPatchSuccess, patchMessage, dispatch]);

  useEffect(() => {
    const selectedProductObject = productsData.find(
      (prod: any) => prod.id === saleOrderData?.product?.id
    );
    const selectedProductMaterialData = selectedProductObject
      ? selectedProductObject.material
      : null;
    setQualities(
      selectedProductMaterialData ? selectedProductMaterialData.qualities : []
    );
  }, [props]);

  useEffect(() => {
    if (values?.product) {
      const selectedProductObject = productsData.find(
        (mt: any) => mt.id === values?.product
      );
      const selectedProductMaterialData = selectedProductObject
        ? selectedProductObject.material
        : null;
      setQualities(
        selectedProductMaterialData
          ? selectedProductMaterialData?.qualities
          : []
      );
    }
  }, [values?.product]);

  const onCancel = () => {
    navigate("/saleOrders/saleOrders-list");
    resetForm();
  };

  return (
    <>
      <FormikProvider value={formik}>
        <FormContainer>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <PaperBox>
              <Box mb={12}>
                <FormHeading>Public Information</FormHeading>
              </Box>
              <Grid
                container
                rowSpacing={12}
                columnSpacing={{ sm: 0, md: 20 }}
                md={10}
                sm={12}
              >
                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <FormInputLable noPrefix>Order Name</FormInputLable>
                    <StyledTextField
                      placeholder="Enter the order name"
                      fullWidth
                      {...getFieldProps("name")}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ opacity: 0.7 }}>
                  <Box width="100%">
                    <FormInputLable noPrefix>Order ID</FormInputLable>
                    <StyledTextField
                      placeholder="Enter the order ID"
                      fullWidth
                      disabled
                      {...getFieldProps("id")}
                      error={Boolean(touched.id && errors.id)}
                      helperText={touched.id && errors.id}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <FormInputLable>Order Date</FormInputLable>
                    <StyledDatePicker
                      placeholder="Enter or select date"
                      {...getFieldProps("date")}
                      value={formik.values.date}
                      error={Boolean(touched.date && errors.date)}
                      helperText={(touched.date as any) && (errors.date as any)}
                      onChange={(newValue: Date | null) =>
                        formik.setFieldValue("date", newValue)
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </PaperBox>

            <PaperBox>
              <Box mb={12}>
                <FormHeading>Customer & Shippment</FormHeading>
              </Box>
              <Grid
                container
                rowSpacing={12}
                columnSpacing={{ sm: 0, md: 20 }}
                md={10}
                sm={12}
              >
                <Grid item md={6} sm={12} xs={12} sx={{ opacity: 0.7 }}>
                  <FormInputLable noPrefix>Customer</FormInputLable>
                  <StyledTextField
                    select
                    fullWidth
                    disabled
                    placeholder="Select a customer"
                    error={Boolean(touched.customer && errors.customer)}
                    helperText={touched.customer && errors.customer}
                    {...getFieldProps("customer")}
                    onChange={formik.handleChange("customer")}
                    defaultValue={[]}
                    SelectProps={{
                      multiple: false,
                    }}
                  >
                    {customersData?.map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.full_name}
                        </MenuItem>
                      );
                    })}
                  </StyledTextField>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <FormInputLable>Related Purchase Order</FormInputLable>
                  <StyledTextField
                    select
                    fullWidth
                    placeholder="Select or create a purchase order"
                    error={Boolean(touched.order && errors.order)}
                    helperText={touched.order && errors.order}
                    {...getFieldProps("order")}
                    onChange={formik.handleChange("order")}
                    defaultValue={[]}
                    SelectProps={{
                      multiple: false,
                    }}
                  >
                    {ordersData?.map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.code}
                        </MenuItem>
                      );
                    })}
                  </StyledTextField>
                </Grid>
                <Grid item md={6} sm={12} xs={12} sx={{ opacity: 0.7 }}>
                  <FormInputLable noPrefix>Origin Warehouse</FormInputLable>
                  <StyledTextField
                    select
                    fullWidth
                    disabled
                    placeholder="Select a Warehouse"
                    error={Boolean(
                      touched.originWarehouse && errors.originWarehouse
                    )}
                    helperText={
                      touched.originWarehouse && errors.originWarehouse
                    }
                    {...getFieldProps("originWarehouse")}
                    onChange={formik.handleChange("originWarehouse")}
                    defaultValue={[]}
                    SelectProps={{
                      multiple: false,
                    }}
                  >
                    {warehousesData?.map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.title}
                        </MenuItem>
                      );
                    })}
                  </StyledTextField>
                </Grid>
                <Grid item md={6} sm={12} xs={12} sx={{ opacity: 0.7 }}>
                  <FormInputLable noPrefix>Port of Loading</FormInputLable>
                  <StyledTextField
                    select
                    fullWidth
                    disabled
                    placeholder="Select a Warehouse"
                    error={Boolean(touched.loadingPort && errors.loadingPort)}
                    helperText={touched.loadingPort && errors.loadingPort}
                    {...getFieldProps("loadingPort")}
                    onChange={formik.handleChange("loadingPort")}
                    defaultValue={[]}
                    SelectProps={{
                      multiple: false,
                    }}
                  >
                    {warehousesData?.map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.title}
                        </MenuItem>
                      );
                    })}
                  </StyledTextField>
                </Grid>
              </Grid>
            </PaperBox>

            <PaperBox>
              <Box mb={12}>
                <FormHeading>Product Details</FormHeading>
              </Box>
              <Grid
                container
                rowSpacing={12}
                columnSpacing={{ sm: 0, md: 20 }}
                md={10}
                sm={12}
              >
                <Grid item md={6} sm={12} xs={12} sx={{ opacity: 0.7 }}>
                  <FormInputLable noPrefix>Product</FormInputLable>
                  <StyledTextField
                    select
                    fullWidth
                    disabled
                    placeholder="Select a product"
                    error={Boolean(touched.product && errors.product)}
                    helperText={touched.product && errors.product}
                    {...getFieldProps("product")}
                    onChange={formik.handleChange("product")}
                    defaultValue={[]}
                    SelectProps={{
                      multiple: false,
                    }}
                  >
                    {productsData?.map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.title}
                        </MenuItem>
                      );
                    })}
                  </StyledTextField>
                </Grid>
                <Grid item xs={12} md={6} sx={{ opacity: 0.7 }}>
                  <Box width="100%">
                    <FormInputLable noPrefix>Quantity</FormInputLable>
                    <StyledTextField
                      placeholder="Enter product quantity"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                `/assets/icons/MTIcon.svg`
                              }
                            />
                          </InputAdornment>
                        ),
                      }}
                      disabled
                      {...getFieldProps("quantity")}
                      error={Boolean(touched.quantity && errors.quantity)}
                      helperText={touched.quantity && errors.quantity}
                    />
                  </Box>
                </Grid>
                <Grid item md={6} sm={12} xs={12} sx={{ opacity: 0.7 }}>
                  <FormInputLable noPrefix>Quality</FormInputLable>
                  <StyledTextField
                    select
                    fullWidth
                    disabled
                    placeholder="Select or add  product quality"
                    error={Boolean(touched.quality && errors.quality)}
                    helperText={touched.quality && errors.quality}
                    {...getFieldProps("quality")}
                    onChange={formik.handleChange("quality")}
                    defaultValue={[]}
                    SelectProps={{
                      multiple: false,
                    }}
                  >
                    {qualities?.map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.title}
                        </MenuItem>
                      );
                    })}
                  </StyledTextField>
                </Grid>
              </Grid>
            </PaperBox>

            <PaperBox>
              <Box mb={12}>
                <FormHeading>General Terms</FormHeading>
              </Box>
              <Grid
                container
                rowSpacing={12}
                columnSpacing={{ sm: 0, md: 20 }}
                md={10}
                sm={12}
              >
                <Grid item md={6} sm={12} xs={12}>
                  <FormInputLable>Delivery Terms</FormInputLable>
                  <StyledTextFieldMulti
                    placeholder="Enter the order delivery terms"
                    fullWidth
                    multiline={true}
                    {...getFieldProps("terms")}
                    error={Boolean(touched.terms && errors.terms)}
                    helperText={touched.terms && errors.terms}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <ColContainer>
                    <Box width="100%">
                      <FormInputLable>Delivery Packaging</FormInputLable>
                      <StyledTextField
                        placeholder="Enter the order Packaging"
                        fullWidth
                        {...getFieldProps("packaging")}
                        error={Boolean(touched.packaging && errors.packaging)}
                        helperText={touched.packaging && errors.packaging}
                      />
                    </Box>
                    <CheckField>
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            disabled
                            color="secondary"
                            checked={values?.partialShipment}
                          />
                        }
                        label="Allow partial shipment for this order"
                        name="partialShipment"
                        onChange={formik.handleChange}
                      />
                    </CheckField>
                  </ColContainer>
                </Grid>
              </Grid>
            </PaperBox>

            <Actions mt={8} width="100%">
              <Box>
                <GrayButton
                  variant="outlined"
                  fullWidth
                  disabled={isPatchLoading}
                  onClick={onCancel}
                >
                  Cancel
                </GrayButton>
              </Box>
              <Box marginLeft={12}>
                <StyledLoadingButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  loading={isPatchLoading}
                  type="submit"
                >
                  <Typography
                    fontSize={16}
                    fontWeight={500}
                    color={colors.gray[850]}
                  >
                    Save and Send
                  </Typography>
                </StyledLoadingButton>
              </Box>
            </Actions>
          </Form>
        </FormContainer>
      </FormikProvider>
    </>
  );
};

const FormContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }

  & > form {
    width: 100%;
  }
`;

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  & > div {
    width: 184px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    & > div {
      width: 142px;
    }
  }
`;

const ColContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
`;

const CheckField = styled("div")`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
