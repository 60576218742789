import { FormControlLabel, FormControlLabelProps } from '@mui/material';

export default function StyledSwitch(props: FormControlLabelProps) {

    return (
        <FormControlLabel
            value={props.value}
            control={props.control}
            label={props.label}
            labelPlacement={props.labelPlacement}
            onSelectCapture={props.onSelectCapture}
        />
    );
}
