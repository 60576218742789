import NotFound from "../features/misc/routes/NotFound";
import { ComponentsPage } from "../components";
import { lazyImport } from "../utils/lazyImports";

const { AuthRoutes } = lazyImport(
  () => import("../features/auth"),
  "AuthRoutes"
);

export const publicRoutes = [
  {
    path: "/auth/*",
    element: <AuthRoutes />,
  },
  {
    path: "/components",
    element: <ComponentsPage />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
