import { FC, useEffect, useState } from "react";
import { Box, styled, Typography } from "@mui/material";
import colors from "src/theme/colors";
import { Alert } from "src/components/Alert/Alert";
import { LinearFileUploader } from "src/components/FileUploader/LinearFileUploader/LinearFileUploader";
import { UploadedFileType } from "src/components/FileUploader/LinearFileUploader/LinearUploadZone";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getSaleOrderCarrierDocuments,
  addSaleOrderCarrierDocuments,
  addCarrierSealNumber,
  editCarrierSealNumber,
  reset as resetCarrier,
  getSaleOrderShipmentCarrierId,
} from "../../../redux/CarriersSlice";
import Loading from "src/components/Loading";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { CarrierInfoPageDetailsCard } from "src/features/saleOrders/components/shippingDetails/originWarehouse/CarrierInfoPageDetailsCard";
import ConfirmModal from "src/components/Modal";
import { deleteSaleOrderShipmentCarrier } from "src/features/saleOrders/redux/SaleOrdersSlice";
import { SaleOrderStuffedCarrierProductsList } from "src/features/saleOrders/components/shippingDetails/portOperation/tables/SaleOrderStuffedCarrierProductsList";
import { SealNumberAddCard } from "src/features/saleOrders/components/shippingDetails/portOperation/shared/SealNumberAddCard";

export enum SaleOrderStuffedCarrierInfoList {
  Additional = "additional",
}

interface SaleOrderStuffedCarrierInfoStates {
  data: any;
  saleOrderName: string;
  saleOrderId: string;
  shipmentId: string;
  warehouseId: number;
}

const SaleOrderStuffedCarrierInfo: FC = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const state = location.state as SaleOrderStuffedCarrierInfoStates;

  const [files, setFiles] = useState<UploadedFileType[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSealModalOpen, setIsSealModalOpen] = useState(false);
  const [singleCarrierData, setSingleCarrierData] = useState(null as any);
  const [singleCarrierDataLoading, setSingleCarrierDataLoading] =
    useState(true);

  const {
    singleCarrier,
    documents,
    addDocuments,
    isGetSingleCarrierLoading,
    isGetDocumentsLoading,
    isAddDocumentsLoading,
    isDeleteShipmentCarriersLoading,
    isAddSealNumberLoading,
    isGetSingleCarrierError,
    isGetDocumentsError,
    isAddDocumentsError,
    isDeleteShipmentCarriersError,
    isAddSealNumberError,
    isGetSingleCarrierSuccess,
    isGetDocumentsSuccess,
    isAddDocumentsSuccess,
    isAddSealNumberSuccess,
    isDeleteShipmentCarriersSuccess,
    getSingleCarrierMessage,
    getDocumentsMessage,
    addDocumentsMessage,
    deleteShipmentCarriersMessage,
    addSealNumberMessage,
  } = useSelector((state: RootState) => state.carriers);

  useEffect(() => {
    if (isGetSingleCarrierError) {
      enqueueSnackbar(getSingleCarrierMessage, { variant: "error" });
    }
    if (isGetSingleCarrierSuccess) {
      setSingleCarrierData(singleCarrier);
      setIsSealModalOpen(false);
      setSingleCarrierDataLoading(false);
    }
    return () => {
      dispatch(resetCarrier());
    };
  }, [
    isGetSingleCarrierError,
    isGetSingleCarrierSuccess,
    getSingleCarrierMessage,
    dispatch,
  ]);

  useEffect(() => {
    if (isGetDocumentsError) {
      enqueueSnackbar(getDocumentsMessage, { variant: "error" });
    }
    if (isGetDocumentsSuccess) {
      if (documents && documents.length > 0) {
        setFiles(documents);
      } else {
        setFiles([]);
      }
    }
    dispatch(resetCarrier());
  }, [
    isGetDocumentsError,
    isGetDocumentsSuccess,
    getDocumentsMessage,
    dispatch,
  ]);

  useEffect(() => {
    if (isAddDocumentsError) {
      enqueueSnackbar(addDocumentsMessage, { variant: "error" });
    }
    if (isAddDocumentsSuccess) {
      if (addDocuments && addDocuments.length > 0) {
        setFiles((prev) => [...prev, ...addDocuments]);
      }
    }
    dispatch(resetCarrier());
  }, [
    isAddDocumentsError,
    isAddDocumentsSuccess,
    addDocumentsMessage,
    dispatch,
  ]);

  useEffect(() => {
    if (isAddSealNumberError) {
      enqueueSnackbar(addSealNumberMessage, { variant: "error" });
    }
    if (isAddSealNumberSuccess) {
      enqueueSnackbar(addSealNumberMessage, { variant: "success" });
      dispatch(getSaleOrderShipmentCarrierId({ id: Number(state?.data?.id) }));
    }
    return () => {
      dispatch(resetCarrier());
    };
  }, [
    isAddSealNumberError,
    isAddSealNumberSuccess,
    addSealNumberMessage,
    dispatch,
  ]);

  useEffect(() => {
    if (isDeleteShipmentCarriersError) {
      enqueueSnackbar(deleteShipmentCarriersMessage, { variant: "error" });
    }
    if (isDeleteShipmentCarriersSuccess) {
      enqueueSnackbar(deleteShipmentCarriersMessage, { variant: "success" });
      navigate(-1);
    }
    return () => {
      dispatch(resetCarrier());
    };
  }, [
    isDeleteShipmentCarriersError,
    isDeleteShipmentCarriersSuccess,
    deleteShipmentCarriersMessage,
  ]);

  useEffect(() => {
    setSingleCarrierDataLoading(true);
    dispatch(getSaleOrderShipmentCarrierId({ id: Number(state?.data?.id) }));
  }, []);

  useEffect(() => {
    dispatch(getSaleOrderCarrierDocuments(Number(state?.data?.id)));
  }, []);

  const onDelete = () => {
    dispatch(deleteSaleOrderShipmentCarrier(Number(state?.data?.id)));
  };

  const getSpecFils = (tag: string) => {
    if (documents && documents.length > 0) {
      const docsCopy = [...files];
      const filteredFiles = docsCopy.filter(
        (doc: UploadedFileType) => doc.tag === tag
      );
      return filteredFiles;
    } else {
      return [];
    }
  };

  const onAddSealNumber = (sealNumber: string) => {
    const addData = {
      carrierId: Number(state?.data?.id),
      saleOrderId: Number(state?.saleOrderId),
      shipmentId: Number(state?.shipmentId),
      seal_number: sealNumber,
    };
    dispatch(addCarrierSealNumber(addData));
  };

  const onEditSealNumber = (sealNumber: string) => {
    const addData = {
      carrierId: Number(state?.data?.id),
      saleOrderId: Number(state?.saleOrderId),
      shipmentId: Number(state?.shipmentId),
      seal_number: sealNumber,
    };
    dispatch(editCarrierSealNumber(addData));
  };

  return (
    <>
      <PageTemplate
        isTransparent
        crumbs={[
          {
            label: "Sale Orders",
            href: "/saleOrders/saleOrders-list",
            depth: -2,
          },
          {
            label: state?.saleOrderName,
            href: "/saleOrders/saleOrders-information",
            depth: -1,
          },
          {
            label: state?.data?.title,
          },
        ]}
      >
        <CarrierInfoPageDetailsCard
          title={state?.data?.title}
          status={state?.data?.status}
          isDeleteloading={isDeleteShipmentCarriersLoading}
          onRemoveTruck={() => setIsDeleteModalOpen(true)}
        />
        {isGetDocumentsLoading ||
        isDeleteShipmentCarriersLoading ||
        singleCarrierDataLoading ? (
          <Loading />
        ) : (
          <>
            <DocsPaper>
              <Container>
                <Title>Documents</Title>
                <Alert
                  title="You can add up to 10 Files for each document, each not exceeding 15MB."
                  isHidden={
                    localStorage.getItem(
                      "saleorders_hide=stuffedCarrier-file-upload-limit"
                    ) === "true"
                  }
                  onHide={() =>
                    localStorage.setItem(
                      "saleorders_hide=stuffedCarrier-file-upload-limit",
                      "true"
                    )
                  }
                />
                <UploadersContainer>
                  <SealNumberAddCard
                    title="Seal Number"
                    index={1}
                    isModalOpen={isSealModalOpen}
                    defaultValue={singleCarrierData?.seal_number}
                    loading={isAddSealNumberLoading || singleCarrierDataLoading}
                    onAddSealNumber={(sealNumber) =>
                      singleCarrierData?.seal_number
                        ? onEditSealNumber(sealNumber)
                        : onAddSealNumber(sealNumber)
                    }
                    onOpenModal={() => setIsSealModalOpen(true)}
                    onCloseModal={() => setIsSealModalOpen(false)}
                  />
                  <Divider />
                  <LinearFileUploader
                    title="Additional"
                    rootID={Number(state?.data?.id)}
                    index={2}
                    maxFiles={10}
                    isAddLoading={isAddDocumentsLoading}
                    tag={SaleOrderStuffedCarrierInfoList.Additional}
                    allFiles={files}
                    files={getSpecFils(
                      SaleOrderStuffedCarrierInfoList.Additional
                    )}
                    deleteEndPoint={`sale-orders/carrier/${state?.data?.id}`}
                    setFiles={setFiles}
                    onAddNewFiles={(addData) => {
                      dispatch(
                        addSaleOrderCarrierDocuments({
                          id: Number(state?.data?.id),
                          data: addData,
                        })
                      );
                    }}
                  />
                </UploadersContainer>
              </Container>
              <SvgBox>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/CarrierDocumentsSVG.svg"
                  }
                  alt="delete"
                />
              </SvgBox>
            </DocsPaper>
            <TablePaper>
              <SaleOrderStuffedCarrierProductsList
                id={Number(state?.data?.id)}
                warehouseId={Number(state?.warehouseId)}
              />
            </TablePaper>
          </>
        )}
      </PageTemplate>

      <ConfirmModal
        title={"Remove Truck"}
        iconName={"ModalRemove"}
        open={isDeleteModalOpen}
        isLoading={isGetDocumentsLoading || isDeleteShipmentCarriersLoading}
        onDismiss={() => setIsDeleteModalOpen(false)}
        onConfirm={onDelete}
        confrimText={"Remove"}
        cancelText={"Cancel"}
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to remove this Truck?
        </Typography>
      </ConfirmModal>
    </>
  );
};

const DocsPaper = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.base.white};
  padding: 24px 32px;
  margin-bottom: 16px;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

const TablePaper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.base.white};
  padding: 24px 32px;
`;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 682px;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

const SvgBox = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 48px;
  & > img {
    width: 280px;
  }
  @media screen and (max-width: 1500px) {
    & > img {
      width: 180px;
    }
    margin-right: 0;
  }
  @media screen and (max-width: 1300px) {
    & > img {
      width: 140px;
    }
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    display: none;
  }
`;

const UploadersContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 24px;
  margin-bottom: 40px;
`;

const Divider = styled("div")`
  width: 100%;
  height: 1px;
  background-color: ${colors.gray[100]};
  margin: 12px 0;
`;

const Title = styled(Typography)({
  color: colors.gray[850],
  margin: "16px 0",
  fontSize: "24px",
  lineHeight: "32px",
  fontWeight: 500,
});

export default SaleOrderStuffedCarrierInfo;
