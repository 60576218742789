import { Grid, Box, styled, Typography } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormInputLable from "src/components/FormInputLabel";
import { GrayButton } from "src/components/GrayButton";
import { StyledTextField } from "src/components/Input";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { StyledTextFieldMulti } from "src/components/MultilineInput";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import * as Yup from "yup";
import { patchContact, reset } from "../redux/CustomersSlice";

interface Props {
  customerId: string;
  contactData: any;
  onCancel: () => void;
}

export const ContactEditForm = (props: Props) => {
  const { customerId, contactData, onCancel } = props;

  const navigate = useNavigate();

  //formik---------------------------------
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const CustomerContactSchema = Yup.object().shape({
    name: Yup.string().required(),
    title: Yup.string().required(),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required(),
    mobile: Yup.string().required(),
    address: Yup.string().required(),
    email: Yup.string().email().required(),
  });

  const formik = useFormik({
    initialValues: {
      name: contactData?.name ?? "",
      title: contactData?.title ?? "",
      phone: contactData?.phone ?? "",
      mobile: contactData?.mobile ?? "",
      email: contactData?.email ?? "",
      address: contactData?.address ?? "",
      customerId: Number(customerId),
      contactId: Number(contactData.id),
    },
    validationSchema: CustomerContactSchema,

    onSubmit: async (values: any) => {
      dispatch(patchContact(values));
    },
  });
  const { errors, touched, resetForm, handleSubmit, getFieldProps } = formik;

  //redux ---------------------------------
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const { isPatchLoading, isPatchSuccess, isPatchError, patchMessage } =
    useSelector((state: RootState) => state.customers);

  useEffect(() => {
    if (isPatchError) {
      enqueueSnackbar(patchMessage, { variant: "error" });
    }
    if (isPatchSuccess) {
      enqueueSnackbar(patchMessage, { variant: "success" });
      onCancel();
    }
    dispatch(reset());
  }, [isPatchError, isPatchSuccess, patchMessage, navigate, dispatch]);

  const onCancelForm = () => {
    resetForm();
    onCancel();
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            rowSpacing={{ xs: 0, md: 18 }}
            gap={{ xs: 12, md: 0 }}
            columnSpacing={12}
          >
            <Grid item xs={12} md={6}>
              <FormInputLable requried>Name</FormInputLable>
              <StyledTextField
                placeholder="Enter contact name"
                fullWidth
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={(touched.name as any) && (errors.name as any)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputLable requried>Title</FormInputLable>
              <StyledTextField
                placeholder="Enter contact title"
                fullWidth
                {...getFieldProps("title")}
                error={Boolean(touched.title && errors.title)}
                helperText={(touched.title as any) && (errors.title as any)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputLable requried>Phone</FormInputLable>
              <StyledTextField
                placeholder="Enter contact phone number"
                fullWidth
                {...getFieldProps("phone")}
                error={Boolean(touched.phone && errors.phone)}
                helperText={(touched.phone as any) && (errors.phone as any)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputLable requried>Mobile</FormInputLable>
              <StyledTextField
                placeholder="Enter contact mobile number"
                fullWidth
                {...getFieldProps("mobile")}
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={(touched.mobile as any) && (errors.mobile as any)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputLable requried>Address</FormInputLable>
              <StyledTextFieldMulti
                placeholder="Enter contact address"
                fullWidth
                multiline
                rows={10}
                {...getFieldProps("address")}
                error={Boolean(touched.address && errors.address)}
                helperText={(touched.address as any) && (errors.address as any)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputLable requried>Email</FormInputLable>
              <StyledTextField
                placeholder="Enter contact email address"
                fullWidth
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={(touched.email as any) && (errors.email as any)}
              />
            </Grid>
          </Grid>
        </Box>
        <Actions>
          <Box width="184px">
            <GrayButton
              variant="outlined"
              fullWidth
              disabled={isPatchLoading}
              onClick={onCancelForm}
            >
              Cancel
            </GrayButton>
          </Box>
          <Box width="184px">
            <StyledLoadingButton
              loading={isPatchLoading}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.gray[850]}
              >
                Add Contact
              </Typography>
            </StyledLoadingButton>
          </Box>
        </Actions>
      </Form>
    </FormikProvider>
  );
};

const Actions = styled(Box)(({ theme }) => ({
  marginTop: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "24px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
    gap: "16px",
    "& > div": {
      width: "100%",
    },
  },
}));
