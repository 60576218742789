import { Box } from "@mui/material";

export const PaperBox = ({ children }: any) => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#ffffff",
        padding: "32px 24px",
        mb: "16px",
        borderRadius: "5px",
      }}
    >
      {children}
    </Box>
  );
};
