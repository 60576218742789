import { Grid, Box, styled, Typography } from "@mui/material";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "src/components/Button";
import FormHeading from "src/components/FormHeading";
import InfoItems from "src/components/InfoItems";
import ConfirmModal from "src/components/Modal";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import { deleteContact, deleteVendor } from "../redux/VendorSlice";
import { EditContactModal } from "./modals/EditContactModal";
import { NewContactModal } from "./modals/NewContactModal";
import VendorInfoContact, { ContactEditEventProps } from "./VendorInfoContact";
import {
  FileUploaderViewer,
  FileUploaderViewerType,
} from "src/components/FileUploader/FileUploaderViewer/FileUploaderViewer";
import { UploadedFileType } from "src/components/FileUploader/UploadZone/UploadZone";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { PaperBox } from "src/components/Paper/Paper";

export interface Contacts {
  address: string | null;
  id: string;
  mobile: string | null;
  name: string | null;
  phone: string | null;
  title: string | null;
}

type Props = {
  isDeleteLoading?: boolean;
  data: {
    id: string;
    name: string;
    code: string;
    company_name: string;
    mobile: string;
    phone: string;
    email: string;
    address: string;
    zip_code: string;
    notes: string;
    documents: UploadedFileType[];
    contacts: Contacts[];
  };
};

const VendorInfoData = (props: Props) => {
  const {
    id,
    name,
    code,
    company_name,
    mobile,
    phone,
    email,
    address,
    zip_code,
    notes,
    documents,
    contacts,
  } = props.data;

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [isNewContactModalOpen, setIsNewContactModalOpen] = useState(false);
  const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false);
  const [isDeleteContactModalOpen, setIsDeleteContactModalOpen] =
    useState(false);
  const [isDeleteVendorModalOpen, setIsDeleteVendorModalOpen] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Contacts>();
  const [selectedContactId, setSelectedContactId] = useState<string>("");

  const moveToEdit = () => {
    navigate("/vendors/vendor-edit", {
      state: { vendorData: props.data, forwardedFromInfo: true },
    });
  };

  const onDeleteVendor = () => {
    const deleteValue = {
      vendor_ids: [Number(props?.data?.id)],
    };
    dispatch(deleteVendor(deleteValue));
  };

  const onDeleteContact = (id: string) => {
    const deleteValue = {
      contactId: Number(id),
      vendorId: Number(props?.data?.id),
    };
    dispatch(deleteContact(deleteValue));
  };

  return (
    <>
      <Container>
        <PaperBox>
          <Box mb={12}>
            <FormHeading>Vendor Information</FormHeading>
          </Box>
          <Grid
            container
            rowSpacing={12}
            columnSpacing={{ sm: 0, md: 20 }}
            md={10}
            sm={12}
          >
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems title="Full Name" subTilte={name ?? "Not Found"} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems
                  title="Vendor display name"
                  subTilte={code ?? "Not Found"}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems
                  title="Company Name"
                  subTilte={company_name ?? "Not Found"}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems title="Mobile" subTilte={mobile ?? "Not Found"} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems title="Phone" subTilte={phone ?? "Not Found"} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems
                  title="Email Address"
                  subTilte={email ?? "Not Found"}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems title="Address" subTilte={address ?? "Not Found"} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems
                  title="Postal Code"
                  subTilte={zip_code ?? "Not Found"}
                />
              </Box>
            </Grid>
          </Grid>

          <ContactsContainer
            onClick={() => setShowContacts(!showContacts)}
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={4}
            pt={8}
            sx={{ cursor: "pointer" }}
          >
            <ShowMoreBtn
              mb={showContacts ? 10 : 0}
              // sx={showContacts ? { marginBottom: "24px" } : {}}
            >
              <Box>
                <Typography
                  fontWeight="700"
                  fontSize="14"
                  color={colors.primary[800]}
                  borderBottom={`1px dashed ${colors.primary[800]}`}
                >
                  {showContacts ? "Show Less" : "Show More"}
                </Typography>
              </Box>
              <Box pt={3}>
                {showContacts ? (
                  <ArrowUp2
                    size="14"
                    variant="Bold"
                    color={colors.primary[800]}
                  />
                ) : (
                  <ArrowDown2
                    size="14"
                    variant="Bold"
                    color={colors.primary[800]}
                  />
                )}
              </Box>
            </ShowMoreBtn>
            {showContacts ? (
              <ContactsItemsBox>
                <VendorInfoContact
                  Contacts={props?.data?.contacts}
                  Id={props?.data?.id}
                  onEditContact={(itemDate: Contacts) => {
                    setSelectedContact(itemDate);
                    setIsEditContactModalOpen(true);
                  }}
                  onDeleteContact={(id: string) => {
                    setSelectedContactId(id);
                    setIsDeleteContactModalOpen(true);
                  }}
                  onCreateNewContact={() => setIsNewContactModalOpen(true)}
                />
              </ContactsItemsBox>
            ) : null}
          </ContactsContainer>
        </PaperBox>

        <PaperBox>
          <Box mb={12}>
            <FormHeading>Notes & Documents</FormHeading>
          </Box>
          <Grid
            container
            rowSpacing={12}
            columnSpacing={{ sm: 0, md: 20 }}
            md={10}
            sm={12}
          >
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems title="Notes" subTilte={notes ?? "Not Found"} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <FileViewerWrapper>
                <FileUploaderViewer
                  type={FileUploaderViewerType.Multiple}
                  files={documents}
                />
              </FileViewerWrapper>
            </Grid>
          </Grid>
        </PaperBox>

        <Actions width="100%">
          {/* <Box>
            <StyledLoadingButton
              variant="outlined"
              color="error"
              fullWidth
              loading={props?.isDeleteLoading}
              onClick={() => setIsDeleteVendorModalOpen(true)}
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.error[500]}
              >
                Remove Vendor
              </Typography>
            </StyledLoadingButton>
          </Box> */}
          <Box marginLeft={12}>
            <StyledButton
              variant="contained"
              color="secondary"
              fullWidth
              disabled={props?.isDeleteLoading}
              onClick={moveToEdit}
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.base.white}
              >
                Edit Vendor
              </Typography>
            </StyledButton>
          </Box>
        </Actions>
      </Container>

      <NewContactModal
        open={isNewContactModalOpen}
        vendorId={props?.data?.id}
        onDismiss={() => setIsNewContactModalOpen(false)}
      />
      <EditContactModal
        open={isEditContactModalOpen}
        vendorId={props?.data?.id}
        contactData={selectedContact!}
        onDismiss={() => setIsEditContactModalOpen(false)}
      />

      <ConfirmModal
        title="Remove Contact"
        iconName="ModalRemove"
        open={isDeleteContactModalOpen}
        isLoading={false}
        onDismiss={() => setIsDeleteContactModalOpen(false)}
        onConfirm={() => onDeleteContact(selectedContactId)}
        confrimText="Remove"
        cancelText="Cancel"
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to remove this Contact?
        </Typography>
      </ConfirmModal>

      <ConfirmModal
        title="Remove Vendor"
        iconName={"ModalRemove"}
        open={isDeleteVendorModalOpen}
        isLoading={props?.isDeleteLoading}
        onDismiss={() => setIsDeleteVendorModalOpen(false)}
        onConfirm={onDeleteVendor}
        confrimText={"Remove"}
        cancelText={"Cancel"}
      >
        <Typography
          textAlign="center"
          fontSize={14}
          fontWeight={400}
          color={colors.gray[450]}
        >
          Are you sure you want to remove this Vendor? By clicking on{" "}
          <b>“Remove Vendor”</b>, all data related to this Vendor will also be
          removed.
        </Typography>
      </ConfirmModal>
    </>
  );
};

export default VendorInfoData;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

const ContactsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0;
`;

const ContactsItemsBox = styled(Box)`
  width: 860px;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

const ShowMoreBtn = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  & > div {
    width: 184px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    & > div {
      width: 142px;
    }
  }
`;

const FileViewerWrapper = styled(Box)`
  width: 170px;
`;
