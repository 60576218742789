import { styled } from "@mui/material/styles";
import { Box, styled as styledMui, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import {
  TotalShippingDetailCard,
  TotalShippingDetailCardType,
} from "./shared/TotalShippingDetailCard";
import { ShippingPortRecievedTrucksList } from "./tables/ShippingPortRecievedTrucksList";
import { ShippingPortStuffedContainersList } from "./tables/ShippingPortStuffedContainersList";
import { ShippingPortRemainingProductsList } from "./tables/ShippingPortRemainingProductsList";

interface SaleShippingPortOperationInfoPageProps {
  details: any;
  product: any;
  saleOrderId: number;
  warehouseId: number;
  saleOrderName: string;
  shipmentId: number;
  originShipmentId: number;
}

export const SaleShippingPortOperationInfoPage: FC<
  SaleShippingPortOperationInfoPageProps
> = (props) => {
  const {
    details,
    product,
    saleOrderId,
    warehouseId,
    saleOrderName,
    shipmentId,
    originShipmentId,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [currentCardStep, setCurrentCardStep] = useState(
    TotalShippingDetailCardType.Recieved
  );

  const tableGenerator = () => {
    switch (currentCardStep) {
      case TotalShippingDetailCardType.Recieved:
        return (
          <ShippingPortRecievedTrucksList
            saleOrderId={saleOrderId}
            shipmentId={shipmentId}
            warehouseId={warehouseId}
            saleOrderName={saleOrderName}
          />
        );
      case TotalShippingDetailCardType.Stuffed:
        return (
          <ShippingPortStuffedContainersList
            saleOrderId={saleOrderId}
            shipmentId={shipmentId}
            warehouseId={warehouseId}
            saleOrderName={saleOrderName}
          />
        );
      case TotalShippingDetailCardType.Remaining:
        return (
          <ShippingPortRemainingProductsList
            saleOrderId={saleOrderId}
            shipmentId={shipmentId}
            warehouseId={warehouseId}
            saleOrderName={saleOrderName}
          />
        );

      default:
        <ShippingPortRecievedTrucksList
          saleOrderId={saleOrderId}
          shipmentId={shipmentId}
          warehouseId={warehouseId}
          saleOrderName={saleOrderName}
        />;
    }
  };

  return (
    <>
      <InfosContainer>
        <Title>Total Shipping Details</Title>
        <InfoCards>
          <Col>
            <TotalShippingDetailCard
              type={TotalShippingDetailCardType.Recieved}
              isActive={
                currentCardStep === TotalShippingDetailCardType.Recieved
              }
              title="Received From Warehouse"
              totalCount={details?.total_received}
              trucks={0}
              products={0}
              onClick={(type) => setCurrentCardStep(type)}
            />
          </Col>
          <Col>
            <TotalShippingDetailCard
              type={TotalShippingDetailCardType.Stuffed}
              isActive={currentCardStep === TotalShippingDetailCardType.Stuffed}
              title="Stuffed In Container"
              totalCount={details?.total_sent}
              trucks={0}
              products={0}
              onClick={(type) => setCurrentCardStep(type)}
            />
          </Col>
          <Col>
            <TotalShippingDetailCard
              type={TotalShippingDetailCardType.Remaining}
              isActive={
                currentCardStep === TotalShippingDetailCardType.Remaining
              }
              title="Remaining At Port"
              totalCount={Math.abs(
                details?.total_received - details?.total_sent
              )}
              trucks={0}
              products={0}
              onClick={(type) => setCurrentCardStep(type)}
            />
          </Col>
        </InfoCards>
      </InfosContainer>
      {tableGenerator()}
    </>
  );
};

const InfosContainer = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});

const Title = styled(Typography)({
  width: "100%",
  fontSize: "24px",
  fontWeight: 500,
  textAlign: "left",
  lineHeight: "32px",
  marginBottom: "24px",
  color: colors.midnight[850],
});

const InfoCards = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("xl")} {
    width: 100%;
    flex-direction: column;
    gap: 12px;
  }
`;

const Col = styled(Box)`
  width: 32%;
  ${(props) => props.theme.breakpoints.down("xl")} {
    width: 100%;
  }
`;
