

export function generatePassword() {
    const key_strings = {
        lowercase: 'abcdefghijklmnopqrstuvwxyz',
        uppercase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        number: '0123456789',
        symbol: "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~",
    };
    let passwordCharSet = "";
    passwordCharSet += key_strings.lowercase;
    passwordCharSet += key_strings.uppercase;
    passwordCharSet += key_strings.number;
    passwordCharSet += key_strings.symbol;
    let password = "";
    for (let i = 0; i < Number(10); i++) {
        password += passwordCharSet[Math.floor(Math.random() * passwordCharSet.length)]
    }
    return password;
}