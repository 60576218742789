import { Box, Grid, styled, Typography } from "@mui/material";
import FormHeading from "src/components/FormHeading";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { deleteItems, getItemsId, reset } from "../redux/ItemSlice";
import Loading from "src/components/Loading";
import TableChip from "src/components/TableChip";
import colors from "src/theme/colors";
import { StyledButton } from "src/components/Button";
import InfoItems from "src/components/InfoItems";
import FileShow from "src/components/FileShow";
import ConfirmModal from "src/components/Modal";
import { PageTemplate } from "src/layout/components/PageTemplate";
import {
  FileUploaderViewer,
  FileUploaderViewerSingleFileType,
  FileUploaderViewerType,
} from "src/components/FileUploader/FileUploaderViewer/FileUploaderViewer";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { PaperBox } from "src/components/Paper/Paper";

interface CustomizedState {
  id: string;
}

const ItemInfo = () => {
  const location = useLocation();
  const state = location.state as CustomizedState;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = state;

  const [materialData, setMaterialData] = useState([] as any);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const {
    singleItem,
    isLoading,
    isError,
    isDeleteError,
    isDeleteLoading,
    isDeleteSuccess,
    isSuccess,
    message,
    deleteMessage,
  } = useSelector((state: RootState) => state.items);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      setMaterialData(singleItem);
      setLoading(false);
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, isSuccess, message, dispatch]);

  useEffect(() => {
    if (isDeleteError) {
      enqueueSnackbar(deleteMessage, { variant: "error" });
    }
    if (isDeleteSuccess) {
      enqueueSnackbar(deleteMessage, { variant: "success" });
      navigate("/materials/materials-list");
    }
    return () => {
      dispatch(reset());
    };
  }, [isDeleteError, isDeleteSuccess, deleteMessage, dispatch]);

  useEffect(() => {
    const infoValue = {
      id: Number(id),
    };
    dispatch(getItemsId(infoValue));
  }, []);

  const onDelete = () => {
    const deleteValue = {
      material_ids: [Number(id)],
    };
    dispatch(deleteItems(deleteValue)).then(() => setIsDeleteModalOpen(false));
  };

  const moveToEdit = () => {
    navigate("/materials/material-edit", {
      state: { data: materialData, forwardedFromInfo: true },
    });
  };

  return (
    <PageTemplate
      isTransparent
      crumbs={[
        {
          label: "materials",
          href: "/materials/materials-list",
          depth: -1,
        },
        {
          label: "material information",
        },
      ]}
    >
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <PaperBox>
            <Box mb={12}>
              <FormHeading>Material Information</FormHeading>
            </Box>
            <Grid
              container
              rowSpacing={12}
              columnSpacing={{ sm: 0, md: 16 }}
              xs={12}
              md={10}
              mb={14}
            >
              <Grid item xs={12} md={6}>
                <Box width="100%">
                  <InfoItems
                    title="Material Name"
                    subTilte={materialData?.name ?? "Not Found!"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box width="100%">
                  <InfoItems
                    title="Material Code"
                    subTilte={materialData?.code ?? "Not Found!"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box width="100%">
                  <InfoItems
                    title="Material Unit"
                    subTilte={materialData?.unit?.title ?? "Not Found!"}
                  />
                </Box>
              </Grid>
            </Grid>
          </PaperBox>

          {materialData?.qualities && materialData?.qualities.length > 0 ? (
            <PaperBox>
              <SubTitleBar>Material Qualities</SubTitleBar>
              <Qualities>
                {materialData?.qualities?.map((innerItem: any) => {
                  return (
                    <Box key={innerItem.id} padding={2}>
                      <TableChip>{innerItem?.title}</TableChip>
                    </Box>
                  );
                })}
              </Qualities>
            </PaperBox>
          ) : null}

          <PaperBox>
            <Box mb={12}>
              <FormHeading>Notes & Files</FormHeading>
            </Box>
            <Grid
              container
              rowSpacing={12}
              columnSpacing={{ sm: 0, md: 16 }}
              md={10}
              xs={12}
            >
              <Grid item xs={12} md={6}>
                <Box width="100%">
                  <InfoItems
                    title="Notes"
                    subTilte={materialData?.notes ?? "Not Found!"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FileViewerWrapper>
                  <FileUploaderViewer
                    type={FileUploaderViewerType.Single}
                    singleFileType={FileUploaderViewerSingleFileType.Image}
                    file={materialData?.image}
                  />
                </FileViewerWrapper>
              </Grid>
            </Grid>
          </PaperBox>

          <Actions width="100%">
            {/* <Box>
              <StyledLoadingButton
                variant="outlined"
                color="error"
                fullWidth
                loading={isDeleteLoading}
                onClick={() => setIsDeleteModalOpen(true)}
              >
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color={colors.error[500]}
                >
                  Remove Material
                </Typography>
              </StyledLoadingButton>
            </Box> */}
            <Box marginLeft={12}>
              <StyledButton
                variant="contained"
                color="secondary"
                fullWidth
                disabled={isDeleteLoading}
                onClick={moveToEdit}
              >
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color={colors.base.white}
                >
                  Edit Material
                </Typography>
              </StyledButton>
            </Box>
          </Actions>
        </Container>
      )}

      <ConfirmModal
        title={`Remove ${materialData?.name} Material`}
        iconName="ModalRemove"
        open={isDeleteModalOpen}
        isLoading={isDeleteLoading}
        onDismiss={() => setIsDeleteModalOpen(false)}
        onConfirm={onDelete}
        confrimText="Remove"
        cancelText="Cancel"
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to remove {materialData?.name}?
        </Typography>
      </ConfirmModal>
    </PageTemplate>
  );
};

export default ItemInfo;

const FileViewerWrapper = styled(Box)`
  width: 170px;
  height: 170px;
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

const Qualities = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 18px;
`;

const SubTitleBar = styled("div")`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 24px;
  color: ${colors.gray[850]};
  font-size: 21px;
  font-weight: 500;
`;

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  & > div {
    width: 184px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    & > div {
      width: 142px;
    }
  }
`;
