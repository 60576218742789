import { Box, Typography } from '@mui/material'
import colors from 'src/theme/colors'

type Props = {
    title?: string | null;
    iconName?: string | null
}

const IconItem = (props: Props) => {
    return (
        <Box display={"flex"} flexDirection="row" alignItems={"center"} justifyContent={"flex-start"}>
            <img src={process.env.PUBLIC_URL + `/assets/icons/${props.iconName}.svg`} />
            <Typography color={colors.gray[750]} fontSize={14} fontWeight={400} pl={3}>
                {props.title ? props.title : "Not Found"}
            </Typography>
        </Box>
    )
}

export default IconItem