import { axios } from "../../../config/Axios";
import { getData, postItem } from "./types";

const getItems = async (data: getData) => {
  const response = await axios.get(
    `/materials?page=${data.page}&limit=${data?.limit ? data?.limit : 10}`
  );
  return response;
};
const getItemsId = async (vendorId: number) => {
  const response = await axios.get(`/materials/${vendorId}`);
  return response;
};
const getUnits = async (data: getData) => {
  const response = await axios.get(`/units?page=${data.page}`);
  return response;
};
const getQualities = async (data: getData) => {
  const response = await axios.get(`/qualities?page=${data.page}`);
  return response;
};

//Items submit
const postItems = async (Data: postItem) => {
  const response = await axios.post("/materials", Data);
  return response;
};
//Items submit
const patchItems = async (data: any) => {
  const response = await axios.post(`/materials/${data.id}`, data.data);
  return response;
};
const deleteItems = async (data: any) => {
  const response = await axios.delete(`/materials`, { data: data });
  return response;
};

const vendorServices = {
  getItems,
  getItemsId,
  deleteItems,
  getUnits,
  getQualities,
  postItems,
  patchItems,
};
export default vendorServices;
