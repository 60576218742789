import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import StyledTable from "src/components/Table";
import { GridColDef } from "@mui/x-data-grid";
import { AppDispatch, RootState } from "src/store";
import { deleteItems, getItems, reset } from "../redux/ItemSlice";
import { IconButton, Box, Typography } from "@mui/material";
import { Add, Eye } from "iconsax-react";
import colors from "src/theme/colors";
import styled from "@emotion/styled";
import { StyledButton } from "src/components/Button";
import TableChip from "src/components/TableChip";
import ConfirmModal from "src/components/Modal";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";

const ItemTable = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(1);
  const [Data, setData] = useState([] as any);
  const [MetaData, setMetaData] = useState([] as any);
  const [selected, setSelected] = useState([] as any);
  const [selectedId, setSelectedId] = useState([0]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const {
    items,
    itemsMeta,
    isError,
    isDeleteError,
    isSuccess,
    isDeleteSuccess,
    message,
    deleteMessage,
    isLoading,
    isDeleteLoading,
  } = useSelector((state: RootState) => state.items);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      if (message) {
        enqueueSnackbar(message, { variant: "success" });
      } else {
        setData(items);
        setMetaData(itemsMeta);
      }
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, isSuccess, message]);

  useEffect(() => {
    if (isDeleteError) {
      enqueueSnackbar(deleteMessage, { variant: "error" });
    }
    if (isDeleteSuccess) {
      enqueueSnackbar(deleteMessage, { variant: "success" });
      dispatch(
        getItems({
          page:
            Data.length <= 1 || selectedId.length >= Data.length
              ? page > 1
                ? page - 1
                : 1
              : page,
        })
      ).then(() => setIsDeleteModalOpen(false));

      if (Data.length <= 1 || selectedId.length >= Data.length) {
        setPage(page > 1 ? page - 1 : 1);
      }
      setSelected([]);
      setSelectedId([]);
    }
    return () => {
      dispatch(reset());
    };
  }, [isDeleteError, isDeleteSuccess, deleteMessage]);

  useEffect(() => {
    dispatch(
      getItems({
        page: page,
      })
    );
    setSelected([]);
    setSelectedId([]);
  }, [page]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
      filterable: false,
    },
    {
      field: "name",
      headerName: "Material Name",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "code",
      headerName: "Material Code",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "qualities",
      headerName: "Material Qualities",
      flex: 1,
      minWidth: 240,
      renderCell: (params) => (
        <Box display="flex" flexDirection="row" flexWrap="wrap" py={10}>
          {params.row?.qualities?.map((item: any) => {
            return (
              <Box key={item.id} padding={2} color={"#1E2329"}>
                <TableChip>{item.title}</TableChip>
              </Box>
            );
          })}
        </Box>
      ),
    },
    {
      field: "unit",
      headerName: "Material Unit",

      flex: 1,
      minWidth: 180,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.unit.title}
        </Box>
      ),
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.4,
      minWidth: 140,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          flexDirection="row"
        >
          <IconButton
            onClick={() =>
              navigate("/materials/material-information", {
                state: { id: params?.id },
              })
            }
          >
            <Eye color={colors.gray[600]} size="24" />
          </IconButton>
          <IconButton
            onClick={() =>
              navigate("/materials/material-edit", {
                state: { data: params.row },
              })
            }
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Edit.svg"}
              alt="edit"
            />
          </IconButton>
          {/* <IconButton>
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Remove.svg"}
              alt="delete"
              onClick={() => {
                setSelectedId([Number(params?.id)]);
                setIsDeleteModalOpen(true);
              }}
            />
          </IconButton> */}
        </Box>
      ),
    },
  ];

  const onDelete = (selected: any) => {
    const deleteValue = {
      material_ids: selected,
    };
    dispatch(deleteItems(deleteValue));
  };

  return (
    <PageTemplate
      crumbs={[
        {
          label: "materials list",
        },
      ]}
    >
      <ListBoundary
        isEmpty={Data.length === 0 || !Data}
        isDataLoading={isLoading}
        fallbackImageURL="/assets/images/emptyStates/Materials-List EmptyState.svg"
        description="Add Your First Material!"
        buttonLabel="New Material"
        onClick={() => navigate("/materials/material-new")}
      >
        <StyledHeader>
          <StyledButton
            variant="contained"
            endIcon={<Add size="24" color={colors.gray[850]} />}
            onClick={() => navigate("/materials/material-new")}
          >
            New Material
          </StyledButton>
          {/* {selected.length !== 0 ? (
            <StyledButton
              variant="contained"
              endIcon={
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/removeWhite.svg"}
                  alt="delete"
                />
              }
              onClick={() => {
                setSelectedId(selected);
                setIsDeleteModalOpen(true);
              }}
              color="error"
            >
              Remove Materials
            </StyledButton>
          ) : (
            <StyledButton
              variant="contained"
              endIcon={<Add size="24" color={colors.gray[850]} />}
              onClick={() => navigate("/materials/material-new")}
            >
              New Material
            </StyledButton>
          )} */}
        </StyledHeader>
        <StyledTable
          disableColumnMenu
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableVirtualization
          disableSelectionOnClick
          loading={isLoading}
          checkboxSelection
          experimentalFeatures={{ newEditingApi: true }}
          rows={Data}
          columns={columns}
          getRowHeight={() => "auto"}
          paginationMode="server"
          onSelectionModelChange={(e) => setSelected(e)}
          onPageChange={(e) => setPage(e + 1)}
          page={page - 1}
          rowCount={MetaData?.pagination?.total_items}
        />
      </ListBoundary>
      <ConfirmModal
        title={selectedId.length > 1 ? "Remove Materials" : "Remove Material"}
        iconName={"ModalRemove"}
        open={isDeleteModalOpen}
        isLoading={isLoading || isDeleteLoading}
        maxWidth={450}
        onDismiss={() => setIsDeleteModalOpen(false)}
        onConfirm={() => onDelete(selectedId)}
        confrimText={"Remove"}
        cancelText={"Cancel"}
      >
        <Typography
          textAlign="center"
          fontSize={14}
          fontWeight={400}
          color={colors.gray[450]}
        >
          Are you sure you want to remove{" "}
          {selectedId.length > 1 ? "these Materials" : "this Material"}? By
          clicking on{" "}
          <b>
            “{selectedId.length > 1 ? "Remove Materials" : "Remove Material"}”
          </b>
          , all data related to{" "}
          {selectedId.length > 1 ? "these materials" : "this material"} will
          also be removed.
        </Typography>
      </ConfirmModal>
    </PageTemplate>
  );
};

const StyledHeader = styled(Box)`
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 24px;
`;

export default ItemTable;
