import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import StockTakingServices from "./StockTakingServices";

const initialState = {
  isError: false,
  isDeleteError: false,
  isStockTakingsExportError: false,
  isProductsError: false,
  isProductsExportError: false,
  isSuccess: false,
  isDeleteSuccess: false,
  isStockTakingsExportSuccess: false,
  isProductsSuccess: false,
  isLoading: true,
  isSingleLoading: true,
  isCreateLoading: false,
  isDeleteLoading: false,
  isProductsLoading: true,
  isStockTakingsExportLoading: false,
  isProductsExportLoading: false,
  message: "",
  deleteMessage: "",
  productsMessage: "",
  StockTakingsExportMessage: "",
  productsExportMessage: "",
  StockTaking: [],
  StockTakingMeta: [],
  singleStockTaking: [],
  products: [],
  productsMeta: [],
};

export const getStockTaking = createAsyncThunk(
  "stock-taking",
  async (data: any, thunkAPI) => {
    try {
      return await StockTakingServices.getStockTaking(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const exportStockTakingsList = createAsyncThunk(
  "stock-takings-export",
  async (data: any, thunkAPI) => {
    try {
      return await StockTakingServices.exportStockTakingsList(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getStockTakingId = createAsyncThunk(
  "stock-taking/id",
  async (data: any, thunkAPI) => {
    try {
      return StockTakingServices.getStockTakingId(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const postStockTaking = createAsyncThunk(
  "stock-taking/post",
  async (data: any, thunkAPI) => {
    try {
      return await StockTakingServices.postStockTaking(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const patchStockTaking = createAsyncThunk(
  "stock-taking/patch",
  async (data: any, thunkAPI) => {
    try {
      return await StockTakingServices.patchStockTaking(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteStockTaking = createAsyncThunk(
  "stock-taking/delete",
  async (data: any, thunkAPI) => {
    try {
      return await StockTakingServices.deleteStockTaking(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const stopStockTaking = createAsyncThunk(
  "stock-taking/finish",
  async (data: any, thunkAPI) => {
    try {
      return await StockTakingServices.stopStockTaking(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const completeStockTaking = createAsyncThunk(
  "stock-taking/complete",
  async (data: any, thunkAPI) => {
    try {
      return await StockTakingServices.completeStockTaking(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getStockTakingProducts = createAsyncThunk(
  "stock-taking/products",
  async (data: any, thunkAPI) => {
    try {
      return await StockTakingServices.getStockTakingProducts(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const exportStockTakingProducts = createAsyncThunk(
  "stock-taking/products-export",
  async (data: any, thunkAPI) => {
    try {
      return await StockTakingServices.exportStockTakingProducts(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const StockTakingSlice = createSlice({
  name: "StockTaking",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isDeleteLoading = false;
      state.isStockTakingsExportLoading = false;
      state.isSingleLoading = true;
      state.isCreateLoading = false;
      state.isDeleteLoading = false;
      state.isProductsLoading = false;
      state.isProductsExportLoading = false;
      state.isSuccess = false;
      state.isDeleteSuccess = false;
      state.isStockTakingsExportSuccess = false;
      state.isProductsSuccess = false;
      state.isError = false;
      state.isDeleteError = false;
      state.isStockTakingsExportError = false;
      state.isProductsError = false;
      state.isProductsExportError = false;
      state.message = "";
      state.deleteMessage = "";
      state.StockTakingsExportMessage = "";
      state.productsMessage = "";
      state.productsExportMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStockTaking.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStockTaking.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.StockTaking = action.payload.data.results;
        state.StockTakingMeta = action.payload.data.meta;
      })
      .addCase(getStockTaking.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })
      .addCase(exportStockTakingsList.pending, (state) => {
        state.isStockTakingsExportLoading = true;
      })
      .addCase(exportStockTakingsList.fulfilled, (state, action) => {
        state.isStockTakingsExportLoading = false;
        state.isStockTakingsExportSuccess = true;
      })
      .addCase(exportStockTakingsList.rejected, (state, action) => {
        state.isStockTakingsExportLoading = false;
        state.isStockTakingsExportError = true;
        state.StockTakingsExportMessage = `${action.payload}`;
      })

      .addCase(getStockTakingId.pending, (state) => {
        state.isSingleLoading = true;
      })
      .addCase(getStockTakingId.fulfilled, (state, action) => {
        state.isSingleLoading = false;
        state.isSuccess = true;
        state.singleStockTaking = action.payload.data.results;
      })
      .addCase(getStockTakingId.rejected, (state, action) => {
        state.isSingleLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(postStockTaking.pending, (state) => {
        state.isLoading = true;
        state.isCreateLoading = true;
      })
      .addCase(postStockTaking.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(postStockTaking.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreateLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(patchStockTaking.pending, (state) => {
        state.isLoading = true;
        state.isCreateLoading = true;
      })
      .addCase(patchStockTaking.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(patchStockTaking.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreateLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(deleteStockTaking.pending, (state) => {
        state.isDeleteLoading = true;
      })
      .addCase(deleteStockTaking.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteSuccess = true;
        state.deleteMessage = `${action.payload.data.message}`;
      })
      .addCase(deleteStockTaking.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.deleteMessage = `${action.payload}`;
      })

      .addCase(stopStockTaking.pending, (state) => {
        state.isLoading = true;
        state.isDeleteLoading = true;
      })
      .addCase(stopStockTaking.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isDeleteLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(stopStockTaking.rejected, (state, action) => {
        state.isLoading = false;
        state.isDeleteLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(getStockTakingProducts.pending, (state) => {
        state.isProductsLoading = true;
      })
      .addCase(getStockTakingProducts.fulfilled, (state, action) => {
        state.isProductsLoading = false;
        state.isProductsSuccess = true;
        state.products = action.payload.data.results;
        state.productsMeta = action.payload.data.meta;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(getStockTakingProducts.rejected, (state, action) => {
        state.isProductsLoading = false;
        state.isProductsError = true;
        state.productsMessage = `${action.payload}`;
      })
      .addCase(exportStockTakingProducts.pending, (state) => {
        state.isLoading = true;
        state.isDeleteLoading = true;
      })
      .addCase(exportStockTakingProducts.fulfilled, (state, action) => {
        state.isProductsExportLoading = false;
        state.productsExportMessage = `${action.payload.data.message}`;
      })
      .addCase(exportStockTakingProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isProductsExportLoading = false;
        state.isProductsExportError = true;
        state.productsExportMessage = `${action.payload}`;
      });
  },
});

export const { reset } = StockTakingSlice.actions;
export default StockTakingSlice.reducer;
