import { axios } from "../../../config/Axios";

//Logs get
const getLogs = async (data: any) => {
  const response = await axios.get(
    `/logs?page=${data.page}&query=${data.search ? data.search : ""}`
  );
  return response;
};

//Logs EXPORT
const exportLogs = async (data: any) => {
  const response = await axios({
    url: `/logs?page=${data.page}${
      data.search ? `&query=${data.search}` : ""
    }&export_excel=true`,
    method: "get",
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${Date.now()}.xlsx`);
  document.body.appendChild(link);
  link.click();

  return response;
};

const LogServices = {
  getLogs,
  exportLogs,
};

export default LogServices;
