import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import colors from "../../theme/colors";
import { IconButton, styled } from "@mui/material";
import { StyledButton } from "../Button";
import { StyledLoadingButton } from "../LoadingButton";

type Props = {
  children: React.ReactNode;
  iconName: string;
  title: string;
  open: boolean;
  maxWidth?: string | number;
  primaryConfirm?: boolean;
  cancelText: string;
  confrimText: string;
  isLoading?: boolean;
  onConfirm: () => void;
  onDismiss: (open: boolean) => void;
};

export default function ConfirmModal(props: Props) {
  const {
    iconName,
    title,
    children,
    open,
    maxWidth,
    primaryConfirm,
    onConfirm,
    onDismiss,
    confrimText,
    cancelText,
  } = props;

  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setShow(open);
  }, [open]);

  return (
    <div>
      <Modal open={show} onClose={() => onDismiss(show)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 350,
            maxWidth: maxWidth ?? "auto",
            bgcolor: colors.base.white,
            padding: "16px 24px 40px",
            border: `1px solid ${colors.gray[200]}`,
            borderRadius: "5px",
          }}
        >
          <StyledOuter>
            <IconButton onClick={() => onDismiss(show)}>
              <img
                src={process.env.PUBLIC_URL + `/assets/icons/close.svg`}
                alt={"close"}
              />
            </IconButton>
          </StyledOuter>
          <StyledInner>
            <img
              src={process.env.PUBLIC_URL + `/assets/icons/${iconName}.svg`}
              alt={iconName}
            />
            <Box pt={20} pb={10}>
              <Typography
                fontSize={18}
                fontWeight={700}
                color={colors.gray[850]}
              >
                {title}
              </Typography>
            </Box>
            {children}
            <Box display="flex" flexDirection="row" gap={5} pt={15}>
              <StyledButton
                color="inherit"
                variant="outlined"
                fullWidth
                disabled={props?.isLoading}
                sx={{ width: "150px" }}
                onClick={() => onDismiss(show)}
              >
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color={colors.gray[800]}
                >
                  {cancelText}
                </Typography>
              </StyledButton>
              <StyledLoadingButton
                loading={props?.isLoading}
                variant="contained"
                color={primaryConfirm ? "primary" : "error"}
                fullWidth
                sx={{ width: "150px" }}
                onClick={() => onConfirm()}
              >
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color={primaryConfirm ? colors.base.black : colors.base.white}
                >
                  {confrimText}
                </Typography>
              </StyledLoadingButton>
            </Box>
          </StyledInner>
        </Box>
      </Modal>
    </div>
  );
}

const StyledInner = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledOuter = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
