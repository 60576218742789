import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import colors from "src/theme/colors";
import CustomPagination from "../Pagination";
import CustomCheckbox from "../Checkbox";
import { CircularProgress } from "@mui/material";

export default function StyledTable(props: DataGridProps) {
  return (
    <Container>
      <Box style={{ flexGrow: 1 }}>
        <StyledDataGrid
          {...props}
          autoHeight
          rowHeight={56}
          sx={{
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-cellContent &.MuiDataGrid-cell--textLeft": {
              textAlign: "left !important",
            },
          }}
          components={{
            Pagination: CustomPagination,
            BaseCheckbox: CustomCheckbox,
            NoRowsOverlay: () => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                Nothing Found
              </Box>
            ),
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </Container>
  );
}

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "auto",
  width: "100%",
  position: "relative",

  // [theme.breakpoints.down("lg")]: {
  //   width: "calc(100% + 60px)",
  //   left: "-30px",
  // },
}));

const StyledDataGrid = styled(DataGrid)(() => ({
  "&.MuiDataGrid-root": {
    border: "none",
  },
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: colors.gray[25],
    border: "none",
    padding: "0 14px",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    textAlign: "right",
    color: colors.gray[850],
  },
  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
    display: "flex",
    justifyContent: "center",
  },
  "& .MuiDataGrid-cellContent": {
    fontWeight: 400,
    fontSize: "14px",
    color: colors.gray[850],
    justifyContent: "end",
  },
  "& .MuiDataGrid-cell": {
    border: "none",
    padding: "0 14px",
  },
  "& .MuiDataGrid-row": {
    border: "none",
    minHeight: "56px !important",
  },
  "& .MuiDataGrid-row:nth-child(even)": {
    backgroundColor: colors.gray[25],
  },
  "& .MuiDataGrid-footerContainer": {
    paddingTop: "48px",
    border: "none",
  },
}));
