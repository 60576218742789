import { Box, IconButton, Modal, styled } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { FC, useEffect, useState } from "react";
import {
  getProductDetails,
  getProducts,
  reset as resetProducts,
} from "src/features/products/redux/ProductsSlice";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";
import StyledTable from "src/components/Table";
import colors from "src/theme/colors";
import moment from "moment";
import {
  ArrowDown,
  ArrowUp,
  ArrowUp2,
  Barcode,
  Location,
  Weight,
} from "iconsax-react";
import { AppDispatch, RootState } from "src/store";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

interface ProductDetailsTableModalprops {
  isOpen: boolean;
  warehouseId: string;
  productId: string;
  productTitle: string;
  barcode: string;
  placement: string;
  onDismiss: () => void;
}

export const ProductDetailsTableModal: FC<ProductDetailsTableModalprops> = (
  props
) => {
  const {
    isOpen,
    warehouseId,
    productId,
    productTitle,
    barcode,
    placement,
    onDismiss,
  } = props;

  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(1);
  const [productDetailsData, setProductDetailsData] = useState<any>(null);
  const [productDetailsDataMeta, setProductDetailsDataMeta] =
    useState<any>(null);

  const {
    productDetails,
    productDetailsMeta,
    isProductDetailsLoading,
    isProductDetailsError,
    isProductDetailsSuccess,
    productDetailsMessage,
  } = useSelector((state: RootState) => state.products);

  useEffect(() => {
    if (isProductDetailsError) {
      enqueueSnackbar(productDetailsMessage, { variant: "error" });
      onDismiss();
      dispatch(resetProducts());
    }
    if (isProductDetailsSuccess) {
      setProductDetailsData(productDetails);
      setProductDetailsDataMeta(productDetailsMeta);
    }
    // return () => {
    //   dispatch(resetProducts());
    // };
  }, [
    isProductDetailsError,
    isProductDetailsSuccess,
    productDetailsMessage,
    isProductDetailsLoading,
  ]);

  useEffect(() => {
    if (isOpen) {
      dispatch(
        getProductDetails({
          warehouseId: Number(warehouseId),
          productId: Number(productId),
        })
      );
    }
    return () => {
      dispatch(resetProducts());
    };
  }, [isOpen]);

  const columns: GridColDef[] = [
    {
      field: "event",
      headerName: "Event",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Box padding={2} color="#1E2329">
          <EventLabel>{params.row?.event}</EventLabel>
          {params.row?.event === "In" ? (
            <ArrowUp size={16} color={colors.success[500]} />
          ) : (
            <ArrowDown size={16} color={colors.error[400]} />
          )}
        </Box>
      ),
    },
    {
      field: "date_time",
      headerName: "Date & Time",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Box padding={2} color="#1E2329">
          {moment(params.row?.date_time).format("DD-MMM h:mma")}
        </Box>
      ),
    },
    {
      field: "employee",
      headerName: "Employee",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "warehouse",
      headerName: "Warehouse",
      flex: 1,
      minWidth: 180,
    },
  ];

  return (
    <ModalContainer
      open={isOpen}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container>
        <StyledHeader>
          <TitleBox>
            <Title>{productTitle}</Title>
            <Badge>
              <BadgeIcon>
                <Barcode size={16} color={colors.secondary[800]} />
              </BadgeIcon>
              {barcode}
            </Badge>
            <Badge>
              <BadgeIcon>
                <Location size={16} color={colors.secondary[800]} />
              </BadgeIcon>
              {placement}
            </Badge>
          </TitleBox>
        </StyledHeader>

        <ListBoundary
          isEmpty={
            productDetailsData?.log?.length === 0 ||
            !productDetailsData?.log ||
            !productDetailsData
          }
          isDataLoading={isProductDetailsLoading}
          fallbackImageURL="/assets/images/emptyStates/ProductDetails-List EmptyState.svg"
          description="No Result Found ..."
        >
          <StyledTable
            disableColumnMenu
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableVirtualization
            disableSelectionOnClick
            loading={isProductDetailsLoading}
            getRowHeight={() => 56}
            experimentalFeatures={{ newEditingApi: true }}
            rows={productDetailsData?.log}
            columns={columns}
            onPageChange={(e) => setPage(e + 1)}
            page={page - 1}
            getRowId={(data) => data.date_time}
            paginationMode="server"
            rowCount={productDetailsDataMeta?.pagination?.total_items}
          />
        </ListBoundary>
      </Container>
    </ModalContainer>
  );
};

const ModalContainer = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled(Box)`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 52px 64px;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 90%;
    padding: 28px 42px;
  }
`;

const StyledHeader = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 24px;
  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TitleBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Title = styled("h6")`
  font-size: 24px;
  line-height: 32px;
  color: ${colors.gray[850]};
  margin-right: 40px;
`;

const EventLabel = styled("span")`
  margin-right: 10px;
`;

const Badge = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 28px;
  border-radius: 30px;
  padding: 6px 16px;
  white-space: nowrap;
  background-color: ${colors.secondary[50]};
  color: ${colors.secondary[800]};
  margin-right: 24px;
  &:last-child {
    margin-right: 0;
  }
`;

const BadgeIcon = styled("div")`
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
