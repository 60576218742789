import { PageTemplate } from "src/layout/components/PageTemplate";
import RoleForm from "../components/RoleForm";

const RoleEdit = () => {
  return (
    <PageTemplate
      isTransparent
      crumbs={[
        {
          label: "Role Management",
          href: "/roles/roles-list",
          depth: -1,
        },
        {
          label: "new Role",
        },
      ]}
    >
      <RoleForm />
    </PageTemplate>
  );
};

export default RoleEdit;
