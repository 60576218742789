import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  contactdeleteValue,
  ContactPatchProps,
  contactValue,
  getData,
  getIdValue,
  vendordeleteValue,
  vendorPatchValue,
  vendorValue,
} from "./types";
import vendorServices from "./VendorServices";

const initialState = {
  isError: false,
  isDeleteError: false,
  isSuccess: false,
  isPatchSuccess: false,
  isDeleteSuccess: false,
  isLoading: true,
  isSingleLoading: true,
  isCreateLoading: false,
  isPatchLoading: false,
  isDeleteLoading: false,
  message: "",
  deleteMessage: "",
  vendors: [],
  vendorsMeta: [],
  singleVendor: [],
  contacts: [],
};

export const getVendors = createAsyncThunk(
  "vendors",
  async (data: any, thunkAPI) => {
    try {
      return await vendorServices.getVendors(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getVendorsId = createAsyncThunk(
  "vendors/id",
  async (data: getIdValue, thunkAPI) => {
    try {
      return vendorServices.getVendorsId(data.id);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getContactId = createAsyncThunk(
  "contact/id",
  async (data: getIdValue, thunkAPI) => {
    try {
      return vendorServices.getContactId(data.id);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const postVendor = createAsyncThunk(
  "vendors/post",
  async (data: vendorValue, thunkAPI) => {
    try {
      return await vendorServices.postVendor(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const postContact = createAsyncThunk(
  "contact/post",
  async (data: contactValue, thunkAPI) => {
    try {
      return await vendorServices.postContact(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const patchVendor = createAsyncThunk(
  "vendors/patch",
  async (data: vendorPatchValue, thunkAPI) => {
    try {
      return await vendorServices.patchVendor(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteVendor = createAsyncThunk(
  "vendors/delete",
  async (data: vendordeleteValue, thunkAPI) => {
    try {
      return await vendorServices.deleteVendor(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteContact = createAsyncThunk(
  "contact/delete",
  async (data: contactdeleteValue, thunkAPI) => {
    try {
      return await vendorServices.deleteContact(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const patchContact = createAsyncThunk(
  "contact/patch",
  async (data: ContactPatchProps, thunkAPI) => {
    try {
      return await vendorServices.patchContact(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isPatchLoading = false;
      state.isCreateLoading = false;
      state.isSingleLoading = true;
      state.isDeleteLoading = false;
      state.isSuccess = false;
      state.isPatchSuccess = false;
      state.isDeleteSuccess = false;
      state.isError = false;
      state.isDeleteError = false;
      state.message = "";
      state.deleteMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVendors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVendors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.vendors = action.payload.data.results;
        state.vendorsMeta = action.payload.data.meta;
      })
      .addCase(getVendors.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(getVendorsId.pending, (state) => {
        state.isSingleLoading = true;
      })
      .addCase(getVendorsId.fulfilled, (state, action) => {
        state.singleVendor = action.payload.data.results;
        state.isSingleLoading = false;
        state.isSuccess = true;
      })
      .addCase(getVendorsId.rejected, (state, action) => {
        state.isSingleLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(getContactId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })
      .addCase(getContactId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContactId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.contacts = action.payload.data.results;
      })

      .addCase(postVendor.pending, (state) => {
        state.isLoading = true;
        state.isCreateLoading = true;
      })
      .addCase(postVendor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(postVendor.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreateLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(postContact.pending, (state) => {
        state.isLoading = true;
        state.isCreateLoading = true;
      })
      .addCase(postContact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(postContact.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreateLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(patchVendor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patchVendor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isPatchSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(patchVendor.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(deleteVendor.pending, (state) => {
        state.isDeleteLoading = true;
      })
      .addCase(deleteVendor.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteSuccess = true;
        state.deleteMessage = `${action.payload.data.message}`;
      })
      .addCase(deleteVendor.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.deleteMessage = `${action.payload}`;
      })

      .addCase(deleteContact.pending, (state) => {
        state.isLoading = true;
        state.isDeleteLoading = true;
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isDeleteLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(deleteContact.rejected, (state, action) => {
        state.isLoading = false;
        state.isDeleteLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(patchContact.pending, (state) => {
        state.isLoading = true;
        state.isPatchLoading = true;
      })
      .addCase(patchContact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isPatchLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(patchContact.rejected, (state, action) => {
        state.isLoading = false;
        state.isPatchLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      });
  },
});

export const { reset } = vendorSlice.actions;
export default vendorSlice.reducer;
