import { Box, Modal, styled } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import colors from "src/theme/colors";
import { downloadURLFile } from "src/utils/downloadURLFile";

interface DriverLicenceViewModal {
  imageUrl: string;
  open: boolean;
  onDismiss: () => void;
}

export const DriverLicenceViewModal: FC<DriverLicenceViewModal> = (props) => {
  const { imageUrl, open, onDismiss } = props;
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Content>
        <Wrapper>
          <Title>Driver Licence</Title>
          <ImageBox>
            <Image style={{ backgroundImage: `url(${imageUrl})` }} />
          </ImageBox>

          <Actions>
            <DownloadBtn
              onClick={() =>
                downloadURLFile(imageUrl, "driver_license", () =>
                  enqueueSnackbar("Failed to download Image", {
                    variant: "error",
                  })
                )
              }
            >
              <img
                src={process.env.PUBLIC_URL + `/assets/icons/DownloadBlue.svg`}
              />
            </DownloadBtn>
          </Actions>

          <CloseIcon onClick={() => onDismiss()}>
            <img
              src={process.env.PUBLIC_URL + `/assets/icons/${"close"}.svg`}
            />
          </CloseIcon>
        </Wrapper>
      </Content>
    </Modal>
  );
};

const Wrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  min-width: 800px;
  max-height: 90vh;
  overflow: auto;
  height: fit-content;
  background-color: ${colors.base.white};
  border: 1px solid ${colors.gray[200]};
  border-radius: 5px;
  padding: 42px;
  ${(props) => props.theme.breakpoints.down("lg")} {
    width: 90%;
    min-width: auto;
  }
`;

const Content = styled(Box)`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  & > form {
    width: 100%;
  }
`;

const Title = styled("h6")`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 28px;
  text-align: center;
  color: ${colors.gray[850]};
`;

const ImageBox = styled("div")`
  width: 690px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin: 0 auto;
  border: 1px dashed ${colors.gray[300]};
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
  @media screen and (max-width: 720px) {
    height: 340px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    height: 280px;
  }
  @media screen and (max-width: 500px) {
    height: 250px;
  }
  @media screen and (max-width: 400px) {
    height: 200px;
  }
`;

const Image = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 5;
`;

const Actions = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 32px;
`;

const DownloadBtn = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

const CloseIcon = styled(Box)`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 4;
  cursor: pointer;
`;
