import {
  Autocomplete,
  Avatar,
  Box,
  Modal,
  TextField,
  styled,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GrayButton } from "src/components/GrayButton";
import { StyledTextField } from "src/components/Input";
import { StyledLoadingButton } from "src/components/LoadingButton";
import {
  getEmployes,
  reset as resetAllEmployes,
} from "src/features/employes/redux/EmployesSlice";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";

interface AddNewEmployeeModal {
  warehouseName: string;
  buttonLoading?: boolean;
  open: boolean;
  onConfirm: (selectedEmployeeId: number) => void;
  onDismiss: () => void;
}

export const AddNewEmployeeModal: FC<AddNewEmployeeModal> = (props) => {
  const { buttonLoading, warehouseName, open, onConfirm, onDismiss } = props;

  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [allEmployeesData, setAllEmployeesData] = useState([] as any);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);

  const {
    employes: allEmployees,
    isLoading: isAllEmployeesLoading,
    isError: isAllEmployeesError,
    isSuccess: isAllEmployeesSuccess,
    message: allEmployeesMessage,
  } = useSelector((state: RootState) => state.employes);

  useEffect(() => {
    if (isAllEmployeesError) {
      enqueueSnackbar(allEmployeesMessage, { variant: "error" });
    }
    if (isAllEmployeesSuccess) {
      setAllEmployeesData(allEmployees);
    }
  }, [
    isAllEmployeesError,
    isAllEmployeesSuccess,
    allEmployeesMessage,
    dispatch,
  ]);

  useEffect(() => {
    if (open) {
      const getAllEmployeesInfo = {
        page: 1,
        limit: 100,
        unAssigned: true,
      };
      dispatch(getEmployes(getAllEmployeesInfo));
    }

    return () => {
      dispatch(resetAllEmployes());
      setSelectedEmployee(null);
    };
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Wrapper>
        <img
          src={process.env.PUBLIC_URL + `/assets/icons/ColorizedBlueAdd.svg`}
        />
        <Title>Add Employee To {warehouseName}</Title>
        <SubTitle>
          Select an employee or register a new employee and add to kabwe
          warehouse:
        </SubTitle>
        <Autocomplete
          id="employee-selection"
          sx={{ width: "100%" }}
          options={allEmployeesData}
          // open={!isAllEmployeesLoading}
          loading={isAllEmployeesLoading}
          loadingText="loading..."
          autoHighlight
          value={selectedEmployee}
          onChange={(event: any, newValue: any) => {
            setSelectedEmployee(newValue);
          }}
          getOptionLabel={(option: any) => option.name || ""}
          renderOption={(props, option) => (
            <EmployeeItem {...props}>
              <Avatar
                alt={option.name}
                src={option.avatar}
                sx={{
                  bgcolor: colors.gray[20],
                  width: "32px",
                  height: "32px",
                  marginRight: "16px",
                }}
              >
                {option.name}
              </Avatar>
              <ItemContent>
                <Name>{option.name}</Name>
                <Role>{option.roles[0]?.title}</Role>
              </ItemContent>
            </EmployeeItem>
          )}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              label="Choose an employee"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isAllEmployeesLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />

        <Actions>
          <CancelButtonBox>
            <GrayButton
              variant="outlined"
              fullWidth
              disabled={buttonLoading}
              onClick={onDismiss}
            >
              Cancel
            </GrayButton>
          </CancelButtonBox>
          <SubmitButtonBox>
            <StyledLoadingButton
              variant="contained"
              fullWidth
              loading={buttonLoading}
              color="primary"
              onClick={() => onConfirm(selectedEmployee?.id)}
            >
              Confirm
            </StyledLoadingButton>
          </SubmitButtonBox>
        </Actions>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  min-width: 600;
  height: fit-content;
  background-color: ${colors.base.white};
  border: 1px solid ${colors.gray[200]};
  border-radius: 5px;
  padding: 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("lg")} {
    width: 90%;
    min-width: auto;
  }
`;

const Title = styled("h6")`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 20px;
  text-align: center;
  color: ${colors.gray[850]};
`;

const SubTitle = styled("p")`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  margin: 24px 0 20px 0;
  color: ${colors.gray[850]};
`;

const EmployeeItem = styled("li")`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ItemContent = styled("div")`
  width: calc(100% - 36px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Name = styled("span")`
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  color: ${colors.gray[850]};
`;

const Role = styled("span")`
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: ${colors.gray[700]};
`;

const Actions = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  ${(props) => props.theme.breakpoints.down("lg")} {
    flex-direction: column;
  }
`;

const CancelButtonBox = styled(Box)`
  width: 184px;
  margin-right: 24px;
  ${(props) => props.theme.breakpoints.down("lg")} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
  }
`;

const SubmitButtonBox = styled(Box)`
  width: 184px;
  ${(props) => props.theme.breakpoints.down("lg")} {
    width: 100%;
  }
`;
