import { styled } from "@mui/material/styles";
import { Box, styled as styledMui, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import { SentReportChartCard } from "./SentReportsChartCard";
import { SentReportItemsCard } from "./SentReportsItemsCard";
import { GridColDef } from "@mui/x-data-grid";
import { ShippingOriginWarehouseTrucksList } from "./tables/ShippingOriginWarehouseTrucksList";

interface SaleShippingOriginWarehouseInfoPageProps {
  details: any;
  product: any;
  saleOrderId: number;
  warehouseId: number;
  saleOrderName: string;
  shipmentId: number;
}

export const SaleShippingOriginWarehouseInfoPage: FC<
  SaleShippingOriginWarehouseInfoPageProps
> = (props) => {
  const { details, product, saleOrderId, warehouseId, saleOrderName, shipmentId } = props;

  const chartData = [{ name: "Orders", value: details?.total_sent }];

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <InfosContainer>
        <Title>Sent Products Report</Title>
        <InfoCards>
          <BigCol>
            <SentReportChartCard
              totalOrders={details?.total_order}
              sentOrders={details?.total_sent}
              chartData={chartData}
            />
          </BigCol>
          <SmallCols>
            <Col>
              <SentReportItemsCard
                totalValue={details?.total_carriers}
                label="Total Trucks"
                iconName="TruckBlueBG"
              />
            </Col>
            <Col>
              <SentReportItemsCard
                totalValue={details?.total_products}
                subTitle={product?.title}
                label="Total Products"
                iconName="ProductBlueBG"
              />
            </Col>
          </SmallCols>
        </InfoCards>
      </InfosContainer>
      <ShippingOriginWarehouseTrucksList
        saleOrderId={saleOrderId}
        shipmentId={shipmentId}
        warehouseId={warehouseId}
        saleOrderName={saleOrderName}
      />
    </>
  );
};

const InfosContainer = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});

const Title = styled(Typography)({
  width: "100%",
  fontSize: "24px",
  fontWeight: 500,
  textAlign: "left",
  lineHeight: "32px",
  marginBottom: "24px",
  color: colors.midnight[850],
});

const InfoCards = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("xl")} {
    width: 100%;
    flex-direction: column;
    gap: 12px;
  }
`;

const SmallCols = styled(Box)`
  width: 48%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  ${(props) => props.theme.breakpoints.down("xl")} {
    width: 100%;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
  }
`;

const BigCol = styled(Box)`
  width: calc(52% - 12px);
  ${(props) => props.theme.breakpoints.down("xl")} {
    width: 100%;
  }
`;

const Col = styled(Box)`
  width: 50%;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;
