import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import colors from "src/theme/colors";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const StyledContainer = styled("div")`
  display: flex;
  flex-direction: column;
  height: 500;
`;
const StyledTool = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const StyledHolder = styled("div")`
  width: 26px;
  height: 26px;
  border-radius: 4px;
  background-color: ${(props: any) => props?.bg};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const EmployeeChart = () => {
  const options = {
    scales: {
      x: {
        display: false,
        barPercentage: 0.4,
      },
      y: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const labels = ["January"];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [12],
        borderRadius: 4,
        backgroundColor: "#6DB5CD",
      },
      {
        label: "Dataset 2",
        data: [5],
        borderRadius: 4,
        backgroundColor: "#F2CB6C",
      },
      {
        label: "Dataset 2",
        data: [3],
        borderRadius: 4,
        backgroundColor: "#55C5A0",
      },
      {
        label: "Dataset 2",
        data: [8],
        borderRadius: 4,
        backgroundColor: "#EF7287",
      },
    ],
    setPercentage: [10, 2, 20, 40, 4, 6, 18], // H
  };

  return (
    <StyledContainer>
      <Box pb={14}>
        <Typography fontSize={18} fontWeight={500} color={colors.gray[900]}>
          Employees
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        alignItems={"flex-end"}
        justifyContent="space-between"
      >
        <Box
          display={"flex"}
          gap={15}
          flexDirection="row"
          maxWidth={300}
          flexWrap="wrap"
        >
          <Box display={"flex"} flexDirection="row" alignItems={"center"}>
            <StyledHolder sx={{ background: "#F2CB6C" }}>
              <Typography
                fontSize={12}
                fontWeight="600"
                color={colors.base.white}
              >
                a
              </Typography>
            </StyledHolder>
            <Box pl={2}>
              <Typography
                fontSize={14}
                fontWeight={600}
                color={colors.gray[300]}
              >
                Managers
              </Typography>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection="row" alignItems={"center"}>
            <StyledHolder sx={{ background: "#55C5A0" }}>
              <Typography
                fontSize={12}
                fontWeight="600"
                color={colors.base.white}
              >
                b
              </Typography>
            </StyledHolder>
            <Box pl={2}>
              <Typography
                fontSize={14}
                fontWeight={600}
                color={colors.gray[300]}
              >
                Clients
              </Typography>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection="row" alignItems={"center"}>
            <StyledHolder sx={{ background: "#64C5ED" }}>
              <Typography
                fontSize={12}
                fontWeight="600"
                color={colors.base.white}
              >
                c
              </Typography>
            </StyledHolder>
            <Box pl={2}>
              <Typography
                fontSize={14}
                fontWeight={600}
                color={colors.gray[300]}
              >
                Operators
              </Typography>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection="row" alignItems={"center"}>
            <StyledHolder sx={{ background: "#EF7287" }}>
              <Typography
                fontSize={12}
                fontWeight="600"
                color={colors.base.white}
              >
                b
              </Typography>
            </StyledHolder>
            <Box pl={2}>
              <Typography
                fontSize={14}
                fontWeight={600}
                color={colors.gray[300]}
              >
                Owners
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ minWidth: 500 }}>
          <Bar options={options} plugins={[ChartDataLabels]} data={data} />
        </Box>
      </Box>
    </StyledContainer>
  );
};

export default EmployeeChart;
