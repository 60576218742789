import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import WarehouseServices from "./WarehouseServices";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  isPatchLoading: false,
  message: "",
  Users: [],
  UsersMeta: [],
  singleWarehouse: [],
};

export const getWarehouseUsers = createAsyncThunk(
  "WarehouseUsers",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.getWarehouseUsers(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const postWarehouseUsers = createAsyncThunk(
  "WarehouseUsers/post",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.postWarehouseUsers(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteWarehouseUsers = createAsyncThunk(
  "WarehouseUsers/delete",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.deleteWarehouseUsers(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const WarehouseUsersSlice = createSlice({
  name: "WarehouseUsers",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isPatchLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWarehouseUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWarehouseUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.Users = action.payload.data.results;
        state.UsersMeta = action.payload.data.meta;
      })
      .addCase(getWarehouseUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(postWarehouseUsers.pending, (state) => {
        state.isPatchLoading = true;
      })
      .addCase(postWarehouseUsers.fulfilled, (state, action) => {
        state.isPatchLoading = false;
        state.isSuccess = true;
        state.Users = action.payload.data.results;
        state.UsersMeta = action.payload.data.meta;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(postWarehouseUsers.rejected, (state, action) => {
        state.isPatchLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(deleteWarehouseUsers.pending, (state) => {
        state.isPatchLoading = true;
      })
      .addCase(deleteWarehouseUsers.fulfilled, (state, action) => {
        state.isPatchLoading = false;
        state.isSuccess = true;
        state.Users = action.payload.data.results;
        state.UsersMeta = action.payload.data.meta;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(deleteWarehouseUsers.rejected, (state, action) => {
        state.isPatchLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      });
  },
});

export const { reset } = WarehouseUsersSlice.actions;
export default WarehouseUsersSlice.reducer;
