import { Box, IconButton, styled } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { FC, useEffect, useState } from "react";
import { GrayButton } from "src/components/GrayButton";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";
import StyledTable from "src/components/Table";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import moment from "moment";
import { Eye } from "iconsax-react";
import { ProductDetailsTableModal } from "./ProductDetailsTableModal";
import { exportSerachProducts } from "src/features/products/redux/ProductsSlice";
import { useSnackbar } from "notistack";
import { reset as resetProducts } from "src/features/products/redux/ProductsSlice";

interface InventoriesSearchResultTableprops {
  isLoading: boolean;
  warehouseId: string;
  searchedProducts: any[];
  rowsCount: number;
  page: number;
  setNextPage: (page: number) => void;
}

export const InventoriesSearchResultTable: FC<
  InventoriesSearchResultTableprops
> = (props) => {
  const {
    isLoading,
    warehouseId,
    searchedProducts,
    rowsCount,
    page,
    setNextPage,
  } = props;

  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [isProductDetailsModalOpen, setIsProductDetailsModalOpen] =
    useState(false);

  const {
    isProductsExportLoading,
    isProductsExportError,
    productsExportMessage,
  } = useSelector((state: RootState) => state.products);

  useEffect(() => {
    if (isProductsExportError) {
      enqueueSnackbar(productsExportMessage, { variant: "error" });
    }
  }, [isProductsExportError, productsExportMessage, isProductsExportLoading]);

  const columns: GridColDef[] = [
    {
      field: "product",
      headerName: "Product",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "internal_barcode",
      headerName: "Internal Barcode",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "placement",
      headerName: "Placement",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "relocation_date_time",
      headerName: "Relocation Date & Time",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Box padding={2} color="#1E2329">
          {moment(params.row?.relocation_date_time).format("DD-MMM h:mma")}
        </Box>
      ),
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.4,
      minWidth: 80,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            setSelectedRowData(params?.row);
            setIsProductDetailsModalOpen(true);
          }}
        >
          <Eye color={colors.gray[600]} size="24" />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Container>
        <ListBoundary
          isEmpty={searchedProducts.length === 0 || !searchedProducts}
          isDataLoading={isLoading}
          fallbackImageURL="/assets/images/emptyStates/SearchedProducts-List EmptyState.svg"
          description="No Result Found ..."
        >
          <StyledHeader>
            <TitleBox>
              <Title>Results</Title>
              <ResultsCount>
                Total Record: {rowsCount ?? 0} Product
              </ResultsCount>
            </TitleBox>
            <Box width="184px">
              <GrayButton
                variant="contained"
                fullWidth
                loading={isProductsExportLoading}
                onClick={() =>
                  dispatch(
                    exportSerachProducts({ warehouseId: warehouseId })
                  ).then(() => resetProducts())
                }
              >
                Export Excel
              </GrayButton>
            </Box>
          </StyledHeader>

          <StyledTable
            disableColumnMenu
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableVirtualization
            disableSelectionOnClick
            loading={isLoading}
            getRowHeight={() => 56}
            experimentalFeatures={{ newEditingApi: true }}
            rows={searchedProducts}
            columns={columns}
            onPageChange={(e) => setNextPage(e + 1)}
            page={page - 1}
            paginationMode="server"
            rowCount={rowsCount}
          />
        </ListBoundary>
      </Container>

      <ProductDetailsTableModal
        isOpen={isProductDetailsModalOpen}
        warehouseId={warehouseId}
        productId={selectedRowData?.id}
        productTitle={selectedRowData?.product}
        barcode={selectedRowData?.internal_barcode}
        placement={selectedRowData?.placement}
        onDismiss={() => setIsProductDetailsModalOpen(false)}
      />
    </>
  );
};

const Container = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 18px;
`;

const StyledHeader = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TitleBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Title = styled("h6")`
  font-size: 24px;
  line-height: 32px;
  color: ${colors.gray[850]};
  margin-right: 24px;
`;

const ResultsCount = styled("h6")`
  text-align: center;
  font-size: 14px;
  line-height: 28px;
  border-radius: 30px;
  padding: 6px 16px;
  white-space: nowrap;
  background-color: ${colors.secondary[50]};
  color: ${colors.secondary[800]};
`;
