import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import colors from "../../../theme/colors";

const Crash = () => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <StyledRow>
        <StyledBox>
          <Typography
            fontSize={56}
            fontWeight={700}
            color={colors.gray[850]}
            pb={4}
          >
            {t("crash.title")}
          </Typography>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={colors.gray[850]}
            letterSpacing={"0.04em"}
          >
            {t("crash.subTitle")}
          </Typography>
          <StyledBtn mt={32} onClick={() => window.location.reload()}>
            <Typography
              fontSize={16}
              fontWeight={500}
              color={colors.gray[800]}
              letterSpacing={"0.04em"}
            >
              {t("crash.btnText")}
            </Typography>
          </StyledBtn>
        </StyledBox>
        <img src={process.env.PUBLIC_URL + "/assets/images/crash.svg"} />
      </StyledRow>
    </StyledContainer>
  );
};

export default Crash;

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: ${colors.base.back2};
`;
const StyledRow = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
`;
const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 450px;
`;
const StyledBtn = styled(Box)`
  border: 1px solid ${colors.gray[500]};
  border-radius: 5px;
  width: 192px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
