import { FileUploaderType } from "./FileUploader";

export function nameLengthValidator(file: any) {
  if (file.size > 5000000) {
    return {
      code: "image is too large",
      message: `Image is larger than 5 MB characters`,
    };
  }

  return null;
}

export const defaultAcceptedFileTypes = (type: FileUploaderType) => {
  if (type === FileUploaderType.Multiple) {
    return {
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
      "application/pdf": [".pdf"],
    };
  } else {
    return {
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
    };
  }
};
