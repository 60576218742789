import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import storage from "../../../utils/storage";
import authService from "./authService";
import {
  checkDataValue,
  forgetValue,
  resendValue,
  resetValue,
  signinValue,
} from "./types";

const token = storage.getToken();
const initialState = {
  token: token ? token : null,
  isError: false,
  isCheckError: false,
  isSuccess: false,
  isCheckSuccess: false,
  isLoading: false,
  isCheckLoading: false,
  message: "",
  checkMessage: "",
  user: [],
};

export const signIn = createAsyncThunk(
  "auth/login",
  async (data: signinValue, thunkAPI) => {
    try {
      return await authService.signIn(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.message ||
            error?.response?.data?.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const me = createAsyncThunk("auth/me", async (_, thunkAPI) => {
  try {
    return await authService.me();
  } catch (error: any) {
    const message =
      error?.response?.status === 500
        ? "Internal Server Error"
        : error?.response.data?.results?.message ||
          error?.response?.data?.message ||
          error?.message;
    return thunkAPI.rejectWithValue(message);
  }
});

export const forgetPassword = createAsyncThunk(
  "auth/forgot-password",
  async (data: forgetValue, thunkAPI) => {
    try {
      return await authService.forgetPassword(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.message ||
            error?.response?.data?.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resendToken = createAsyncThunk(
  "auth/resend-token",
  async (data: resendValue, thunkAPI) => {
    try {
      return await authService.resendToken(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.message ||
            error?.response?.data?.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/reset-password",
  async (data: resetValue, thunkAPI) => {
    try {
      return await authService.resetPassword(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.message ||
            error?.response?.data?.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const checkToken = createAsyncThunk(
  "auth/check-token",
  async (data: checkDataValue, thunkAPI) => {
    try {
      return await authService.checkToken(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.message ||
            error?.response?.data?.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const signOut = createAsyncThunk("auth/signOut", async () => {
  await authService.signOut();
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isCheckLoading = false;
      state.isSuccess = false;
      state.isCheckSuccess = false;
      state.isError = false;
      state.isCheckError = false;
      state.message = "";
      state.checkMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.token = action.payload?.data?.results?.access_token;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.token = null;
        state.message = `${action.payload}`;
      })
      .addCase(forgetPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(forgetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })
      .addCase(resendToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resendToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(resendToken.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.token = null;
      })

      .addCase(me.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(me.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.data.results;
      })
      .addCase(me.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(checkToken.pending, (state) => {
        state.isCheckLoading = true;
      })
      .addCase(checkToken.fulfilled, (state, action) => {
        console.log(action);
        state.isCheckLoading = false;
        state.isCheckSuccess = true;
        state.checkMessage = `${action.payload.data.message}`;
      })
      .addCase(checkToken.rejected, (state, action) => {
        state.isCheckLoading = false;
        state.isCheckError = true;
        state.checkMessage = `${action.payload}`;
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
