import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PageTemplate } from "src/layout/components/PageTemplate";
import storage from "src/utils/storage";
import colors from "../../../theme/colors";

const NotFoundContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuthorized = storage.getToken() as string | null;

  useEffect(() => {
    if (!isAuthorized) {
      window.location.replace("/auth/login");
    }
  }, [isAuthorized]);

  return (
    <StyledContainer>
      <StyledRow padding={20}>
        <StyledBox>
          <Typography
            fontSize={56}
            fontWeight={700}
            color={colors.gray[850]}
            pb={4}
          >
            {t("notFound.title")}
          </Typography>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={colors.gray[850]}
            letterSpacing={"0.04em"}
          >
            {t("notFound.subTitle")}
          </Typography>
          <StyledBtn
            mt={32}
            onClick={() =>
              isAuthorized
                ? navigate(-1)
                : window.location.assign(window.location.origin)
            }
          >
            <img src={process.env.PUBLIC_URL + "/assets/icons/arrow.svg"} />
            <Typography
              fontSize={16}
              fontWeight={500}
              color={colors.gray[800]}
              width={"104px"}
              textAlign="center"
            >
              {t("notFound.btnText")}
            </Typography>
          </StyledBtn>
        </StyledBox>
        <img src={process.env.PUBLIC_URL + "/assets/images/notFound.svg"} />
      </StyledRow>
    </StyledContainer>
  );
};

const NotFound = () => {
  const isAuthorized = storage.getToken();

  if (isAuthorized) {
    return (
      <PageTemplate>
        <NotFoundContent />
      </PageTemplate>
    );
  } else {
    return (
      <OuterWrapper>
        <NotFoundContent />
      </OuterWrapper>
    );
  }
};

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: ${colors.base.back2};
`;

const OuterWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: ${colors.base.back2};
`;
const StyledRow = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  ${(props) => props.theme.breakpoints.down("lg")} {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
`;
const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  ${(props) => props.theme.breakpoints.down("lg")} {
    align-items: center;
    text-align: center;
  }
`;
const StyledBtn = styled(Box)`
  border: 1px solid ${colors.gray[500]};
  border-radius: 5px;
  width: 160px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  cursor: pointer;
`;

export default NotFound;
