/* eslint-disable react/no-unescaped-entities */
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import colors from "src/theme/colors";
import { IconButton, styled } from "@mui/material";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { ArrowLeft2 } from "iconsax-react";
import { GrayButton } from "src/components/GrayButton";
import { GppGood } from "@mui/icons-material";

type Props = {
  requestName: string;
  isButtonLoading: boolean;
  selectedQuality: string;
  onApprove: () => void;
  onGoBack: () => void;
  onDismiss: () => void;
};

export const ApproveResultStep = (props: Props) => {
  const {
    requestName,
    isButtonLoading,
    selectedQuality,
    onApprove,
    onGoBack,
    onDismiss,
  } = props;

  return (
    <Container>
      <Content>
        <Head>
          <IconButton onClick={onGoBack}>
            <ArrowLeft2 size="15" type="bold" color={colors.base.black} />
          </IconButton>
          <IconButton onClick={onDismiss}>
            <img
              src={process.env.PUBLIC_URL + `/assets/icons/close.svg`}
              alt={"close"}
            />
          </IconButton>
        </Head>

        <TitleBar>
          <GppGood fontSize="large" color="secondary" />
          <Box pt={10}>
            <Typography fontSize={18} fontWeight={700} color={colors.gray[850]}>
              Local Inspection Request Result Confirmation
            </Typography>
          </Box>
        </TitleBar>

        <SubTitle>
          Are you sure you want to approve inspection result{" "}
          <b>"{requestName}"</b> with quality <b>"{selectedQuality}"</b> ?
        </SubTitle>

        <Actions>
          <CancelButtonBox>
            <GrayButton
              variant="outlined"
              disabled={isButtonLoading}
              fullWidth
              onClick={onDismiss}
            >
              Cancel
            </GrayButton>
          </CancelButtonBox>
          <SubmitButtonBox>
            <StyledLoadingButton
              variant="contained"
              fullWidth
              loading={isButtonLoading}
              color="primary"
              onClick={onApprove}
            >
              Approve Inspection
            </StyledLoadingButton>
          </SubmitButtonBox>
        </Actions>
      </Content>
    </Container>
  );
};

const Container = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Content = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Head = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const TitleBar = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubTitle = styled("p")`
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  margin: 24px 0 32px 0;
  color: ${colors.gray[850]};
`;

const Actions = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: column;
  }
`;

const CancelButtonBox = styled(Box)`
  width: 184px;
  margin-right: 24px;
  ${(props) => props.theme.breakpoints.down("lg")} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
  }
`;

const SubmitButtonBox = styled(Box)`
  width: 184px;
  ${(props) => props.theme.breakpoints.down("lg")} {
    width: 100%;
  }
`;
