import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "src/components/Loading";
import { getItems, reset } from "src/features/items/redux/ItemSlice";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { AppDispatch, RootState } from "src/store";
import WarehouseNewForm from "../components/WarehouseNewForm";

const WharahouseName = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const [Data, setData] = useState([] as any);

  const { items, isError, isSuccess, message, isLoading } = useSelector(
    (state: RootState) => state.items
  );

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      setData(items);
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, isLoading, isSuccess, message]);

  useEffect(() => {
    dispatch(
      getItems({
        page: page,
        limit: 100,
      })
    );
  }, []);

  return (
    <PageTemplate
      isTransparent
      crumbs={[
        {
          label: "warehouses",
          href: "/warehouses/warehouse-list",
          depth: -1,
        },
        {
          label: "new warehouse",
        },
      ]}
    >
      {isLoading ? <Loading /> : <WarehouseNewForm items={items} />}
    </PageTemplate>
  );
};

export default WharahouseName;
