import { CloudUploadOutlined } from "@mui/icons-material";
import {
  Box,
  Typography,
  Modal,
  Switch,
  SwitchProps,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import { FC, useState } from "react";
import Loading from "src/components/Loading";
import colors from "src/theme/colors";
import { FileUploaderType } from "../FileUploader";

import { LinearFileUploadZone, UploadedFileType } from "./LinearUploadZone";

interface LinearFileUploaderProps {
  allFiles: UploadedFileType[] | [];
  files: UploadedFileType[] | [];
  rootID: number;
  title: string;
  index: number;
  tag: string;
  isAddLoading?: boolean;
  acceptedTypes?: any;
  maxFiles?: number;
  deleteEndPoint?: string;
  hasChecker?: boolean;
  isChecked?: boolean;
  isCheckLoading?: boolean;
  onCheckChange?: (checked: boolean) => void;
  setFiles: React.Dispatch<React.SetStateAction<UploadedFileType[]>>;
  onAddNewFiles: (addData: any) => void;
}

export const LinearFileUploader: FC<LinearFileUploaderProps> = (props) => {
  const {
    allFiles,
    files,
    rootID,
    title,
    index,
    tag,
    isAddLoading,
    maxFiles,
    acceptedTypes,
    deleteEndPoint,
    hasChecker,
    isChecked,
    isCheckLoading,
    onCheckChange,
    setFiles,
    onAddNewFiles,
  } = props;

  const isBelowSmSize = useMediaQuery("(max-width:900px)");

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Toggler>
        <Title>
          {index}. {title}
        </Title>
        <Infos>
          {files?.length > 0 ? (
            <UploadedCount>
              {files.length}
              {isBelowSmSize ? "" : " File Uploaded"}
            </UploadedCount>
          ) : null}
          <UploadIcon onClick={() => setIsModalOpen(true)}>
            {files?.length > 0 ? (
              <Banner
                style={{
                  backgroundImage: `url(${files[files.length - 1].url})`,
                }}
              />
            ) : null}
            <img src={process.env.PUBLIC_URL + "/assets/icons/Upload.svg"} />
          </UploadIcon>
          {hasChecker && onCheckChange ? (
            isCheckLoading ? (
              <CircularProgress color="secondary" sx={{ ml: 11 }} />
            ) : (
              <StyledSwitch
                color="secondary"
                checked={isChecked}
                disabled={isCheckLoading || files.length === 0}
                onChange={(e) => onCheckChange(e.target.checked)}
              />
            )
          ) : null}
        </Infos>
      </Toggler>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MultiWrapper>
          <CloseIcon onClick={() => setIsModalOpen(false)}>
            <img
              src={process.env.PUBLIC_URL + `/assets/icons/${"close"}.svg`}
            />
          </CloseIcon>
          <MultiUploader>
            <ModalTitle noWrap gutterBottom>
              {title}
            </ModalTitle>
            <LinearFileUploadZone
              type={FileUploaderType.Multiple}
              rootID={rootID}
              maxFiles={maxFiles}
              allFiles={allFiles}
              files={files}
              acceptedTypes={acceptedTypes}
              deleteEndPoint={deleteEndPoint}
              isChecked={isChecked!}
              tag={tag}
              isAddLoading={isAddLoading}
              setFiles={setFiles}
              onAddNewFiles={onAddNewFiles}
            />
          </MultiUploader>
        </MultiWrapper>
      </Modal>
    </>
  );
};

const Toggler = muiStyled(Box)(({ theme }) => ({
  width: "100%",
  height: "44px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  zIndex: 4,

  [theme.breakpoints.down("md")]: {
    height: "auto",
  },
}));

const MultiWrapper = muiStyled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  minWidth: "340px",
  backgroundColor: colors.base.white,
  border: `1px solid ${colors.gray[200]}`,
  borderRadius: "5px",
  padding: "42px 64px 58px 64px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
});

const Infos = muiStyled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
});

const UploadedCount = muiStyled(Box)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "26px",
  padding: "3px 12px",
  marginRight: "18px",
  borderRadius: "40px",
  color: colors.secondary[800],
  backgroundColor: colors.secondary[10],

  [theme.breakpoints.down("md")]: {
    borderRadius: "100%",
  },
}));

const UploadIcon = muiStyled(Box)({
  position: "relative",
  width: "40px",
  height: "40px",
  fontSize: "21px",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px dashed #C2CBD7",
  borderRadius: "5px",
  cursor: "pointer",
  overflow: "hidden",
  color: colors.gray[750],
});

const Banner = muiStyled(Box)({
  position: "absolute",
  width: "100%",
  height: "100%",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  borderRadius: "5px",
  opacity: 0.2,
});

const MultiUploader = muiStyled(Box)({
  width: "320px",
  height: "345px",
});

const Title = muiStyled(Typography)(({ theme }) => ({
  fontSize: "15px",
  fontWeight: 600,
  lineHeight: "20px",
  textAlign: "left",
  marginRight: "12px",
  color: colors.gray[750],

  [theme.breakpoints.down("md")]: {
    fontSize: "13px",
    fontWeight: 400,
  },
}));

const CloseIcon = muiStyled(Box)({
  position: "absolute",
  top: "12px",
  right: "12px",
  zIndex: 4,
  cursor: "pointer",
});

const StyledSwitch = muiStyled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 22,
  padding: 0,
  marginLeft: "20px",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    top: "2px",
    left: "4px",
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark"
            ? colors.secondary[600]
            : colors.secondary[600],
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: colors.secondary[600],
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 13,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const ModalTitle = muiStyled(Typography)({
  width: "100%",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  textAlign: "center",
  marginBottom: "12px",
  color: colors.gray[750],
});
