import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "src/components/Loading";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { AppDispatch, RootState } from "src/store";
import { InternationalInspectionEditForm } from "../components/InternationalInspectionEditForm";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

import {
  getProducts,
  reset as resetProducts,
} from "src/features/products/redux/ProductsSlice";
import {
  getWarehousePlacements,
  getWarehouses,
  reset as resetWarehouses,
} from "src/features/warehouses/redux/WarehouseSlice";
import { InternationalEditProductsList } from "../components/InternationalEditProductsList";
import { useLocation } from "react-router-dom";

interface LocationState {
  data?: any;
}

const InternationalInspectionEdit = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const state = location.state as LocationState;

  const [currentStep, setCurrentStep] = useState("form");
  const [placementsList, setPlacementsList] = useState([
    {
      id: "0",
      title: "All",
    },
  ]);

  const {
    products,
    isError: isProductsError,
    isSuccess: isProductsSuccess,
    message: productsMessage,
    isLoading: isProductsLoading,
  } = useSelector((state: RootState) => state.products);
  const {
    Warehouses,
    placements,
    isError: isWarehousesError,
    isPlacementsError,
    isSuccess: isWarehousesSuccess,
    isPlacementsSuccess,
    message: WarehousesMessage,
    placementsMessage,
    isLoading: isWarehousesLoading,
    isPlacementsLoading,
  } = useSelector((state: RootState) => state.warehouse);

  useEffect(() => {
    if (isProductsError) {
      enqueueSnackbar(productsMessage, { variant: "error" });
    }
    return () => {
      dispatch(resetProducts());
    };
  }, [isProductsError, isProductsSuccess, productsMessage, isProductsLoading]);

  useEffect(() => {
    if (isWarehousesError) {
      enqueueSnackbar(WarehousesMessage, { variant: "error" });
    }
    return () => {
      dispatch(resetWarehouses());
    };
  }, [
    isWarehousesError,
    isWarehousesSuccess,
    WarehousesMessage,
    isWarehousesLoading,
  ]);

  useEffect(() => {
    if (isPlacementsError) {
      enqueueSnackbar(placementsMessage, { variant: "error" });
    }
    if (isPlacementsSuccess) {
      const allPlacements =
        placements?.length > 0
          ? [{ id: "0", title: "All" }, ...placements]
          : ([{ id: "0", title: "All" }] as any);

      setPlacementsList(allPlacements);
    }
    return () => {
      dispatch(resetWarehouses());
    };
  }, [
    isPlacementsError,
    isPlacementsSuccess,
    placementsMessage,
    isPlacementsLoading,
  ]);

  //formik---------------------------------
  const ItemSchema = Yup.object().shape({
    title: Yup.string().required(),
    warehouse: Yup.number().required(),
    product: Yup.number().required(),
    placement: Yup.number(),
    startDate: Yup.date().nullable(),
    endDate: Yup.date().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      title: state?.data?.title,
      warehouse: state?.data?.warehouse?.id,
      product: state?.data?.product?.id,
      placement:
        state?.data?.placements != null && state?.data?.placements.length > 0
          ? state?.data?.placements.length === 1
            ? state?.data?.placements[0]?.id
            : "0"
          : "0",
      startDate: state?.data?.from_date,
      endDate: state?.data?.to_date,
    },
    validationSchema: ItemSchema,

    onSubmit: async (values: any) => {
      setCurrentStep("list");
    },
  });

  const { values } = formik;

  useEffect(() => {
    dispatch(
      getProducts({
        page: 1,
        limit: 99,
      })
    );
    dispatch(
      getWarehouses({
        page: 1,
        limit: 99,
      })
    );
  }, []);

  useEffect(() => {
    if (values?.warehouse) {
      dispatch(
        getWarehousePlacements({
          warehouseId: values?.warehouse,
          page: 1,
          limit: 99,
        })
      );
    }
  }, [values?.warehouse]);

  return (
    <PageTemplate
      isTransparent
      crumbs={[
        {
          label: "inspections",
          href: "/international-inspections/international-inspection-list",
          depth: -1,
        },
        {
          label: "edit international inspection",
        },
      ]}
    >
      {currentStep === "form" ? (
        isProductsLoading || isWarehousesLoading || isPlacementsLoading ? (
          <Loading />
        ) : (
          <InternationalInspectionEditForm
            warehouses={Warehouses}
            products={products}
            placements={placementsList}
            formik={formik}
          />
        )
      ) : (
        <InternationalEditProductsList
          values={{
            warehouseId: Number(values.warehouse),
            product: values.product ? Number(values.product) : null,
            from: values.startDate
              ? moment((values.startDate as any).$d).format("YYYY-MM-DD")
              : null,
            to: values.endDate
              ? moment((values.endDate as any).$d).format("YYYY-MM-DD")
              : null,
          }}
          id={state?.data?.id}
          title={values?.title}
          warehouse={
            values?.warehouse
              ? (
                  Warehouses.find(
                    (item: any) => item.id === values?.warehouse
                  ) as any
                )?.title ?? ""
              : ""
          }
          product={
            values?.product
              ? (
                  products.find(
                    (item: any) => item.id === values?.product
                  ) as any
                )?.title ?? ""
              : ""
          }
          placementId={
            values?.placement && values?.placement !== "0"
              ? [values?.placement]
              : []
          }
          placement={
            values?.placement
              ? (
                  placements.find(
                    (item: any) => item.id === values?.placement
                  ) as any
                )?.title ?? "All"
              : "All"
          }
          dateRange={
            values.startDate || values.endDate
              ? `${
                  values.startDate
                    ? moment((values.startDate as any).$d).format("DD MMM")
                    : ""
                } - ${
                  values.endDate
                    ? moment((values.endDate as any).$d).format("DD MMM")
                    : ""
                }`
              : ""
          }
          onGoBack={() => setCurrentStep("form")}
        />
      )}
    </PageTemplate>
  );
};

export default InternationalInspectionEdit;
