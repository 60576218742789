export default {
  10: "#FFF1F3",
  50: "#FCE4E8",
  100: "#F8BBC6",
  200: "#F390A2",
  300: "#EC667E",
  400: "#E54A64",
  500: "#DF354C",
  600: "#CF304B",
  700: "#BA2A47",
  800: "#A62445",
  900: "#831B3F",
};
