import { FC, MouseEventHandler } from "react";
import {
  Box,
  Stepper,
  Step,
  styled,
  StepLabel,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Check from "@mui/icons-material/Check";
import colors from "src/theme/colors";
import { StepIconProps } from "@mui/material/StepIcon";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import useResponsive from "src/hooks/useResponsive";

function QontoStepIcon(props: StepIconProps, index: number) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active, completed }} className={className}>
      <div className="QontoStepIcon-circle">
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          index + 1
        )}
      </div>
    </QontoStepIconRoot>
  );
}

interface ShippingDetailsStepperProps {
  steps: string[];
  activeStep: number;
  completed: {
    [k: number]: boolean;
  };
  handleStep: (index: number) => MouseEventHandler<HTMLDivElement> | undefined;
  className?: string;
}

export const ShippingDetailsStepper: FC<ShippingDetailsStepperProps> = (
  props
) => {
  const { steps, activeStep, completed, handleStep, className } = props;

  const isBelowTablet = useResponsive("down", "md");

  return (
    <Container className={className}>
      <Stepper
        alternativeLabel={isBelowTablet ? false : true}
        orientation={isBelowTablet ? "vertical" : "horizontal"}
        activeStep={activeStep}
        connector={<QontoConnector />}
        sx={{ width: "100%" }}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StyledStepLabel
              StepIconComponent={(args) => QontoStepIcon(args, index)}
              onClick={index !== activeStep ? handleStep(index) : undefined}
              sx={{ flexDirection: "column-reverse" }}
            >
              {label}
            </StyledStepLabel>
          </Step>
        ))}
      </Stepper>
    </Container>
  );
};

const Container = styled(Box)({
  width: "100%",
  maxWidth: "800px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "40px",
});

const TopLabel = styled(Typography)<{
  isActive?: boolean;
  isCompleted?: boolean;
}>(({ theme, isActive, isCompleted }) => ({
  fontSize: 16,
  textAlign: "center",
  marginBottom: 12,
  ...(isActive || isCompleted
    ? {
        fontWeight: 500,
        color: colors.midnight[850],
      }
    : {
        fontWeight: 400,
        color: colors.midnight[600],
      }),
}));

const StyledStepLabel = styled(StepLabel)<{
  isActive?: boolean;
  isCompleted?: boolean;
}>(({ theme, isActive, isCompleted }) => ({
  "&.MuiStepLabel-root": {
    flexDirection: "column-reverse",
    [theme.breakpoints.down("md")]: {
      padding: 0,
      flexDirection: "row",
    },
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      fontSize: 16,
      textAlign: "center",
      marginTop: 0,
      marginBottom: 12,
      "&.Mui-active": {
        fontWeight: 500,
        color: colors.midnight[850],
      },
      [theme.breakpoints.down("md")]: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
  },
}));

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 56,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
    [theme.breakpoints.down("md")]: {
      left: "24px",
      right: "auto",
    },
  },
  [`&.${stepConnectorClasses.root}`]: {
    zIndex: 2,
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      height: "42px",
    },
    "& .MuiStepConnector-line": {
      height: "100%",
    },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.secondary[500],
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.success[500],
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: colors.secondary[500],
    borderTopWidth: 2,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: colors.base.white,
  color: colors.base.white,
  cursor: "pointer",
  display: "flex",
  width: 42,
  height: 42,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "100%",
  ...(ownerState.active &&
    !ownerState.completed && {
      border: "3px solid",
      zIndex: 5,
      borderColor: colors.secondary[500],
    }),
  ...(ownerState.active &&
    ownerState.completed && {
      border: "3px solid",
      zIndex: 5,
      borderColor: colors.success[500],
    }),
  "& .QontoStepIcon-completedIcon": {
    color: colors.base.white,
    zIndex: 1,
    fontSize: 16,
  },
  "& .QontoStepIcon-circle": {
    width: 32,
    height: 32,
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.secondary[500],
    ...(ownerState.active && {
      backgroundColor: colors.secondary[500],
    }),
    ...(ownerState.completed && {
      backgroundColor: colors.success[400],
    }),
  },
}));
