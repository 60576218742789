import { alpha, Box, CircularProgress, IconButton } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import { FC } from "react";
import colors from "src/theme/colors";
import {
  FileUploadZoneSinglePreviewType,
  UploadedFileType,
} from "../UploadZone";
import styled from "@emotion/styled";

interface SingleZonePreviewerProps {
  type: FileUploadZoneSinglePreviewType;
  file: UploadedFileType;
  deleteLoading?: boolean;
  onDelete: () => void;
}

export const SingleZonePreviewer: FC<SingleZonePreviewerProps> = (props) => {
  const { type, file, deleteLoading, onDelete } = props;

  return (
    <Container sx={file == null ? { display: "none" } : {}}>
      {type === FileUploadZoneSinglePreviewType.Image ? (
        <FilePreview src={file?.url} />
      ) : (
        <FileName>{file?.name}</FileName>
      )}
      <SingleCloseIcon>
        {deleteLoading ? (
          <CircularProgress color="secondary" size={21} />
        ) : (
          <IconButton color="error" size="small" onClick={onDelete}>
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Remove.svg"}
              alt="delete"
            />
          </IconButton>
        )}
      </SingleCloseIcon>
    </Container>
  );
};

const Container = muiStyled(Box)({
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  userSelect: "none",
});

const SingleCloseIcon = muiStyled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  bottom: "4px",
  right: "4px",
  backgroundColor: alpha(colors.base.white, 0.6),
  borderRadius: "50%",
});

const FilePreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: ${colors.base.white};
  user-select: none;
`;

const FileName = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.base.white};
  color: ${colors.secondary[700]};
  font-weight: bold;
  user-select: none;
`;
