import { configureStore } from "@reduxjs/toolkit";

import authReducer from "../features/auth/redux/authSlice";
import roleReducer from "../features/role/redux/roleSlice";
import ordersReducer from "../features/orders/redux/orderSlice";
import vendorReducer from "../features/vendors/redux/VendorSlice";
import employesReducer from "../features/employes/redux/EmployesSlice";
import itemsReducer from "../features/items/redux/ItemSlice";
import unitsReducer from "../features/items/redux/unitSlice";
import qualitiesReducer from "../features/items/redux/qualitySlice";
import chatReducer from "src/features/chat/redux/chatSlice";
import warehouseReducer from "src/features/warehouses/redux/WarehouseSlice";
import stockTakingReducer from "src/features/stocktakings/redux/StockTakingSlice";
import trucksReducer from "src/features/warehouses/redux/TruckSlice";
import warehouseUsersReducer from "src/features/warehouses/redux/WarehouseUserSlice";
import WarehouseProductItemsReducer from "src/features/warehouses/redux/WarehouseProductItemsSlice";
import PlacementsReducer from "src/features/warehouses/redux/PlacementSlice";
import localInspectionReducer from "src/features/localInspection/redux/LocalInspectionSlice";
import internationalInspectionReducer from "src/features/internationalInspection/redux/InternationalInspectionSlice";
import productsReducer from "src/features/products/redux/ProductsSlice";
import customersReducer from "src/features/customers/redux/CustomersSlice";
import saleOrdersReducer from "src/features/saleOrders/redux/SaleOrdersSlice";
import carriersReducer from "src/features/saleOrders/redux/CarriersSlice";
import logsReducer from "src/features/logs/redux/LogsSlice";
import layoutStore from "src/layout/redux/layoutStore";

export const store = configureStore({
  reducer: {
    layout: layoutStore,
    auth: authReducer,
    role: roleReducer,
    vendor: vendorReducer,
    employes: employesReducer,
    items: itemsReducer,
    units: unitsReducer,
    qualities: qualitiesReducer,
    orders: ordersReducer,
    chat: chatReducer,
    warehouse: warehouseReducer,
    stockTaking: stockTakingReducer,
    trucks: trucksReducer,
    warehouseUsers: warehouseUsersReducer,
    WarehouseProductItems: WarehouseProductItemsReducer,
    placements: PlacementsReducer,
    localInspections: localInspectionReducer,
    internationalInspections: internationalInspectionReducer,
    products: productsReducer,
    customers: customersReducer,
    saleOrders: saleOrdersReducer,
    carriers: carriersReducer,
    logs: logsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
