import {
  Box,
  Breadcrumbs as MuiBreadCrumbs,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import colors from "src/theme/colors";

export interface BreadCrumbItemProps {
  label: string;
  href?: string;
  depth?: number;
}

interface BreadCrumbsProps {
  crumbs: BreadCrumbItemProps[];
}

export const BreadCrumbs: FC<BreadCrumbsProps> = (props) => {
  const { crumbs } = props;

  const navigate = useNavigate();

  return (
    <Stack direction="row" alignItems="baseline" spacing={3}>
      {crumbs.map((crumb) => (
        <MuiBreadCrumbs key={crumb.label} aria-label="breadcrumb">
          {crumb.href && crumb.depth ? (
            <Crumb onClick={() => navigate(crumb.depth!)}>
              <CrumbLabel>{crumb.label}</CrumbLabel>
              <NavigateNextIcon fontSize="medium" color="inherit" />
            </Crumb>
          ) : (
            <CrumbLabel boldLabel>{crumb.label}</CrumbLabel>
          )}
        </MuiBreadCrumbs>
      ))}
    </Stack>
  );
};

interface StyledProps {
  boldLabel?: boolean;
}

const Crumb = styled(Box)(() => ({
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: colors.gray[850],
  "& > a": {
    textDecoration: "none",
  },
}));

const CrumbLabel = styled(Typography)<StyledProps>(({ boldLabel }) => ({
  color: colors.gray[850],
  fontWeight: 700,
  fontSize: boldLabel ? "18px" : "14px",
  marginRight: boldLabel ? 0 : "6px",
  textTransform: "capitalize",
}));
