import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import StyledTable from "src/components/Table";
import { GridColDef } from "@mui/x-data-grid";
import { AppDispatch, RootState } from "src/store";
import { deleteWarehouse, getWarehouses, reset } from "../redux/WarehouseSlice";
import { IconButton, Box, Typography } from "@mui/material";
import { Add, Eye } from "iconsax-react";
import colors from "src/theme/colors";
import styled from "@emotion/styled";
import { StyledButton } from "src/components/Button";
import ConfirmModal from "src/components/Modal";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";
import { WarehouseDetailsCard } from "../components/infos/WarehouseDetailsCard";

const WarehouseTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [WarehousesData, setWarehousesData] = useState([] as any);
  const [WarehousesMetaData, setWarehousesMetaData] = useState([] as any);
  const navigate = useNavigate();
  const [selected, setSelected] = useState([] as any);

  const { Warehouses, WarehousesMeta, isError, isSuccess, message, isLoading } =
    useSelector((state: RootState) => state.warehouse);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      if (message) {
        enqueueSnackbar(message, { variant: "success" });
      } else {
        setWarehousesData(Warehouses);
        setWarehousesMetaData(WarehousesMeta);
      }
    }

    return () => {
      dispatch(reset());
    };
  }, [isError, isSuccess, message, page]);

  useEffect(() => {
    dispatch(
      getWarehouses({
        page: page,
      })
    );
  }, [page]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 80, filterable: false },
    {
      field: "title",
      headerName: "Warehouse Name",
      flex: 1,
      minWidth: 240,
    },
    {
      field: "code",
      headerName: "Warehouse Code",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "phone",
      headerName: "Warehouse Phone",
      flex: 1,
      minWidth: 140,
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.4,
      minWidth: 100,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          flexDirection="row"
        >
          <IconButton
            onClick={() =>
              navigate("/warehouses/warehouse-information", {
                state: { id: params?.id, data: params.row },
              })
            }
          >
            <Eye color={colors.gray[600]} size="24" />
          </IconButton>
          <IconButton
            onClick={() =>
              navigate("/Warehouses/Warehouse-edit", {
                state: { data: params.row },
              })
            }
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Edit.svg"}
              alt="edit"
            />
          </IconButton>
        </Box>
      ),
    },
  ];

  //confirmModal
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState([0]);
  const handelOpen = (open: boolean) => {
    setOpen(!open);
  };

  return (
    <PageTemplate
      crumbs={[
        {
          label: "warehouses list",
        },
      ]}
    >
      <ListBoundary
        isEmpty={WarehousesData.length === 0 || !WarehousesData}
        isDataLoading={isLoading}
        fallbackImageURL="/assets/images/emptyStates/Warehouses-List EmptyState.svg"
        description="Create Your First Warehouse"
        buttonLabel="New Warehouse"
        onClick={() => navigate("/Warehouses/warehouse-new")}
      >
        <StyledHeader>
          <StyledButton
            variant="contained"
            endIcon={<Add size="24" color={colors.gray[850]} />}
            onClick={() => navigate("/Warehouses/warehouse-new")}
          >
            New Warehouse
          </StyledButton>
        </StyledHeader>
        <StyledTable
          disableColumnMenu
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableVirtualization
          disableSelectionOnClick
          loading={isLoading}
          checkboxSelection
          experimentalFeatures={{ newEditingApi: true }}
          rows={WarehousesData}
          columns={columns}
          onSelectionModelChange={(e) => setSelected(e)}
          onPageChange={(e) => setPage(e + 1)}
          page={page - 1}
          paginationMode="server"
          rowCount={WarehousesMetaData?.pagination?.total_items}
        />
      </ListBoundary>
    </PageTemplate>
  );
};

const StyledHeader = styled(Box)`
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 24px;
`;

export default WarehouseTable;
