import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import WarehouseServices from "./WarehouseServices";

const initialState = {
  isError: false,
  isExportError: false,
  isCreateError: false,
  isPatchBarcodeError: false,
  isDeleteError: false,
  isSuccess: false,
  isExportSuccess: false,
  isCreateSuccess: false,
  isPatchBarcodeSuccess: false,
  isDeleteSuccess: false,
  isLoading: false,
  isExportLoading: false,
  isCreateLoading: false,
  isPatchBarcodeLoading: false,
  isDeleteLoading: false,
  message: "",
  exportMessage: "",
  createMessage: "",
  patchBarcodeMessage: "",
  deleteMessage: "",
  productItems: [],
  productItemsMeta: [],
};

export const getWarehouseProductItems = createAsyncThunk(
  "WarehouseProductItems",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.getWarehouseProductItems(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const exportWarehouseProductItems = createAsyncThunk(
  "WarehouseProductItems/export",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.exportWarehouseProductItems(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const postWarehouseProductItem = createAsyncThunk(
  "WarehouseProductItems/create",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.postWarehouseProductItem(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const patchWarehouseProductItemBarcode = createAsyncThunk(
  "WarehouseProductItems/patch",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.patchWarehouseProductItemBarcode(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteWarehouseProductItem = createAsyncThunk(
  "WarehouseProductItems/delete",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.deleteWarehouseProductItem(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const WarehouseProductItemsSlice = createSlice({
  name: "WarehouseProductItems",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isExportError = false;
      state.isCreateError = false;
      state.isPatchBarcodeError = false;
      state.isDeleteError = false;
      state.isLoading = false;
      state.isExportLoading = false;
      state.isCreateLoading = false;
      state.isPatchBarcodeLoading = false;
      state.isDeleteLoading = false;
      state.isSuccess = false;
      state.isExportSuccess = false;
      state.isCreateSuccess = false;
      state.isPatchBarcodeSuccess = false;
      state.isDeleteSuccess = false;
      state.message = "";
      state.exportMessage = "";
      state.createMessage = "";
      state.patchBarcodeMessage = "";
      state.deleteMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWarehouseProductItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWarehouseProductItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.productItems = action.payload.data.results;
        state.productItemsMeta = action.payload.data.meta;
      })
      .addCase(getWarehouseProductItems.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(exportWarehouseProductItems.pending, (state) => {
        state.isExportLoading = true;
      })
      .addCase(exportWarehouseProductItems.fulfilled, (state, action) => {
        state.isExportLoading = false;
        state.isExportSuccess = true;
        state.exportMessage = `${action.payload.data.message}`;
      })
      .addCase(exportWarehouseProductItems.rejected, (state, action) => {
        state.isExportLoading = false;
        state.isExportError = true;
        state.isExportSuccess = false;
        state.exportMessage = `${action.payload}`;
      })

      .addCase(postWarehouseProductItem.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(postWarehouseProductItem.fulfilled, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateSuccess = true;
        state.createMessage = `${action.payload.data.message}`;
      })
      .addCase(postWarehouseProductItem.rejected, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateError = true;
        state.createMessage = `${action.payload}`;
      })

      .addCase(patchWarehouseProductItemBarcode.pending, (state) => {
        state.isPatchBarcodeLoading = true;
      })
      .addCase(patchWarehouseProductItemBarcode.fulfilled, (state, action) => {
        state.isPatchBarcodeLoading = false;
        state.isPatchBarcodeSuccess = true;
        state.patchBarcodeMessage = `${action.payload.data.message}`;
      })
      .addCase(patchWarehouseProductItemBarcode.rejected, (state, action) => {
        state.isPatchBarcodeLoading = false;
        state.isPatchBarcodeError = true;
        state.patchBarcodeMessage = `${action.payload}`;
      })

      .addCase(deleteWarehouseProductItem.pending, (state) => {
        state.isDeleteLoading = true;
      })
      .addCase(deleteWarehouseProductItem.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteSuccess = true;
        state.deleteMessage = `${action.payload.data.message}`;
      })
      .addCase(deleteWarehouseProductItem.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.deleteMessage = `${action.payload}`;
      });
  },
});

export const { reset } = WarehouseProductItemsSlice.actions;
export default WarehouseProductItemsSlice.reducer;
