import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import InternationalInspectionServices from "./InternationalInspectionServices";

const initialState = {
  isError: false,
  isDeleteError: false,
  isAddRequestError: false,
  isUpdateRequestError: false,
  isExportError: false,
  isSetResultError: false,
  isProductItemsError: false,
  isProductItemsExportExcelError: false,
  isProductItemsExportPDFError: false,
  isSuccess: false,
  isDeleteSuccess: false,
  isAddRequestSuccess: false,
  isUpdateRequestSuccess: false,
  isExportSuccess: false,
  isSetResultSuccess: false,
  isProductItemsSuccess: false,
  isProductItemsExportExcelSuccess: false,
  isProductItemsExportPDFSuccess: false,
  isLoading: true,
  isDeleteLoading: true,
  isAddRequestLoading: false,
  isUpdateRequestLoading: false,
  isExportLoading: false,
  isSetResultLoading: false,
  isProductItemsLoading: true,
  isProductItemsExportExcelLoading: false,
  isProductItemsExportPDFLoading: false,
  message: "",
  deleteMessage: "",
  addRequestMessage: "",
  updateRequestMessage: "",
  exportMessage: "",
  setResultMessage: "",
  productItemsMessage: "",
  productItemsExportExcelMessage: "",
  productItemsExportPDFMessage: "",
  internationalInspections: [],
  internationalInspectionsMeta: [],
  singleInternationalInspection: [],
  productItems: [],
  productItemsMeta: [],
};

export const getInternationalInspections = createAsyncThunk(
  "InternationalInspections",
  async (data: any, thunkAPI) => {
    try {
      return await InternationalInspectionServices.getInternationalInspections(
        data
      );
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const exportInternationalInspections = createAsyncThunk(
  "InternationalInspections/export",
  async (data: any, thunkAPI) => {
    try {
      return await InternationalInspectionServices.exportInternationalInspections();
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getInternationalInspectionId = createAsyncThunk(
  "InternationalInspections/id",
  async (data: any, thunkAPI) => {
    try {
      return InternationalInspectionServices.getInternationalInspectionId(
        data.id
      );
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const addInternationalInspection = createAsyncThunk(
  "InternationalInspections/post",
  async (data: any, thunkAPI) => {
    try {
      return await InternationalInspectionServices.addInternationalInspection(
        data
      );
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const patchInternationalInspection = createAsyncThunk(
  "InternationalInspections/patch",
  async (data: any, thunkAPI) => {
    try {
      return await InternationalInspectionServices.patchInternationalInspection(
        data
      );
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteInternationalInspection = createAsyncThunk(
  "InternationalInspections/delete",
  async (id: number, thunkAPI) => {
    try {
      return await InternationalInspectionServices.deleteInternationalInspection(
        id
      );
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const setInternationalInspectionResult = createAsyncThunk(
  "InternationalInspections/results",
  async (data: any, thunkAPI) => {
    try {
      return await InternationalInspectionServices.setInternationalInspectionResult(
        data
      );
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getInternationalInspectionProductItems = createAsyncThunk(
  "InternationalInspections/product-items",
  async (data: any, thunkAPI) => {
    try {
      return await InternationalInspectionServices.getInternationalInspectionProductItems(
        data
      );
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const exportInternationalProductsExcel = createAsyncThunk(
  "InternationalInspections/products-export-excel",
  async (id: number, thunkAPI) => {
    try {
      return await InternationalInspectionServices.exportInternationalProductsExcel(
        id
      );
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const exportInternationalProductsPDF = createAsyncThunk(
  "InternationalInspections/products-export-pdf",
  async (id: number, thunkAPI) => {
    try {
      return await InternationalInspectionServices.exportInternationalProductsPDF(
        id
      );
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getInternationalInspectionSelectedProductItems = createAsyncThunk(
  "InternationalInspections/selected-product-items",
  async (data: any, thunkAPI) => {
    try {
      return await InternationalInspectionServices.getInternationalInspectionSelectedProductItems(
        data
      );
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const exportInternationalSelectedProductsExcel = createAsyncThunk(
  "InternationalInspections/selecteds-export-excel",
  async (id: number, thunkAPI) => {
    try {
      return await InternationalInspectionServices.exportInternationalSelectedProductsExcel(
        id
      );
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const exportInternationalSelectedProductsPDF = createAsyncThunk(
  "InternationalInspections/selecteds-export-pdf",
  async (id: number, thunkAPI) => {
    try {
      return await InternationalInspectionServices.exportInternationalSelectedProductsPDF(
        id
      );
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const InternationalInspectionSlice = createSlice({
  name: "InternationalInspection",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isDeleteLoading = false;
      state.isAddRequestLoading = false;
      state.isUpdateRequestLoading = false;
      state.isExportLoading = false;
      state.isSetResultLoading = false;
      state.isProductItemsLoading = true;
      state.isProductItemsExportExcelLoading = false;
      state.isProductItemsExportPDFLoading = false;
      state.isSuccess = false;
      state.isDeleteSuccess = false;
      state.isAddRequestSuccess = false;
      state.isUpdateRequestSuccess = false;
      state.isExportSuccess = false;
      state.isSetResultSuccess = false;
      state.isProductItemsSuccess = false;
      state.isProductItemsExportExcelSuccess = false;
      state.isProductItemsExportPDFSuccess = false;
      state.isError = false;
      state.isDeleteError = false;
      state.isAddRequestError = false;
      state.isUpdateRequestError = false;
      state.isExportError = false;
      state.isSetResultError = false;
      state.isProductItemsError = false;
      state.isProductItemsExportExcelError = false;
      state.isProductItemsExportPDFError = false;
      state.message = "";
      state.addRequestMessage = "";
      state.updateRequestMessage = "";
      state.exportMessage = "";
      state.setResultMessage = "";
      state.productItemsMessage = "";
      state.productItemsExportExcelMessage = "";
      state.productItemsExportPDFMessage = "";
      state.deleteMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInternationalInspections.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInternationalInspections.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.internationalInspections = action.payload.data.results;
        state.internationalInspectionsMeta = action.payload.data.meta;
      })
      .addCase(getInternationalInspections.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })
      .addCase(exportInternationalInspections.pending, (state) => {
        state.isExportLoading = true;
      })
      .addCase(exportInternationalInspections.fulfilled, (state, action) => {
        state.isExportLoading = false;
        state.isExportSuccess = true;
      })
      .addCase(exportInternationalInspections.rejected, (state, action) => {
        state.isExportLoading = false;
        state.isExportError = true;
        state.exportMessage = `${action.payload}`;
      })

      .addCase(getInternationalInspectionId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInternationalInspectionId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.singleInternationalInspection = action.payload.data.results;
      })

      .addCase(getInternationalInspectionId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })
      .addCase(addInternationalInspection.pending, (state) => {
        state.isAddRequestLoading = true;
      })
      .addCase(addInternationalInspection.fulfilled, (state, action) => {
        state.isAddRequestLoading = false;
        state.isAddRequestSuccess = true;
        state.addRequestMessage = `${action.payload.data.message}`;
      })
      .addCase(addInternationalInspection.rejected, (state, action) => {
        state.isAddRequestLoading = false;
        state.isAddRequestError = true;
        state.addRequestMessage = `${action.payload}`;
      })

      .addCase(patchInternationalInspection.pending, (state) => {
        state.isUpdateRequestLoading = true;
      })
      .addCase(patchInternationalInspection.fulfilled, (state, action) => {
        state.isUpdateRequestLoading = false;
        state.isUpdateRequestSuccess = true;
        state.updateRequestMessage = `${action.payload.data.message}`;
      })
      .addCase(patchInternationalInspection.rejected, (state, action) => {
        state.isUpdateRequestLoading = false;
        state.isUpdateRequestError = true;
        state.updateRequestMessage = `${action.payload}`;
      })

      .addCase(deleteInternationalInspection.pending, (state) => {
        state.isDeleteLoading = true;
      })
      .addCase(deleteInternationalInspection.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteSuccess = true;
        state.deleteMessage = `${action.payload.data.message}`;
      })
      .addCase(deleteInternationalInspection.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.deleteMessage = `${action.payload}`;
      })

      .addCase(setInternationalInspectionResult.pending, (state) => {
        state.isSetResultLoading = true;
      })
      .addCase(setInternationalInspectionResult.fulfilled, (state, action) => {
        state.isSetResultLoading = false;
        state.isSetResultSuccess = true;
        state.setResultMessage = `${action.payload.data.message}`;
      })
      .addCase(setInternationalInspectionResult.rejected, (state, action) => {
        state.isSetResultLoading = false;
        state.isSetResultError = true;
        state.setResultMessage = `${action.payload}`;
      })

      .addCase(getInternationalInspectionProductItems.pending, (state) => {
        state.isProductItemsLoading = true;
      })
      .addCase(
        getInternationalInspectionProductItems.fulfilled,
        (state, action) => {
          state.isProductItemsLoading = false;
          state.isProductItemsSuccess = true;
          state.productItems = action.payload.data.results;
          state.productItemsMeta = action.payload.data.meta;
        }
      )
      .addCase(
        getInternationalInspectionProductItems.rejected,
        (state, action) => {
          state.isProductItemsLoading = false;
          state.isProductItemsError = true;
          state.productItemsMessage = `${action.payload}`;
        }
      )

      .addCase(exportInternationalProductsExcel.pending, (state) => {
        state.isProductItemsExportExcelLoading = true;
      })
      .addCase(exportInternationalProductsExcel.fulfilled, (state, action) => {
        state.isProductItemsExportExcelLoading = false;
        state.isProductItemsExportExcelSuccess = true;
      })
      .addCase(exportInternationalProductsExcel.rejected, (state, action) => {
        state.isProductItemsExportExcelLoading = false;
        state.isProductItemsExportExcelError = true;
        state.productItemsExportExcelMessage = `${action.payload}`;
      })
      .addCase(exportInternationalProductsPDF.pending, (state) => {
        state.isProductItemsExportPDFLoading = true;
      })
      .addCase(exportInternationalProductsPDF.fulfilled, (state, action) => {
        state.isProductItemsExportPDFLoading = false;
        state.isProductItemsExportPDFSuccess = true;
      })
      .addCase(exportInternationalProductsPDF.rejected, (state, action) => {
        state.isProductItemsExportPDFLoading = false;
        state.isProductItemsExportPDFError = true;
        state.productItemsExportPDFMessage = `${action.payload}`;
      })

      .addCase(
        getInternationalInspectionSelectedProductItems.pending,
        (state) => {
          state.isProductItemsLoading = true;
        }
      )
      .addCase(
        getInternationalInspectionSelectedProductItems.fulfilled,
        (state, action) => {
          state.isProductItemsLoading = false;
          state.isProductItemsSuccess = true;
          state.productItems = action.payload.data.results;
          state.productItemsMeta = action.payload.data.meta;
        }
      )
      .addCase(
        getInternationalInspectionSelectedProductItems.rejected,
        (state, action) => {
          state.isProductItemsLoading = false;
          state.isProductItemsError = true;
          state.productItemsMessage = `${action.payload}`;
        }
      )

      .addCase(exportInternationalSelectedProductsExcel.pending, (state) => {
        state.isProductItemsExportExcelLoading = true;
      })
      .addCase(
        exportInternationalSelectedProductsExcel.fulfilled,
        (state, action) => {
          state.isProductItemsExportExcelLoading = false;
          state.isProductItemsExportExcelSuccess = true;
        }
      )
      .addCase(
        exportInternationalSelectedProductsExcel.rejected,
        (state, action) => {
          state.isProductItemsExportExcelLoading = false;
          state.isProductItemsExportExcelError = true;
          state.productItemsExportExcelMessage = `${action.payload}`;
        }
      )
      .addCase(exportInternationalSelectedProductsPDF.pending, (state) => {
        state.isProductItemsExportPDFLoading = true;
      })
      .addCase(
        exportInternationalSelectedProductsPDF.fulfilled,
        (state, action) => {
          state.isProductItemsExportPDFLoading = false;
          state.isProductItemsExportPDFSuccess = true;
        }
      )
      .addCase(
        exportInternationalSelectedProductsPDF.rejected,
        (state, action) => {
          state.isProductItemsExportPDFLoading = false;
          state.isProductItemsExportPDFError = true;
          state.productItemsExportPDFMessage = `${action.payload}`;
        }
      );
  },
});

export const { reset } = InternationalInspectionSlice.actions;
export default InternationalInspectionSlice.reducer;
