import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StyledTable from "src/components/Table";
import { GridColDef } from "@mui/x-data-grid";
import { AppDispatch, RootState } from "src/store";
import {
  IconButton,
  Box,
  Typography,
  InputAdornment,
  css,
} from "@mui/material";
import { Add, Eye } from "iconsax-react";
import colors from "src/theme/colors";
import { styled } from "@mui/material/styles";
import { StyledButton } from "src/components/Button";
import ConfirmModal from "src/components/Modal";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";
import { GrayButton } from "src/components/GrayButton";
import { StyledTextField } from "src/components/Input";
import { Search } from "@mui/icons-material";
import {
  deleteSaleOrder,
  exportSaleOrders,
  getSaleOrders,
  reset as resetSaleOrders,
} from "../redux/SaleOrdersSlice";

enum StatusBadgeTypes {
  Preparing = "preparing",
  PortOperation = "port_operations",
  SendingToPort = "sending_to_port",
  Completed = "completed",
}

const SaleOrdersList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [searchValue, setSearchValue] = useState("");
  const [Data, setData] = useState([] as any);
  const [MetaData, setMetaData] = useState([] as any);

  const [isDeletetModalOpen, setIsDeletetModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const {
    saleOrders,
    saleOrdersMeta,
    isError,
    isExportError,
    isDeleteError,
    isSuccess,
    isExportSuccess,
    isDeleteSuccess,
    message,
    deleteMessage,
    exportMessage,
    isLoading,
    isDeleteLoading,
    isExportLoading,
  } = useSelector((state: RootState) => state.saleOrders);

  useEffect(() => {
    if (isExportError) {
      enqueueSnackbar(exportMessage, { variant: "error" });
    }
  }, [isExportError, isExportSuccess, exportMessage, dispatch]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      if (message) {
        enqueueSnackbar(message, { variant: "success" });
      } else {
        setData(saleOrders);
        setMetaData(saleOrdersMeta);
      }
    }
    return () => {
      dispatch(resetSaleOrders());
    };
  }, [isError, isSuccess, message, page]);

  useEffect(() => {
    if (isDeleteError) {
      enqueueSnackbar(deleteMessage, { variant: "error" });
    }
    if (isDeleteSuccess) {
      enqueueSnackbar(deleteMessage, { variant: "success" });
      dispatch(
        getSaleOrders({
          page: Data.length <= 1 ? (page > 1 ? page - 1 : 1) : page,
        })
      ).then(() => setIsDeletetModalOpen(false));

      if (Data.length <= 1) {
        setPage(page > 1 ? page - 1 : 1);
      }
    }
    return () => {
      dispatch(resetSaleOrders());
    };
  }, [isDeleteError, isDeleteSuccess, deleteMessage]);

  useEffect(() => {
    dispatch(
      getSaleOrders({
        page: page,
        search: searchValue,
      })
    );
  }, [page, searchValue]);

  const onDelete = (selected: any) => {
    dispatch(deleteSaleOrder(selected));
  };

  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: "Order ID",
      flex: 1,
      minWidth: 140,
    },
    {
      field: "name",
      headerName: "Order Title",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "customer",
      headerName: "Customer Name",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.customer?.full_name}
        </Box>
      ),
    },
    {
      field: "product",
      headerName: "Product",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.product?.title}
        </Box>
      ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "origin_warehouse",
      headerName: "Warehouse",
      flex: 1,
      minWidth: 260,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.origin_warehouse?.title}
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        switch (params.row?.status) {
          case StatusBadgeTypes.Preparing:
            return (
              <BadgeBox type={StatusBadgeTypes.Preparing}>
                <BadgeIcon>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icons/preparingStatusIcon.svg"
                    }
                    alt="status"
                  />
                </BadgeIcon>
                Preparing
              </BadgeBox>
            );

          case StatusBadgeTypes.PortOperation:
            return (
              <BadgeBox type={StatusBadgeTypes.PortOperation}>
                <BadgeIcon>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/icons/LoadingBlue.svg"
                    }
                    alt="status"
                  />
                </BadgeIcon>
                Port Operation
              </BadgeBox>
            );

          case StatusBadgeTypes.SendingToPort:
            return (
              <BadgeBox type={StatusBadgeTypes.SendingToPort}>
                <BadgeIcon>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/icons/LoadingBlue.svg"
                    }
                    alt="status"
                  />
                </BadgeIcon>
                Sending to Port
              </BadgeBox>
            );

          case StatusBadgeTypes.Completed:
            return (
              <BadgeBox type={StatusBadgeTypes.Completed}>
                <BadgeIcon>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/icons/Check.svg"}
                    alt="status"
                  />
                </BadgeIcon>
                Completed
              </BadgeBox>
            );

          default:
            break;
        }
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.2,
      minWidth: 140,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <Box
          width="100%"
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          flexDirection="row"
        >
          <IconButton
            onClick={() => {
              navigate("/saleOrders/saleOrders-information", {
                state: {
                  data: params?.row,
                },
              });
            }}
          >
            <Eye color={colors.gray[600]} size="24" />
          </IconButton>
          <IconButton
            onClick={() => {
              navigate("/saleOrders/saleOrders-edit", {
                state: {
                  id: params?.id,
                },
              });
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Edit.svg"}
              alt="edit"
            />
          </IconButton>
          {/* {params?.row?.status === StatusBadgeTypes.Preparing ? (
            <IconButton>
              <img
                src={process.env.PUBLIC_URL + "/assets/icons/Remove.svg"}
                alt="delete"
                onClick={() => {
                  setSelectedId(Number(params?.id));
                  setIsDeletetModalOpen(true);
                }}
              />
            </IconButton>
          ) : null} */}
        </Box>
      ),
    },
  ];

  return (
    <PageTemplate
      crumbs={[
        {
          label: "Sale Orders list",
        },
      ]}
    >
      <StyledHeader>
        <SearchBox>
          <StyledTextField
            placeholder="Search"
            fullWidth
            height="48px"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </SearchBox>
        <ButtonsBox>
          <ActionsBox>
            <ActionBtn>
              <GrayButton
                variant="contained"
                fullWidth
                loading={isExportLoading}
                endIcon={
                  <img
                    src={process.env.PUBLIC_URL + "/assets/icons/Export.svg"}
                    alt="export"
                  />
                }
                onClick={() => {
                  dispatch(exportSaleOrders({})).then(() => resetSaleOrders());
                }}
              >
                Export
              </GrayButton>
            </ActionBtn>
          </ActionsBox>
          <StyledButton
            variant="contained"
            endIcon={<Add size="24" color={colors.gray[850]} />}
            onClick={() => navigate("/saleOrders/saleOrders-new")}
          >
            New Sale Order
          </StyledButton>
        </ButtonsBox>
      </StyledHeader>
      <ListBoundary
        isEmpty={Data.length === 0 || !Data}
        isDataLoading={isLoading}
        fallbackImageURL="/assets/images/emptyStates/SaleOrders-List EmptyState.svg"
        description="Register Your First Sale Order!"
        buttonLabel="New Sale Order"
        onClick={() => navigate("/saleOrders/saleOrders-new")}
      >
        <StyledTable
          disableColumnMenu
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableVirtualization
          disableSelectionOnClick
          loading={isLoading}
          experimentalFeatures={{ newEditingApi: true }}
          rows={Data}
          columns={columns}
          onPageChange={(e) => setPage(e + 1)}
          page={page - 1}
          paginationMode="server"
          rowCount={MetaData?.pagination?.total_items}
        />
      </ListBoundary>
      <ConfirmModal
        title={"Remove Sale Order"}
        iconName={"ModalRemove"}
        open={isDeletetModalOpen}
        isLoading={isLoading || isDeleteLoading}
        onDismiss={() => setIsDeletetModalOpen(false)}
        onConfirm={() => onDelete(selectedId)}
        confrimText={"Remove"}
        cancelText={"Cancel"}
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to remove this Sale Order?
        </Typography>
      </ConfirmModal>
    </PageTemplate>
  );
};

interface StyledProps {
  type?: StatusBadgeTypes;
}

const StyledHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  ${(props) => props.theme.breakpoints.down("xl")} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SearchBox = styled(Box)`
  width: 372px;
  ${(props) => props.theme.breakpoints.down("xl")} {
    margin-bottom: 24px;
    width: 315px;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    margin-bottom: 18px;
  }
`;

const ButtonsBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("xl")} {
    width: 100%;
    justify-content: space-between;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

const ActionsBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 24px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

const ActionBtn = styled(Box)`
  width: 145px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

const BadgeBox = styled(Box)<StyledProps>(({ type }) => {
  let color = colors.primary[800];
  let bgColor = colors.primary[10];

  switch (type) {
    case StatusBadgeTypes.Preparing:
      color = colors.primary[800];
      bgColor = colors.primary[10];
      break;
    case StatusBadgeTypes.PortOperation:
      color = colors.secondary[800];
      bgColor = colors.secondary[50];
      break;
    case StatusBadgeTypes.SendingToPort:
      color = colors.secondary[800];
      bgColor = colors.secondary[50];
      break;
    case StatusBadgeTypes.Completed:
      color = colors.success[700];
      bgColor = colors.success[50];
      break;

    default:
      break;
  }

  return css`
    padding: 2px 15px 2px 12px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    line-height: 26px;
    font-weight: 400;
    font-size: 13px;
    background-color: ${bgColor};
    color: ${color};
  `;
});

const BadgeIcon = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default SaleOrdersList;
