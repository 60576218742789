import { Box, css, Typography } from "@mui/material";
import emoStyled, { CSSObject } from "@emotion/styled";
import styled from "@emotion/styled/macro";
import type { FC } from "react";
import colors from "src/theme/colors";
import ChevronRight from "@mui/icons-material/ChevronRight";

export enum TotalShippingDetailCardType {
  Recieved = "Recieved",
  Stuffed = "Stuffed",
  Remaining = "Remaining",
}

interface TotalShippingDetailCard {
  type: TotalShippingDetailCardType;
  isActive?: boolean;
  title: string;
  totalCount: number;
  trucks?: number;
  products?: number;
  onClick: (type: TotalShippingDetailCardType) => void;
}

export const TotalShippingDetailCard: FC<TotalShippingDetailCard> = (props) => {
  const { type, isActive, title, totalCount, trucks, products, onClick } =
    props;

  const iconGenerator = () => {
    switch (type) {
      case TotalShippingDetailCardType.Recieved:
        return "Warehouse";
      case TotalShippingDetailCardType.Stuffed:
        return "Container";
      case TotalShippingDetailCardType.Remaining:
        return "PortAnchor";

      default:
        return "Warehouse";
    }
  };

  const LeftIconGenerator = () => {
    switch (type) {
      case TotalShippingDetailCardType.Recieved:
        return "MiniTruck";
      case TotalShippingDetailCardType.Stuffed:
        return "MiniContainer";
      case TotalShippingDetailCardType.Remaining:
        return "MiniBoxYellow";

      default:
        return "MiniTruck";
    }
  };

  const RightIconGenerator = () => {
    switch (type) {
      case TotalShippingDetailCardType.Recieved:
        return "MiniBox";
      case TotalShippingDetailCardType.Stuffed:
        return "MiniBoxGreen";

      default:
        return "MiniBox";
    }
  };

  return (
    <Container
      cardType={type}
      isActive={isActive}
      onClick={() => onClick(type)}
    >
      <Head>
        <TitleBox>
          <img
            src={
              process.env.PUBLIC_URL +
              `/assets/icons/saleOrderShipment/${iconGenerator()}.svg`
            }
            alt="Icon"
          />
          <Title>{title}</Title>
        </TitleBox>
        <NavBtn cardType={type} isActive={isActive}>
          <ChevronRight />
        </NavBtn>
      </Head>
      <TotalCount isActive={isActive}>
        <TotalCountValue>{totalCount ?? 0}</TotalCountValue>
        <span>MT</span>
      </TotalCount>
      <Row>
        {type !== TotalShippingDetailCardType.Remaining ? (
          <Col>
            <Label>Sent Trucks</Label>
            <CountBox>
              <img
                src={
                  process.env.PUBLIC_URL +
                  `/assets/icons/saleOrderShipment/${LeftIconGenerator()}.svg`
                }
                alt="Icon"
              />
              <Value isActive={isActive}>{trucks}</Value>
            </CountBox>
          </Col>
        ) : null}
        <Col>
          <Label>
            {type !== TotalShippingDetailCardType.Remaining
              ? "Sent Products"
              : "Products"}
          </Label>
          <CountBox>
            <img
              src={
                process.env.PUBLIC_URL +
                `/assets/icons/saleOrderShipment/${RightIconGenerator()}.svg`
              }
              alt="Icon"
            />
            <Value isActive={isActive}>{products}</Value>
          </CountBox>
        </Col>
      </Row>
    </Container>
  );
};

interface StyledProps {
  cardType?: TotalShippingDetailCardType;
  isActive?: boolean;
}

const Head = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-betweeen",
  alignItems: "center",
});

const TitleBox = styled(Box)({
  width: "calc(100% - 40px)",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "12px",
});

const NavBtn = styled(Box)<StyledProps>(({ isActive, cardType }) => {
  let color;
  let bgColor;

  switch (cardType) {
    case TotalShippingDetailCardType.Recieved:
      color = colors.secondary[600];
      bgColor = colors.secondary[50];
      break;

    case TotalShippingDetailCardType.Stuffed:
      color = colors.success[500];
      bgColor = colors.success[50];
      break;

    case TotalShippingDetailCardType.Remaining:
      color = colors.primary[600];
      bgColor = colors.primary[50];
      break;

    default:
      color = colors.secondary[600];
      bgColor = colors.secondary[50];
      break;
  }

  return css`
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${isActive ? color : bgColor};
    & > svg {
      color: ${isActive ? "#ffffff" : color};
    }
  `;
});

const TotalCount = styled(Box)<StyledProps>(({ isActive }) => {
  return css`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 28px 0 16px 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: ${isActive ? colors.midnight[850] : colors.midnight[400]};
    transition: 150ms all ease-in;
  `;
});

const TotalCountValue = styled(Box)({
  fontSize: "32px",
  fontWeight: 700,
  lineHeight: "42px",
});

const Row = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "100px",
});

const Col = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
});

const CountBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "8px",
});

const Title = styled(Typography)({
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "24px",
  color: colors.midnight[850],
});

const Label = styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "32px",
  color: colors.midnight[500],
});

const Value = styled(Typography)<StyledProps>(({ isActive }) => {
  return css`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: ${isActive ? colors.midnight[850] : colors.midnight[400]};
    transition: 150ms all ease-in;
  `;
});

const Container = styled(Box)<StyledProps>(({ isActive, cardType }) => {
  let borderColor;
  let navBgColor;

  switch (cardType) {
    case TotalShippingDetailCardType.Recieved:
      borderColor = "linear-gradient(to bottom, #FCFCFC, #4ACAFC) 30";
      break;

    case TotalShippingDetailCardType.Stuffed:
      borderColor = "linear-gradient(to bottom, #FCFCFC, #29D39C) 30";
      break;

    case TotalShippingDetailCardType.Remaining:
      borderColor = "linear-gradient(to bottom, #FCFCFC, #FFDB63) 30";
      break;

    default:
      borderColor = "linear-gradient(to bottom, #FCFCFC, #4ACAFC) 30";
      break;
  }

  switch (cardType) {
    case TotalShippingDetailCardType.Recieved:
      navBgColor = colors.secondary[600];
      break;

    case TotalShippingDetailCardType.Stuffed:
      navBgColor = colors.success[500];
      break;

    case TotalShippingDetailCardType.Remaining:
      navBgColor = colors.primary[500];
      break;

    default:
      navBgColor = colors.secondary[500];
      break;
  }

  return css`
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 36px 32px;
    border: 2px solid;
    border-color: ${colors.midnight[100]};
    border-image: ${isActive ? borderColor : "unset"};
    border-radius: 5px;
    transition: 150ms all ease-in;

    &:hover {
      border-image: ${borderColor};
      border-width: 2px;
      border-style: solid;
      ${TotalCount} {
        transition: 150ms all ease-in;
        color: ${colors.midnight[850]};
      }
      ${Value} {
        transition: 150ms all ease-in;
        color: ${colors.midnight[850]};
      }
      ${NavBtn} {
        background-color: ${navBgColor};
        & > svg {
          color: #ffffff;
        }
      }
    }
  `;
});
