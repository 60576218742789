import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import SaleOrdersEdit from "../pages/SaleOrdersEdit";
import SaleOrdersInfo from "../pages/SaleOrdersInfo";
import SaleOrderOriginCarrierInfo from "../pages/shippingDetails/originWarehouse/SaleOrderOriginCarrierInfo";
import SaleOrderRecievedCarrierInfo from "../pages/shippingDetails/portOperation/SaleOrderRecievedCarrierInfo";
import SaleOrderStuffedCarrierInfo from "../pages/shippingDetails/portOperation/SaleOrderStuffedCarrierInfo";
import SaleOrdersNew from "../pages/SaleOrdersNew";
import SaleOrdersList from "../pages/SaleOrdersList";

export const SaleOrdersRoutes = () => {
  const { t } = useTranslation();
  return (
    <Routes>
      <Route path="/saleOrders-list" element={<SaleOrdersList />} />
      <Route path="/saleOrders-new" element={<SaleOrdersNew />} />
      <Route path="/saleOrders-edit" element={<SaleOrdersEdit />} />
      <Route path="/saleOrders-information" element={<SaleOrdersInfo />} />
      <Route
        path="/saleOrders-information/origin-carrier-information"
        element={<SaleOrderOriginCarrierInfo />}
      />
      <Route
        path="/saleOrders-information/recieved-carrier-information"
        element={<SaleOrderRecievedCarrierInfo />}
      />
      <Route
        path="/saleOrders-information/container-information"
        element={<SaleOrderStuffedCarrierInfo />}
      />
    </Routes>
  );
};
