import { createTheme } from "@mui/material/styles";
import shadows, { Shadows } from "@mui/material/styles/shadows";
import { pxToRem } from "../utils/pxToRem";
import palette from "./palette";
// A custom theme for this app

const MainFont = "IBM Plex Sans";

const theme = createTheme({
  shadows: shadows.map(() => "none") as Shadows,
  spacing: 2,

  typography: {
    fontFamily: MainFont,
    h1: {
      fontSize: pxToRem(56),
    },
    h2: {
      fontSize: pxToRem(36),
    },
    h3: {
      fontSize: pxToRem(28),
    },
    h4: {
      fontSize: pxToRem(24),
    },
    h5: {
      fontSize: pxToRem(18),
    },
    h6: {
      fontSize: pxToRem(17),
    },
    subtitle1: {
      fontSize: pxToRem(16),
    },
    body1: {
      fontSize: pxToRem(16),
    },
    body2: {
      fontSize: pxToRem(14),
    },
    caption: {
      fontSize: pxToRem(12),
    },
    button: {
      fontWeight: 500,
      fontSize: pxToRem(16),
      textTransform: "capitalize",
    },
  },

  palette,
});
export default theme;
