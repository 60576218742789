import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import StyledTable from "src/components/Table";
import { GridColDef } from "@mui/x-data-grid";
import { AppDispatch, RootState } from "src/store";
import { deleteRoles, getRoles, reset } from "../redux/roleSlice";
import { IconButton, Box, Typography } from "@mui/material";
import TableChip from "src/components/TableChip";
import { Add, Eye } from "iconsax-react";
import colors from "src/theme/colors";
import styled from "@emotion/styled";
import { StyledButton } from "src/components/Button";
import ConfirmModal from "src/components/Modal";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";

const RoleTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [Data, setData] = useState([] as any);
  const [MetaData, setMetaData] = useState([] as any);
  const [selectedId, setSelectedId] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const {
    roles,
    rolesMeta,
    isError,
    isDeleteError,
    isSuccess,
    isDeleteSuccess,
    isLoading,
    isDeleteLoading,
    message,
    deleteMessage,
  } = useSelector((state: RootState) => state.role);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      if (message) {
        enqueueSnackbar(message, { variant: "success" });
      } else {
        setData(roles);
        setMetaData(rolesMeta);
      }
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, isSuccess, message]);

  useEffect(() => {
    if (isDeleteError) {
      enqueueSnackbar(deleteMessage, { variant: "error" });
    }
    if (isDeleteSuccess) {
      enqueueSnackbar(deleteMessage, { variant: "success" });
      dispatch(
        getRoles({
          page: Data.length <= 1 ? (page > 1 ? page - 1 : 1) : page,
        })
      ).then(() => setIsDeleteModalOpen(false));

      if (Data.length <= 1) {
        setPage(page > 1 ? page - 1 : 1);
      }
    }
    return () => {
      dispatch(reset());
    };
  }, [isDeleteError, isDeleteSuccess, deleteMessage]);

  useEffect(() => {
    dispatch(
      getRoles({
        page: page,
      })
    );
  }, [page]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 80 },
    {
      field: "title",
      headerName: "Role name",
      flex: 0.2,
      minWidth: 120,
    },
    {
      field: "permissions",
      headerName: "Permissions",
      flex: 1,
      minWidth: 800,
      renderCell: (params) => (
        <Box display="flex" flexDirection="row" flexWrap="wrap" py={10}>
          {params.row?.permissions?.map((item: any) => {
            return (
              <Box key={item.id} padding={2}>
                <TableChip>{item.description}</TableChip>
              </Box>
            );
          })}
        </Box>
      ),
    },
    {
      field: "users_count",
      headerName: "Users",
      width: 80,
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.2,
      minWidth: 140,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <>
          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            flexDirection="row"
          >
            <IconButton
              onClick={() =>
                navigate("/roles-management/role-information", {
                  state: { id: params?.id },
                })
              }
            >
              <Eye color={colors.gray[600]} size="24" />
            </IconButton>
            <IconButton
              onClick={() =>
                navigate("/roles-management/role-edit", {
                  state: {
                    id: params?.id,
                    permissions: params.row?.permissions.map((item: any) => {
                      return item.id;
                    }),
                    title: params.row.title,
                  },
                })
              }
            >
              <img
                src={process.env.PUBLIC_URL + "/assets/icons/Edit.svg"}
                alt="edit"
              />
            </IconButton>
            {/* <IconButton>
              <img
                src={process.env.PUBLIC_URL + "/assets/icons/Remove.svg"}
                alt="delete"
                onClick={() => {
                  setSelectedId(Number(params?.id));
                  setIsDeleteModalOpen(true);
                }}
              />
            </IconButton> */}
          </Box>
        </>
      ),
    },
  ];

  const onDelete = (id: number) => {
    const deleteValue = {
      id: id,
    };
    dispatch(deleteRoles(deleteValue));
  };

  return (
    <PageTemplate
      crumbs={[
        {
          label: "Role list",
        },
      ]}
    >
      <ListBoundary
        isEmpty={Data.length === 0 || !Data}
        isDataLoading={isLoading}
        fallbackImageURL="/assets/images/emptyStates/Roles-List EmptyState.svg"
        description="Register The First Role!"
        buttonLabel="New Role"
        onClick={() => navigate("/roles-management/new-role")}
      >
        <StyledHeader>
          <StyledButton
            variant="contained"
            endIcon={<Add size="24" color={colors.gray[850]} />}
            onClick={() => navigate("/roles-management/new-role")}
          >
            {t("role.roleTable.tableNewButton")}
          </StyledButton>
        </StyledHeader>
        <StyledTable
          disableColumnMenu
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableVirtualization
          disableSelectionOnClick
          loading={isLoading}
          getRowHeight={() => "auto"}
          experimentalFeatures={{ newEditingApi: true }}
          rows={Data}
          columns={columns}
          paginationMode="server"
          onSelectionModelChange={(e) => setSelectedId(Number(e))}
          onPageChange={(e) => setPage(e + 1)}
          page={page - 1}
          rowCount={MetaData?.pagination?.total_items}
        />
      </ListBoundary>
      <ConfirmModal
        title={"Remove Role"}
        iconName={"ModalRemove"}
        open={isDeleteModalOpen}
        isLoading={isLoading || isDeleteLoading}
        onDismiss={() => setIsDeleteModalOpen(false)}
        onConfirm={() => onDelete(selectedId)}
        confrimText={"Remove"}
        cancelText={"Cancel"}
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to remove this roles?
        </Typography>
      </ConfirmModal>
    </PageTemplate>
  );
};

const StyledHeader = styled(Box)`
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 24px;
`;

export default RoleTable;
