export function nameLengthValidator(file: any) {
  if (file.size > 15000000) {
    return {
      code: "image is too large",
      message: `Image is larger than 15 MB characters`,
    };
  }

  return null;
}

export const defaultAcceptedFileTypes = () => {
  return {
    "image/jpeg": [".jpeg"],
    "image/jpg": [".jpg"],
    "application/pdf": [".pdf"],
  };
};
