import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { styled as muiStyled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { PageTemplate } from "src/layout/components/PageTemplate";
import {
  getProducts,
  reset as resetProducts,
  serachProducts,
} from "src/features/products/redux/ProductsSlice";
import { AppDispatch, RootState } from "src/store";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { InventoriesSearchForm } from "../components/inventoriesSearch/InventoriesSearchForm";
import { InventoriesSearchResultTable } from "../components/inventoriesSearch/InventoriesSearchResultTable";
import colors from "src/theme/colors";
import { Typography } from "@mui/material";
import { PaperBox } from "src/components/Paper/Paper";

interface LocationStateProps {
  warehouseId: string;
  warehouseTitle: string;
}

const InventoriesSearch: FC = (props) => {
  const location = useLocation();
  const state = location.state as LocationStateProps;
  const { warehouseId, warehouseTitle } = state;
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(1);
  const [searchedData, setSearchedData] = useState<any>(null);
  const [tableDefaultState, setTableDefaultState] = useState(true);
  const [productsData, setProductsData] = useState([] as any);

  const {
    products: allProductsData,
    searchedProducts: searchedProductsData,
    searchedProductsMeta: searchedProductsDataMeta,
    isLoading: isAllProductsDataLoading,
    isSearchLoading: isSearchedProductsDataLoading,
    isError: isAllProductsDataError,
    isSuccess: isAllProductsDataSuccess,
    isSearchSuccess: isSearchedProductsDataSuccess,
    message: allProductsDataMessage,
  } = useSelector((state: RootState) => state.products);

  useEffect(() => {
    if (isAllProductsDataError) {
      enqueueSnackbar(allProductsDataMessage, { variant: "error" });
    }
    if (isAllProductsDataSuccess) {
      if (allProductsDataMessage) {
        enqueueSnackbar(allProductsDataMessage, { variant: "success" });
      } else {
        setProductsData(allProductsData);
      }
    }
    return () => {
      dispatch(resetProducts());
    };
  }, [
    isAllProductsDataError,
    isAllProductsDataSuccess,
    allProductsDataMessage,
    isAllProductsDataLoading,
  ]);

  useEffect(() => {
    const getAllProductsInfo = {
      page: 1,
      limit: 100,
    };
    dispatch(getProducts(getAllProductsInfo));

    return () => {
      dispatch(resetProducts());
    };
  }, []);

  useEffect(() => {
    if (searchedData) {
      dispatch(
        serachProducts({
          ...searchedData,
          page: page,
        })
      );
    }
  }, [page, searchedData]);

  return (
    <PageTemplate
      isTransparent
      crumbs={[
        {
          label: "Warehouses List",
          href: "/warehouses/warehouse-list",
          depth: -2,
        },
        {
          label: warehouseTitle,
          href: "/warehouses/warehouse-information",
          depth: -1,
        },
        {
          label: "Search Products",
        },
      ]}
    >
      <>
        <PaperBox>
          <InventoriesSearchForm
            warehouseId={warehouseId}
            products={productsData}
            onSearch={(data) => {
              setPage(1);
              setSearchedData(data);
              setTableDefaultState(false);
            }}
          />
        </PaperBox>

        <PaperBox>
          {tableDefaultState ? (
            <DefaultStateWrapper>
              <Image>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/SearchedProducts-DefaultState.svg"
                  }
                />
              </Image>
              <Desc>Search for any transaction of product you want!</Desc>
            </DefaultStateWrapper>
          ) : (
            <InventoriesSearchResultTable
              isLoading={isSearchedProductsDataLoading}
              warehouseId={warehouseId}
              searchedProducts={searchedProductsData}
              rowsCount={
                (searchedProductsDataMeta as any)?.pagination?.total_items
              }
              page={page}
              setNextPage={(page) => setPage(page)}
            />
          )}
        </PaperBox>
      </>
    </PageTemplate>
  );
};

export default InventoriesSearch;

const DefaultStateWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 340px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 18px;
`;

const Image = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Desc = muiStyled(Typography)({
  color: colors.gray[600],
  textAlign: "center",
  fontSize: "18px",
  margin: "40px 0 24px 0",
});
