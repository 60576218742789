export default {
  10: "#FFFBEC",
  50: "#FFF8E0",
  100: "#FEEBB2",
  200: "#FDDE80",
  300: "#FCD34B",
  400: "#FCD535",
  500: "#EFB731",
  600: "#FBB000",
  700: "#FB9D00",
  800: "#C89358",
  900: "#FC6C00",
};
