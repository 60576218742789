import { Box, Typography, Button, IconButton } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import { FC } from "react";
import colors from "src/theme/colors";
import { useMemo, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { defaultAcceptedFileTypes, nameLengthValidator } from "../zoneHelpers";
import {
  acceptStyle,
  activeStyle,
  defaultTypeBaseStyle,
  inputTypeBaseStyle,
  rejectStyle,
} from "../zoneStyles";
import { UploaderSlidesCarousel } from "../UploaderSlidesCarousel/UploaderSlidesCarousel";
import styled from "@emotion/styled";
import { FileUploaderType } from "../FileUploader";
import { SingleZonePreviewer } from "./SingleZonePreviewer/SingleZonePreviewer";
import { useDeleteFile } from "../useDeleteFile";
import ConfirmModal from "src/components/Modal";

export enum FileUploadZoneType {
  Default,
  Input,
}

export enum FileUploadZoneSinglePreviewType {
  Image,
  Document,
}

export interface UploadedFileType {
  id?: string;
  path?: string;
  url: string;
  lastModified?: number;
  lastModifiedDate?: Date;
  name: string;
  size?: number;
  mime: string;
}

interface FileUploadZoneProps {
  type: FileUploaderType;
  zoneType?: FileUploadZoneType;
  singlePreviewType?: FileUploadZoneSinglePreviewType;
  files: UploadedFileType[];
  acceptedTypes?: any;
  maxFiles?: number;
  deleteEndPoint?: string;
  setFiles: React.Dispatch<React.SetStateAction<UploadedFileType[]>>;
  onAfterDeleteSingleFile?: () => void;
}

export const FileUploadZone: FC<FileUploadZoneProps> = (props) => {
  const {
    type,
    zoneType,
    singlePreviewType,
    files,
    acceptedTypes,
    maxFiles,
    deleteEndPoint,
    setFiles,
    onAfterDeleteSingleFile,
  } = props;

  const [loading, deleteFileFromApi] = useDeleteFile();
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: acceptedTypes ?? defaultAcceptedFileTypes(type),
    noClick: true,
    noKeyboard: true,
    // maxFiles: maxFiles,
    validator: nameLengthValidator,
    onDrop: (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) => {
        return Object.assign(file, {
          url: URL.createObjectURL(file),
          mime: file.type,
        });
      });
      setFiles((prev) => [...prev, ...newFiles]);
    },
  });

  useEffect(() => {
    if (maxFiles) {
      if (files.length > maxFiles) {
        setFiles(files.slice(files.length - maxFiles));
      }
    } else {
      if (files.length > 1) {
        setFiles(files.slice(files.length - 1));
      }
    }
  }, [files]);

  const baseStyle =
    zoneType === FileUploadZoneType.Default
      ? defaultTypeBaseStyle
      : inputTypeBaseStyle;

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const onDeleteLocalFile = () => {
    if (currentSlide != null) {
      setFiles(files.filter((fl) => fl.name !== files[currentSlide].name));
      if (currentSlide !== 0) {
        setCurrentSlide((prev) => prev - 1);
      }
    }
  };

  const onDeleteMultiFile = () => {
    if (files[currentSlide].path) {
      onDeleteLocalFile();
      setIsModalOpen(false);
    } else {
      const fileId = files[currentSlide].id;
      if (deleteEndPoint && fileId) {
        deleteFileFromApi(onDeleteLocalFile, deleteEndPoint, fileId);
        setIsModalOpen(false);
      }
    }
  };

  const onDeleteSingleFile = () => {
    setFiles([]);
    setIsModalOpen(false);
    if (onAfterDeleteSingleFile) {
      onAfterDeleteSingleFile();
    }
  };

  return (
    <>
      <Container isMultiple={type === FileUploaderType.Multiple}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {zoneType === FileUploadZoneType.Default ? (
            <>
              <IconBox>
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/DragAndDrop.svg"}
                />
              </IconBox>
              <Title as="span">Drag file here</Title>
              <Title as="span">Or</Title>
              <UploadButton
                variant="text"
                color="warning"
                size="small"
                onClick={open}
              >
                Browse File
              </UploadButton>
              {type === FileUploaderType.Multiple ? (
                <SubTitle>
                  You can add up to {maxFiles} Files, each not exceeding 5MB.
                </SubTitle>
              ) : null}
            </>
          ) : (
            <>
              {files.length === 0 || !files ? (
                <OpenLink onClick={open}>Browse files</OpenLink>
              ) : (
                <SingleUploadedBox>
                  <FileName>{files[0].name}</FileName>
                  <IconButton
                    color="error"
                    size="small"
                    onClick={() => setFiles([])}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/assets/icons/Remove.svg"}
                      alt="delete"
                    />
                  </IconButton>
                </SingleUploadedBox>
              )}
            </>
          )}

          {zoneType === FileUploadZoneType.Default ? (
            type === FileUploaderType.Multiple ? (
              <UploaderSlidesCarousel
                currentSlide={files[currentSlide]}
                currentSlideNumber={currentSlide + 1}
                uploadedSlides={files.length}
                max={maxFiles}
                deleteLoading={loading}
                onBrowse={open}
                isPrevDisabled={currentSlide <= 0}
                isNextDisabled={currentSlide >= files.length - 1}
                onPrev={() => setCurrentSlide((prev) => prev - 1)}
                onNext={() => setCurrentSlide((prev) => prev + 1)}
                onDeleteSlide={() => setIsModalOpen(true)}
              />
            ) : (
              <SingleZonePreviewer
                type={
                  singlePreviewType ?? FileUploadZoneSinglePreviewType.Image
                }
                file={files[0]}
                deleteLoading={loading}
                onDelete={() => setIsModalOpen(true)}
              />
            )
          ) : null}
        </div>
      </Container>

      <ConfirmModal
        title={"Remove File"}
        iconName={"ModalRemove"}
        open={isModalOpen}
        onDismiss={() => setIsModalOpen(false)}
        onConfirm={() => {
          if (type === FileUploaderType.Multiple) {
            onDeleteMultiFile();
          } else {
            onDeleteSingleFile();
          }
        }}
        confrimText={"Remove"}
        cancelText={"Cancel"}
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to remove these orders?
        </Typography>
      </ConfirmModal>
    </>
  );
};

FileUploadZone.defaultProps = {
  zoneType: FileUploadZoneType.Default,
};

interface StyledProps {
  isMultiple?: boolean;
}

const Container = styled(Box)<StyledProps>(({ isMultiple }) => ({
  width: "100%",
  height: isMultiple ? "calc(100% - 24px)" : "100%",
  display: "flex",
  position: "relative",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 4,
}));

const IconBox = muiStyled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "4px",
});

const Title = muiStyled(Typography)({
  fontSize: "13px",
  lineHeight: "22px",
  textAlign: "center",
  color: colors.gray[750],
});

const SubTitle = muiStyled(Typography)({
  fontSize: "13px",
  color: colors.gray[850],
  textAlign: "center",
});

const FileName = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.base.white};
  color: ${colors.secondary[700]};
  font-weight: bold;
  user-select: none;
`;

const OpenLink = muiStyled(Typography)({
  cursor: "pointer",
  fontSize: "13px",
  color: colors.primary[800],
});

const SingleUploadedBox = muiStyled(Typography)({
  position: "relative",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const UploadButton = muiStyled(Button)({
  marginBottom: "16px",
  borderRadius: 0,
  borderBottom: `1px dashed ${colors.primary[800]}`,
  color: colors.primary[800],
  fontSize: "13px",
  ":hover": {
    backgroundColor: colors.primary[50],
  },
});
