import { axios } from "../../../config/Axios";

//inspections/international get
const getInternationalInspections = async (data: any) => {
  const response = await axios.get(
    `/inspections/international?page=${data.page}${
      data?.search ? `&title=${data?.search}` : ""
    }`
  );
  return response;
};
//inspections/international EXPORT
const exportInternationalInspections = async () => {
  const response = await axios({
    url: `/inspections/international?export_excel=true`,
    method: "get",
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${Date.now()}.xlsx`);
  document.body.appendChild(link);
  link.click();

  return response;
};
//inspections/international get by id
const getInternationalInspectionId = async (id: number) => {
  const response = await axios.get(`/inspections/international/${id}`);
  return response;
};
//inspections/international create
const addInternationalInspection = async (data: any) => {
  const response = await axios.post("/inspections/international", data);
  return response;
};
//inspections/international patch
const patchInternationalInspection = async (data: any) => {
  const response = await axios.patch(
    `/inspections/international/${data.id}`,
    data
  );
  return response;
};
//inspections/international delete
const deleteInternationalInspection = async (id: number) => {
  const response = await axios.delete(`/inspections/international/${id}`);
  return response;
};
//inspections/international set result
const setInternationalInspectionResult = async (data: any) => {
  const response = await axios.post(
    `/inspections/international/${data.id}/results`,
    data
  );
  return response;
};
//inspections/international product-items
const getInternationalInspectionProductItems = async (data: any) => {
  const response = await axios.get(
    `/inspections/international/${data.id}/product-items?page=${data.page}`
  );
  return response;
};
//inspections/international product-items EXPORT_Excel
const exportInternationalProductsExcel = async (id: number) => {
  const response = await axios({
    url: `/inspections/international/${id}/product-items?export_excel=true`,
    method: "get",
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${Date.now()}.xlsx`);
  document.body.appendChild(link);
  link.click();

  return response;
};
//inspections/international product-items EXPORT_PDF
const exportInternationalProductsPDF = async (id: number) => {
  const response = await axios({
    url: `/inspections/international/${id}/product-items?export_pdf=true`,
    method: "get",
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${Date.now()}.pdf`);
  document.body.appendChild(link);
  link.click();

  return response;
};
//inspections/international selected-product-items
const getInternationalInspectionSelectedProductItems = async (data: any) => {
  const response = await axios.get(
    `/inspections/international/${data.id}/selected-product-items?page=${data.page}`
  );
  return response;
};
//inspections/international selected-product-items EXPORT_Excel
const exportInternationalSelectedProductsExcel = async (id: number) => {
  const response = await axios({
    url: `/inspections/international/${id}/selected-product-items?export_excel=true`,
    method: "get",
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${Date.now()}.xlsx`);
  document.body.appendChild(link);
  link.click();

  return response;
};
//inspections/international selected-product-items EXPORT_PDF
const exportInternationalSelectedProductsPDF = async (id: number) => {
  const response = await axios({
    url: `/inspections/international/${id}/selected-product-items?export_pdf=true`,
    method: "get",
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${Date.now()}.pdf`);
  document.body.appendChild(link);
  link.click();

  return response;
};

const InternationalInspectionservices = {
  getInternationalInspections,
  exportInternationalInspections,
  getInternationalInspectionId,
  addInternationalInspection,
  patchInternationalInspection,
  deleteInternationalInspection,
  setInternationalInspectionResult,
  getInternationalInspectionProductItems,
  exportInternationalProductsExcel,
  exportInternationalProductsPDF,
  getInternationalInspectionSelectedProductItems,
  exportInternationalSelectedProductsExcel,
  exportInternationalSelectedProductsPDF,
};
export default InternationalInspectionservices;
