import { FC, useEffect, useState } from "react";
import { Box, styled, Typography } from "@mui/material";
import colors from "src/theme/colors";
import { Alert } from "src/components/Alert/Alert";
import { LinearFileUploader } from "src/components/FileUploader/LinearFileUploader/LinearFileUploader";
import { UploadedFileType } from "src/components/FileUploader/LinearFileUploader/LinearUploadZone";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  addSaleOrderDocuments,
  changeSaleOrderDocumentDownloadble,
  getSaleOrderDocuments,
  getSaleOrderDocumentsByTag,
  reset as resetDocuments,
} from "../redux/SaleOrdersSlice";
import Loading from "src/components/Loading";
import { PaperBox } from "src/components/Paper/Paper";

export enum SaleOrderDocumentsList {
  Truck1 = "truck_1",
  Truck2 = "truck_2",
  Truck3 = "truck_3",
  Truck4 = "truck_4",
  Consignment1 = "consignment_1",
  Consignment2 = "consignment_2",
  Consignment3 = "consignment_3",
  Consignment4 = "consignment_4",
  Consignment5 = "consignment_5",
  Consignment6 = "consignment_6",
}

interface SaleOrderDocumentsProps {
  data: any;
}

export const SaleOrderDocuments: FC<SaleOrderDocumentsProps> = (props) => {
  const { data } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [files, setFiles] = useState<UploadedFileType[]>([]);
  const [documentsByTagDetails, setDocumentsByTagDetails] = useState<any>([]);
  const [activeChangeTag, setActiveChangeTag] = useState<any>(null);

  const {
    documents,
    addDocuments,
    documentsByTag,
    isGetDocumentsLoading,
    isGetDocumentsByTagLoading,
    isAddDocumentsLoading,
    isChangeDocumentDownloadbleLoading,
    isGetDocumentsError,
    isGetDocumentsByTagError,
    isAddDocumentsError,
    isChangeDocumentDownloadbleError,
    isGetDocumentsSuccess,
    isGetDocumentsByTagSuccess,
    isAddDocumentsSuccess,
    isChangeDocumentDownloadbleSuccess,
    getDocumentsMessage,
    getDocumentsByTagMessage,
    addDocumentsMessage,
    changeDocumentDownloadbleMessage,
  } = useSelector((state: RootState) => state.saleOrders);

  useEffect(() => {
    if (isGetDocumentsError) {
      enqueueSnackbar(getDocumentsMessage, { variant: "error" });
    }
    if (isGetDocumentsSuccess) {
      if (documents && documents.length > 0) {
        setFiles(documents);
      } else {
        setFiles([]);
      }
    }
    dispatch(resetDocuments());
  }, [
    isGetDocumentsError,
    isGetDocumentsSuccess,
    getDocumentsMessage,
    dispatch,
  ]);

  useEffect(() => {
    if (isGetDocumentsByTagError) {
      enqueueSnackbar(getDocumentsByTagMessage, { variant: "error" });
    }
    if (isGetDocumentsByTagSuccess) {
      setDocumentsByTagDetails(documentsByTag);
    }
    dispatch(resetDocuments());
  }, [
    isGetDocumentsByTagError,
    isGetDocumentsByTagSuccess,
    getDocumentsByTagMessage,
    dispatch,
  ]);

  useEffect(() => {
    if (isAddDocumentsError) {
      enqueueSnackbar(addDocumentsMessage, { variant: "error" });
    }
    if (isAddDocumentsSuccess) {
      if (addDocuments && addDocuments.length > 0) {
        setFiles((prev) => [...prev, ...addDocuments]);
      }
    }
    dispatch(resetDocuments());
  }, [
    isAddDocumentsError,
    isAddDocumentsSuccess,
    addDocumentsMessage,
    dispatch,
  ]);

  useEffect(() => {
    if (isChangeDocumentDownloadbleError) {
      enqueueSnackbar(changeDocumentDownloadbleMessage, { variant: "error" });
    }
    if (isChangeDocumentDownloadbleSuccess) {
      dispatch(getSaleOrderDocumentsByTag(Number(data?.id)));
    }
    dispatch(resetDocuments());
  }, [
    isChangeDocumentDownloadbleError,
    isChangeDocumentDownloadbleSuccess,
    changeDocumentDownloadbleMessage,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(getSaleOrderDocuments(Number(data?.id)));
    dispatch(getSaleOrderDocumentsByTag(Number(data?.id)));
  }, []);

  const getSpecFils = (tag: string) => {
    if (documents && documents.length > 0) {
      const docsCopy = [...files];
      const filteredFiles = docsCopy.filter(
        (doc: UploadedFileType) => doc.tag === tag
      );
      return filteredFiles;
    } else {
      return [];
    }
  };

  const onChangeDownloadble = (check: boolean, tag: string) => {
    setActiveChangeTag(tag);
    const infos = {
      id: Number(data?.id),
      document: tag,
      downloadable: check,
    };
    dispatch(changeSaleOrderDocumentDownloadble(infos));
  };

  if (isGetDocumentsLoading) {
    return <Loading />;
  } else {
    return (
      <Container>
        <PaperBox>
          <UploadersContainer>
            <Alert
              title="You can add up to 10 Files for each document, each not exceeding 15MB."
              isHidden={
                localStorage.getItem("saleorders_hide=file-upload-limit") ===
                "true"
              }
              onHide={() =>
                localStorage.setItem(
                  "saleorders_hide=file-upload-limit",
                  "true"
                )
              }
            />
            <Title>Truck Documents</Title>
            <LinearFileUploader
              title="Export Premit"
              rootID={Number(data?.id)}
              index={1}
              maxFiles={10}
              tag={SaleOrderDocumentsList.Truck1}
              allFiles={files}
              isAddLoading={isAddDocumentsLoading}
              files={getSpecFils(SaleOrderDocumentsList.Truck1)}
              deleteEndPoint={`sale-orders/${data?.id}`}
              setFiles={setFiles}
              onAddNewFiles={(addData) => {
                dispatch(
                  addSaleOrderDocuments({
                    id: Number(data?.id),
                    data: addData,
                  })
                );
              }}
            />
            <Divider />
            <LinearFileUploader
              title="Minerals Trading Permit"
              rootID={Number(data?.id)}
              index={2}
              maxFiles={10}
              tag={SaleOrderDocumentsList.Truck2}
              allFiles={files}
              isAddLoading={isAddDocumentsLoading}
              files={getSpecFils(SaleOrderDocumentsList.Truck2)}
              deleteEndPoint={`sale-orders/${data?.id}`}
              setFiles={setFiles}
              onAddNewFiles={(addData) => {
                dispatch(
                  addSaleOrderDocuments({
                    id: Number(data?.id),
                    data: addData,
                  })
                );
              }}
            />
            <Divider />
            <LinearFileUploader
              title="Tax Clearance Certificate"
              rootID={Number(data?.id)}
              index={3}
              maxFiles={10}
              tag={SaleOrderDocumentsList.Truck3}
              allFiles={files}
              isAddLoading={isAddDocumentsLoading}
              files={getSpecFils(SaleOrderDocumentsList.Truck3)}
              deleteEndPoint={`sale-orders/${data?.id}`}
              setFiles={setFiles}
              onAddNewFiles={(addData) => {
                dispatch(
                  addSaleOrderDocuments({
                    id: Number(data?.id),
                    data: addData,
                  })
                );
              }}
            />
            <Divider />
            <LinearFileUploader
              title="Mineral Analysis Certificate"
              rootID={Number(data?.id)}
              index={4}
              maxFiles={10}
              tag={SaleOrderDocumentsList.Truck4}
              allFiles={files}
              isAddLoading={isAddDocumentsLoading}
              files={getSpecFils(SaleOrderDocumentsList.Truck4)}
              deleteEndPoint={`sale-orders/${data?.id}`}
              setFiles={setFiles}
              onAddNewFiles={(addData) => {
                dispatch(
                  addSaleOrderDocuments({
                    id: Number(data?.id),
                    data: addData,
                  })
                );
              }}
            />
          </UploadersContainer>
        </PaperBox>

        <PaperBox>
          <UploadersContainer>
            <Title>Consignment Documents</Title>
            <StyledAlert
              withArrow
              title="You can enable or disable the permission of the customer to download these documents."
              isHidden={
                localStorage.getItem(
                  "saleorders_hide=file-upload-dosnload-permission"
                ) === "true"
              }
              onHide={() =>
                localStorage.setItem(
                  "saleorders_hide=file-upload-dosnload-permission",
                  "true"
                )
              }
            />
            <LinearFileUploader
              title="Original \ Forwarder Bill of lading - Warehouse Received"
              rootID={Number(data?.id)}
              index={1}
              maxFiles={10}
              tag={SaleOrderDocumentsList.Consignment1}
              allFiles={files}
              isAddLoading={isAddDocumentsLoading}
              hasChecker
              isChecked={
                documentsByTagDetails.find(
                  (item: any) =>
                    item.tag === SaleOrderDocumentsList.Consignment1
                )
                  ? documentsByTagDetails.find(
                      (item: any) =>
                        item.tag === SaleOrderDocumentsList.Consignment1
                    ).downloadable
                  : false
              }
              isCheckLoading={
                isChangeDocumentDownloadbleLoading &&
                activeChangeTag === SaleOrderDocumentsList.Consignment1
              }
              onCheckChange={(check) =>
                onChangeDownloadble(check, SaleOrderDocumentsList.Consignment1)
              }
              files={getSpecFils(SaleOrderDocumentsList.Consignment1)}
              deleteEndPoint={`sale-orders/${data?.id}`}
              setFiles={setFiles}
              onAddNewFiles={(addData) => {
                dispatch(
                  addSaleOrderDocuments({
                    id: Number(data?.id),
                    data: addData,
                  })
                );
              }}
            />
            <Divider />
            <LinearFileUploader
              title="Commercial Invoice"
              rootID={Number(data?.id)}
              index={2}
              maxFiles={10}
              tag={SaleOrderDocumentsList.Consignment2}
              allFiles={files}
              isAddLoading={isAddDocumentsLoading}
              hasChecker
              isChecked={
                documentsByTagDetails.find(
                  (item: any) =>
                    item.tag === SaleOrderDocumentsList.Consignment2
                )
                  ? documentsByTagDetails.find(
                      (item: any) =>
                        item.tag === SaleOrderDocumentsList.Consignment2
                    ).downloadable
                  : false
              }
              isCheckLoading={
                isChangeDocumentDownloadbleLoading &&
                activeChangeTag === SaleOrderDocumentsList.Consignment2
              }
              onCheckChange={(check) =>
                onChangeDownloadble(check, SaleOrderDocumentsList.Consignment2)
              }
              files={getSpecFils(SaleOrderDocumentsList.Consignment2)}
              deleteEndPoint={`sale-orders/${data?.id}`}
              setFiles={setFiles}
              onAddNewFiles={(addData) => {
                dispatch(
                  addSaleOrderDocuments({
                    id: Number(data?.id),
                    data: addData,
                  })
                );
              }}
            />
            <Divider />
            <LinearFileUploader
              title="Packing List"
              rootID={Number(data?.id)}
              index={3}
              maxFiles={10}
              tag={SaleOrderDocumentsList.Consignment3}
              allFiles={files}
              isAddLoading={isAddDocumentsLoading}
              hasChecker
              isChecked={
                documentsByTagDetails.find(
                  (item: any) =>
                    item.tag === SaleOrderDocumentsList.Consignment3
                )
                  ? documentsByTagDetails.find(
                      (item: any) =>
                        item.tag === SaleOrderDocumentsList.Consignment3
                    ).downloadable
                  : false
              }
              isCheckLoading={
                isChangeDocumentDownloadbleLoading &&
                activeChangeTag === SaleOrderDocumentsList.Consignment3
              }
              onCheckChange={(check) =>
                onChangeDownloadble(check, SaleOrderDocumentsList.Consignment3)
              }
              files={getSpecFils(SaleOrderDocumentsList.Consignment3)}
              deleteEndPoint={`sale-orders/${data?.id}`}
              setFiles={setFiles}
              onAddNewFiles={(addData) => {
                dispatch(
                  addSaleOrderDocuments({
                    id: Number(data?.id),
                    data: addData,
                  })
                );
              }}
            />
            <Divider />
            <LinearFileUploader
              title="Certificate of Origin"
              rootID={Number(data?.id)}
              index={4}
              maxFiles={10}
              tag={SaleOrderDocumentsList.Consignment4}
              allFiles={files}
              isAddLoading={isAddDocumentsLoading}
              hasChecker
              isChecked={
                documentsByTagDetails.find(
                  (item: any) =>
                    item.tag === SaleOrderDocumentsList.Consignment4
                )
                  ? documentsByTagDetails.find(
                      (item: any) =>
                        item.tag === SaleOrderDocumentsList.Consignment4
                    ).downloadable
                  : false
              }
              isCheckLoading={
                isChangeDocumentDownloadbleLoading &&
                activeChangeTag === SaleOrderDocumentsList.Consignment4
              }
              onCheckChange={(check) =>
                onChangeDownloadble(check, SaleOrderDocumentsList.Consignment4)
              }
              files={getSpecFils(SaleOrderDocumentsList.Consignment4)}
              deleteEndPoint={`sale-orders/${data?.id}`}
              setFiles={setFiles}
              onAddNewFiles={(addData) => {
                dispatch(
                  addSaleOrderDocuments({
                    id: Number(data?.id),
                    data: addData,
                  })
                );
              }}
            />
            <Divider />
            <LinearFileUploader
              title="Certificate of Insurance"
              rootID={Number(data?.id)}
              index={5}
              maxFiles={10}
              tag={SaleOrderDocumentsList.Consignment5}
              allFiles={files}
              isAddLoading={isAddDocumentsLoading}
              hasChecker
              isChecked={
                documentsByTagDetails.find(
                  (item: any) =>
                    item.tag === SaleOrderDocumentsList.Consignment5
                )
                  ? documentsByTagDetails.find(
                      (item: any) =>
                        item.tag === SaleOrderDocumentsList.Consignment5
                    ).downloadable
                  : false
              }
              isCheckLoading={
                isChangeDocumentDownloadbleLoading &&
                activeChangeTag === SaleOrderDocumentsList.Consignment5
              }
              onCheckChange={(check) =>
                onChangeDownloadble(check, SaleOrderDocumentsList.Consignment5)
              }
              files={getSpecFils(SaleOrderDocumentsList.Consignment5)}
              deleteEndPoint={`sale-orders/${data?.id}`}
              setFiles={setFiles}
              onAddNewFiles={(addData) => {
                dispatch(
                  addSaleOrderDocuments({
                    id: Number(data?.id),
                    data: addData,
                  })
                );
              }}
            />
            <Divider />
            <LinearFileUploader
              title="Inspection Certificate"
              rootID={Number(data?.id)}
              index={6}
              maxFiles={10}
              tag={SaleOrderDocumentsList.Consignment6}
              allFiles={files}
              isAddLoading={isAddDocumentsLoading}
              hasChecker
              isChecked={
                documentsByTagDetails.find(
                  (item: any) =>
                    item.tag === SaleOrderDocumentsList.Consignment6
                )
                  ? documentsByTagDetails.find(
                      (item: any) =>
                        item.tag === SaleOrderDocumentsList.Consignment6
                    ).downloadable
                  : false
              }
              isCheckLoading={
                isChangeDocumentDownloadbleLoading &&
                activeChangeTag === SaleOrderDocumentsList.Consignment6
              }
              onCheckChange={(check) =>
                onChangeDownloadble(check, SaleOrderDocumentsList.Consignment6)
              }
              files={getSpecFils(SaleOrderDocumentsList.Consignment6)}
              deleteEndPoint={`sale-orders/${data?.id}`}
              setFiles={setFiles}
              onAddNewFiles={(addData) => {
                dispatch(
                  addSaleOrderDocuments({
                    id: Number(data?.id),
                    data: addData,
                  })
                );
              }}
            />
          </UploadersContainer>
        </PaperBox>
      </Container>
    );
  }
};

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 16px;
`;

const UploadersContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 782px;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

const Divider = styled("div")`
  width: 100%;
  height: 1px;
  background-color: ${colors.gray[100]};
  margin: 12px 0;
`;

const Title = styled(Typography)({
  color: colors.gray[850],
  margin: "16px 0",
  fontSize: "24px",
  lineHeight: "32px",
  fontWeight: 500,
});
