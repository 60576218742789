import { Box, styled, Typography } from "@mui/material";
import type { FC } from "react";
import colors from "src/theme/colors";

interface SentReportItemsCard {
  totalValue: number;
  subTitle?: string;
  label: string;
  iconName: string;
}

export const SentReportItemsCard: FC<SentReportItemsCard> = (props) => {
  const { totalValue, label, subTitle, iconName } = props;

  return (
    <Container>
      <IconBox>
        <img
          src={
            process.env.PUBLIC_URL +
            `/assets/icons/saleOrderShipment/${iconName}.svg`
          }
          alt="Icon"
        />
      </IconBox>
      <Count>
        {subTitle ? (
          <Row>
            <Value>{totalValue ?? 0}</Value>
            <Prefix>{subTitle}</Prefix>
          </Row>
        ) : (
          <Value>{totalValue ?? 0}</Value>
        )}
        <Label>{label}</Label>
      </Count>
    </Container>
  );
};

const Container = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 22px;
  border: 1px solid;
  border-color: ${colors.midnight[50]};
  border-radius: 5px;
  @media screen and (max-width: 760px) {
    padding: 18px 12px;
  }
`;

const IconBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: "38px",
});

const Count = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "10px",
});

const Row = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-end",
  gap: "4px",
});

const Label = styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "32px",
  color: colors.midnight[500],
});

const Value = styled(Typography)({
  fontSize: "40px",
  fontWeight: 700,
  lineHeight: "52px",
  color: colors.midnight[850],
});

const Prefix = styled(Typography)({
  fontSize: "15px",
  fontWeight: 500,
  lineHeight: "30px",
  color: colors.primary[800],
});
