import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import LogsServices from "./LogsServices";

const initialState = {
  isError: false,
  isLogsExportError: false,
  isSuccess: false,
  isLogsExportSuccess: false,
  isLoading: true,
  isLogsExportLoading: false,
  message: "",
  logsExportMessage: "",
  logs: [],
  logsMeta: [],
};

export const getLogs = createAsyncThunk("logs", async (data: any, thunkAPI) => {
  try {
    return await LogsServices.getLogs(data);
  } catch (error: any) {
    const message =
      error?.response?.status === 500
        ? "Internal Server Error"
        : error?.response.data?.results?.errors[0]?.message ||
          error?.response.data?.meta?.errors[0]?.message ||
          error?.response.data.message ||
          error?.message;
    return thunkAPI.rejectWithValue(message);
  }
});
export const exportLogs = createAsyncThunk(
  "logs/export",
  async (data: any, thunkAPI) => {
    try {
      return await LogsServices.exportLogs(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isLogsExportLoading = false;
      state.isSuccess = false;
      state.isLogsExportSuccess = false;
      state.isError = false;
      state.isLogsExportError = false;
      state.message = "";
      state.logsExportMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLogs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLogs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.logs = action.payload.data.results;
        state.logsMeta = action.payload.data.meta;
      })
      .addCase(getLogs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(exportLogs.pending, (state) => {
        state.isLogsExportLoading = true;
      })
      .addCase(exportLogs.fulfilled, (state, action) => {
        state.isLogsExportLoading = false;
        state.isLogsExportSuccess = true;
        state.logsExportMessage = `${action.payload.data.message}`;
      })
      .addCase(exportLogs.rejected, (state, action) => {
        state.isLogsExportLoading = false;
        state.isLogsExportError = true;
        state.isLogsExportSuccess = false;
        state.logsExportMessage = `${action.payload}`;
      });
  },
});

export const { reset } = logsSlice.actions;
export default logsSlice.reducer;
