import { Box, Grid, styled, Typography } from "@mui/material";
import FormHeading from "src/components/FormHeading";
import FormSubHeading from "src/components/FormSubHeading";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { deleteRoles, getRolesId, reset } from "../redux/roleSlice";
import Loading from "src/components/Loading";
import TableChip from "src/components/TableChip";
import colors from "src/theme/colors";
import { StyledButton } from "src/components/Button";
import InfoItems from "src/components/InfoItems";
import { PageTemplate } from "src/layout/components/PageTemplate";
import ConfirmModal from "src/components/Modal";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { PaperBox } from "src/components/Paper/Paper";

interface CustomizedState {
  id: string;
}

const RoleInfo = () => {
  const location = useLocation();
  const state = location.state as CustomizedState;

  const navigate = useNavigate();
  const { id } = state;

  const [roleData, setRoleData] = useState([] as any);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const {
    singleRole,
    isSingleLoading,
    isDeleteLoading,
    isSingleError,
    isDeleteError,
    isSingleSuccess,
    isDeleteSuccess,
    singleMessage,
    deleteMessage,
  } = useSelector((state: RootState) => state.role);
  const dispatch = useDispatch<AppDispatch>();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSingleError) {
      enqueueSnackbar(singleMessage, { variant: "error" });
    }
    if (isSingleSuccess) {
      setRoleData(singleRole);
    }
    return () => {
      dispatch(reset());
    };
  }, [isSingleError, isSingleSuccess, singleMessage, dispatch]);

  useEffect(() => {
    if (isDeleteError) {
      enqueueSnackbar(deleteMessage, { variant: "error" });
    }
    if (isDeleteSuccess) {
      enqueueSnackbar(deleteMessage, { variant: "success" });
      navigate("/roles/roles-list");
    }
    return () => {
      dispatch(reset());
    };
  }, [isDeleteError, isDeleteSuccess, deleteMessage, dispatch]);

  useEffect(() => {
    const infoValue = {
      id: Number(id),
    };
    dispatch(getRolesId(infoValue));
  }, []);

  const moveToEdit = () => {
    const ids = roleData.permissions?.map((item: any, index: any) => {
      return item.values?.map((innerItem: any) => {
        return innerItem.id;
      });
    });

    navigate("/roles-management/role-edit", {
      state: {
        id: id,
        permissions: ids.flat(1),
        title: roleData.title,
        forwardedFromInfo: true,
      },
    });
  };
  console.log(roleData);
  const onDelete = () => {
    const deleteValue = {
      id: Number(id),
    };
    dispatch(deleteRoles(deleteValue)).then(() => setIsDeleteModalOpen(false));
  };

  return (
    <PageTemplate
      isTransparent
      crumbs={[
        {
          label: "Role Management",
          href: "/roles/roles-list",
          depth: -1,
        },
        {
          label: "Role information",
        },
      ]}
    >
      {isSingleLoading ? (
        <Loading />
      ) : (
        <Container>
          <PaperBox>
            <Box mb={12}>
              <FormHeading>Role Information</FormHeading>
            </Box>
            <Grid
              container
              rowSpacing={12}
              columnSpacing={{ xs: 0, md: 16 }}
              xs={12}
              md={10}
            >
              <Grid item xs={12} md={6}>
                <Box width="100%">
                  <InfoItems
                    title="Role name"
                    subTilte={roleData?.title ?? "Not Found!"}
                  />
                </Box>
              </Grid>
            </Grid>
          </PaperBox>

          <PaperBox>
            <Box>
              <FormHeading>Role Information</FormHeading>
            </Box>
            {roleData.permissions?.map((item: any, index: any) => {
              return (
                <Box pt={16} key={index}>
                  <FormSubHeading>{item.key} :</FormSubHeading>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={2}
                    pt={8}
                    flexWrap="wrap"
                  >
                    {item?.values?.map((innerItem: any) => {
                      return (
                        <Box key={innerItem.id} padding={2}>
                          <TableChip>{innerItem?.description}</TableChip>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </PaperBox>

          <Actions width="100%">
            {/* <Box>
              <StyledLoadingButton
                variant="outlined"
                color="error"
                fullWidth
                loading={isDeleteLoading}
                onClick={() => setIsDeleteModalOpen(true)}
              >
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color={colors.error[500]}
                >
                  Remove Role
                </Typography>
              </StyledLoadingButton>
            </Box> */}
            <Box marginLeft={12}>
              <StyledButton
                variant="contained"
                color="secondary"
                fullWidth
                disabled={isDeleteLoading}
                onClick={moveToEdit}
              >
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color={colors.base.white}
                >
                  Edit Role
                </Typography>
              </StyledButton>
            </Box>
          </Actions>
        </Container>
      )}

      <ConfirmModal
        title="Remove orders"
        iconName="ModalRemove"
        open={isDeleteModalOpen}
        isLoading={isDeleteLoading}
        onDismiss={() => setIsDeleteModalOpen(false)}
        onConfirm={onDelete}
        confrimText="Remove"
        cancelText="Cancel"
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to remove this order?
        </Typography>
      </ConfirmModal>
    </PageTemplate>
  );
};

export default RoleInfo;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  & > div {
    width: 184px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    & > div {
      width: 142px;
    }
  }
`;
