/* eslint-disable react/no-unescaped-entities */
import { FC } from "react";
import { Box, MenuItem, styled, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CanselButton } from "src/components/CanselButton";
import FormHeading from "src/components/FormHeading";
import FormInputLable from "src/components/FormInputLabel";
import { StyledTextField } from "src/components/Input";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import * as Yup from "yup";
import { patchStockTaking, reset } from "../redux/StockTakingSlice";
import { StyledDatePicker } from "src/components/DatePicker/DatePicker";
import { GrayButton } from "src/components/GrayButton";

interface Props {
  warehouses: any;
  employes: any;
  products: any;
  defaultValues: any;
  onSuccess: () => void;
  onClose: () => void;
}

export const StockTakingEditForm: FC<Props> = (props) => {
  const { onSuccess, onClose } = props;

  //formik---------------------------------
  const ItemSchema = Yup.object().shape({
    title: Yup.string(),
    warehouse: Yup.number().required(),
    employee: Yup.array().min(1),
    startDate: Yup.date().required("Start Date is Required").nullable(),
    products: Yup.array().required(),
  });

  const formik = useFormik({
    initialValues: {
      title: props?.defaultValues?.title ?? "",
      warehouse: props?.defaultValues?.warehouse?.id,
      employee: props?.defaultValues?.employee
        ? props?.defaultValues?.employee.map((item: any) => {
            return item.id;
          })
        : [],
      startDate: props?.defaultValues?.start_date,
      products: props?.defaultValues?.products
        ? props?.defaultValues?.products.map((item: any) => {
            return item.id;
          })
        : [],
    },
    validationSchema: ItemSchema,

    onSubmit: async (values: any) => {
      const postData = {
        id: props?.defaultValues?.id,
        title: values.title,
        warehouse: values.warehouse,
        employee: values.employee,
        start_date: values.startDate?.$d
          ? values.startDate?.$d.toISOString()
          : values.startDate,
        products:
          values.products && values.products.length > 0
            ? values.products
            : null,
      };

      dispatch(patchStockTaking(postData)).then(() => onSuccess());
    },
  });

  const {
    errors,
    touched,
    values,
    setFieldValue,
    handleSubmit,
    getFieldProps,
  } = formik;

  //redux ---------------------------------
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const { isCreateLoading, isError, isSuccess, message } = useSelector(
    (state: RootState) => state.stockTaking
  );
  //redux ---------------------------------

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      // enqueueSnackbar(message, { variant: "success" });
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, isSuccess, message, dispatch]);

  const [employes, setEmployes] = useState([] as any);

  useEffect(() => {
    const selectedWarehouse = props.warehouses.filter(
      (warehouse: any) => warehouse.id === values.warehouse
    );
    setEmployes(
      selectedWarehouse && selectedWarehouse[0]
        ? selectedWarehouse[0].users
        : []
    );
  }, [props, values.warehouse, touched.warehouse]);

  return (
    <FormikProvider value={formik}>
      <FormContainer>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box
            sx={{
              marginBottom: "28px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + `/assets/icons/newCrudBlue.svg`}
            />
            <Title>Edit Stocktaking</Title>
          </Box>

          <FieldsContainer>
            <Box mb={12} width="100%">
              <FormInputLable noPrefix>Title</FormInputLable>
              <StyledTextField
                placeholder="Enter Stocktaking title"
                fullWidth
                disabled
                {...getFieldProps("title")}
                error={Boolean(touched.title && errors.title)}
                helperText={(touched.title as any) && (errors.title as any)}
              />
            </Box>
            <Box mb={12} width="100%">
              <FormInputLable requried>Warehouse</FormInputLable>
              <StyledTextField
                select
                fullWidth
                disabled
                placeholder="Select a warehouse"
                error={Boolean(touched.warehouse && errors.warehouse)}
                helperText={
                  (touched.warehouse as any) && (errors.warehouse as any)
                }
                {...getFieldProps("warehouse")}
                onChange={formik.handleChange("warehouse")}
                defaultValue={[]}
                SelectProps={{
                  multiple: false,
                }}
              >
                {props?.warehouses?.map((item: any) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </StyledTextField>
            </Box>
            <Box mb={12} width="100%">
              <FormInputLable requried>Employee</FormInputLable>
              <StyledTextField
                select
                fullWidth
                placeholder="Select a employee"
                error={Boolean(touched.employee && errors.employee)}
                helperText={
                  (touched.employee as any) && (errors.employee as any)
                }
                {...getFieldProps("employee")}
                onChange={formik.handleChange("employee")}
                defaultValue={[]}
                SelectProps={{
                  multiple: true,
                }}
              >
                {employes?.map((item: any) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </StyledTextField>
            </Box>
            <Box mb={12} width="100%">
              <FormInputLable requried>Start Date</FormInputLable>
              <StyledDatePicker
                disabled
                placeholder="Enter or select date"
                {...getFieldProps("startDate")}
                value={formik.values.startDate}
                error={Boolean(touched.startDate && errors.startDate)}
                helperText={
                  (touched.startDate as any) && (errors.startDate as any)
                }
                onChange={(newValue: Date | null) =>
                  formik.setFieldValue("startDate", newValue)
                }
              />
            </Box>
            <Box mb={6} width="100%">
              <FormInputLable noPrefix>Product</FormInputLable>
              <StyledTextField
                select
                fullWidth
                disabled
                placeholder="Select a product"
                error={Boolean(touched.products && errors.products)}
                helperText={
                  (touched.products as any) && (errors.products as any)
                }
                {...getFieldProps("products")}
                onChange={formik.handleChange("products")}
                defaultValue={[]}
                SelectProps={{
                  multiple: true,
                }}
              >
                {props?.products?.map((item: any) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </StyledTextField>
            </Box>
            <ProductsAlertBox>
              <InfoOutlinedIcon />{" "}
              <InfoText>
                You can only select countable products, and if you don't select
                a product, all products will be selected by default!
              </InfoText>
            </ProductsAlertBox>
          </FieldsContainer>

          <Box
            display="flex"
            paddingTop={24}
            width="100%"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Box width="184px">
              <GrayButton
                variant="outlined"
                size="large"
                fullWidth
                disabled={isCreateLoading}
                onClick={onClose}
              >
                Cancel
              </GrayButton>
            </Box>
            <Box width="184px" marginLeft={12}>
              <StyledLoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={isCreateLoading}
                type="submit"
              >
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color={colors.gray[850]}
                >
                  Confirm
                </Typography>
              </StyledLoadingButton>
            </Box>
          </Box>
        </Form>
      </FormContainer>
    </FormikProvider>
  );
};

const Title = styled("h6")`
  margin-top: 14px;
  font-size: 18px;
  font-weight: 700;
  color: ${colors.gray[850]};
`;

const FormContainer = styled("div")`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }

  & > form {
    width: 100%;
  }
`;

const FieldsContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProductsAlertBox = styled("div")`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > svg {
    color: ${colors.secondary[700]};
  }
`;

const InfoText = styled("span")`
  width: calc(100% - 24px);
  margin-left: 8px;
`;
