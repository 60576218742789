import { Grid, Box, Typography, styled } from "@mui/material";
import { Form, FormikProvider, FormikValues, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FileUploader,
  FileUploaderType,
} from "src/components/FileUploader/FileUploader";
import { UploadedFileType } from "src/components/FileUploader/UploadZone/UploadZone";
import FormHeading from "src/components/FormHeading";
import FormInputLable from "src/components/FormInputLabel";
import { GrayButton } from "src/components/GrayButton";
import { StyledTextField } from "src/components/Input";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { StyledTextFieldMulti } from "src/components/MultilineInput";
import { PaperBox } from "src/components/Paper/Paper";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import * as Yup from "yup";
import { vendorValue } from "../redux/types";
import { postVendor, reset } from "../redux/VendorSlice";

const VendorNewForm = () => {
  const navigate = useNavigate();
  const [files, setFiles] = useState<UploadedFileType[]>([]);

  //formik---------------------------------
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const VendorSchema = Yup.object().shape({
    name: Yup.string(),
    code: Yup.string().required(),
    company_name: Yup.string(),
    mobile: Yup.string(),
    phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    email: Yup.string().email(),
    address: Yup.string(),
    zip_code: Yup.string(),
    notes: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      code: "",
      company_name: "",
      mobile: "",
      phone: "",
      email: "",
      address: "",
      zip_code: "",
      notes: "",
    },
    validationSchema: VendorSchema,

    onSubmit: async (values: vendorValue) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("code", values.code);
      formData.append("company_name", values.company_name);
      formData.append("mobile", values.mobile);
      formData.append("phone", values.phone);
      formData.append("email", values.email);
      formData.append("address", values.address);
      formData.append("zip_code", values.zip_code);
      formData.append("notes", values.notes);
      if (files.length > 0) {
        files.forEach((file, index) =>
          formData.append(`files[${index}]`, file as any, file.name)
        );
      }

      dispatch(postVendor(formData as any));
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  //redux ---------------------------------
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const { isCreateLoading, isError, isSuccess, message } = useSelector(
    (state: RootState) => state.vendor
  );

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      enqueueSnackbar(message, { variant: "success" });
      navigate("/vendors/vendors-list");
    }
    dispatch(reset());
  }, [isError, isSuccess, message, navigate, dispatch]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <PaperBox>
          <Box mb={12}>
            <FormHeading>Vendor Information</FormHeading>
          </Box>
          <Grid
            container
            rowSpacing={12}
            columnSpacing={{ sm: 0, md: 20 }}
            md={10}
            sm={12}
          >
            <Grid item md={6} sm={12} xs={12}>
              <FormInputLable>Full name</FormInputLable>
              <StyledTextField
                placeholder="Enter vendor full name"
                fullWidth
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <FormInputLable requried>Code</FormInputLable>
              <StyledTextField
                placeholder="Enter vendor Code"
                fullWidth
                {...getFieldProps("code")}
                error={Boolean(touched.code && errors.code)}
                helperText={touched.code && errors.code}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <FormInputLable>Company Name</FormInputLable>
              <StyledTextField
                placeholder="Enter vendor company name"
                fullWidth
                {...getFieldProps("company_name")}
                error={Boolean(touched.company_name && errors.company_name)}
                helperText={touched.company_name && errors.company_name}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <FormInputLable>Mobile Number</FormInputLable>
              <StyledTextField
                placeholder="Enter vendor mobile number"
                fullWidth
                {...getFieldProps("mobile")}
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <FormInputLable>Phone Number</FormInputLable>
              <StyledTextField
                placeholder="Enter vendor phone number"
                fullWidth
                {...getFieldProps("phone")}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <FormInputLable>Email Address</FormInputLable>
              <StyledTextField
                placeholder="Enter vendor email address"
                fullWidth
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <FormInputLable>Address</FormInputLable>
              <StyledTextFieldMulti
                placeholder="Enter vendor address"
                fullWidth
                multiline
                rows={10}
                {...getFieldProps("address")}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <FormInputLable>Postal Code</FormInputLable>
              <StyledTextField
                placeholder="Enter vendor postal code"
                fullWidth
                {...getFieldProps("zip_code")}
                error={Boolean(touched.zip_code && errors.zip_code)}
                helperText={touched.zip_code && errors.zip_code}
              />
            </Grid>
          </Grid>
        </PaperBox>

        <PaperBox>
          <Box mb={12}>
            <FormHeading>Notes & Files</FormHeading>
          </Box>
          <Grid
            container
            rowSpacing={12}
            columnSpacing={{ sm: 0, md: 20 }}
            md={10}
            sm={12}
          >
            <Grid item md={6} sm={12} xs={12}>
              <FormInputLable>Note</FormInputLable>
              <StyledTextFieldMulti
                placeholder="Enter vendor address"
                fullWidth
                multiline={true}
                {...getFieldProps("notes")}
                error={Boolean(touched.notes && errors.notes)}
                helperText={touched.notes && errors.notes}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <FormInputLable>Files</FormInputLable>
              <FileUploader
                type={FileUploaderType.Multiple}
                maxFiles={5}
                files={files}
                setFiles={setFiles}
              />
            </Grid>
          </Grid>
        </PaperBox>

        <Actions width="100%">
          <Box>
            <GrayButton
              variant="outlined"
              fullWidth
              disabled={isCreateLoading}
              onClick={() => navigate("/vendors/vendors-list")}
            >
              Cancel
            </GrayButton>
          </Box>
          <Box marginLeft={12}>
            <StyledLoadingButton
              loading={isCreateLoading}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.gray[850]}
              >
                Save Information
              </Typography>
            </StyledLoadingButton>
          </Box>
        </Actions>
      </Form>
    </FormikProvider>
  );
};

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  & > div {
    width: 184px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    & > div {
      width: 142px;
    }
  }
`;

export default VendorNewForm;
