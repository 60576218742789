import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import Loading from "../components/Loading";
import Crash from "../features/misc/routes/Crash";

const ErrorFallback = () => {
  return <Crash />;
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
      <React.Suspense fallback={<Loading />}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <HelmetProvider>
            <Router>{children}</Router>
          </HelmetProvider>
        </ErrorBoundary>
      </React.Suspense>
    </SnackbarProvider>
  );
};
