import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "src/components/Loading";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { AppDispatch, RootState } from "src/store";
import { LocalInspectionNewForm } from "../components/LocalInspectionNewForm";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import {
  getorders,
  reset as resetOrders,
} from "src/features/orders/redux/orderSlice";
import {
  getVendors,
  reset as resetVendors,
} from "src/features/vendors/redux/VendorSlice";
import {
  getWarehouses,
  reset as resetWarehouses,
} from "src/features/warehouses/redux/WarehouseSlice";
import { TrucksList } from "../components/TrucksList";
import { useLocation } from "react-router-dom";

interface LocationState {
  warehouseId?: string;
}

const LocalInspectionNew = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const state = location.state as LocationState;

  const [currentStep, setCurrentStep] = useState("form");

  const {
    vendors,
    isError: isVendorsError,
    isSuccess: isVendorsSuccess,
    message: VendorsMessage,
    isLoading: isVendorsLoading,
  } = useSelector((state: RootState) => state.vendor);
  const {
    Warehouses,
    isError: isWarehousesError,
    isSuccess: isWarehousesSuccess,
    message: WarehousesMessage,
    isLoading: isWarehousesLoading,
  } = useSelector((state: RootState) => state.warehouse);
  const {
    orders,
    isError: isOrdersError,
    isSuccess: isOrdersSuccess,
    message: OrdersMessage,
    isLoading: isOrdersLoading,
  } = useSelector((state: RootState) => state.orders);

  useEffect(() => {
    if (isVendorsError) {
      enqueueSnackbar(VendorsMessage, { variant: "error" });
    }
    return () => {
      dispatch(resetVendors());
    };
  }, [isVendorsError, isVendorsSuccess, VendorsMessage, isVendorsLoading]);

  useEffect(() => {
    if (isWarehousesError) {
      enqueueSnackbar(WarehousesMessage, { variant: "error" });
    }
    return () => {
      dispatch(resetWarehouses());
    };
  }, [
    isWarehousesError,
    isWarehousesSuccess,
    WarehousesMessage,
    isWarehousesLoading,
  ]);

  useEffect(() => {
    if (isOrdersError) {
      enqueueSnackbar(OrdersMessage, { variant: "error" });
    }
    return () => {
      dispatch(resetOrders());
    };
  }, [isOrdersError, isOrdersSuccess, OrdersMessage, isOrdersLoading]);

  useEffect(() => {
    dispatch(
      getVendors({
        page: 1,
        limit: 99,
      })
    );
    dispatch(
      getWarehouses({
        page: 1,
        limit: 99,
      })
    );
    // dispatch(getorders({ page: 1, limit: 99 }));
  }, []);

  //formik---------------------------------
  const ItemSchema = Yup.object().shape({
    title: Yup.string().required(),
    warehouse: Yup.number().required(),
    vendor: Yup.number(),
    order: Yup.number().required(),
    startDate: Yup.date().nullable(),
    endDate: Yup.date().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      warehouse: state && state?.warehouseId ? state?.warehouseId : "",
      vendor: "",
      order: "",
      startDate: null,
      endDate: null,
    },
    validationSchema: ItemSchema,

    onSubmit: async (values: any) => {
      setCurrentStep("list");
    },
  });

  const { values, setFieldValue } = formik;

  useEffect(() => {
    setFieldValue("order", "");
    if (values?.vendor) {
      dispatch(getorders({ vendor_id: values.vendor, page: 1, limit: 99 }));
    }
  }, [values?.vendor]);

  return (
    <PageTemplate
      isTransparent
      crumbs={[
        {
          label: "inspections",
          href: "/inspections/local-inspection-list",
          depth: -1,
        },
        {
          label: "new local inspection",
        },
      ]}
    >
      {currentStep === "form" ? (
        isVendorsLoading || isWarehousesLoading ? (
          <Loading />
        ) : (
          <LocalInspectionNewForm
            warehouses={Warehouses}
            vendors={vendors}
            orders={orders}
            formik={formik}
          />
        )
      ) : (
        <TrucksList
          values={{
            warehouse_id: Number(values.warehouse),
            order_id: Number(values.order),
            vendor_id: Number(values.vendor),
            from: values.startDate
              ? moment((values.startDate as any).$d).format("YYYY-MM-DD")
              : null,
            to: values.endDate
              ? moment((values.endDate as any).$d).format("YYYY-MM-DD")
              : null,
          }}
          title={values?.title}
          warehouse={
            values?.warehouse
              ? (
                  Warehouses.find(
                    (item: any) => item.id === values?.warehouse
                  ) as any
                )?.title ?? ""
              : ""
          }
          vendor={
            values?.vendor
              ? (vendors.find((item: any) => item.id === values?.vendor) as any)
                  ?.name ?? ""
              : ""
          }
          order={
            values?.order
              ? (orders.find((item: any) => item.id === values?.order) as any)
                  ?.code ?? ""
              : ""
          }
          dateRange={
            values.startDate || values.endDate
              ? `${
                  values.startDate
                    ? moment((values.startDate as any).$d).format("DD MMM")
                    : ""
                } - ${
                  values.endDate
                    ? moment((values.endDate as any).$d).format("DD MMM")
                    : ""
                }`
              : ""
          }
          onGoBack={() => setCurrentStep("form")}
        />
      )}
    </PageTemplate>
  );
};

export default LocalInspectionNew;
