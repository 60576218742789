import { Grid, Box, Typography, Switch, MenuItem, styled } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CanselButton } from "src/components/CanselButton";
import FormHeading from "src/components/FormHeading";
import FormInputLable from "src/components/FormInputLabel";
import { StyledTextField } from "src/components/Input";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { StyledTextFieldMulti } from "src/components/MultilineInput";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import * as Yup from "yup";
import { employesPatchValue } from "../redux/types";
import { patchEmployes, postEmployes, reset } from "../redux/EmployesSlice";
import StyledSwitch from "src/components/Switch";
import { generatePassword } from "src/utils/password";
import { useTranslation } from "react-i18next";
import {
  FileUploader,
  FileUploaderType,
} from "src/components/FileUploader/FileUploader";
import { UploadedFileType } from "src/components/FileUploader/UploadZone/UploadZone";
import {
  useUploadNewFile,
  UseUploadNewFilesType,
} from "src/components/FileUploader/useUploadNewFile";
import { PaperBox } from "src/components/Paper/Paper";

interface Props {
  roles: any;
  data: employesPatchValue;
}

const EmployesEditForm = (props: Props) => {
  const { t } = useTranslation();
  const { roles } = props;
  const navigate = useNavigate();
  const [uploadFilesLoading, uploadNewFiles] = useUploadNewFile(
    UseUploadNewFilesType.Multiple
  );
  const [files, setFiles] = useState<UploadedFileType[]>([]);

  //formik---------------------------------
  const VendorSchema = Yup.object().shape({
    name: Yup.string().required(),
    id_card_number: Yup.number().nullable(true),
    cellphone: Yup.number().nullable(true),
    phone: Yup.number().nullable(true),
    address: Yup.string().nullable(true),
    username: Yup.string().required().nullable(true),
    email: Yup.string().email().required(),
    password: Yup.string()
      .nullable(true)
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        t("validation.passwordValid")
      )
      .min(8, t("validation.passwordLenght")),
    notes: Yup.string().nullable(true),
    roles: Yup.array().nullable(true),
  });

  const rolesDefaults = props.data?.roles
    ? props.data?.roles.map((role: any) => role.id)
    : [];
  const formik = useFormik({
    initialValues: {
      id: props.data?.id,
      name: props.data?.name,
      id_card_number: props.data?.id_card_number ?? "",
      cellphone: props.data?.cellphone ?? "",
      phone: props.data?.phone ?? "",
      address: props.data?.address ?? "",
      username: props.data?.username,
      email: props.data?.email,
      password: props.data?.password ?? "",
      notes: props.data?.notes ?? "",
      roles: rolesDefaults,
    },
    validationSchema: VendorSchema,

    onSubmit: async (values: employesPatchValue) => {
      uploadNewFiles(
        () => dispatch(patchEmployes(values)),
        `users/${props?.data?.id}`,
        files
      );
    },
  });
  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  //redux ---------------------------------
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, isError, isPatchSuccess, message } = useSelector(
    (state: RootState) => state.employes
  );

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isPatchSuccess) {
      enqueueSnackbar(message, { variant: "success" });
      // navigate("/employees/employee-list");
    }
    dispatch(reset());
  }, [isError, isPatchSuccess, message, dispatch]);

  useEffect(() => {
    const documents = props?.data?.documents ?? [];
    setFiles(documents as any);
  }, [props]);

  //auto pass -----------------------------
  const [passChecked, setPassChecked] = useState(false);
  const handelPassChecked = () => {
    if (passChecked === true) {
      setPassChecked(false);
      setFieldValue("password", "");
    } else if (passChecked === false) {
      setPassChecked(true);
      const pass = generatePassword();
      setFieldValue("password", pass);
    }
  };

  //use email -----------------------------
  const [emailChecked, setEmailChecked] = useState(false);
  const handelEmailChecked = () => {
    if (emailChecked === true) {
      setEmailChecked(false);
      setFieldValue("username", "");
    } else if (emailChecked === false) {
      if (values.email != "") {
        setEmailChecked(true);
        setFieldValue("username", values.email);
      } else {
        setEmailChecked(false);
        enqueueSnackbar("email is not provided", { variant: "error" });
      }
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <PaperBox>
          <Box mb={12}>
            <FormHeading>Employee Information</FormHeading>
          </Box>
          <Grid
            container
            rowSpacing={12}
            columnSpacing={{ sm: 0, md: 20 }}
            md={10}
            sm={12}
          >
            <Grid item md={6} sm={12}>
              <FormInputLable requried>Full name</FormInputLable>
              <StyledTextField
                placeholder="Enter employee full name"
                fullWidth
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <FormInputLable>Id Card Number</FormInputLable>
              <StyledTextField
                placeholder="Enter employee ID card number"
                fullWidth
                {...getFieldProps("id_card_number")}
                error={Boolean(touched.id_card_number && errors.id_card_number)}
                helperText={touched.id_card_number && errors.id_card_number}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <FormInputLable>Mobile Number</FormInputLable>
              <StyledTextField
                placeholder="Enter employee mobile number"
                fullWidth
                {...getFieldProps("cellphone")}
                error={Boolean(touched.cellphone && errors.cellphone)}
                helperText={touched.cellphone && errors.cellphone}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <FormInputLable>Phone Number</FormInputLable>
              <StyledTextField
                placeholder="Enter employee phone number"
                fullWidth
                {...getFieldProps("phone")}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <FormInputLable>Address</FormInputLable>
              <StyledTextFieldMulti
                placeholder="Enter employee address"
                fullWidth
                multiline
                rows={10}
                {...getFieldProps("address")}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <FormInputLable requried>Email Address</FormInputLable>
              <StyledTextField
                placeholder="Enter vendor employee address"
                fullWidth
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid>
          </Grid>
        </PaperBox>

        <PaperBox>
          <Box mb={12}>
            <FormHeading>Organizational Information</FormHeading>
          </Box>
          <Grid
            container
            rowSpacing={12}
            columnSpacing={{ sm: 0, md: 20 }}
            md={10}
            sm={12}
          >
            <Grid item sm={12}>
              <Row>
                <RowItem>
                  <FormInputLable>Role</FormInputLable>
                  <StyledTextField
                    select
                    fullWidth
                    {...getFieldProps("roles")}
                    placeholder="Select employee job position"
                    error={Boolean(touched.roles && errors.roles)}
                    helperText={(touched.roles as any) && (errors.roles as any)}
                    onChange={(e) => setFieldValue("roles", [e.target.value])}
                  >
                    {roles?.map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.title}
                        </MenuItem>
                      );
                    })}
                  </StyledTextField>
                </RowItem>
                <RowItem>
                  <StyledSwitch
                    control={
                      <Switch
                        checked={passChecked}
                        color="secondary"
                        onChange={handelPassChecked}
                      />
                    }
                    label="Auto generate organizational password"
                    labelPlacement="end"
                  />
                  <FormInputLable requried>
                    Organizational Password
                  </FormInputLable>
                  <StyledTextField
                    placeholder="Auto generate organizational password"
                    fullWidth
                    {...getFieldProps("password")}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </RowItem>
              </Row>
            </Grid>
            <Grid item md={6} sm={12}>
              <StyledSwitch
                control={
                  <Switch
                    color="secondary"
                    checked={emailChecked}
                    onChange={handelEmailChecked}
                  />
                }
                label="Use email address as the organizational username"
                labelPlacement="end"
              />
              <FormInputLable requried>Organizational Username</FormInputLable>
              <StyledTextField
                placeholder="Enter employee organizational username"
                fullWidth
                {...getFieldProps("username")}
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
              />
            </Grid>
          </Grid>
        </PaperBox>

        <PaperBox>
          <Box mb={12}>
            <FormHeading>Notes & Files</FormHeading>
          </Box>
          <Grid
            container
            rowSpacing={12}
            columnSpacing={{ sm: 0, md: 20 }}
            md={10}
            sm={12}
          >
            <Grid item md={6} sm={12}>
              <FormInputLable>Notes</FormInputLable>
              <StyledTextFieldMulti
                placeholder="Write Something"
                fullWidth
                multiline={true}
                {...getFieldProps("notes")}
                error={Boolean(touched.notes && errors.notes)}
                helperText={touched.notes && errors.notes}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <FormInputLable>Files</FormInputLable>
              <FileUploader
                type={FileUploaderType.Multiple}
                maxFiles={5}
                files={files}
                deleteEndPoint={`users/${props.data?.id}`}
                setFiles={setFiles}
              />
            </Grid>
          </Grid>
        </PaperBox>

        <Box
          display="flex"
          paddingTop={20}
          flexDirection="row"
          alignItems="center"
        >
          <Box width="168">
            <CanselButton onClick={() => navigate("/employees/employee-list")}>
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.gray[850]}
              >
                Cancel
              </Typography>
            </CanselButton>
          </Box>
          <Box width="176px" marginLeft={12}>
            <StyledLoadingButton
              loading={isLoading || uploadFilesLoading}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.gray[850]}
              >
                Save Information
              </Typography>
            </StyledLoadingButton>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};

const Row = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  gap: "40px",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    gap: "24px",
  },
}));

const RowItem = styled(Box)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

export default EmployesEditForm;
