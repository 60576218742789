import { FC } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Modal,
  styled,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormInputLable from "src/components/FormInputLabel";
import { StyledTextField } from "src/components/Input";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import * as Yup from "yup";
import { GrayButton } from "src/components/GrayButton";

interface AddNewVariantModalProps {
  open: boolean;
  variants: any;
  onAddVariant: (data: any) => void;
  onDismiss: () => void;
}

export const AddNewVariantModal: FC<AddNewVariantModalProps> = (props) => {
  const { open, variants, onAddVariant, onDismiss } = props;

  //formik---------------------------------
  const ItemSchema = Yup.object().shape({
    id: Yup.number().required(),
    has_initial_value: Yup.boolean(),
    initial_value_required: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      has_initial_value: false,
      initial_value_required: false,
    },
    validationSchema: ItemSchema,

    onSubmit: async (values: any) => {
      const variantData = {
        id: values.id,
        name: variants
          ? variants.find((item: any) => item.id === values.id).title
          : "",
        initial_value: "",
        has_initial_value: values.has_initial_value,
        initial_value_required: values.initial_value_required,
      };

      onAddVariant(variantData);
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Wrapper>
        <FormikProvider value={formik}>
          <FormContainer>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Box
                sx={{
                  marginBottom: "28px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    `/assets/icons/ColorizedBlueAdd.svg`
                  }
                />
                <Title>Add New Variant</Title>
              </Box>

              <FieldsContainer>
                <Box mb={16} width="100%">
                  <FormInputLable noPrefix>Variant Title</FormInputLable>
                  <StyledTextField
                    select
                    fullWidth
                    placeholder="Select a variant"
                    error={Boolean(touched.id && errors.id)}
                    helperText={touched.id && errors.id}
                    {...getFieldProps("id")}
                    onChange={formik.handleChange("id")}
                    defaultValue={[]}
                    SelectProps={{
                      multiple: false,
                    }}
                  >
                    {variants?.map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.title}
                        </MenuItem>
                      );
                    })}
                  </StyledTextField>
                </Box>
                <ProductsChecksBox>
                  <CheckField>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          checked={values?.has_initial_value}
                        />
                      }
                      label="Has Initial Value"
                      name="has_initial_value"
                      onChange={formik.handleChange}
                    />
                  </CheckField>
                  <CheckField>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          checked={values?.initial_value_required}
                        />
                      }
                      label="Is Required"
                      name="initial_value_required"
                      onChange={formik.handleChange}
                    />
                  </CheckField>
                </ProductsChecksBox>
              </FieldsContainer>

              <Actions>
                <Box>
                  <GrayButton variant="outlined" fullWidth onClick={onDismiss}>
                    Cancel
                  </GrayButton>
                </Box>
                <Box>
                  <StyledLoadingButton
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                  >
                    <Typography
                      fontSize={16}
                      fontWeight={500}
                      color={colors.gray[850]}
                    >
                      Add Variant
                    </Typography>
                  </StyledLoadingButton>
                </Box>
              </Actions>
            </Form>
          </FormContainer>
        </FormikProvider>
        <CloseIcon onClick={onDismiss}>
          <img src={process.env.PUBLIC_URL + `/assets/icons/${"close"}.svg`} />
        </CloseIcon>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  minWidth: 460,
  maxWidth: 600,
  height: "fit-content",
  maxHeight: "95vh",
  backgroundColor: colors.base.white,
  border: `1px solid ${colors.gray[200]}`,
  borderRadius: "5px",
  padding: "42px 24px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  overflow: "auto",
  [theme.breakpoints.down("md")]: {
    width: "90%",
    minWidth: "auto",
  },
}));

const CloseIcon = styled(Box)`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 4;
  cursor: pointer;
`;

const Title = styled("h6")`
  margin-top: 14px;
  font-size: 18px;
  font-weight: 700;
  color: ${colors.gray[850]};
`;

const FormContainer = styled("div")`
  width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }

  & > form {
    width: 100%;
  }
`;

const FieldsContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Actions = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  gap: 24px;
  & > div {
    width: 184px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    gap: 14px;
    & > div {
      width: 100%;
    }
  }
`;

const ProductsChecksBox = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
`;

const CheckField = styled("div")`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
