import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CustomersServices from "./CustomersServices";

const initialState = {
  isError: false,
  isDeleteError: false,
  isExportError: false,
  isPatchError: false,
  isSuccess: false,
  isExportSuccess: false,
  isPatchSuccess: false,
  isDeleteSuccess: false,
  isLoading: true,
  isCreateLoading: false,
  isPatchLoading: false,
  isDeleteLoading: true,
  isExportLoading: false,
  message: "",
  exportMessage: "",
  createMessage: "",
  patchMessage: "",
  deleteMessage: "",
  customers: [],
  customersMeta: [],
  singleCustomer: [],
  contacts: [],
};

export const getCustomers = createAsyncThunk(
  "Customers",
  async (data: any, thunkAPI) => {
    try {
      return await CustomersServices.getCustomers(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const exportCustomers = createAsyncThunk(
  "Customers/export",
  async (data: any, thunkAPI) => {
    try {
      return await CustomersServices.exportCustomers();
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getCustomerId = createAsyncThunk(
  "Customers/id",
  async (data: any, thunkAPI) => {
    try {
      return CustomersServices.getCustomerId(data.id);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const addCustomer = createAsyncThunk(
  "Customers/post",
  async (data: any, thunkAPI) => {
    try {
      return await CustomersServices.addCustomer(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const patchCustomer = createAsyncThunk(
  "Customers/patch",
  async (data: any, thunkAPI) => {
    try {
      return await CustomersServices.patchCustomer(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteCustomer = createAsyncThunk(
  "Customers/delete",
  async (id: number, thunkAPI) => {
    try {
      return await CustomersServices.deleteCustomer(id);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getContactId = createAsyncThunk(
  "contact/id",
  async (data: any, thunkAPI) => {
    try {
      return CustomersServices.getContactId(data.id);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteContact = createAsyncThunk(
  "contact/delete",
  async (data: any, thunkAPI) => {
    try {
      return await CustomersServices.deleteContact(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const postContact = createAsyncThunk(
  "contact/post",
  async (data: any, thunkAPI) => {
    try {
      return await CustomersServices.postContact(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const patchContact = createAsyncThunk(
  "contact/patch",
  async (data: any, thunkAPI) => {
    try {
      return await CustomersServices.patchContact(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Customerslice = createSlice({
  name: "Customers",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isCreateLoading = false;
      state.isDeleteLoading = false;
      state.isPatchLoading = false;
      state.isDeleteLoading = false;
      state.isExportLoading = false;
      state.isSuccess = false;
      state.isExportSuccess = false;
      state.isPatchSuccess = false;
      state.isDeleteSuccess = false;
      state.isError = false;
      state.isExportError = false;
      state.isPatchError = false;
      state.isDeleteError = false;
      state.message = "";
      state.exportMessage = "";
      state.createMessage = "";
      state.patchMessage = "";
      state.deleteMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.customers = action.payload.data.results;
        state.customersMeta = action.payload.data.meta;
      })
      .addCase(getCustomers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(exportCustomers.pending, (state) => {
        state.isExportLoading = true;
      })
      .addCase(exportCustomers.fulfilled, (state, action) => {
        state.isExportLoading = false;
        state.isExportSuccess = true;
      })
      .addCase(exportCustomers.rejected, (state, action) => {
        state.isExportLoading = false;
        state.isExportError = true;
        state.exportMessage = `${action.payload}`;
      })

      .addCase(getCustomerId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomerId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.singleCustomer = action.payload.data.results;
      })
      .addCase(getCustomerId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(addCustomer.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(addCustomer.fulfilled, (state, action) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.createMessage = `${action.payload.data.message}`;
      })
      .addCase(addCustomer.rejected, (state, action) => {
        state.isCreateLoading = false;
        state.isError = true;
        state.createMessage = `${action.payload}`;
      })

      .addCase(patchCustomer.pending, (state) => {
        state.isPatchLoading = true;
      })
      .addCase(patchCustomer.fulfilled, (state, action) => {
        state.isPatchLoading = false;
        state.isSuccess = true;
        state.patchMessage = `${action.payload.data.message}`;
      })
      .addCase(patchCustomer.rejected, (state, action) => {
        state.isPatchLoading = false;
        state.isError = true;
        state.patchMessage = `${action.payload}`;
      })

      .addCase(deleteCustomer.pending, (state) => {
        state.isDeleteLoading = true;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteSuccess = true;
        state.deleteMessage = `${action.payload.data.message}`;
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.deleteMessage = `${action.payload}`;
      })

      .addCase(getContactId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })
      .addCase(getContactId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContactId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.contacts = action.payload.data.results;
      })

      .addCase(deleteContact.pending, (state) => {
        state.isLoading = true;
        state.isDeleteLoading = true;
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isDeleteLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(deleteContact.rejected, (state, action) => {
        state.isLoading = false;
        state.isDeleteLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(postContact.pending, (state) => {
        state.isLoading = true;
        state.isCreateLoading = true;
      })
      .addCase(postContact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(postContact.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreateLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(patchContact.pending, (state) => {
        state.isLoading = true;
        state.isPatchLoading = true;
      })
      .addCase(patchContact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isPatchLoading = false;
        state.isSuccess = true;
        state.isPatchSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(patchContact.rejected, (state, action) => {
        state.isLoading = false;
        state.isPatchLoading = false;
        state.isError = true;
        state.isPatchError = true;
        state.message = `${action.payload}`;
      });
  },
});

export const { reset } = Customerslice.actions;
export default Customerslice.reducer;
