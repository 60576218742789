import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import StyledTable from "src/components/Table";
import { GridColDef } from "@mui/x-data-grid";
import { AppDispatch, RootState } from "src/store";
import {
  deleteProduct,
  exportProducts,
  getProducts,
  reset,
} from "../redux/ProductsSlice";
import { Add, Eye } from "iconsax-react";
import {
  IconButton,
  Box,
  Typography,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import colors from "src/theme/colors";
import { styled } from "@mui/material/styles";
import { StyledButton } from "src/components/Button";
import ConfirmModal from "src/components/Modal";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";
import { StyledTextField } from "src/components/Input";
import { Search } from "@mui/icons-material";
import TableChip from "src/components/TableChip";
import moment from "moment";
import { GrayButton } from "src/components/GrayButton";

interface CustomizedRouteState {
  isRedirectedToCreate: boolean;
}

const ProductsTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const isBelowSmSize = useMediaQuery("(max-width:600px)");

  const [page, setPage] = useState(1);
  const [ProductsData, setProductsData] = useState([] as any);
  const [ProductsMetaData, setProductsMetaData] = useState([] as any);
  const [searchValue, setSearchValue] = useState("");
  const [selected, setSelected] = useState([] as any);
  const [selectedId, setSelectedId] = useState([0]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const {
    products,
    productsMeta,
    isDeleteLoading,
    isProductsExportLoading,
    isLoading,
    isError,
    isDeleteError,
    isProductsExportError,
    isSuccess,
    isDeleteSuccess,
    isProductsExportSuccess,
    productsExportMessage,
    message,
    deleteMessage,
  } = useSelector((state: RootState) => state.products);

  useEffect(() => {
    if (isProductsExportError) {
      enqueueSnackbar(productsExportMessage, { variant: "error" });
    }
  }, [
    isProductsExportError,
    isProductsExportLoading,
    productsExportMessage,
    dispatch,
  ]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      if (message) {
        enqueueSnackbar(message, { variant: "success" });
      } else {
        setProductsData(products);
        setProductsMetaData(productsMeta);
      }
    }

    return () => {
      dispatch(reset());
    };
  }, [isError, isSuccess, message, page]);

  useEffect(() => {
    if (isDeleteError) {
      enqueueSnackbar(deleteMessage, { variant: "error" });
    }
    if (isDeleteSuccess) {
      enqueueSnackbar(deleteMessage, { variant: "success" });
      dispatch(
        getProducts({
          page:
            ProductsData.length <= 1 || selectedId.length >= ProductsData.length
              ? page > 1
                ? page - 1
                : 1
              : page,
        })
      ).then(() => setIsDeleteModalOpen(false));

      if (
        ProductsData.length <= 1 ||
        selectedId.length >= ProductsData.length
      ) {
        setPage(page > 1 ? page - 1 : 1);
      }
      setSelected([]);
      setSelectedId([]);
    }
    return () => {
      dispatch(reset());
    };
  }, [isDeleteError, isDeleteSuccess, deleteMessage]);

  useEffect(() => {
    dispatch(
      getProducts({
        page: page,
        title: searchValue,
      })
    );
    setSelected([]);
    setSelectedId([]);
  }, [page, searchValue]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 80, filterable: false },
    {
      field: "title",
      headerName: "Product Title",
      flex: 1,
      minWidth: 160,
    },
    {
      field: "warehouses",
      headerName: "Warehouse",
      flex: 1,
      minWidth: 400,
      renderCell: (params) => (
        <Box display="flex" flexDirection="row" flexWrap="wrap" py={10}>
          {params.row?.warehouses?.map((item: any) => {
            return (
              <Box key={item.id} padding={2}>
                <TableChip>{item.title}</TableChip>
              </Box>
            );
          })}
        </Box>
      ),
    },
    {
      field: "variants",
      headerName: "Variants",
      flex: 1,
      minWidth: 400,
      renderCell: (params) => (
        <Box display="flex" flexDirection="row" flexWrap="wrap" py={10}>
          {params.row?.variants?.map((item: any) => {
            return (
              <Box key={item.id} padding={2}>
                <TableChip>{item.title}</TableChip>
              </Box>
            );
          })}
        </Box>
      ),
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.5,
      minWidth: 140,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          flexDirection="row"
        >
          <IconButton
            onClick={() =>
              navigate("/products/product-information", {
                state: { data: params.row },
              })
            }
          >
            <Eye color={colors.gray[600]} size="24" />
          </IconButton>
          <IconButton
            onClick={() =>
              navigate("/products/product-edit", {
                state: { data: params.row },
              })
            }
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Edit.svg"}
              alt="edit"
            />
          </IconButton>
          {/* <IconButton
            onClick={() => {
              setSelectedId([Number(params?.id)]);
              setIsDeleteModalOpen(true);
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Remove.svg"}
              alt="delete"
            />
          </IconButton> */}
        </Box>
      ),
    },
  ];

  const onDelete = (selected: number[]) => {
    dispatch(deleteProduct(selected));
  };

  return (
    <PageTemplate
      crumbs={[
        {
          label: "Products list",
        },
      ]}
    >
      <ListBoundary
        isEmpty={(ProductsData.length === 0 || !ProductsData) && !searchValue}
        isDataLoading={isLoading && !searchValue}
        fallbackImageURL="/assets/images/emptyStates/Products-List EmptyState.svg"
        description="Start First Product!"
        buttonLabel="New Product"
        onClick={() => navigate("/products/product-new")}
      >
        <StyledHeader>
          <SearchBox>
            <StyledTextField
              placeholder="Search"
              fullWidth
              height="48px"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </SearchBox>
          <ButtonsBox>
            <ActionsBox>
              <ActionBtn>
                <GrayButton
                  variant="contained"
                  fullWidth
                  loading={isProductsExportLoading}
                  endIcon={
                    <img
                      src={process.env.PUBLIC_URL + "/assets/icons/Export.svg"}
                      alt="export"
                    />
                  }
                  onClick={() => {
                    dispatch(exportProducts({})).then(() => reset());
                  }}
                >
                  Export
                </GrayButton>
              </ActionBtn>
              {/* <ActionBtn>
                <GrayButton
                  variant="contained"
                  fullWidth
                  endIcon={
                    <img
                      src={process.env.PUBLIC_URL + "/assets/icons/Print.svg"}
                      alt="print"
                    />
                  }
                  onClick={() => {
                    setSelectedId(selected);
                    setIsPrintModalOpen(true);
                  }}
                  color="error"
                >
                  Print
                </GrayButton>
              </ActionBtn> */}
            </ActionsBox>
            <StyledButton
              variant="contained"
              fullWidth={isBelowSmSize}
              endIcon={<Add size="24" color={colors.gray[850]} />}
              onClick={() => navigate("/products/product-new")}
            >
              New Product
            </StyledButton>
            {/* {selected.length !== 0 ? (
              <StyledButton
                variant="contained"
                fullWidth={isBelowSmSize}
                endIcon={
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/icons/removeWhite.svg"
                    }
                    alt="delete"
                  />
                }
                onClick={() => {
                  setSelectedId(selected);
                  setIsDeleteModalOpen(true);
                }}
                color="error"
              >
                Remove Product
              </StyledButton>
            ) : (
              <StyledButton
                variant="contained"
                fullWidth={isBelowSmSize}
                endIcon={<Add size="24" color={colors.gray[850]} />}
                onClick={() => navigate("/products/product-new")}
              >
                New Product
              </StyledButton>
            )} */}
          </ButtonsBox>
        </StyledHeader>
        <StyledTable
          disableColumnMenu
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableVirtualization
          disableSelectionOnClick
          loading={isLoading}
          getRowHeight={() => "auto"}
          checkboxSelection
          experimentalFeatures={{ newEditingApi: true }}
          rows={ProductsData}
          columns={columns}
          onSelectionModelChange={(e) => {
            setSelected(e);
          }}
          onPageChange={(e) => setPage(e + 1)}
          page={page - 1}
          paginationMode="server"
          rowCount={ProductsMetaData?.pagination?.total_items}
        />
      </ListBoundary>
      <ConfirmModal
        title={"Remove Products"}
        iconName={"ModalRemove"}
        open={isDeleteModalOpen}
        isLoading={isDeleteLoading}
        onDismiss={() => setIsDeleteModalOpen(false)}
        onConfirm={() => onDelete(selectedId)}
        confrimText="Remove"
        cancelText="Cancel"
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to remove this Products?
        </Typography>
      </ConfirmModal>
    </PageTemplate>
  );
};

const StyledHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SearchBox = styled(Box)`
  width: 372px;
  ${(props) => props.theme.breakpoints.down("md")} {
    margin-bottom: 24px;
    width: 315px;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    margin-bottom: 18px;
  }
`;

const ButtonsBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    justify-content: space-between;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

const ActionsBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 24px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
    margin-right: 16px;
  }
`;

const ActionBtn = styled(Box)`
  width: 145px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

const CreateBtnBox = styled(Box)`
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

export default ProductsTable;
