import styled from "@emotion/styled";
import { Menu, MenuItem, IconButton, Box, css } from "@mui/material";
import { FC, MouseEvent, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import colors from "src/theme/colors";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

interface CarrierInfoPageDetailsCardProps {
  title: string;
  status: string;
  isDeleteloading?: boolean;
  onRemoveTruck: () => void;
}

export const CarrierInfoPageDetailsCard: FC<CarrierInfoPageDetailsCardProps> = (
  props
) => {
  const { title, status, isDeleteloading, onRemoveTruck } = props;

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <Content>
        <IconButton onClick={() => navigate(-1)}>
          <ChevronLeft />
        </IconButton>
        <TitleBox>
          <img
            width={40}
            height={40}
            src={
              process.env.PUBLIC_URL +
              "/assets/icons/saleOrderShipment/TruckBlueBG.svg"
            }
          />
          <Title>{title}</Title>
        </TitleBox>
        <IconButton onClick={() => console.log("")}>
          <img src={process.env.PUBLIC_URL + "/assets/icons/Edit.svg"} />
        </IconButton>
      </Content>

      <BadgeBox status={status}>
        <BadgeIcon>
          <img
            src={
              process.env.PUBLIC_URL +
              `/assets/icons/${
                status === "loading" ? "LoadingBlue" : "Check"
              }.svg`
            }
            alt="status"
          />
        </BadgeIcon>
        {status === "loading" ? "In Progress" : "Completed"}
      </BadgeBox>

      <div>
        <MenuIcon>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </MenuIcon>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "20ch",
            },
          }}
        >
          <MenuItem key={1} onClick={onRemoveTruck} sx={{ p: 6 }}>
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/RemoveSlim.svg"}
              alt="Remove"
            />
            <span style={{ marginLeft: "8px", color: colors.error[500] }}>
              Remove Truck
            </span>
          </MenuItem>
        </Menu>
      </div>
    </Container>
  );
};

interface StyledProps {
  status?: string;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 24px;
  border-radius: 4px;
  margin-bottom: 16px;
  background-color: ${colors.base.white};
`;

const Content = styled.div`
  width: calc(100% - 140px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 24px;
`;

const Title = styled.h4`
  font-size: 18px;
  line-height: 24px;
  margin: 0 8px;
  color: ${colors.gray[850]};
`;

const MenuIcon = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-color: ${colors.gray[200]};
`;

const BadgeBox = styled(Box)<StyledProps>(({ status }) => {
  return css`
    padding: 2px 15px 2px 12px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    line-height: 26px;
    font-weight: 400;
    font-size: 13px;
    margin-right: 32px;
    white-space: nowrap;
    background-color: ${status === "loading"
      ? colors.secondary[50]
      : colors.success[50]};
    color: ${status === "loading"
      ? colors.secondary[800]
      : colors.success[700]};
  `;
});

const BadgeIcon = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
