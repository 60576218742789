import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const Base = {
  white: "#FFFFFF",
  black: "#000000",
};
const GREY = {
  0: "#F9FCFF",
  20: "#F5F8FD",
  25: "#F8F8F8",
  50: "#F0F2F7",
  100: "#EAECEF",
  200: "#C2CBD7",
  300: "#A9B6C6",
  400: "#B7BDC5",
  450: "#95A4B7",
  500: "#8193AA",
  600: "#707A8A",
  700: "#515B68",
  750: "#474D57",
  800: "#3d4550",
  850: "#1E2329",
  900: "#181A20",
};

const PRIMARY = {
  10: "#FFFBEC",
  50: "#FFF8E0",
  100: "#FEEBB2",
  200: "#FDDE80",
  300: "#FCD34B",
  400: "#FCD535",
  500: "#EFB731",
  600: "#FBB000",
  700: "#FB9D00",
  800: "#C89358",
  900: "#FC6C00",
};
const SECONDARY = {
  50: "#E9ECFF",
  100: "#C7CEFE",
  200: "#A0AFFD",
  300: "#738FFD",
  400: "#4B74FC",
  500: "#035AF9",
  600: "#0051ED",
  700: "#0045E0",
  800: "#003AD5",
  900: "#0025BD",
};
const SUCCESS = {
  50: "#E1F4EE",
  100: "#B5E3D3",
  200: "#86D1B7",
  300: "#56BE9B",
  400: "#33AF86",
  500: "#18A074",
  600: "#149268",
  700: "#0E8259",
  800: "#02724C",
  900: "#005631",
};
const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
};
const ERROR = {
  50: "#FCE4E8",
  100: "#F8BBC6",
  200: "#F390A2",
  300: "#EC667E",
  400: "#E54A64",
  500: "#DF354C",
  600: "#CF304B",
  700: "#BA2A47",
  800: "#A62445",
  900: "#831B3F",
};
const MIDNIGHT = {
  1: "#FFFFFF",
  200: "#C2CBD7",
};

const GRADIENTS = {
  primary: createGradient(PRIMARY[100], PRIMARY[200]),
};

const CHART_COLORS = {
  violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
  blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
  green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
  yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
  red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
};

const palette = {
  common: { ...Base },
  primary: { main: "#FCD34B" },
  secondary: { main: "#1797C2" },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  red: GREY,
  midnight: MIDNIGHT,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500],
  text: { primary: GREY[500], secondary: GREY[600], disabled: GREY[500] },
  background: { paper: "#fff", default: "#fff", neutral: GREY[200] },
  action: {},
};

export default palette;
