import { Checkbox, CheckboxProps } from "@mui/material";
import colors from "src/theme/colors";

export default function CustomCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        color: colors.gray[400],
      }}
      color="secondary"
      size="medium"
      {...props}
    />
  );
}
