import { Box, Grid, Typography } from "@mui/material";
import FormHeading from "src/components/FormHeading";
import FormSubHeading from "src/components/FormSubHeading";
import FormInputLable from "src/components/FormInputLabel";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPermissions, patchRoles, reset } from "../redux/roleSlice";
import { StyledFormControlLabel } from "src/components/FormControlLabel";
import CustomCheckbox from "src/components/Checkbox";
import colors from "src/theme/colors";
import { StyledTextField } from "src/components/Input";
import { CanselButton } from "src/components/CanselButton";
import { StyledButton } from "src/components/Button";
import Loading from "src/components/Loading";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { PaperBox } from "src/components/Paper/Paper";

interface Props {
  id: string;
  title: string;
  rolePermissions: Array<number>;
  permissionData: any;
}

const RoleEditForm = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [name, setName] = useState("");
  const [checked, setChecked] = useState([] as Array<number>);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { isError, isSuccess, message, isLoading } = useSelector(
    (state: RootState) => state.role
  );

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      enqueueSnackbar(message, { variant: "success" });
    }
    dispatch(reset());
  }, [isError, isSuccess, message, dispatch]);

  useEffect(() => {
    setChecked(props.rolePermissions);
    setName(props.title);
  }, []);

  const handleChecked = (key: any) => {
    const CheckedItems = checked;
    if (!CheckedItems.includes(key)) {
      CheckedItems.push(key);
    } else {
      CheckedItems.splice(CheckedItems.indexOf(key), 1);
    }
    setChecked([...CheckedItems]);
  };

  const onSubmit = () => {
    const roleValue = {
      title: name,
      permissions: checked,
      id: props.id,
    };
    dispatch(patchRoles(roleValue));
  };

  return (
    <>
      <Box>
        <PaperBox>
          <Grid container>
            <Grid md={6} sm={12}>
              <Box pb={10}>
                <FormHeading>{t("role.roleNew.heading")}</FormHeading>
              </Box>
              <FormInputLable noPrefix>
                {t("role.roleNew.inputLable")}
              </FormInputLable>
              <StyledTextField
                placeholder="Enter role name"
                value={name}
                fullWidth
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
          </Grid>
        </PaperBox>

        <PaperBox>
          <Box>
            <FormHeading>{t("role.roleNew.permissions")}</FormHeading>
          </Box>
          <Grid container>
            <Grid md={8} sm={12}>
              {props.permissionData.length === 0 ? (
                <Loading />
              ) : (
                props.permissionData.map((item: any, index: any) => {
                  return (
                    <Box pt={16} key={index}>
                      <FormSubHeading>{item.key} :</FormSubHeading>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        rowGap={3}
                        columnGap={7}
                        pt={8}
                        flexWrap="wrap"
                      >
                        {item?.values?.map((innerItem: any) => {
                          return (
                            <StyledFormControlLabel
                              key={innerItem?.id}
                              control={
                                <CustomCheckbox
                                  onClick={() => handleChecked(innerItem?.id)}
                                  checked={
                                    checked.includes(innerItem?.id)
                                      ? true
                                      : false
                                  }
                                />
                              }
                              label={
                                <Typography
                                  fontSize="14"
                                  fontWeight="400"
                                  color={colors.gray[850]}
                                >
                                  {innerItem?.description}
                                </Typography>
                              }
                            />
                          );
                        })}
                      </Box>
                    </Box>
                  );
                })
              )}
            </Grid>
          </Grid>
        </PaperBox>

        <Box display="flex" mt={12} flexDirection="row" alignItems="center">
          <Box width="168">
            <CanselButton
              onClick={() => navigate("/roles-management/roles-list")}
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.gray[850]}
              >
                {t("role.roleNew.cancelbtn")}
              </Typography>
            </CanselButton>
          </Box>
          <Box width="176px" marginLeft={12}>
            <StyledLoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={isLoading}
              disabled={checked.length === 0 ? true : false}
              onClick={onSubmit}
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.gray[850]}
              >
                {t("role.roleNew.submitbtn")}
              </Typography>
            </StyledLoadingButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RoleEditForm;
