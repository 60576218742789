import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC } from "react";
import { AppDispatch } from "src/store";
import { useDispatch } from "react-redux";
import { BreadCrumbItemProps } from "src/components/BreadCrumbs/BreadCrumbs";
import Navbar from "./Navbar";
import { toggleSidebar } from "../redux/layoutStore";
import colors from "src/theme/colors";

interface PageTemplateProps {
  crumbs?: BreadCrumbItemProps[];
  isTransparent?: boolean;
  children: any;
  noMargins?: boolean;
}

export const PageTemplate: FC<PageTemplateProps> = (props) => {
  const { crumbs, isTransparent, children, noMargins } = props;

  const dispatch = useDispatch<AppDispatch>();

  return (
    <Box>
      <Navbar crumbs={crumbs} onOpenSidebar={() => dispatch(toggleSidebar())} />

      <Main component="main" isTransparent={isTransparent}>
        <Box
          component="div"
          sx={
            crumbs
              ? {
                  backgroundColor: isTransparent ? colors.base.back : "#ffffff",
                  margin: 0,
                  p: isTransparent ? 0 : 8,
                  borderRadius: "5px",
                }
              : {}
          }
        >
          {children}
        </Box>
      </Main>
    </Box>
  );
};

interface StyledProps {
  isTransparent?: boolean;
}

const Main = styled(Box)<StyledProps>(({ theme, isTransparent }) => ({
  position: "absolute",
  right: 0,
  top: "100px",
  flexGrow: 1,
  padding: "10px 24px 24px 24px",
  backgroundColor: colors.base.back,
  overflow: "auto",
  width: "calc(100% - 300px)",
  height: "calc(100vh - 100px)",

  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));
