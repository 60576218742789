import { axios } from "../../../config/Axios";

//StockTaking get
const getStockTaking = async (data: any) => {
  const response = await axios.get(
    `/stock-taking?page=${data.page}&query=${data.search ? data.search : ""}`
  );
  return response;
};
//StockTakings EXPORT
const exportStockTakingsList = async (data: any) => {
  const response = await axios({
    url: `/stock-taking?page=${data.page}${
      data.search ? `&query=${data.search}` : ""
    }&export_excel=true`,
    method: "get",
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${Date.now()}.xlsx`);
  document.body.appendChild(link);
  link.click();

  return response;
};
//StockTaking get by id
const getStockTakingId = async (data: any) => {
  const response = await axios.get(
    `/warehouses/${data.warehouse}/stock-taking/${data.stockTaking}?${
      data.product ? `product=${data.product}` : null
    }`
  );
  return response;
};
//StockTaking submit
const postStockTaking = async (data: any) => {
  const response = await axios.post(
    `/warehouses/${data.warehouse}/stock-taking`,
    {
      title: data.title,
      employee: data.employee,
      start_date: data.start_date,
      product: data.product,
    }
  );
  return response;
};
//StockTaking submit
const patchStockTaking = async (data: any) => {
  const response = await axios.patch(
    `/warehouses/${data.warehouse}/stock-taking/${data.id}`,
    {
      title: data.title,
      employee: data.employee,
      start_date: data.start_date,
      product: data.product,
    }
  );
  return response;
};
//StockTaking delete
const deleteStockTaking = async (data: any) => {
  const response = await axios.delete(
    `/warehouses/${data.warehouse}/stock-taking/${data.stockTaking}`
  );
  return response;
};
//StockTaking finish
const stopStockTaking = async (data: any) => {
  const response = await axios.put(
    `/warehouses/${data.warehouse}/stock-taking/${data.stockTaking}/finish`
  );
  return response;
};
//StockTaking complete
const completeStockTaking = async (data: any) => {
  const response = await axios.put(
    `/warehouses/${data.warehouse}/stock-taking/${data.stockTaking}/complete`
  );
  return response;
};
//StockTaking products
const getStockTakingProducts = async (data: any) => {
  const response = await axios.get(
    `/warehouses/${data.warehouse}/stock-taking/${
      data.stockTaking
    }/products?page=${data.page}${data.filter ? `&filter=${data.filter}` : ""}`
  );
  return response;
};
//StockTaking products EXPORT_Excel
const exportStockTakingProducts = async (data: any) => {
  const response = await axios({
    url: `/warehouses/${data.warehouse}/stock-taking/${data.stockTaking}/products?export_excel=true`,
    method: "get",
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${Date.now()}.xlsx`);
  document.body.appendChild(link);
  link.click();

  return response;
};

const StockTakingServices = {
  getStockTaking,
  exportStockTakingsList,
  getStockTakingId,
  postStockTaking,
  patchStockTaking,
  deleteStockTaking,
  stopStockTaking,
  completeStockTaking,
  getStockTakingProducts,
  exportStockTakingProducts,
};
export default StockTakingServices;
