import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import StyledTable from "src/components/Table";
import { GridColDef } from "@mui/x-data-grid";
import { AppDispatch, RootState } from "src/store";
import {
  deleteStockTaking,
  getStockTaking,
  exportStockTakingsList,
  reset,
  stopStockTaking,
} from "../redux/StockTakingSlice";
import { IconButton, Box, Typography, InputAdornment } from "@mui/material";
import { Add, Eye } from "iconsax-react";
import colors from "src/theme/colors";
import { styled } from "@mui/material/styles";
import { StyledButton } from "src/components/Button";
import ConfirmModal from "src/components/Modal";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";
import { StyledTextField } from "src/components/Input";
import { Search } from "@mui/icons-material";
import { NewStockTakingModal } from "../components/modals/NewStockTakingModal";
import {
  getWarehouses,
  reset as resetWarehouses,
} from "src/features/warehouses/redux/WarehouseSlice";
import { EditStockTakingModal } from "../components/modals/EditStockTakingModal";
import {
  getEmployes,
  reset as resetEmployes,
} from "src/features/employes/redux/EmployesSlice";
import TableChip from "src/components/TableChip";
import moment from "moment";
import {
  getProducts,
  reset as resetProducts,
} from "src/features/products/redux/ProductsSlice";
import { GrayButton } from "src/components/GrayButton";

interface CustomizedRouteState {
  isRedirectedToCreate: boolean;
  isRedirectedToEdit: boolean;
  rowData: boolean;
}

const StockTakingTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const state = location?.state as CustomizedRouteState;

  const { enqueueSnackbar } = useSnackbar();
  const [dataLoading, setDataLoading] = useState(true);
  const [StockTakingsData, setStockTakingsData] = useState([] as any);
  const [StockTakingsMetaData, setStockTakingsMetaData] = useState([] as any);
  const [searchValue, setSearchValue] = useState("");
  const [selected, setSelected] = useState([] as any);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState<number>();

  //CRUD modals
  const [isCreateNewModalOpen, setIsCreateNewModalOpen] = useState(
    state?.isRedirectedToCreate ?? false
  );
  const [isEditModalOpen, setIsEditModalOpen] = useState(
    state?.isRedirectedToEdit ?? false
  );

  //confirm Modals
  const [openFinishModal, setOpenFinishModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState([0]);
  const handelOpenDeleteModal = (open: boolean) => {
    setOpenDeleteModal(!open);
  };
  const handelOpenFinishModal = (open: boolean) => {
    setOpenFinishModal(!open);
  };

  const {
    StockTaking,
    StockTakingMeta,
    isLoading,
    isDeleteLoading,
    isStockTakingsExportLoading,
    isError,
    isDeleteError,
    isSuccess,
    isDeleteSuccess,
    message,
    deleteMessage,
  } = useSelector((state: RootState) => state.stockTaking);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      setStockTakingsData(StockTaking);
      setStockTakingsMetaData(StockTakingMeta);
      setDataLoading(false);
    }

    return () => {
      dispatch(reset());
    };
  }, [isError, isSuccess, message, page]);

  useEffect(() => {
    dispatch(
      getStockTaking({
        page: page,
        search: searchValue,
      })
    );
  }, [page, searchValue]);

  // CRUD default values
  const [employesData, setEmployes] = useState([] as any);
  const [warehousesData, setWarehousesData] = useState([] as any);
  const [productsData, setProductsData] = useState([] as any);
  const [rowDefaultValues, setRowDefaultValues] = useState(
    state?.isRedirectedToEdit ? state.rowData : ({} as any)
  );

  const {
    Warehouses,
    isError: isWarehousesError,
    isSuccess: isWarehousesSuccess,
    message: WarehousesMessage,
    isLoading: isWarehousesLoading,
  } = useSelector((state: RootState) => state.warehouse);
  const {
    employes,
    isError: isEmployesError,
    isSuccess: isEmployesSuccess,
    message: EmployesMessage,
    isLoading: isEmployesLoading,
  } = useSelector((state: RootState) => state.employes);
  const {
    products: allProductsData,
    productsMeta: allProductsDataMeta,
    isLoading: isAllProductsDataLoading,
    isError: isAllProductsDataError,
    isSuccess: isAllProductsDataSuccess,
    message: allProductsDataMessage,
  } = useSelector((state: RootState) => state.products);

  useEffect(() => {
    if (isWarehousesError) {
      enqueueSnackbar(WarehousesMessage, { variant: "error" });
    }
    if (isWarehousesSuccess) {
      setWarehousesData(Warehouses);
    }
    return () => {
      dispatch(resetWarehouses());
    };
  }, [
    isWarehousesError,
    isWarehousesSuccess,
    WarehousesMessage,
    isWarehousesLoading,
  ]);

  useEffect(() => {
    if (isEmployesError) {
      enqueueSnackbar(EmployesMessage, { variant: "error" });
    }
    if (isEmployesSuccess) {
      setEmployes(employes);
    }
    return () => {
      dispatch(resetEmployes());
    };
  }, [isEmployesError, isEmployesSuccess, EmployesMessage, isEmployesLoading]);

  useEffect(() => {
    if (isAllProductsDataError) {
      enqueueSnackbar(allProductsDataMessage, { variant: "error" });
    }
    if (isAllProductsDataSuccess) {
      setProductsData(allProductsData);
    }
    return () => {
      dispatch(resetProducts());
    };
  }, [
    isAllProductsDataError,
    isAllProductsDataSuccess,
    allProductsDataMessage,
    isAllProductsDataLoading,
  ]);

  useEffect(() => {
    if (isDeleteError) {
      enqueueSnackbar(deleteMessage, { variant: "error" });
    }
    if (isDeleteSuccess) {
      enqueueSnackbar(deleteMessage, { variant: "success" });
      dispatch(
        getStockTaking({
          page: StockTakingsData.length <= 1 ? (page > 1 ? page - 1 : 1) : page,
        })
      ).then(() => setOpenDeleteModal(false));
      if (StockTakingsData.length <= 1) {
        setPage(page > 1 ? page - 1 : 1);
      }
    }
    return () => {
      dispatch(reset());
    };
  }, [isDeleteError, isDeleteSuccess, deleteMessage]);

  useEffect(() => {
    dispatch(
      getWarehouses({
        page: page,
        limit: 100,
      })
    );
    dispatch(
      getEmployes({
        page: 1,
      })
    );
    dispatch(
      getProducts({
        limit: 100,
        isCountable: true,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getEmployes({
        page: page,
      })
    );
    setSelected([]);
    setSelectedId([]);
  }, [page]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 80, filterable: false },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "warehouse",
      headerName: "Warehouse",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.warehouse?.title}
        </Box>
      ),
    },
    {
      field: "operator",
      headerName: "Operator",
      flex: 1,
      minWidth: 260,
      renderCell: (params) => (
        <Box display="flex" flexDirection="row" flexWrap="wrap" py={10}>
          {params.row?.employee?.map((item: any) => {
            return (
              <Box key={item.id} padding={2}>
                <TableChip>{item.name}</TableChip>
              </Box>
            );
          })}
        </Box>
      ),
    },
    {
      field: "total_products_counts",
      headerName: "Total Counts",
      flex: 1,
      minWidth: 140,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      flex: 1,
      minWidth: 140,
      renderCell: (params) => (
        <Box padding={2} color="#1E2329">
          {moment(params.row?.start_date).format("DD-MMM-YYYY")}
        </Box>
      ),
    },
    {
      field: "finished_date",
      headerName: "Completed Date",
      flex: 1,
      minWidth: 140,
      renderCell: (params) => {
        if (params?.row?.status === "inProgress") {
          return (
            <Box
              padding={2}
              color={"#1E2329"}
              display="flex"
              alignItems="center"
            >
              In Progress{" "}
              <img
                src={process.env.PUBLIC_URL + "/assets/icons/Loading.svg"}
                alt="loading"
              />
            </Box>
          );
        } else if (params.row?.finished_date != null) {
          return (
            <Box padding={2} color="#1E2329">
              {moment(params.row?.finished_date).format("DD-MMM-YYYY")}
            </Box>
          );
        } else {
          return (
            <Box padding={2} color="#1E2329" display="flex" alignItems="center">
              New
            </Box>
          );
        }
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.4,
      minWidth: 140,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          flexDirection="row"
        >
          <IconButton
            onClick={() =>
              navigate("/stocktakings/stocktaking-information", {
                state: { data: params?.row },
              })
            }
          >
            <Eye color={colors.gray[600]} size="24" />
          </IconButton>
          <IconButton
            onClick={() => {
              setRowDefaultValues(params?.row);
              setIsEditModalOpen(true);
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Edit.svg"}
              alt="edit"
            />
          </IconButton>
          <IconButton>
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/handStop.svg"}
              alt="stop"
              onClick={() => {
                setSelectedId([Number(params?.id)]);
                setSelectedWarehouseId(Number(params?.row?.warehouse?.id));
                handelOpenFinishModal(openDeleteModal);
              }}
            />
            {/* {params?.row?.status === "inProgress" ? (
              <img
                src={process.env.PUBLIC_URL + "/assets/icons/handStop.svg"}
                alt="stop"
                onClick={() => {
                  setSelectedId([Number(params?.id)]);
                  setSelectedWarehouseId(Number(params?.row?.warehouse?.id));
                  handelOpenFinishModal(openDeleteModal);
                }}
              />
            ) : (
              <img
                src={process.env.PUBLIC_URL + "/assets/icons/Remove.svg"}
                alt="delete"
                onClick={() => {
                  setSelectedId([Number(params?.id)]);
                  setSelectedWarehouseId(Number(params?.row?.warehouse?.id));
                  handelOpenDeleteModal(openDeleteModal);
                }}
              />
            )} */}
          </IconButton>
        </Box>
      ),
    },
  ];

  const onDelete = (selected: any) => {
    const deleteValue = {
      warehouse: selectedWarehouseId,
      stockTaking: selected,
    };
    dispatch(deleteStockTaking(deleteValue));
  };

  const onFinish = (selected: any) => {
    const stopValues = {
      warehouse: selectedWarehouseId,
      stockTaking: selected,
    };
    dispatch(stopStockTaking(stopValues))
      .then(() =>
        dispatch(
          getStockTaking({
            page: page,
          })
        )
      )
      .then(() => setOpenFinishModal(false));
  };

  return (
    <PageTemplate
      crumbs={[
        {
          label: "Stocktaking list",
        },
      ]}
    >
      <ListBoundary
        isEmpty={
          (StockTakingsData.length === 0 || !StockTakingsData) && !searchValue
        }
        isDataLoading={(isLoading || dataLoading) && !searchValue}
        fallbackImageURL="/assets/images/emptyStates/StockTaking-List EmptyState.svg"
        description="Start First Stocktaking!"
        buttonLabel="New Stocktaking"
        onClick={() => setIsCreateNewModalOpen(true)}
      >
        <StyledHeader>
          <SearchBox>
            <StyledTextField
              placeholder="Search"
              fullWidth
              height="48px"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </SearchBox>
          <ButtonsHead>
            <ExportButton>
              {StockTakingsData && StockTakingsData.length > 0 ? (
                <GrayButton
                  variant="contained"
                  fullWidth
                  loading={isStockTakingsExportLoading}
                  endIcon={
                    <img
                      src={process.env.PUBLIC_URL + "/assets/icons/Export.svg"}
                      alt="export"
                    />
                  }
                  onClick={() => {
                    dispatch(
                      exportStockTakingsList({
                        page: page,
                        search: searchValue,
                      })
                    ).then(() => reset());
                  }}
                >
                  Export
                </GrayButton>
              ) : null}
            </ExportButton>

            <ActionButton>
              {selected.length !== 0 ? (
                <StyledButton
                  variant="contained"
                  fullWidth
                  endIcon={
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/icons/removeWhite.svg"
                      }
                      alt="delete"
                    />
                  }
                  onClick={() => {
                    setSelectedId(selected);
                    handelOpenDeleteModal(openDeleteModal);
                  }}
                  color="error"
                >
                  Remove Stocktakings
                </StyledButton>
              ) : (
                <StyledButton
                  variant="contained"
                  fullWidth
                  endIcon={<Add size="24" color={colors.gray[850]} />}
                  onClick={() => setIsCreateNewModalOpen(true)}
                >
                  New Stocktaking
                </StyledButton>
              )}
            </ActionButton>
          </ButtonsHead>
        </StyledHeader>
        <StyledTable
          disableColumnMenu
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableVirtualization
          disableSelectionOnClick
          loading={isLoading || dataLoading}
          getRowHeight={() => "auto"}
          // checkboxSelection
          experimentalFeatures={{ newEditingApi: true }}
          rows={StockTakingsData}
          columns={columns}
          onSelectionModelChange={(e) => {
            setSelected(e);
          }}
          onPageChange={(e) => setPage(e + 1)}
          page={page - 1}
          paginationMode="server"
          rowCount={StockTakingsMetaData?.pagination?.total_items}
        />
      </ListBoundary>
      <ConfirmModal
        title={"Remove Stocktaking"}
        iconName={"ModalRemove"}
        open={openDeleteModal}
        isLoading={isDeleteLoading || isLoading}
        onDismiss={(open) => handelOpenDeleteModal(open)}
        onConfirm={() => onDelete(selectedId)}
        confrimText={"Remove"}
        cancelText={"Cancel"}
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to remove this Stocktaking?
        </Typography>
      </ConfirmModal>
      <ConfirmModal
        title={"Finish Stocktaking"}
        iconName={"ModalRemove"}
        open={openFinishModal}
        isLoading={isDeleteLoading}
        onDismiss={(open) => handelOpenFinishModal(open)}
        onConfirm={() => onFinish(selectedId)}
        confrimText={"Finish"}
        cancelText={"Cancel"}
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to Finish this Stocktaking?
        </Typography>
      </ConfirmModal>

      <NewStockTakingModal
        open={isCreateNewModalOpen}
        warehouses={warehousesData}
        employes={employesData}
        products={productsData}
        onSuccess={() => {
          dispatch(
            getStockTaking({
              page: page,
            })
          ).then(() => setIsCreateNewModalOpen(false));
        }}
        onDismiss={() => setIsCreateNewModalOpen(false)}
      />
      <EditStockTakingModal
        open={isEditModalOpen}
        warehouses={warehousesData}
        employes={employesData}
        products={productsData}
        defaultValues={rowDefaultValues}
        onSuccess={() => {
          dispatch(
            getStockTaking({
              page: page,
            })
          ).then(() => setIsEditModalOpen(false));
        }}
        onDismiss={() => setIsEditModalOpen(false)}
      />
    </PageTemplate>
  );
};

const StyledHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: column;
    align-items: flex-end;
  }
`;

const SearchBox = styled(Box)`
  width: 372px;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    margin-bottom: 18px;
  }
`;

const ButtonsHead = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  ${(props) => props.theme.breakpoints.down("md")} {
    margin-top: 24px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
  }
`;

const ActionButton = styled(Box)`
  width: 220px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

const ExportButton = styled(Box)`
  width: 164px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

export default StockTakingTable;
