import { ThemeProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme/theme";
import "./index.css";
import GlobalStyles from "./theme/components/GlobalStyle";
import { Provider } from "react-redux";
import { store } from "./store";
import { Helmet } from "react-helmet";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
if (process.env.NODE_ENV === "production") {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.error = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.debug = () => {};
}

const isOnIRDomain = window.location.hostname.endsWith(".ir");

root.render(
  <>
    {window && window.location && (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{isOnIRDomain ? "معدن گستر" : "Mineral Axis"}</title>
        <meta
          name="msapplication-TileColor"
          content={isOnIRDomain ? "#2d89ef" : "#2b5797"}
        />
        <link
          rel="icon"
          href={`/favicon/${isOnIRDomain ? "iran" : "global"}/favicon.ico`}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`/favicon/${
            isOnIRDomain ? "iran" : "global"
          }/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`/favicon/${
            isOnIRDomain ? "iran" : "global"
          }/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`/favicon/${
            isOnIRDomain ? "iran" : "global"
          }/favicon-16x16.png`}
        />
        <link
          rel="manifest"
          href={`/manifests/${
            isOnIRDomain ? "iran" : "global"
          }/site.webmanifest`}
        />
        <link
          rel="mask-icon"
          href={`/favicon/${
            isOnIRDomain ? "iran" : "global"
          }/safari-pinned-tab.svg`}
          color="#5bbad5"
        />
      </Helmet>
    )}
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <App />
          <GlobalStyles />
        </MuiThemeProvider>
      </ThemeProvider>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
