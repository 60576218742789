import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import MainLayout from "src/layout/Main";
import EmployesEdit from "../pages/EmployesEdit";
import EmployesInfo from "../pages/EmployesInfo";
import EmployesNew from "../pages/EmployesNew";
import EmployesTable from "../pages/EmployesTable";

export const EmployesRoutes = () => {
    const { t } = useTranslation();
    return (

        <Routes>
            <Route path="/employee-list" element={<EmployesTable />} />
            <Route path="/employee-new" element={<EmployesNew />} />
            <Route path="/employee-edit" element={<EmployesEdit />} />
            <Route path="/employee-information" element={<EmployesInfo />} />
        </Routes>

    );
};
