import { Box, styled, Typography } from "@mui/material";
import type { FC } from "react";
import colors from "src/theme/colors";
import { TotalOrderChart } from "./TotalOrderChart";

interface SentReportChartCard {
  totalOrders: number;
  sentOrders: number;
  chartData: { name: string; value: number }[];
}

export const SentReportChartCard: FC<SentReportChartCard> = (props) => {
  const { totalOrders, sentOrders, chartData } = props;

  const remainingOrders = totalOrders - sentOrders;

  return (
    <Container>
      <ChartBox>
        <TotalOrderChart
          total={totalOrders ?? 0}
          value={sentOrders}
          data={chartData}
        />
      </ChartBox>
      <CountsBox>
        <Count>
          <Row>
            <Badge colored />
            Sent To Port
          </Row>
          {sentOrders ? (
            <Value>
              {sentOrders} <Prefix as="span">MT</Prefix>
            </Value>
          ) : (
            <Value>0</Value>
          )}
        </Count>
        <Count>
          <Row>
            <Badge />
            Remaining
          </Row>
          {remainingOrders ? (
            <Value>
              {remainingOrders} <Prefix as="span">MT</Prefix>
            </Value>
          ) : (
            <Value>0</Value>
          )}
        </Count>
      </CountsBox>
    </Container>
  );
};

interface StyledProps {
  colored?: boolean;
}

const Container = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 48px;
  border: 1px solid;
  border-color: ${colors.midnight[50]};
  border-radius: 5px;
  @media screen and (max-width: 760px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
  }
`;

const ChartBox = styled(Box)`
  width: 320px;
  margin-right: 10%;
  @media screen and (max-width: 760px) {
    margin-right: 0;
  }
`;

const CountsBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  @media screen and (max-width: 760px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    margin-top: 32px;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    margin-top: 32px;
  }
`;

const Count = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "10px",
});

const Row = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "6px",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "32px",
  color: colors.midnight[300],
});

const Badge = styled(Box)<StyledProps>(({ theme, colored }) => ({
  width: "12px",
  height: "12px",
  borderRadius: "50%",
  backgroundColor: colored ? colors.secondary[500] : colors.midnight[300],
}));

const Value = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: "30px",
  color: colors.midnight[850],
});

const Prefix = styled(Typography)({
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "30px",
  color: colors.midnight[850],
});
