import styled from "@emotion/styled";
import { Box, Typography, styled as styledMui } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import { useEffect, useState } from "react";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";
import StyledTable from "src/components/Table";
import colors from "src/theme/colors";
import { GrayButton } from "src/components/GrayButton";

import { useLocation, useNavigate } from "react-router-dom";
import {
  getInternationalInspectionSelectedProductItems,
  exportInternationalSelectedProductsExcel,
  exportInternationalSelectedProductsPDF,
  reset as resetInternationalInspections,
} from "../redux/InternationalInspectionSlice";
import { reset as resetProducts } from "src/features/products/redux/ProductsSlice";
import { PageTemplate } from "src/layout/components/PageTemplate";

interface LocationState {
  title?: string;
  warehouseId?: string | number;
  inspectionId?: string | number;
}

const InternationalProductsInformation = () => {
  const location = useLocation();

  const state = location.state as LocationState;

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState([] as any);
  const [productsData, setProductsData] = useState([] as any);
  const [productsMeta, setProductsMeta] = useState([] as any);

  const {
    productItems,
    productItemsMeta,
    isProductItemsLoading,
    isProductItemsError,
    isProductItemsSuccess,
    productItemsMessage,
    isProductItemsExportExcelLoading,
    isProductItemsExportPDFLoading,
  } = useSelector((state: RootState) => state.internationalInspections);

  useEffect(() => {
    if (isProductItemsError) {
      enqueueSnackbar(productItemsMessage, { variant: "error" });
    }
    if (isProductItemsSuccess) {
      setProductsData(productItems);
      setProductsMeta(productItemsMeta);
    }
    return () => {
      dispatch(resetProducts());
    };
  }, [
    isProductItemsError,
    isProductItemsSuccess,
    productItemsMessage,
    dispatch,
  ]);

  const columns: GridColDef[] = [
    {
      field: "product",
      headerName: "Product",
      flex: 1,
      minWidth: 140,
      filterable: false,
    },
    {
      field: "placement",
      headerName: "Placement",
      flex: 1,
      minWidth: 140,
      filterable: false,
    },
    {
      field: "weight",
      headerName: "Weight",
      flex: 0.6,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "barcode",
      headerName: "Internal Barcode",
      flex: 1,
      minWidth: 140,
      filterable: false,
    },
  ];

  useEffect(() => {
    const postData = {
      id: state ? state.inspectionId : null,
      page: page,
    };

    dispatch(getInternationalInspectionSelectedProductItems(postData));
  }, [page]);

  return (
    <PageTemplate
      crumbs={[
        {
          label: "inspections",
          href: "/international-inspections/international-inspection-list",
          depth: -1,
        },
        {
          label: state.title ?? "",
        },
      ]}
    >
      <StyledHeader>
        <FiltersRow>
          {productItemsMeta && !isProductItemsLoading ? (
            <ResultsCount>
              total products:{" "}
              {(productItemsMeta as any)?.pagination?.total_items}
            </ResultsCount>
          ) : null}
        </FiltersRow>
        <HeadActionsBox>
          <ActionBtn>
            <GrayButton
              variant="contained"
              fullWidth
              loading={isProductItemsExportExcelLoading}
              endIcon={
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/Export.svg"}
                  alt="export"
                />
              }
              onClick={() => {
                dispatch(
                  exportInternationalSelectedProductsExcel(
                    Number(state?.inspectionId)
                  )
                ).then(() => resetInternationalInspections());
              }}
            >
              Export Excel
            </GrayButton>
          </ActionBtn>
          <ActionBtn>
            <GrayButton
              variant="contained"
              fullWidth
              loading={isProductItemsExportPDFLoading}
              endIcon={
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/Export.svg"}
                  alt="export"
                />
              }
              onClick={() => {
                dispatch(
                  exportInternationalSelectedProductsPDF(
                    Number(state?.inspectionId)
                  )
                ).then(() => resetInternationalInspections());
              }}
            >
              Export PDF
            </GrayButton>
          </ActionBtn>
        </HeadActionsBox>
      </StyledHeader>
      <ListBoundary
        isEmpty={productItems.length === 0 || !productItems}
        isDataLoading={isProductItemsLoading}
        fallbackImageURL="/assets/images/emptyStates/Products-List EmptyState.svg"
        description="Nothing found!"
      >
        <StyledTable
          disableColumnMenu
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableVirtualization
          disableSelectionOnClick
          loading={false}
          columns={columns}
          experimentalFeatures={{ newEditingApi: true }}
          rows={productItems}
          //   checkboxSelection
          onSelectionModelChange={(e) => {
            setSelected(e);
          }}
          onPageChange={(e) => setPage(e + 1)}
          page={page - 1}
          paginationMode="server"
          rowCount={(productItemsMeta as any)?.pagination?.total_items}
        />
      </ListBoundary>
    </PageTemplate>
  );
};

const StyledHeader = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const FiltersRow = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ResultsCount = styled("h6")`
  text-align: center;
  font-size: 14px;
  line-height: 28px;
  border-radius: 30px;
  padding: 6px 16px;
  white-space: nowrap;
  background-color: ${colors.secondary[50]};
  color: ${colors.secondary[800]};
`;

const HeadActionsBox = styledMui(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 24px;
  gap: 10px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
    margin-right: 16px;
  }
`;

const ActionBtn = styledMui(Box)`
  width: 160px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

export default InternationalProductsInformation;
