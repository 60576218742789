/* eslint-disable react/no-unescaped-entities */
import { FC, useEffect, useState } from "react";
import { Box, css, styled, Tab, Typography } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { useLocation, useNavigate } from "react-router-dom";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { AppDispatch, RootState } from "src/store";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { SaleOrderDetails } from "../components/SaleOrderDetails";
import { SaleOrderDocuments } from "../components/SaleOrderDocuments";
import { SaleOrderShippingDetail } from "../components/shippingDetails/SaleOrderShippingDetail";
import { StyledButton } from "src/components/Button";
import Loading from "src/components/Loading";
import {
  getSaleOrdersId,
  updateSaleOrder,
  reset as resetSaleOrders,
} from "../redux/SaleOrdersSlice";
import colors from "src/theme/colors";
import ConfirmModal from "src/components/Modal";
import { PaperBox } from "src/components/Paper/Paper";

interface LocationState {
  data: any;
}

const SaleOrdersInfo: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const state = location.state as LocationState;
  const { data } = state;

  const [saleOrderData, setSaleOrderData] = useState({} as any);
  const [activeTab, setActiveTab] = useState("1");
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);

  const {
    singleSaleOrder,
    isSingleLoading,
    isUpdateLoading,
    isSingleError,
    isUpdateError,
    isSingleSuccess,
    isUpdateSuccess,
    singleMessage,
    updateMessage,
  } = useSelector((state: RootState) => state.saleOrders);

  useEffect(() => {
    if (isSingleError) {
      enqueueSnackbar(singleMessage, { variant: "error" });
    }
    if (isSingleSuccess) {
      setSaleOrderData(singleSaleOrder);
    }
    return () => {
      dispatch(resetSaleOrders());
    };
  }, [isSingleError, isSingleSuccess, singleMessage]);

  useEffect(() => {
    if (isUpdateError) {
      enqueueSnackbar(updateMessage, { variant: "error" });
    }
    if (isUpdateSuccess) {
      enqueueSnackbar(updateMessage, { variant: "success" });
      setIsCompleteModalOpen(false);
      dispatch(
        getSaleOrdersId({
          id: Number(data.id),
        })
      );
    }
    return () => {
      dispatch(resetSaleOrders());
    };
  }, [isUpdateError, isUpdateSuccess, updateMessage]);

  useEffect(() => {
    if (data?.id) {
      dispatch(
        getSaleOrdersId({
          id: Number(data.id),
        })
      );
    }
  }, [data]);

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const onCompleteSaleOrder = () => {
    const patchInfo = {
      id: Number(data.id),
      status: "completed",
    };
    dispatch(updateSaleOrder(patchInfo));
  };

  return (
    <>
      <PageTemplate
        isTransparent
        crumbs={[
          {
            label: "Sale Orders",
            href: "/saleOrders/saleOrders-list",
            depth: -1,
          },
          {
            label: data?.name,
          },
        ]}
      >
        {isSingleLoading ? (
          <Loading />
        ) : (
          <TabContext value={activeTab}>
            <PaperBox>
              <Row>
                <TabList
                  onChange={onTabChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="sale order info tabs"
                >
                  <Tab label="Order Details" value="1" />
                  <Tab label="Documents" value="2" />
                  <Tab label="Shipping Detail" value="3" />
                </TabList>
                {saleOrderData ? (
                  saleOrderData?.status !== "completed" ? (
                    <StyledButton
                      variant="contained"
                      onClick={() => setIsCompleteModalOpen(true)}
                    >
                      Set As Completed
                    </StyledButton>
                  ) : (
                    <BadgeBox>
                      <BadgeIcon>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/icons/${
                              status === "loading" ? "LoadingBlue" : "Check"
                            }.svg`
                          }
                          alt="status"
                        />
                      </BadgeIcon>
                      Completed
                    </BadgeBox>
                  )
                ) : null}
              </Row>
            </PaperBox>

            <TabPanel value="1">
              <SaleOrderDetails data={data} />
            </TabPanel>
            <TabPanel value="2">
              <SaleOrderDocuments data={data} />
            </TabPanel>
            <TabPanel value="3">
              <SaleOrderShippingDetail data={data} />
            </TabPanel>
          </TabContext>
        )}
      </PageTemplate>

      <ConfirmModal
        title={"Complete Sale Order"}
        iconName={"Warning"}
        open={isCompleteModalOpen}
        primaryConfirm
        isLoading={isSingleLoading || isUpdateLoading}
        onDismiss={() => setIsCompleteModalOpen(false)}
        onConfirm={onCompleteSaleOrder}
        confrimText={"Confirm"}
        cancelText={"Cancel"}
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to complete this Sale Order?
        </Typography>
      </ConfirmModal>
    </>
  );
};

const Row = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const BadgeBox = styled(Box)(() => {
  return css`
    padding: 2px 15px 2px 12px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    line-height: 26px;
    font-weight: 400;
    font-size: 13px;
    background-color: ${colors.success[50]};
    color: ${colors.success[700]};
  `;
});

const BadgeIcon = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default SaleOrdersInfo;
