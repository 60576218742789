import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { StyledButton } from "src/components/Button";
import Loading from "src/components/Loading";
import Chat from "src/features/chat/components/Chat";
import colors from "src/theme/colors";
import { EmployeeCard, EmployeeCardProps } from "./EmployeeCard";

interface EmployeeCardsBoxProps {
  loading?: boolean;
  employees: EmployeeCardProps[];
  onAddNewEmployee: () => void;
  onRemoveEmployee: (employeeId: string) => void;
}

export const EmployeeCardsBox: FC<EmployeeCardsBoxProps> = (props) => {
  const { loading, employees, onAddNewEmployee, onRemoveEmployee } = props;

  const [activeEmployee, setActiveEmployee] = useState({
    id: "",
    name: "",
    avatar: "",
  });
  const [isChatOpen, setIsChatOpen] = useState(false);

  return (
    <>
      <Container>
        <Titlebar>
          <Title>Employees</Title>
          <Actions>
            <StyledButton
              color="inherit"
              variant="outlined"
              sx={{ width: "160px" }}
              disableElevation
              onClick={onAddNewEmployee}
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.gray[800]}
              >
                Add Employee
              </Typography>
            </StyledButton>
          </Actions>
        </Titlebar>
        <Content>
          {loading && employees == null ? (
            <Center>
              <Loading />
            </Center>
          ) : employees?.length === 0 ? (
            <Center>Nothing Found</Center>
          ) : (
            employees?.map((employee: EmployeeCardProps) => (
              <StyledEmployeeCard
                key={employee.id}
                cardData={employee}
                onRemoveEmployee={() => onRemoveEmployee(employee.id!)}
                onRedirectChat={() => {
                  setActiveEmployee({
                    id: employee.id!,
                    name: employee?.name,
                    avatar: employee.avatar!,
                  });
                  setIsChatOpen(true);
                }}
              />
            ))
          )}
        </Content>
      </Container>

      <Chat
        userId={activeEmployee?.id}
        name={activeEmployee?.name}
        avatar={activeEmployee?.avatar}
        open={isChatOpen}
        onDismiss={() => setIsChatOpen(false)}
      />
    </>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  border-radius: 4px;
  background-color: ${colors.base.white};
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 14px;
`;

const Titlebar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h6`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: ${colors.gray[850]};
`;

const Actions = styled.div``;

const StyledEmployeeCard = styled(EmployeeCard)`
  margin-bottom: 16px;
`;

const Center = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
