import {
  Box,
  Toolbar,
  AppBar,
  Typography,
  Menu,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  IconButton,
  styled,
} from "@mui/material";
import { LogoutCurve, HambergerMenu } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "src/store";
import { signOut, reset } from "../../features/auth/redux/authSlice";
import useResponsive from "../../hooks/useResponsive";
import { useTranslation } from "react-i18next";
import colors from "../../theme/colors";
import Profile from "./Profile";
import ConfirmModal from "src/components/Modal";
import {
  BreadCrumbItemProps,
  BreadCrumbs,
} from "src/components/BreadCrumbs/BreadCrumbs";
import storage from "src/utils/storage";

type NavbarProps = {
  crumbs?: BreadCrumbItemProps[];
  onOpenSidebar: () => void;
};

const DRAWER_WIDTH = 300;

const Navbar = ({ crumbs, onOpenSidebar }: NavbarProps) => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { token } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!token && !window.location.href.endsWith("/auth/login")) {
      navigate("/auth/login");
    }
  }, [token]);

  const [open, setOpen] = useState(false);
  const handelOpen = (open: boolean) => {
    setOpen(!open);
  };

  const Logout = () => {
    dispatch(reset());
    storage.clearToken();
    storage.clearRefToken();
    navigate("/auth/login");
    // dispatch(signOut());
  };

  const isDesktop = useResponsive("up", "lg");
  const { t } = useTranslation();

  return (
    <StyledAppBar>
      <StyledToolbar>
        <Box pb={12}>
          {!isDesktop && (
            <IconButton onClick={onOpenSidebar}>
              <HambergerMenu size="32" color={colors.gray[750]} />
            </IconButton>
          )}
          {isDesktop && crumbs && <BreadCrumbs crumbs={crumbs} />}
        </Box>
        <Box sx={{ flexGrow: 0 }} pb={12} pr={20}>
          <Box
            display={"flex"}
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ cursor: "pointer" }}
            onClick={handleOpenUserMenu}
          >
            <Profile />
            <ConfirmModal
              title={t("navbar.ModalTitle")}
              iconName={"logout"}
              open={open}
              onDismiss={(open) => handelOpen(open)}
              onConfirm={Logout}
              confrimText={"Logout"}
              cancelText={"cancel"}
            >
              <Typography
                fontSize={14}
                fontWeight={400}
                color={colors.gray[450]}
              >
                {t("navbar.ModalSubtitle")}
              </Typography>
            </ConfirmModal>
          </Box>
          <Menu
            sx={{
              mt: "60px",
              bgcolor: "transparent !important",
              paddingTop: "0px !important",
            }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <StyledListItemButton onClick={() => handelOpen(open)}>
              <StyledListItemIcon>
                <LogoutCurve
                  size="20"
                  color={colors.error[600]}
                  variant="Outline"
                />
              </StyledListItemIcon>
              <StyledListItemText>
                <Typography
                  fontSize={15}
                  fontWeight={500}
                  color={colors.error[600]}
                >
                  {t("navbar.logoutBtn")}
                </Typography>
              </StyledListItemText>
            </StyledListItemButton>
          </Menu>
        </Box>
      </StyledToolbar>
    </StyledAppBar>
  );
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  minHeight: "100px",
  backgroundColor: colors.base.back,
  paddingLeft: "24px",
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
}));
const StyledToolbar = styled(Toolbar)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  min-height: 100px !important;
`;
const StyledListItemButton = styled(ListItemButton)`
  height: 56px;
  width: 200px;
  border: 1px solid ${colors.gray[100]};
  box-shadow: "0px 0px 10px rgba(0, 0, 0, 0.11)";
  border-radius: 3px;
  padding-top: 0px !important;
`;
const StyledListItemText = styled(ListItemText)`
  padding-left: 18px;
`;
const StyledListItemIcon = styled(ListItemIcon)`
  padding-left: 0px;
  min-width: 24px;
`;

export default Navbar;
