import { FC } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import colors from "src/theme/colors";
import { useNavigate } from "react-router-dom";
import FormHeading from "src/components/FormHeading";
import { StyledButton } from "src/components/Button";
import InfoItems from "src/components/InfoItems";
import moment from "moment";
import { PaperBox } from "src/components/Paper/Paper";

interface SaleOrderDetailsProps {
  data: any;
}

export const SaleOrderDetails: FC<SaleOrderDetailsProps> = (props) => {
  const { data } = props;

  const navigate = useNavigate();

  const moveToEdit = () => {
    navigate("/saleOrders/saleOrders-edit", {
      state: { data: data, forwardedFromInfo: true },
    });
  };

  return (
    <Container>
      <PaperBox>
        <Box pb={12}>
          <FormHeading>Public Information</FormHeading>
        </Box>
        <Grid
          container
          rowSpacing={12}
          columnSpacing={{ xs: 0, md: 16 }}
          xs={12}
          md={10}
          mb={14}
        >
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InfoItems
                title="Order Name"
                subTilte={data?.name ?? "Not Found!"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InfoItems
                title="Order ID"
                subTilte={data?.code ?? "Not Found!"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InfoItems
                title="Order Date"
                subTilte={
                  data?.date
                    ? moment(data?.date).format("DD-MMM-YYYY")
                    : "Not Found!"
                }
              />
            </Box>
          </Grid>
        </Grid>
      </PaperBox>

      <PaperBox>
        <Box pb={12}>
          <FormHeading>Customer & Shippment</FormHeading>
        </Box>
        <Grid
          container
          rowSpacing={12}
          columnSpacing={{ xs: 0, md: 16 }}
          xs={12}
          md={10}
          mb={14}
        >
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InfoItems
                title="Customer"
                subTilte={data?.customer?.full_name ?? "Not Found!"}
                description1={data?.customer?.address ?? "Not Found!"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InfoItems
                title="Related Purchase Order"
                subTilte={data?.related_purchase_order?.code ?? "Not Found!"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InfoItems
                title="Origin Warehouse"
                subTilte={data?.origin_warehouse?.title ?? "Not Found!"}
                description1={data?.origin_warehouse?.address ?? "Not Found!"}
                description2={data?.origin_warehouse?.phone ?? "Not Found!"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InfoItems
                title="Port of Loading"
                subTilte={data?.port_of_loading?.title ?? "Not Found!"}
                description1={data?.port_of_loading?.address ?? "Not Found!"}
                description2={data?.port_of_loading?.phone ?? "Not Found!"}
              />
            </Box>
          </Grid>
        </Grid>
      </PaperBox>

      <PaperBox>
        <Box pb={12}>
          <FormHeading>Product Details</FormHeading>
        </Box>
        <Grid
          container
          rowSpacing={12}
          columnSpacing={{ xs: 0, md: 16 }}
          xs={12}
          md={10}
          mb={14}
        >
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InfoItems
                title="Product"
                subTilte={data?.product?.title ?? "Not Found!"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InfoItems
                title="Quantity"
                subTilte={data?.quantity ?? "Not Found!"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InfoItems
                title="Quality"
                subTilte={data?.quality?.title ?? "Not Found!"}
              />
            </Box>
          </Grid>
        </Grid>
      </PaperBox>

      <PaperBox>
        <Box pb={12}>
          <FormHeading>General Terms</FormHeading>
        </Box>
        <Grid
          container
          rowSpacing={12}
          columnSpacing={{ xs: 0, md: 16 }}
          md={10}
          xs={12}
        >
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InfoItems
                title="Delivery Terms"
                subTilte={data?.delivery_terms ?? "Not Found!"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <InfoItems
                title="Delivery Packaging"
                subTilte={data?.delivery_packaging ?? "Not Found!"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width="100%">
              <CheckField>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      color="secondary"
                      checked={data?.allow_partial_shipment}
                    />
                  }
                  label="Allow partial shipment for this order"
                  name="allow_partial_shipment"
                />
              </CheckField>
            </Box>
          </Grid>
        </Grid>
      </PaperBox>

      <Actions width="100%">
        <Box>
          <StyledButton
            variant="contained"
            color="secondary"
            fullWidth
            onClick={moveToEdit}
          >
            <Typography
              fontSize={16}
              fontWeight={500}
              color={colors.base.white}
            >
              Edit Sale Order
            </Typography>
          </StyledButton>
        </Box>
      </Actions>
    </Container>
  );
};

const Container = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  & > div {
    width: 184px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    & > div {
      width: 142px;
    }
  }
`;

const CheckField = styled("div")`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
