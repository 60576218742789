import { Divider, List, Toolbar, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { StyledDrawer } from "../../components/Drawer";
import useResponsive from "../../hooks/useResponsive";
import { useTranslation } from "react-i18next";
import storage from "src/utils/storage";
import MenuItem from "./MenuItem";
import { useEffect, useState } from "react";
import { Permissions } from "src/routes/IPermissions";

const DRAWER_WIDTH = 300;

type sidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
};

const parseJwt = (token: any) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

interface PermissionsList {
  key: string;
  values: any[];
}

export default function Sidebar(props: sidebarProps) {
  const { isOpenSidebar, onCloseSidebar } = props;

  const isDesktop = useResponsive("up", "lg");
  const { t } = useTranslation();
  const token = storage.getToken();
  const [permissions, setPermissions] = useState<PermissionsList[] | []>([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    const permissionList = parseJwt(token)?.permissions;
    const superAdmin = parseJwt(token)?.superadmin;
    setPermissions(permissionList);
    setIsSuperAdmin(superAdmin);
  }, [token]);

  const checkPermission = (permission: Permissions) => {
    const findedPermission = permissions?.find(
      (e: any) => e.key === permission
    );
    const permissionKey = findedPermission?.key;

    if (isSuperAdmin || permissionKey === permission) {
      return true;
    } else {
      return false;
    }
  };

  const isOnIRDomain = window.location.hostname.endsWith(".ir");

  const content = (
    <Box>
      <StyledToolbar>
        <StyledImage
          src={`/assets/images/${isOnIRDomain ? "iranLogo" : "logo"}.svg`}
        />
      </StyledToolbar>
      {token ? (
        <List sx={{ margin: "0px 20px 0px 20px" }}>
          <MenuItem
            name="Dashboard"
            route="/"
            iconName="dashboard"
            navigation="/"
          />

          {isSuperAdmin || checkPermission(Permissions.ORDERS) ? (
            <MenuItem
              name="Purchase Orders"
              route="/orders"
              iconName="purchase"
              navigation="/orders/orders-list"
            />
          ) : null}

          {isSuperAdmin || checkPermission(Permissions.SALEORDERS) ? (
            <MenuItem
              name="Sale Orders"
              route="/saleOrders"
              iconName="saleOrders"
              navigation="/saleOrders/saleOrders-list"
            />
          ) : null}

          {isSuperAdmin ||
          checkPermission(Permissions.WAREHOUSES) ||
          checkPermission(Permissions.STOCKTAKING) ? (
            <MenuItem
              name="Warehouses"
              route="/Warehouses-lists"
              iconName="warehouses"
              navigation=""
              expand
            >
              {checkPermission(Permissions.WAREHOUSES) ? (
                <MenuItem
                  name="Warehouses List"
                  route="/warehouses"
                  navigation="/warehouses/warehouse-list"
                />
              ) : null}
              {checkPermission(Permissions.STOCKTAKING) ? (
                <MenuItem
                  name="Stocktakings List"
                  route="/stocktakings"
                  navigation="/stocktakings/stocktakings-list"
                />
              ) : null}
            </MenuItem>
          ) : null}

          {isSuperAdmin ||
          checkPermission(Permissions.LOCALINSPECTIONS) ||
          checkPermission(Permissions.INTERNATIONALSPECTIONS) ? (
            <MenuItem
              name="inspections-lists"
              route="/inspections-lists"
              iconName="InspectionIcon"
              navigation=""
              expand
            >
              {checkPermission(Permissions.LOCALINSPECTIONS) ? (
                <MenuItem
                  name="Local Inspections"
                  route="/local-inspection"
                  navigation="/local-inspections/local-inspections-list"
                />
              ) : null}

              {checkPermission(Permissions.INTERNATIONALSPECTIONS) ? (
                <MenuItem
                  name="International Inspections"
                  route="/international-inspections"
                  navigation="/international-inspections/international-inspections-list"
                />
              ) : null}
            </MenuItem>
          ) : null}

          <Divider />

          {isSuperAdmin || checkPermission(Permissions.USERS) ? (
            <MenuItem
              name="Employees List"
              route="/employees"
              iconName="users"
              navigation="/employees/employee-list"
            />
          ) : null}

          {isSuperAdmin || checkPermission(Permissions.VENDORS) ? (
            <MenuItem
              name="Vendors"
              route="/vendors"
              iconName="vendors"
              navigation="/vendors/vendors-list"
            />
          ) : null}

          {isSuperAdmin || checkPermission(Permissions.CUSTOMERS) ? (
            <MenuItem
              name="Customers"
              route="/customers"
              iconName="customersIcon"
              navigation="/customers/customers-list"
            />
          ) : null}

          {isSuperAdmin || checkPermission(Permissions.LOGS) ? (
            <MenuItem
              name="Logs"
              route="/logs"
              iconName="statsLog"
              navigation="/logs/logs-list"
            />
          ) : null}

          {isSuperAdmin ||
          checkPermission(Permissions.ROLES) ||
          checkPermission(Permissions.MATERIALS) ||
          checkPermission(Permissions.PRODUCTS) ? (
            <MenuItem
              name="Master Data"
              route="/MasterData"
              iconName="MasterData"
              navigation=""
              expand
            >
              {checkPermission(Permissions.ROLES) ? (
                <MenuItem
                  name="Roles Management"
                  route="/roles-management"
                  navigation="/roles-management/roles-list"
                />
              ) : null}

              {checkPermission(Permissions.MATERIALS) ? (
                <MenuItem
                  name="Materials"
                  route="/materials"
                  navigation="/materials/materials-list"
                />
              ) : null}

              {checkPermission(Permissions.PRODUCTS) ? (
                <MenuItem
                  name="Products"
                  route="/products"
                  navigation="/products/products-list"
                />
              ) : null}
            </MenuItem>
          ) : null}
        </List>
      ) : null}
    </Box>
  );
  return (
    <RootStyle>
      {!isDesktop && (
        <StyledDrawer open={isOpenSidebar} onClose={onCloseSidebar}>
          {content}
        </StyledDrawer>
      )}
      {isDesktop && (
        <StyledDrawer open variant="permanent" anchor="left">
          {content}
        </StyledDrawer>
      )}
    </RootStyle>
  );
}

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 74px !important;
  min-height: 120px !important;
  margin: "0px 12px 0px 6px";
`;

const StyledImage = styled("img")`
  width: 80px;
  height: 80px;
`;
