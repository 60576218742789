import React from "react";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import Loading from "src/components/Loading";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getVendors,
  reset as resetVendors,
} from "src/features/vendors/redux/VendorSlice";
import {
  getItems,
  reset as resetItems,
} from "src/features/items/redux/ItemSlice";
import {
  getWarehouses,
  reset as resetWarehouses,
} from "src/features/warehouses/redux/WarehouseSlice";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { AppDispatch, RootState } from "src/store";
import {
  getQualities,
  reset as resetQualities,
} from "src/features/items/redux/qualitySlice";
import OrderEditForm from "../componets/OrderEditForm";
import { getordersId, reset as resetOrder } from "../redux/orderSlice";

const OrderEdit = () => {
  const location = useLocation();
  const { state } = location as any;
  const [page, setPage] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [orderData, setOrderData] = useState({} as any);
  const [orderDataLoading, setOrderDataLoading] = useState(true);

  const {
    singleorder: singleOrderData,
    isSingleLoading: isOrderDataLoading,
    isError: isOrderDataError,
    isSuccess: isOrderDataSuccess,
    message: orderDataMessage,
  } = useSelector((state: RootState) => state.orders);

  const {
    vendors,
    isError: isVendorsError,
    isSuccess: isVendorsSuccess,
    message: VendorsMessage,
    isLoading: isVendorsLoading,
  } = useSelector((state: RootState) => state.vendor);
  const {
    Warehouses,
    isError: isWarehousesError,
    isSuccess: isWarehousesSuccess,
    message: WarehousesMessage,
    isLoading: isWarehousesLoading,
  } = useSelector((state: RootState) => state.warehouse);
  const {
    items,
    isError: isItemsError,
    isSuccess: isItemsSuccess,
    message: itemsMessage,
    isLoading: isItemsLoading,
  } = useSelector((state: RootState) => state.items);
  const {
    qualities,
    isError: isQualitiesError,
    isSuccess: isQualitiesSuccess,
    message: QualitiesMessage,
    isLoading: isQualitiesLoading,
  } = useSelector((state: RootState) => state.qualities);

  useEffect(() => {
    if (isOrderDataError) {
      enqueueSnackbar(orderDataMessage, { variant: "error" });
    }
    if (isOrderDataSuccess) {
      setOrderData(singleOrderData);
      setOrderDataLoading(false);
    }
    return () => {
      dispatch(resetOrder());
    };
  }, [
    isOrderDataError,
    isOrderDataSuccess,
    orderDataMessage,
    isOrderDataLoading,
  ]);

  useEffect(() => {
    if (isVendorsError) {
      enqueueSnackbar(VendorsMessage, { variant: "error" });
    }
    return () => {
      dispatch(resetVendors());
    };
  }, [isVendorsError, isVendorsSuccess, VendorsMessage, isVendorsLoading]);

  useEffect(() => {
    if (isWarehousesError) {
      enqueueSnackbar(WarehousesMessage, { variant: "error" });
    }
    return () => {
      dispatch(resetWarehouses());
    };
  }, [
    isWarehousesError,
    isWarehousesSuccess,
    WarehousesMessage,
    isWarehousesLoading,
  ]);

  useEffect(() => {
    if (isItemsError) {
      enqueueSnackbar(itemsMessage, { variant: "error" });
    }
    return () => {
      dispatch(resetItems());
    };
  }, [isItemsError, isItemsSuccess, itemsMessage, isItemsLoading]);

  useEffect(() => {
    if (isQualitiesError) {
      enqueueSnackbar(QualitiesMessage, { variant: "error" });
    }
    return () => {
      dispatch(resetQualities());
    };
  }, [
    isQualitiesError,
    isQualitiesSuccess,
    QualitiesMessage,
    isQualitiesLoading,
  ]);

  useEffect(() => {
    dispatch(
      getordersId({
        id: Number(state.orderData?.id),
      })
    );
    dispatch(
      getVendors({
        page: page,
        limit: 100,
      })
    );
    dispatch(
      getWarehouses({
        page: page,
        limit: 100,
      })
    );
    dispatch(
      getItems({
        page: page,
        limit: 100,
      })
    );
    dispatch(
      getQualities({
        page: page,
        limit: 100,
      })
    );

    return () => {
      setOrderDataLoading(true);
    };
  }, []);

  return (
    <PageTemplate
      isTransparent
      crumbs={
        state?.forwardedFromInfo
          ? [
              {
                label: "orders",
                href: "/orders/orders-list",
                depth: -2,
              },
              {
                label: "order info",
                href: "/orders/order-information",
                depth: -1,
              },
              {
                label: "edit order",
              },
            ]
          : [
              {
                label: "orders",
                href: "/orders/orders-list",
                depth: -1,
              },
              {
                label: "edit order",
              },
            ]
      }
    >
      {isWarehousesLoading || isItemsLoading || orderDataLoading ? (
        <Loading />
      ) : (
        <OrderEditForm
          vendors={vendors}
          materials={items}
          warehouses={Warehouses}
          qualities={qualities}
          orderData={orderData}
        />
      )}
    </PageTemplate>
  );
};
export default OrderEdit;
