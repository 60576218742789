import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import React from 'react'
import colors from 'src/theme/colors'

type Props = {
    message: string
    key: number
    time: string
    date?: string
}
const StyledBox = styled("div")`
    background: #EAECEF;
    border-radius: 0px 6px 6px 6px;
    padding: 8px 10px;
    margin: 16px;
    max-width: 60%;
    width: fit-content;
    display: flex;
    flex-direction: column;
`
const MessageLeft = (props: Props) => {
    const { message, key, time, date } = props
    return (
        <StyledBox key={key}>
            <Typography fontWeight={400} fontSize={14} color={colors.gray[850]}>
                {message}
            </Typography>
            <Typography fontWeight={400} fontSize={8} color={colors.gray[850]} textAlign="end">
                {date}
                {"  "}
                {time}
            </Typography>
        </StyledBox>
    )
}

export default MessageLeft