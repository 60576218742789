import { FC } from "react";
import { Box, Modal, styled } from "@mui/material";
import colors from "src/theme/colors";
import { ContactNewForm } from "../ContactNewForm";

interface NewContactModalProps {
  open: boolean;
  customerId: string;
  onDismiss: () => void;
}

export const NewContactModal: FC<NewContactModalProps> = (props) => {
  const { open, customerId, onDismiss } = props;

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Wrapper>
        <Box
          sx={{
            marginBottom: "28px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <EditImg src={process.env.PUBLIC_URL + `/assets/icons/Contact.svg`} />
          <Title>Customer - Add New Contact</Title>
        </Box>

        <ContactNewForm customerId={customerId} onCancel={onDismiss} />
        <CloseIcon onClick={onDismiss}>
          <img src={process.env.PUBLIC_URL + `/assets/icons/${"close"}.svg`} />
        </CloseIcon>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "820px",
  minWidth: 460,
  maxWidth: 820,
  height: "fit-content",
  maxHeight: "95vh",
  backgroundColor: colors.base.white,
  border: `1px solid ${colors.gray[200]}`,
  borderRadius: "5px",
  padding: "42px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  overflow: "auto",
  "& > form": {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    minWidth: "auto",
  },
}));

const EditImg = styled("img")`
  width: 48px;
  height: 48px;
`;

const Title = styled("h6")`
  margin-top: 14px;
  font-size: 18px;
  font-weight: 700;
  color: ${colors.gray[850]};
`;

const CloseIcon = styled(Box)`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 4;
  cursor: pointer;
`;
