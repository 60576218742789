import { axios } from "../../../config/Axios";

//inspections/local get
const getLocalInspections = async (data: any) => {
  const response = await axios.get(
    `/inspections/local?page=${data.page}${
      data?.search ? `&q=${data?.search}` : ""
    }`
  );
  return response;
};
//inspections/local EXPORT
const exportLocalInspections = async () => {
  const response = await axios({
    url: `/inspections/local?export_excel=true`,
    method: "get",
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${Date.now()}.xlsx`);
  document.body.appendChild(link);
  link.click();

  return response;
};
//inspections/local get by id
const getLocalInspectionsId = async (data: number) => {
  const response = await axios.get(`/inspections/local/${data}`);
  return response;
};
//inspections/local submit
const postLocalInspections = async (data: any) => {
  const response = await axios.post("/inspections/local", data);
  return response;
};
//inspections/local patch
const patchLocalInspections = async (data: any) => {
  const response = await axios.patch(`/inspections/local/${data.id}`, data);
  return response;
};
//inspections/local delete
const deleteLocalInspections = async (data: any) => {
  const response = await axios.delete(`/inspections/local`, { data: data });
  return response;
};
//inspection/local approve
const approveLocalInspections = async (data: any) => {
  const response = await axios.post(
    `/inspections/local/${data?.id}/approve`,
    data
  );
  return response;
};
//inspection/local reject
const rejectLocalInspections = async (data: any) => {
  const response = await axios.post(
    `/inspections/local/${data?.id}/reject`,
    data
  );
  return response;
};
//inspection/local request add
const addLocalInspection = async (data: any) => {
  const response = await axios.post(`/inspections/local`, data);
  return response;
};

const LocalInspectionservices = {
  getLocalInspections,
  exportLocalInspections,
  getLocalInspectionsId,
  postLocalInspections,
  patchLocalInspections,
  deleteLocalInspections,
  approveLocalInspections,
  rejectLocalInspections,
  addLocalInspection,
};
export default LocalInspectionservices;
