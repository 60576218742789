import { Divider, IconButton, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { StyledButton } from "src/components/Button";
import IconItem from "src/components/IconItem";
import { StyledTextField } from "src/components/Input";
import { AppDispatch } from "src/store";
import colors from "src/theme/colors";

interface Contacts {
  address: string | null;
  id: string;
  mobile: string | null;
  name: string | null;
  phone: string | null;
  title: string | null;
  email?: string | null;
}

export interface ContactEditEventProps {
  id: string;
  name: string;
}

interface Props {
  Contacts: Contacts[];
  Id: string;
  onEditContact: (itemData: Contacts) => void;
  onDeleteContact: (id: string) => void;
  onCreateNewContact: () => void;
}

const CustomerInfoContact = (props: Props) => {
  const { onEditContact, onDeleteContact, onCreateNewContact } = props;

  const [Data, setData] = useState(props.Contacts);
  const [Filter, setFilter] = useState("");

  useEffect(() => {
    if (Filter !== "") {
      const results = Data.filter((item: Contacts) => {
        return (
          item?.name !== null &&
          item?.name.toLowerCase().startsWith(Filter.toLowerCase())
        );
      });
      setData(results);
    } else {
      setData(props.Contacts);
    }
  }, [Filter]);

  const dispatch = useDispatch<AppDispatch>();

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent="space-between"
        gap={4}
      >
        <StyledTextField
          placeholder="Search"
          fullWidth
          onChange={(e) => setFilter(e.target.value)}
          size="medium"
        />
        <Box width={"200px"} height="100%">
          <StyledButton
            variant="contained"
            fullWidth
            color="secondary"
            size="large"
            onClick={onCreateNewContact}
            sx={{ height: 56 }}
          >
            <Typography fontSize={16} fontWeight={500} noWrap>
              New Contact
            </Typography>
          </StyledButton>
        </Box>
      </Box>
      {Data?.map((item: Contacts) => {
        return (
          <StyledCard key={item.id}>
            <Box
              display={"flex"}
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              width={"100%"}
            >
              <Box display={"flex"}>
                <Typography
                  fontWeight={500}
                  fontSize={16}
                  color={colors.gray[850]}
                >
                  {item.name}
                </Typography>
              </Box>
              <Box display={"flex"} flexDirection="row" alignItems="center">
                <IconButton onClick={() => onEditContact(item)}>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/icons/Edit.svg"}
                    alt="edit"
                  />
                </IconButton>
                <IconButton onClick={() => onDeleteContact(item.id)}>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/icons/Remove.svg"}
                    alt="delete"
                  />
                </IconButton>
              </Box>
            </Box>
            <Divider sx={{ borderColor: colors.gray[100] }} />
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent="space-between"
            >
              <IconItem title={item?.mobile} iconName="ItemMobile" />
              <IconItem title={item?.phone} iconName="ItemPhone" />
              <IconItem title={item.email} iconName="ItemEmail" />
            </Box>
            <Box>
              <IconItem title={item.address} iconName="ItemAddress" />
            </Box>
          </StyledCard>
        );
      })}
    </>
  );
};

export default CustomerInfoContact;

const StyledCard = styled("div")`
  background: ${colors.base.white};
  border: 1px solid ${colors.gray[100]};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 24px;
  gap: 16px;
  margin-top: 14px;
`;
