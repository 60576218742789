import { axios } from "../../../config/Axios";
import storage from "../../../utils/storage";

import {
  forgetValue,
  resetValue,
  signinValue,
  resendValue,
  checkDataValue,
} from "./types";

const API_URL = "/auth/";

//signIn
const signIn = async (singInData: signinValue) => {
  const response = await axios.post(API_URL + "signin", singInData);
  if (response?.data?.returnCode === 200) {
    storage.setToken(response?.data?.results?.access_token);
    storage.setRefToken(response?.data?.results?.refresh_token);
  }
  return response;
};

//me
const me = async () => {
  const response = await axios.get(API_URL + "me");
  return response;
};

//refresh
const refresh = async () => {
  const response = await axios.post(API_URL + "refresh");
  return response;
};

//forget-password
const forgetPassword = async (forgetPasswordData: forgetValue) => {
  const response = await axios.post(
    API_URL + "forgot-password",
    forgetPasswordData
  );
  return response;
};

//check-token
const checkToken = async (checkData: checkDataValue) => {
  const response = await axios.post(API_URL + "check-token", checkData);
  return response;
};

//reset-password
const resetPassword = async (resetPasswordData: resetValue) => {
  const response = await axios.post(
    API_URL + "reset-password",
    resetPasswordData
  );
  return response;
};

//resend-token
const resendToken = async (resendTokenData: resendValue) => {
  const response = await axios.post(API_URL + "resend-token", resendTokenData);
  return response;
};

//signOut
const signOut = async () => {
  const response = await axios.post(API_URL + "signout");
  storage.clearToken();
  storage.clearRefToken();
  return response;
};

const authService = {
  forgetPassword,
  resetPassword,
  resendToken,
  checkToken,
  refresh,
  signOut,
  signIn,
  me,
};

export default authService;
