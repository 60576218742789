import styled from "@emotion/styled";
import { Menu, MenuItem, IconButton } from "@mui/material";
import { FC, MouseEvent, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import colors from "src/theme/colors";

interface WarehouseDetailsCardProps {
  title: string;
  code: string;
  phone: string;
  address: string;
  onEditWarehouse: () => void;
}

export const WarehouseDetailsCard: FC<WarehouseDetailsCardProps> = (props) => {
  const { title, code, phone, address, onEditWarehouse } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <Content>
        <TitleBox>
          <img src={process.env.PUBLIC_URL + "/assets/icons/Warehouse.svg"} />
          <Titles>
            <Title>{title}</Title>
            <SubTitle>{code}</SubTitle>
          </Titles>
        </TitleBox>
        <InfoBox>
          <Text>
            <Icon>
              <img src={process.env.PUBLIC_URL + "/assets/icons/Phone.svg"} />
            </Icon>
            {phone}
          </Text>
          <Text>
            <Icon>
              <img src={process.env.PUBLIC_URL + "/assets/icons/Map.svg"} />
            </Icon>
            {address}
          </Text>
        </InfoBox>
      </Content>
      <div>
        <MenuIcon>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </MenuIcon>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "20ch",
            },
          }}
        >
          <MenuItem key={1} onClick={onEditWarehouse} sx={{ p: 6 }}>
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Edit.svg"}
              alt="edit"
            />
            <span style={{ marginLeft: "8px" }}>Edit Information</span>
          </MenuItem>
        </Menu>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 32px;
  border-radius: 4px;
  margin-bottom: 16px;
  background-color: ${colors.base.white};
`;

const Content = styled.div`
  width: calc(100% - 140px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 58px;
`;

const Titles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
`;

const Title = styled.h4`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
  color: ${colors.gray[850]};
`;

const SubTitle = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray[700]};
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Text = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray[700]};
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const MenuIcon = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-color: ${colors.gray[200]};
`;
