import { Box, Grid, Typography } from "@mui/material";
import FormHeading from "src/components/FormHeading";
import FormSubHeading from "src/components/FormSubHeading";
import FormInputLable from "src/components/FormInputLabel";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPermissions, postRoles, reset } from "../redux/roleSlice";
import { StyledFormControlLabel } from "src/components/FormControlLabel";
import CustomCheckbox from "src/components/Checkbox";
import colors from "src/theme/colors";
import { StyledTextField } from "src/components/Input";
import { CanselButton } from "src/components/CanselButton";
import { StyledButton } from "src/components/Button";
import Loading from "src/components/Loading";
import { PaperBox } from "src/components/Paper/Paper";

const RoleForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [name, setName] = useState("");
  const [checked, setChecked] = useState([] as Array<number>);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [permissionData, setPermissionData] = useState([] as any);
  const navigate = useNavigate();

  const {
    permissions,
    isPermissionsError,
    isPermissionsSuccess,
    permissionsMessage,
    isPermissionsLoading,
  } = useSelector((state: RootState) => state.role);

  useEffect(() => {
    if (isPermissionsError) {
      enqueueSnackbar(permissionsMessage, { variant: "error" });
    }
    if (isPermissionsSuccess) {
      if (permissionsMessage) {
        enqueueSnackbar(permissionsMessage, { variant: "success" });
        navigate("/roles-management/roles-list");
      } else {
        setPermissionData(permissions);
      }
    }
    return () => {
      dispatch(reset());
    };
  }, [
    isPermissionsError,
    isPermissionsLoading,
    isPermissionsSuccess,
    permissionsMessage,
  ]);

  useEffect(() => {
    dispatch(getPermissions());
  }, []);

  const handleChecked = (key: any) => {
    const CheckedItems = checked;
    if (!CheckedItems.includes(key)) {
      CheckedItems.push(key);
    } else {
      CheckedItems.splice(CheckedItems.indexOf(key), 1);
    }
    setChecked(CheckedItems);
  };

  const onSubmit = () => {
    const roleValue = {
      title: name,
      permissions: checked,
    };
    dispatch(postRoles(roleValue));
  };

  return (
    <>
      {isPermissionsLoading ? (
        <Loading />
      ) : (
        <Box>
          <PaperBox>
            <Grid container>
              <Grid md={6} sm={12}>
                <Box pb={10}>
                  <FormHeading>{t("role.roleNew.heading")}</FormHeading>
                </Box>
                <FormInputLable noPrefix>
                  {t("role.roleNew.inputLable")}
                </FormInputLable>
                <StyledTextField
                  placeholder="Enter role name"
                  fullWidth
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
            </Grid>
          </PaperBox>

          <PaperBox>
            <Box>
              <FormHeading>{t("role.roleNew.permissions")}</FormHeading>
            </Box>
            <Grid container>
              <Grid md={8} sm={12}>
                {permissionData.length === 0 ? (
                  <Loading />
                ) : (
                  permissionData.map((item: any, index: any) => {
                    return (
                      <Box pt={16} key={index}>
                        <FormSubHeading>{item.key} :</FormSubHeading>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          rowGap={3}
                          columnGap={7}
                          pt={8}
                          flexWrap="wrap"
                        >
                          {item?.values?.map((innerItem: any) => {
                            return (
                              <StyledFormControlLabel
                                key={innerItem?.id}
                                control={
                                  <CustomCheckbox
                                    onClick={() => handleChecked(innerItem?.id)}
                                  />
                                }
                                label={
                                  <Typography
                                    fontSize="14"
                                    fontWeight="400"
                                    color={colors.gray[850]}
                                  >
                                    {innerItem?.description}
                                  </Typography>
                                }
                              />
                            );
                          })}
                        </Box>
                      </Box>
                    );
                  })
                )}
              </Grid>
            </Grid>
          </PaperBox>

          <Box display="flex" mt={12} flexDirection="row" alignItems="center">
            <Box width="168">
              <CanselButton
                onClick={() => navigate("/roles-management/roles-list")}
              >
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color={colors.gray[850]}
                >
                  {t("role.roleNew.cancelbtn")}
                </Typography>
              </CanselButton>
            </Box>
            <Box width="176px" marginLeft={12}>
              <StyledButton
                variant="contained"
                color="primary"
                fullWidth
                disabled={
                  checked.length === 0 && name.length === 0 ? true : false
                }
                onClick={onSubmit}
              >
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color={colors.gray[850]}
                >
                  {t("role.roleNew.submitbtn")}
                </Typography>
              </StyledButton>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default RoleForm;
