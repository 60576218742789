import { useLocation } from "react-router-dom";
import { PageTemplate } from "src/layout/components/PageTemplate";
import RoleEditForm from "../components/RoleEditForm";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import {
  getPermissions,
  getRolesId,
  reset as resetRole,
} from "../redux/roleSlice";
import Loading from "src/components/Loading";

interface CustomizedState {
  id: string;
  permissions?: any;
  title?: any;
  forwardedFromInfo?: boolean;
}

const RoleEdit = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const { id, permissions: statePermissions, forwardedFromInfo } = state;

  const [permissionData, setPermissionData] = useState([] as any);
  const [roleData, setRoleData] = useState([] as any);
  const [roleDataLoading, setRoleDataLoading] = useState(true);

  const {
    singleRole: singleRoleData,
    permissions,
    isSingleLoading,
    isPermissionsLoading,
    isSingleError,
    isPermissionsError,
    isSingleSuccess,
    isPermissionsSuccess,
    singleMessage,
    permissionsMessage,
  } = useSelector((state: RootState) => state.role);

  useEffect(() => {
    if (isSingleError) {
      enqueueSnackbar(singleMessage, { variant: "error" });
    }
    if (isSingleSuccess) {
      setRoleData(singleRoleData);
      setRoleDataLoading(false);
    }
  }, [isSingleError, isSingleSuccess, singleMessage, dispatch]);

  useEffect(() => {
    if (isPermissionsError) {
      enqueueSnackbar(permissionsMessage, { variant: "error" });
    }
    if (isPermissionsSuccess) {
      setPermissionData(permissions);
    }
  }, [isPermissionsError, isPermissionsSuccess, permissionsMessage, dispatch]);

  useEffect(() => {
    dispatch(
      getRolesId({
        id: Number(id),
      })
    );
    dispatch(getPermissions());
  }, []);

  return (
    <PageTemplate
      crumbs={
        forwardedFromInfo
          ? [
              {
                label: "Role Management",
                href: "/roles/roles-list",
                depth: -2,
              },
              {
                label: "Role info",
                href: "/roles-management/role-information",
                depth: -1,
              },
              {
                label: "edit Role",
              },
            ]
          : [
              {
                label: "roles",
                href: "/roles/roles-list",
                depth: -1,
              },
              {
                label: "edit role",
              },
            ]
      }
    >
      {roleDataLoading || isPermissionsLoading ? (
        <Loading />
      ) : (
        <RoleEditForm
          id={id}
          title={roleData?.title}
          rolePermissions={statePermissions}
          permissionData={permissions}
        />
      )}
    </PageTemplate>
  );
};

export default RoleEdit;
