import { useTheme } from "@emotion/react";
import { Typography, Box, Avatar, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import storage from "../../utils/storage";
import { me, reset } from "../../features/auth/redux/authSlice";
import { AppDispatch, RootState } from "../../store";
import colors from "../../theme/colors";
import { User } from "iconsax-react";

const token = storage.getToken();
const Profile = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [userData, setUserData] = useState([] as any);
  const { t } = useTranslation();

  useMemo(() => {
    dispatch(me());
  }, [token]);
  const { enqueueSnackbar } = useSnackbar();
  const { user, isError, isSuccess, message, isLoading } = useSelector(
    (state: RootState) => state.auth
  );

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      setUserData(user);
    }
    dispatch(reset());
  }, [isError, isLoading, isSuccess, message, navigate, dispatch]);

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems={"center"}
        >
          <Box pr={2.5}>
            {!userData?.avatar ? (
              <Avatar
                sx={{ height: 56, width: 56 }}
                src={userData?.avatar}
                alt={userData?.name}
              />
            ) : (
              <Avatar sx={{ height: 56, width: 56 }}>
                <User variant="Bold" size={32} />
              </Avatar>
            )}
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="center">
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Typography
                color={colors.gray[850]}
                fontWeight={500}
                fontSize={16}
              >
                {userData?.name}
              </Typography>
              {userData?.roles ? (
                <Typography
                  color={colors.gray[600]}
                  fontWeight={400}
                  fontSize={13}
                >
                  {userData?.roles[0]?.title}
                </Typography>
              ) : null}
            </Box>
            <Box pl={5}>
              <img
                src={process.env.PUBLIC_URL + "/assets/icons/arrowdown.svg"}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default React.memo(Profile);
