import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import LocalInspectionServices from "./LocalInspectionServices";

const initialState = {
  isError: false,
  isExportError: false,
  isDeleteError: false,
  isAddRequestError: false,
  isSuccess: false,
  isExportSuccess: false,
  isDeleteSuccess: false,
  isAddRequestSuccess: false,
  isLoading: true,
  isExportLoading: false,
  isDeleteLoading: false,
  isAddRequestLoading: false,
  message: "",
  exportMessage: "",
  addRequestMessage: "",
  deleteMessage: "",
  LocalInspections: [],
  LocalInspectionsMeta: [],
  singleLocalInspection: [],
};

export const getLocalInspections = createAsyncThunk(
  "LocalInspections",
  async (data: any, thunkAPI) => {
    try {
      return await LocalInspectionServices.getLocalInspections(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const exportLocalInspections = createAsyncThunk(
  "LocalInspections/export",
  async (data: any, thunkAPI) => {
    try {
      return await LocalInspectionServices.exportLocalInspections();
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getLocalInspectionsId = createAsyncThunk(
  "LocalInspections/id",
  async (data: any, thunkAPI) => {
    try {
      return LocalInspectionServices.getLocalInspectionsId(data.id);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const postLocalInspection = createAsyncThunk(
  "LocalInspections/post",
  async (data: any, thunkAPI) => {
    try {
      return await LocalInspectionServices.postLocalInspections(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const patchLocalInspection = createAsyncThunk(
  "LocalInspections/patch",
  async (data: any, thunkAPI) => {
    try {
      return await LocalInspectionServices.patchLocalInspections(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteLocalInspection = createAsyncThunk(
  "LocalInspections/delete",
  async (data: any, thunkAPI) => {
    try {
      return await LocalInspectionServices.deleteLocalInspections(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const approveLocalInspections = createAsyncThunk(
  "LocalInspections/approve",
  async (data: any, thunkAPI) => {
    try {
      return await LocalInspectionServices.approveLocalInspections(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const rejectLocalInspections = createAsyncThunk(
  "LocalInspections/reject",
  async (data: any, thunkAPI) => {
    try {
      return await LocalInspectionServices.rejectLocalInspections(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const addLocalInspection = createAsyncThunk(
  "LocalInspections/add",
  async (data: any, thunkAPI) => {
    try {
      return await LocalInspectionServices.addLocalInspection(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const LocalInspectionSlice = createSlice({
  name: "LocalInspection",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isDeleteLoading = false;
      state.isExportLoading = false;
      state.isAddRequestLoading = false;
      state.isSuccess = false;
      state.isDeleteSuccess = false;
      state.isExportSuccess = false;
      state.isAddRequestSuccess = false;
      state.isError = false;
      state.isDeleteError = false;
      state.isExportError = false;
      state.isAddRequestError = false;
      state.message = "";
      state.exportMessage = "";
      state.addRequestMessage = "";
      state.deleteMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocalInspections.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLocalInspections.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.LocalInspections = action.payload.data.results;
        state.LocalInspectionsMeta = action.payload.data.meta;
      })
      .addCase(getLocalInspections.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })
      .addCase(exportLocalInspections.pending, (state) => {
        state.isExportLoading = true;
      })
      .addCase(exportLocalInspections.fulfilled, (state, action) => {
        state.isExportLoading = false;
        state.isExportSuccess = true;
      })
      .addCase(exportLocalInspections.rejected, (state, action) => {
        state.isExportLoading = false;
        state.isExportError = true;
        state.exportMessage = `${action.payload}`;
      })

      .addCase(getLocalInspectionsId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLocalInspectionsId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.singleLocalInspection = action.payload.data.results;
      })

      .addCase(getLocalInspectionsId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })
      .addCase(postLocalInspection.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postLocalInspection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(postLocalInspection.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(patchLocalInspection.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patchLocalInspection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(patchLocalInspection.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(deleteLocalInspection.pending, (state) => {
        state.isDeleteLoading = true;
      })
      .addCase(deleteLocalInspection.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteSuccess = true;
        state.deleteMessage = `${action.payload.data.message}`;
      })
      .addCase(deleteLocalInspection.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.deleteMessage = `${action.payload}`;
      })

      .addCase(approveLocalInspections.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(approveLocalInspections.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(approveLocalInspections.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })
      .addCase(rejectLocalInspections.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(rejectLocalInspections.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(rejectLocalInspections.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(addLocalInspection.pending, (state) => {
        state.isAddRequestLoading = true;
      })
      .addCase(addLocalInspection.fulfilled, (state, action) => {
        state.isAddRequestLoading = false;
        state.isAddRequestSuccess = true;
        state.addRequestMessage = `${action.payload.data.message}`;
      })
      .addCase(addLocalInspection.rejected, (state, action) => {
        state.isAddRequestLoading = false;
        state.isAddRequestError = true;
        state.addRequestMessage = `${action.payload}`;
      });
  },
});

export const { reset } = LocalInspectionSlice.actions;
export default LocalInspectionSlice.reducer;
