import { axios } from "../../../config/Axios";

//Products get
const getProducts = async (data: any) => {
  const response = await axios.get(
    `/products?page=${data.page}&${
      data.title ? `title=${data.title}` : ""
    }&limit=${data.limit ? data.limit : ""}&${
      data.isPackage === true
        ? "is_package=1"
        : data.isPackage === false
        ? "is_package=0"
        : ""
    }&${
      data.isCountable === true
        ? "is_countable=1"
        : data.isCountable === false
        ? "is_countable=0"
        : ""
    }
    &${
      data.isProduct === true
        ? "is_product=1"
        : data.isProduct === false
        ? "is_product=0"
        : ""
    }&warehouse_id=${data.warehouse_id ? data.warehouse_id : ""}`
  );
  return response;
};
// EXPORT Products list
const exportProducts = async (data: any) => {
  const response = await axios({
    url: `/products?export_excel=true`,
    method: "get",
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${Date.now()}.xlsx`);
  document.body.appendChild(link);
  link.click();

  return response;
};
//Products get by id
const getProduct = async (id: number) => {
  const response = await axios.get(`/products/${id}`);
  return response;
};
//Products add new
const postProduct = async (data: any) => {
  const response = await axios.post(`/products`, data);
  return response;
};
//Products update
const patchProduct = async (data: any) => {
  const response = await axios.post(`/products/${data.id}`, data.data);
  return response;
};
//Products delete
const deleteProduct = async (product_ids: number[]) => {
  const response = await axios.delete(`/products/${product_ids}`);
  return response;
};
// search Products
const serachProducts = async (data: any) => {
  const response = await axios.get(
    `/warehouses/${data.warehouseId}/search-products`,
    {
      params: {
        ...data,
      },
    }
  );
  return response;
};
// EXPORT searched Products
const exportSerachProducts = async (data: any) => {
  const response = await axios({
    url: `/warehouses/${data.warehouseId}/search-products?export_excel=true`,
    method: "get",
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${Date.now()}.xlsx`);
  document.body.appendChild(link);
  link.click();

  return response;
};
// Product details
const getProductDetails = async (data: any) => {
  const response = await axios.get(
    `/warehouses/${data.warehouseId}/productItem/${data.productId}`,
    data
  );
  return response;
};
// get variants
const getIndexVariants = async () => {
  const response = await axios.get(`/products/variants`);
  return response;
};

const ProductsServices = {
  getProducts,
  exportProducts,
  getProduct,
  postProduct,
  patchProduct,
  deleteProduct,
  serachProducts,
  exportSerachProducts,
  getProductDetails,
  getIndexVariants,
};
export default ProductsServices;
