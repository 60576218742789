import { Typography, Box } from "@mui/material";
import React from "react";
import colors from "src/theme/colors";

const RecentVendor = () => {
  return (
    <div>
      <Typography color={colors.gray[900]} fontWeight="500" fontSize={18}>
        RecentVendor
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="center" mt={8}>
        <img src={process.env.PUBLIC_URL + "/assets/icons/Membr.svg"} />
        <Typography
          color={colors.gray[900]}
          fontWeight="500"
          fontSize={14}
          pl={5}
        >
          Benny Chagur
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" mt={8}>
        <img src={process.env.PUBLIC_URL + "/assets/icons/Membr.svg"} />

        <Typography
          color={colors.gray[900]}
          fontWeight="500"
          fontSize={14}
          pl={5}
        >
          Chynita Heree
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" mt={8}>
        <img src={process.env.PUBLIC_URL + "/assets/icons/Membr.svg"} />

        <Typography
          color={colors.gray[900]}
          fontWeight="500"
          fontSize={14}
          pl={5}
        >
          David Yers
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" mt={8}>
        <img src={process.env.PUBLIC_URL + "/assets/icons/Membr.svg"} />

        <Typography
          color={colors.gray[900]}
          fontWeight="500"
          fontSize={14}
          pl={5}
        >
          Hayder Jahid
        </Typography>
      </Box>
    </div>
  );
};

export default RecentVendor;
