export default {
  10: "#F1FBFF",
  50: "#E3F6FF",
  100: "#B8EAFF",
  200: "#8ADCFF",
  300: "#5CCEFD",
  400: "#3EC5F8",
  500: "#34BBF2",
  600: "#2AABDD",
  700: "#1797C2",
  800: "#0084A8",
  900: "#00627A",
};
