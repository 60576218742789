import { styled } from "@mui/material/styles";
import { Button, ButtonProps } from "@mui/material";
import colors from "src/theme/colors";

export const CanselButton = (props: ButtonProps) => {
  return <CustomButton {...props} />;
};

const CustomButton = styled(Button)`
  height: 48px;
  border-radius: 5px;
  border: 1px solid ${colors.gray[500]};
  width: 136px;
`;
