import { Box, Typography, Button } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import { FC } from "react";
import colors from "src/theme/colors";
import { useMemo, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { defaultAcceptedFileTypes, nameLengthValidator } from "./helpers";
import {
  acceptStyle,
  activeStyle,
  defaultTypeBaseStyle,
  rejectStyle,
} from "../zoneStyles";
import { UploaderSlidesCarousel } from "../UploaderSlidesCarousel/UploaderSlidesCarousel";
import styled from "@emotion/styled";
import { FileUploaderType } from "../FileUploader";
import { useDeleteFile } from "../useDeleteFile";
import ConfirmModal from "src/components/Modal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import { useSnackbar } from "notistack";
import {
  addSaleOrderDocuments,
  reset as resetSaleDocuments,
} from "src/features/saleOrders/redux/SaleOrdersSlice";

export interface UploadedFileType {
  id?: string;
  path?: string;
  url: string;
  lastModified?: number;
  lastModifiedDate?: Date;
  name: string;
  size?: number;
  mime: string;
  tag?: string;
}

interface LinearFileUploadZoneProps {
  type: FileUploaderType;
  rootID: number;
  allFiles: UploadedFileType[] | [];
  files: UploadedFileType[] | [];
  isAddLoading?: boolean;
  acceptedTypes?: any;
  maxFiles?: number;
  isChecked: boolean;
  tag: string;
  deleteEndPoint?: string;
  setFiles: React.Dispatch<React.SetStateAction<UploadedFileType[]>>;
  onAddNewFiles: (addData: any) => void;
}

export const LinearFileUploadZone: FC<LinearFileUploadZoneProps> = (props) => {
  const {
    type,
    rootID,
    allFiles,
    files,
    isAddLoading,
    acceptedTypes,
    maxFiles,
    isChecked,
    tag,
    deleteEndPoint,
    setFiles,
    onAddNewFiles,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, deleteFileFromApi] = useDeleteFile();
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: acceptedTypes ?? defaultAcceptedFileTypes(),
    noClick: true,
    noKeyboard: true,
    maxFiles: maxFiles,
    validator: nameLengthValidator,
    onDrop: (acceptedFiles) => {
      const addData = new FormData();
      addData.append("type", tag);
      addData.append("downloadable", isChecked ? "1" : "0");
      if (acceptedFiles.length > 0) {
        acceptedFiles.forEach((file, index) =>
          addData.append(`files[${index}]`, file as any, file.name)
        );
      }
      onAddNewFiles(addData);
    },
  });

  const baseStyle = defaultTypeBaseStyle;

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const onDeleteLocalFile = () => {
    if (currentSlide != null) {
      const filteredFiles = allFiles.filter(
        (fl) => fl.id !== files[currentSlide].id
      );
      setFiles(filteredFiles);
      if (currentSlide !== 0) {
        setCurrentSlide((prev) => prev - 1);
      }
    }
  };

  const onDeleteServerFile = () => {
    const fileId = files[currentSlide].id;
    if (deleteEndPoint && fileId) {
      deleteFileFromApi(onDeleteLocalFile, deleteEndPoint, fileId);
      setIsModalOpen(false);
    }
  };

  return (
    <>
      <Container isMultiple={true}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <>
            <IconBox>
              <img
                src={process.env.PUBLIC_URL + "/assets/icons/DragAndDrop.svg"}
              />
            </IconBox>
            <Title as="span">Drag file here</Title>
            <Title as="span">Or</Title>
            <UploadButton
              variant="text"
              color="warning"
              size="small"
              onClick={open}
            >
              Browse File
            </UploadButton>
            <SubTitle>
              You can add up to {maxFiles} Files, each not exceeding 15MB.
            </SubTitle>
          </>

          <UploaderSlidesCarousel
            currentSlide={files[currentSlide]}
            currentSlideNumber={currentSlide + 1}
            files={files}
            uploadedSlides={files.length}
            max={maxFiles}
            addLoading={isAddLoading}
            deleteLoading={loading}
            onBrowse={open}
            isPrevDisabled={currentSlide <= 0}
            isNextDisabled={currentSlide >= files.length - 1}
            onPrev={() => setCurrentSlide((prev) => prev - 1)}
            onNext={() => setCurrentSlide((prev) => prev + 1)}
            onDeleteSlide={() => setIsModalOpen(true)}
          />
        </div>
      </Container>

      <ConfirmModal
        title={"Remove File"}
        iconName={"ModalRemove"}
        open={isModalOpen}
        onDismiss={() => setIsModalOpen(false)}
        onConfirm={onDeleteServerFile}
        confrimText={"Remove"}
        cancelText={"Cancel"}
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to remove these Files?
        </Typography>
      </ConfirmModal>
    </>
  );
};

interface StyledProps {
  isMultiple?: boolean;
}

const Container = styled(Box)<StyledProps>(({ isMultiple }) => ({
  width: "100%",
  height: isMultiple ? "calc(100% - 48px)" : "100%",
  display: "flex",
  position: "relative",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 4,
}));

const IconBox = muiStyled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "4px",
});

const Title = muiStyled(Typography)({
  fontSize: "13px",
  lineHeight: "22px",
  textAlign: "center",
  color: colors.gray[750],
});

const SubTitle = muiStyled(Typography)({
  fontSize: "13px",
  color: colors.gray[850],
  textAlign: "center",
});

const FileName = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.base.white};
  color: ${colors.secondary[700]};
  font-weight: bold;
  user-select: none;
`;

const OpenLink = muiStyled(Typography)({
  cursor: "pointer",
  fontSize: "13px",
  color: colors.primary[800],
});

const SingleUploadedBox = muiStyled(Typography)({
  position: "relative",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const UploadButton = muiStyled(Button)({
  marginBottom: "16px",
  borderRadius: 0,
  borderBottom: `1px dashed ${colors.primary[800]}`,
  color: colors.primary[800],
  fontSize: "13px",
  ":hover": {
    backgroundColor: colors.primary[50],
  },
});
