import Axios, { AxiosRequestConfig } from "axios";
import https from "https";

import { API_BASE } from "./Api";
import storage from "../utils/storage";

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getToken();
  config.headers = config.headers ?? {};
  if (token) {
    config.headers.authorization = "Bearer " + token;
  }
  config.headers.Accept = "application/json";
  config.httpsAgent = new https.Agent({ rejectUnauthorized: false });
  return config;
}

export const axios = Axios.create({
  baseURL: API_BASE,
});

axios.interceptors.request.use(authRequestInterceptor);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Prevent infinite loops
    if (
      (error.response.status === 401 || error.response.status === 400) &&
      originalRequest.url === "/auth/refresh"
    ) {
      window.location.replace("/auth/login");
      return Promise.reject(error);
    } else {
      if (error.response.status === 401) {
        if (storage.getRefToken() != null && storage.getRefToken() != "") {
          return axios
            .post("/auth/refresh", {
              refresh_token: storage.getRefToken(),
            })
            .then((response) => {
              storage.setToken(response?.data?.results?.access_token);
              storage.setRefToken(response?.data?.results?.refresh_token);

              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${response.data?.results?.access_token}`;
              error.config.headers["Authorization"] =
                "Bearer " + response.data?.results?.access_token;

              error.hasRefreshedToken = true;
              return axios.request(error.config);
            })
            .catch((ref_error) => {
              if (ref_error.response.status !== 400) {
                const tokenError = new Error("User authentication failed");
                tokenError.cause = ref_error;
                storage.clearToken();
                storage.clearRefToken();
                window.location.replace("/auth/login");
                return Promise.reject(tokenError);
              } else {
                return Promise.reject(ref_error);
              }
            });
        } else {
          storage.clearToken();
          storage.clearRefToken();
          window.location.replace("/auth/login");
          return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    }
  }
);
