import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import WarehouseServices from "./WarehouseServices";

const initialState = {
  isError: false,
  isCreateError: false,
  isDeleteError: false,
  isSuccess: false,
  isCreateSuccess: false,
  isDeleteSuccess: false,
  isLoading: false,
  isCreateLoading: false,
  isDeleteLoading: false,
  message: "",
  createMessage: "",
  deleteMessage: "",
  placements: [],
  placementsMeta: [],
};

export const getWarehousePlacements = createAsyncThunk(
  "Placements",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.getWarehousePlacements(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const postWarehousePlacement = createAsyncThunk(
  "Placements/create",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.postWarehousePlacement(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteWarehousePlacement = createAsyncThunk(
  "Placements/delete",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.deleteWarehousePlacement(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const PlacementSlice = createSlice({
  name: "Placements",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isCreateError = false;
      state.isDeleteError = false;
      state.isLoading = false;
      state.isCreateLoading = false;
      state.isDeleteLoading = false;
      state.isSuccess = false;
      state.isCreateSuccess = false;
      state.isDeleteSuccess = false;
      state.message = "";
      state.createMessage = "";
      state.deleteMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWarehousePlacements.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWarehousePlacements.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.placements = action.payload.data.results;
        state.placementsMeta = action.payload.data.meta;
      })
      .addCase(getWarehousePlacements.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(postWarehousePlacement.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(postWarehousePlacement.fulfilled, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateSuccess = true;
        state.createMessage = `${action.payload.data.message}`;
      })
      .addCase(postWarehousePlacement.rejected, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateError = true;
        state.createMessage = `${action.payload}`;
      })

      .addCase(deleteWarehousePlacement.pending, (state) => {
        state.isDeleteLoading = true;
      })
      .addCase(deleteWarehousePlacement.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteSuccess = true;
        state.deleteMessage = `${action.payload.data.message}`;
      })
      .addCase(deleteWarehousePlacement.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.deleteMessage = `${action.payload}`;
      });
  },
});

export const { reset } = PlacementSlice.actions;
export default PlacementSlice.reducer;
