/* eslint-disable react/no-unescaped-entities */
import { FC } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormHeading from "src/components/FormHeading";
import FormInputLable from "src/components/FormInputLabel";
import { StyledTextField } from "src/components/Input";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import * as Yup from "yup";
import { StyledDatePicker } from "src/components/DatePicker/DatePicker";
import { GrayButton } from "src/components/GrayButton";
import moment from "moment";
import {
  serachProducts,
  reset as resetSerachProducts,
} from "../../../products/redux/ProductsSlice";

interface Props {
  warehouseId: string;
  products: any;
  onSearch: (data: any) => void;
}

export const InventoriesSearchForm: FC<Props> = (props) => {
  const { warehouseId, products, onSearch } = props;

  const [showSoldProducts, setShowSoldProducts] = useState(false);

  //formik---------------------------------
  const ItemSchema = Yup.object().shape({
    product: Yup.string(),
    barcode: Yup.string(),
    startDate: Yup.date().nullable(),
    endDate: Yup.date().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      product: "",
      barcode: "",
      startDate: null,
      endDate: null,
    },
    validationSchema: ItemSchema,

    onSubmit: async (values: any) => {
      const postData = {
        warehouseId: warehouseId,
        product: values.product ? Number(values.product) : null,
        barcode: values.barcode,
        from: values.startDate
          ? moment(values.startDate.$d).format("YYYY-MM-DDTHH:mm:ss")
          : null,
        to: values.endDate
          ? moment(values.endDate.$d).format("YYYY-MM-DDTHH:mm:ss")
          : null,
        show_sold_products: showSoldProducts === true ? 1 : 0,
      };

      onSearch(postData);
    },
  });

  const { errors, touched, values, handleSubmit, resetForm, getFieldProps } =
    formik;

  //redux ---------------------------------
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const { isSearchLoading, isError, isSearchSuccess, searchProductsMessage } =
    useSelector((state: RootState) => state.products);
  //redux ---------------------------------

  const onReset = () => {
    resetForm();
  };

  return (
    <Container>
      <FormikProvider value={formik}>
        <FormContainer>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box mb={12}>
              <FormHeading>Search Inputs</FormHeading>
            </Box>
            <FieldsContainer>
              <Row width="100%">
                <Box
                  mb={16}
                  mr={{
                    md: 20,
                    sm: 0,
                  }}
                >
                  <FormInputLable noPrefix>Product</FormInputLable>
                  <StyledTextField
                    select
                    fullWidth
                    {...getFieldProps("product")}
                    placeholder="Select a product"
                    error={Boolean(touched.product && errors.product)}
                    helperText={touched.product && errors.product}
                    onChange={formik.handleChange("product")}
                    defaultValue={""}
                    SelectProps={{
                      multiple: false,
                    }}
                  >
                    {products?.map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.title}
                        </MenuItem>
                      );
                    })}
                  </StyledTextField>
                </Box>
                <Box mb={16}>
                  <FormInputLable noPrefix>Barcode</FormInputLable>
                  <StyledTextField
                    placeholder="Enter product barcode"
                    fullWidth
                    {...getFieldProps("barcode")}
                    error={Boolean(touched.barcode && errors.barcode)}
                    helperText={touched.barcode && errors.barcode}
                  />
                </Box>
              </Row>

              <Row width="100%">
                <Box
                  mb={16}
                  mr={{
                    md: 20,
                    sm: 0,
                  }}
                >
                  <FormInputLable noPrefix>Date From</FormInputLable>
                  <StyledDatePicker
                    placeholder="Enter or select date"
                    {...getFieldProps("startDate")}
                    // fullWidth
                    value={formik.values.startDate}
                    error={Boolean(touched.startDate && errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                    onChange={(newValue: Date | null) =>
                      formik.setFieldValue("startDate", newValue)
                    }
                  />
                </Box>
                <Box mb={16}>
                  <FormInputLable noPrefix>Date To</FormInputLable>
                  <StyledDatePicker
                    placeholder="Enter or select date"
                    {...getFieldProps("endDate")}
                    // fullWidth
                    value={formik.values.endDate}
                    error={Boolean(touched.endDate && errors.endDate)}
                    helperText={touched.endDate && errors.endDate}
                    onChange={(newValue: Date | null) =>
                      formik.setFieldValue("endDate", newValue)
                    }
                  />
                </Box>
              </Row>

              <CheckField>
                <FormControlLabel
                  label="Show the sold products of the warehouse."
                  control={
                    <Checkbox
                      checked={showSoldProducts}
                      onChange={(e) => setShowSoldProducts(e.target.checked)}
                      name="sold products"
                    />
                  }
                />
              </CheckField>
            </FieldsContainer>

            <Actions width="100%">
              <Box>
                <GrayButton
                  variant="outlined"
                  fullWidth
                  disabled={isSearchLoading}
                  onClick={onReset}
                >
                  Reset
                </GrayButton>
              </Box>
              <Box marginLeft={12}>
                <StyledLoadingButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  loading={isSearchLoading}
                  type="submit"
                >
                  <Typography
                    fontSize={16}
                    fontWeight={500}
                    color={colors.gray[850]}
                  >
                    Confirm
                  </Typography>
                </StyledLoadingButton>
              </Box>
            </Actions>
          </Form>
        </FormContainer>
      </FormikProvider>
    </Container>
  );
};

const Container = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
`;

const FormContainer = styled("div")`
  width: 792px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
  & > form {
    width: 100%;
  }
`;

const FieldsContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CheckField = styled("div")`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: -16px;
`;

const Row = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > div {
    width: 50%;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    flex-wrap: wrap;
    & > div {
      width: 100%;
    }
  }
`;

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  & > div {
    width: 152px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    & > div {
      width: 122px;
    }
  }
`;
