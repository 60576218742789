import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import React from 'react'
import colors from 'src/theme/colors'

type Props = {
    message: string
    key: number
    time: string
    date?: string
}
const StyledBox = styled("div")`
   background: #61CFAA;
    border-radius:  6px 6px 0px 6px;
    padding: 8px 10px;
    margin: 8px 16px;
    max-width: 60%;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`
const MessageRight = (props: Props) => {
    const { message, key, time, date } = props
    return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <StyledBox key={key}>
                <Typography fontWeight={400} fontSize={14} color={colors.gray[850]}>
                    {message}
                </Typography>
                <Typography fontWeight={400} fontSize={8} color={colors.gray[850]} textAlign="end">
                    {date}
                    {"  "}
                    {time}
                </Typography>
            </StyledBox>
        </div>

    )
}

export default MessageRight