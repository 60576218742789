import { FC, useEffect, useState } from "react";
import { Box, Modal, styled, Typography } from "@mui/material";
import FormInputLable from "src/components/FormInputLabel";
import { StyledTextField } from "src/components/Input";
import { StyledLoadingButton } from "src/components/LoadingButton";
import colors from "src/theme/colors";
import { GrayButton } from "src/components/GrayButton";

interface EditContainerNumberModalProps {
  open: boolean;
  loading?: boolean;
  defaultNumber: string;
  onAddVariant: (reg: string) => void;
  onDismiss: () => void;
}

export const EditContainerNumberModal: FC<EditContainerNumberModalProps> = (
  props
) => {
  const { open, loading, defaultNumber, onAddVariant, onDismiss } = props;

  const [containerNumber, setContainerNumber] = useState(defaultNumber ?? "");

  const onAdd = () => {
    onAddVariant(containerNumber);
  };

  useEffect(() => {
    if (open) {
      setContainerNumber(defaultNumber);
    } else {
      setContainerNumber("");
    }

    return () => setContainerNumber("");
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Wrapper>
        <Box
          sx={{
            marginBottom: "28px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              `/assets/icons/saleOrderShipment/ContainerAdd.svg`
            }
          />
          <Title>Edit Container Number</Title>
        </Box>

        <FieldsContainer>
          <Box width="100%">
            <FormInputLable noPrefix>Container Number</FormInputLable>
            <StyledTextField
              placeholder="Enter Container number"
              fullWidth
              value={containerNumber}
              defaultValue={defaultNumber}
              onChange={(e) => setContainerNumber(e.target.value)}
            />
          </Box>
        </FieldsContainer>

        <Actions>
          <Box>
            <GrayButton
              variant="outlined"
              fullWidth
              disabled={loading}
              onClick={onDismiss}
            >
              Cancel
            </GrayButton>
          </Box>
          <Box>
            <StyledLoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={loading}
              disabled={!containerNumber}
              onClick={onAdd}
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.midnight[850]}
              >
                Confirm
              </Typography>
            </StyledLoadingButton>
          </Box>
        </Actions>
        <CloseIcon onClick={onDismiss}>
          <img src={process.env.PUBLIC_URL + `/assets/icons/${"close"}.svg`} />
        </CloseIcon>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  minWidth: 600,
  maxWidth: 600,
  height: "fit-content",
  maxHeight: "95vh",
  backgroundColor: colors.base.white,
  border: `1px solid ${colors.gray[200]}`,
  borderRadius: "5px",
  padding: "42px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  overflow: "auto",
  [theme.breakpoints.down("md")]: {
    width: "90%",
    minWidth: "auto",
    padding: "42px 24px",
  },
}));

const CloseIcon = styled(Box)`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 4;
  cursor: pointer;
`;

const Title = styled("h6")`
  margin-top: 14px;
  font-size: 18px;
  font-weight: 700;
  color: ${colors.midnight[850]};
`;

const SubTitle = styled("p")`
  text-align: center;
  margin-top: 24px;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  color: ${colors.midnight[850]};
`;

const FieldsContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Actions = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  gap: 24px;
  width: 100%;
  & > div {
    width: 500%;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    gap: 14px;
    & > div {
      width: 100%;
    }
  }
`;
