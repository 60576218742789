import { Route, Routes } from "react-router-dom";
import ProductsTable from "../pages/ProductsTable";
import ProductsNew from "../pages/ProductsNew";
import ProductsEdit from "../pages/ProductsEdit";
import ProductsInfo from "../pages/ProductsInfo";

export const ProductsRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/products-list" element={<ProductsTable />} />
        <Route path="/product-new" element={<ProductsNew />} />
        <Route path="/product-edit" element={<ProductsEdit />} />
        <Route path="/product-information" element={<ProductsInfo />} />
      </Routes>
    </>
  );
};
