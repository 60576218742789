import { useState } from "react";
import { Box, Typography, Modal, IconButton } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import { UploadedFileType } from "../UploadZone/UploadZone";
import { MultiFilesViewer } from "./MultiFilesViewer/MultiFilesViewer";
import colors from "src/theme/colors";
import styled from "@emotion/styled";
import { downloadURLFile } from "src/utils/downloadURLFile";
import { useSnackbar } from "notistack";

export enum FileUploaderViewerSingleFileType {
  Document,
  Image,
}

export enum FileUploaderViewerType {
  Multiple,
  Single,
}

interface FileUploaderViewerMultiProps {
  type: FileUploaderViewerType.Multiple;
  files: UploadedFileType[];
}

interface FileUploaderViewerSingleImageProps {
  type: FileUploaderViewerType.Single;
  singleFileType: FileUploaderViewerSingleFileType.Image;
  file: string;
}
interface FileUploaderViewerSingleDocumentProps {
  type: FileUploaderViewerType.Single;
  singleFileType: FileUploaderViewerSingleFileType.Document;
  file: UploadedFileType;
}

type FileUploaderViewerSingleProps =
  | FileUploaderViewerSingleImageProps
  | FileUploaderViewerSingleDocumentProps;

type FileUploaderViewerProps =
  | FileUploaderViewerMultiProps
  | FileUploaderViewerSingleProps;

export const FileUploaderViewer = (props: FileUploaderViewerProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (props?.type === FileUploaderViewerType.Multiple) {
    return (
      <>
        <ModalToggler
          sx={
            props?.files && props?.files?.length > 0
              ? { opacity: 1, cursor: "pointer" }
              : { opacity: 0.6, cursor: "no-drop" }
          }
          onClick={() =>
            props?.files && props?.files?.length > 0
              ? setIsModalOpen(true)
              : undefined
          }
        >
          <UploadIcon>
            <img src={process.env.PUBLIC_URL + `/assets/icons/Expand.svg`} />
          </UploadIcon>
          <Title>Show Files</Title>
          <Badge sx={{ marginLeft: "6px" }}>
            {props?.files ? props?.files.length : 0}
          </Badge>
        </ModalToggler>
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MultiWrapper>
            <CloseIcon onClick={() => setIsModalOpen(false)}>
              <img src={process.env.PUBLIC_URL + `/assets/icons/close.svg`} />
            </CloseIcon>
            <MultiUploader>
              <MultiFilesViewer
                currentSlide={props?.files ? props?.files[currentSlide] : null}
                uploadedSlides={props?.files?.length}
                isPrevDisabled={currentSlide <= 0}
                isNextDisabled={currentSlide >= props?.files?.length - 1}
                onPrev={() => setCurrentSlide((prev) => prev - 1)}
                onNext={() => setCurrentSlide((prev) => prev + 1)}
              />
            </MultiUploader>
            <DownloadBtn
              onClick={() =>
                downloadURLFile(
                  props?.files && props?.files[currentSlide]
                    ? props?.files[currentSlide]?.url
                    : "",
                  props?.files && props?.files[currentSlide]
                    ? props?.files[currentSlide]?.name
                    : "",
                  () =>
                    enqueueSnackbar("Failed to download File", {
                      variant: "error",
                    })
                )
              }
            >
              <IconButton sx={{ width: "38px" }}>
                <img
                  src={
                    process.env.PUBLIC_URL + `/assets/icons/DownloadBlue.svg`
                  }
                />
              </IconButton>
            </DownloadBtn>
          </MultiWrapper>
        </Modal>
      </>
    );
  } else {
    return (
      <SingleFilesViewer>
        {props?.singleFileType === FileUploaderViewerSingleFileType.Image ? (
          props?.file != null ? (
            <FilePreview src={props?.file} />
          ) : (
            <NoFile>no File attached</NoFile>
          )
        ) : props?.file != null ? (
          <FileName>{props?.file?.name}</FileName>
        ) : (
          <NoFile>no File attached</NoFile>
        )}
      </SingleFilesViewer>
    );
  }
};

const SingleFilesViewer = muiStyled(Box)({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 4,
});

const MultiWrapper = muiStyled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  minWidth: "340px",
  backgroundColor: colors.base.white,
  border: `1px solid ${colors.gray[200]}`,
  borderRadius: "5px",
  padding: "52px 36px 36px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
});

const ModalToggler = muiStyled(Box)({
  width: "230px",
  height: "48px",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "5px",
  border: `1px solid ${colors.gray[200]}`,
});

const Badge = muiStyled(Box)({
  width: "25px",
  height: "25px",
  borderRadius: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: colors.secondary[800],
  color: colors.base.white,
});

const DownloadBtn = muiStyled(Box)({
  position: "absolute",
  bottom: "12px",
  left: "12px",
  zIndex: 4,
  cursor: "pointer",
});

const UploadIcon = muiStyled(Box)({
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "10px",
  color: colors.gray[750],
});

const MultiUploader = muiStyled(Box)({
  width: "320px",
  height: "290px",
  marginBottom: "24px",
});

const Title = muiStyled(Typography)({
  fontSize: "15px",
  fontWeight: 600,
  lineHeight: "20px",
  textAlign: "center",
  color: colors.gray[750],
});

const NoFile = muiStyled(Box)({
  width: "100%",
  height: "100%",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: `1px solid ${colors.gray[200]}`,
  color: colors.gray[600],
});

const CloseIcon = muiStyled(Box)({
  position: "absolute",
  top: "12px",
  right: "12px",
  zIndex: 4,
  cursor: "pointer",
});

const FilePreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: ${colors.base.white};
  border: 1px solid ${colors.gray[200]};
  user-select: none;
`;

const FileName = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.base.white};
  color: ${colors.secondary[700]};
  font-weight: bold;
  user-select: none;
`;
