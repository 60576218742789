import { FC } from "react";
import {
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormHeading from "src/components/FormHeading";
import FormInputLable from "src/components/FormInputLabel";
import { StyledTextField } from "src/components/Input";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { StyledTextFieldMulti } from "src/components/MultilineInput";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import * as Yup from "yup";
import { postorder, reset } from "../redux/orderSlice";
import { StyledDatePicker } from "src/components/DatePicker/DatePicker";
import {
  FileUploader,
  FileUploaderType,
} from "src/components/FileUploader/FileUploader";
import { UploadedFileType } from "src/components/FileUploader/UploadZone/UploadZone";
import {
  OrderNewActionsModal,
  OrderNewActionsModalType,
} from "./modals/OrderNewActionsModal";
import { PaperBox } from "src/components/Paper/Paper";
import { GrayButton } from "src/components/GrayButton";

interface Props {
  vendors: any;
  warehouses: any;
  materials: any;
  qualities: any;
}

const OrderNewForm: FC<Props> = (props) => {
  const navigate = useNavigate();

  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState<boolean>(false);
  const [files, setFiles] = useState<UploadedFileType[]>([]);

  //formik---------------------------------
  const ItemSchema = Yup.object().shape({
    vendor: Yup.number().required(),
    code: Yup.string().required(),
    warehouse: Yup.number().required(),
    deliveryTime: Yup.string(),
    material: Yup.number().required(),
    quantity: Yup.string().required(),
    quality: Yup.number().required().nullable(),
    notes: Yup.string(),
    orderDate: Yup.date().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      vendor: "",
      code: "",
      warehouse: "",
      deliveryTime: "",
      material: "1",
      quantity: "",
      quality: "",
      notes: "",
      orderDate: null,
    },
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: ItemSchema,

    onSubmit: async (values: any) => {
      const formData = new FormData();
      formData.append("vendor_id", values.vendor);
      formData.append("code", `PO-${values.code}`);
      formData.append("warehouse_id", values.warehouse);
      formData.append("delivery_timeframe", values.deliveryTime);
      formData.append("material_id", values.material);
      formData.append("quantity", values.quantity);
      formData.append("quality_id", values.quality);
      formData.append("notes", values.notes);
      formData.append(
        "date",
        values.orderDate ? values.orderDate.$d.toISOString().slice(0, 10) : ""
      );
      if (files.length > 0) {
        files.forEach((file, index) =>
          formData.append(`files[${index}]`, file as any, file.name)
        );
      }

      dispatch(postorder(formData));
    },
  });

  const { errors, touched, values, isValid, handleSubmit, getFieldProps } =
    formik;

  //redux ---------------------------------
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const { isCreateLoading, isCreateError, isCreateSuccess, createMessage } =
    useSelector((state: RootState) => state.orders);
  //redux ---------------------------------

  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar(createMessage, { variant: "error" });
    }
    if (isCreateSuccess) {
      enqueueSnackbar(createMessage, { variant: "success" });
      navigate("/orders/orders-list");
      setIsSubmitModalOpen(false);
    }
    return () => {
      dispatch(reset());
    };
  }, [isCreateError, isCreateSuccess, createMessage, dispatch]);

  const [vendors, setVendors] = useState(props.vendors);
  const [warehouses, setWarehouses] = useState(props.warehouses);
  const [materials, setMaterials] = useState(props.materials);
  const [qualities, setQualities] = useState(
    props.materials ? props.materials[0]?.qualities : []
  );

  useEffect(() => {
    setVendors(props.vendors);
    setWarehouses(props.warehouses);
    setMaterials(props.materials);
    setQualities(props.materials ? props.materials[0]?.qualities : []);
  }, [props]);

  useEffect(() => {
    if (values?.material) {
      const selectedMaterialObject = materials.find(
        (mt: any) => mt.id === values?.material
      );
      setQualities(selectedMaterialObject?.qualities ?? []);
    }
  }, [values?.material]);

  const orderNewActionsValues = {
    orderId: `PO-${values.code}`,
    orderQuantity: values.quantity,
    materialName: materials?.find(
      (material: any) => material.id === values.material
    )?.name,
    warehouseName: warehouses?.find(
      (warehouse: any) => warehouse.id === values.warehouse
    )?.title,
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <PaperBox>
            <Box mb={12}>
              <FormHeading>Vendor Information</FormHeading>
            </Box>
            <Grid
              container
              rowSpacing={12}
              columnSpacing={{ sm: 0, md: 20 }}
              md={10}
              sm={12}
            >
              <Grid item md={6} sm={12}>
                <FormInputLable requried>Vendor</FormInputLable>
                <StyledTextField
                  select
                  placeholder="Select a Vendor"
                  fullWidth
                  {...getFieldProps("vendor")}
                  error={Boolean(touched.vendor && errors.vendor)}
                  helperText={touched.vendor && errors.vendor}
                  onChange={formik.handleChange("vendor")}
                  defaultValue={[]}
                  SelectProps={{
                    multiple: false,
                  }}
                >
                  {vendors?.map((item: any) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.code}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </Grid>
              <Grid item md={6} sm={12}>
                <FormInputLable requried>Order ID</FormInputLable>
                <StyledTextField
                  placeholder="Enter a Code"
                  fullWidth
                  {...getFieldProps("code")}
                  error={Boolean(touched.code && errors.code)}
                  helperText={touched.code && errors.code}
                />
              </Grid>
            </Grid>
          </PaperBox>

          <PaperBox>
            <Box mb={12}>
              <FormHeading>Destination Warehouse</FormHeading>
            </Box>
            <Grid
              container
              rowSpacing={12}
              columnSpacing={{ sm: 0, md: 20 }}
              md={10}
              sm={12}
            >
              <Grid item md={6} sm={12}>
                <FormInputLable requried>Destination Warehouse</FormInputLable>
                <StyledTextField
                  select
                  fullWidth
                  placeholder="Select a warehouse"
                  error={Boolean(touched.warehouse && errors.warehouse)}
                  helperText={touched.warehouse && errors.warehouse}
                  {...getFieldProps("warehouse")}
                  onChange={formik.handleChange("warehouse")}
                  defaultValue={[]}
                  SelectProps={{
                    multiple: false,
                  }}
                >
                  {warehouses?.map((item: any) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.title}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </Grid>
              <Grid item md={6} sm={12}>
                <FormInputLable>Delivery Time Frame</FormInputLable>
                <StyledTextField
                  placeholder="Enter order delivery time frame"
                  fullWidth
                  {...getFieldProps("deliveryTime")}
                  error={Boolean(touched.deliveryTime && errors.deliveryTime)}
                  helperText={touched.deliveryTime && errors.deliveryTime}
                />
              </Grid>
            </Grid>
          </PaperBox>

          <PaperBox>
            <Box mb={12}>
              <FormHeading>Material Details</FormHeading>
            </Box>
            <Grid
              container
              rowSpacing={12}
              columnSpacing={{ sm: 0, md: 20 }}
              md={10}
              sm={12}
            >
              <Grid item md={6} sm={12}>
                <FormInputLable requried>Default Material</FormInputLable>
                <StyledTextField
                  select
                  fullWidth
                  placeholder="Select the Material"
                  error={Boolean(touched.material && errors.material)}
                  helperText={touched.material && errors.material}
                  {...getFieldProps("material")}
                  onChange={formik.handleChange("material")}
                  defaultValue={[]}
                  SelectProps={{
                    multiple: false,
                  }}
                >
                  {materials?.map((item: any) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </Grid>
              <Grid item md={6} sm={12}>
                <FormInputLable requried>Quantity</FormInputLable>
                <StyledTextField
                  placeholder="Enter item Quantity"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          src={
                            process.env.PUBLIC_URL + `/assets/icons/MTIcon.svg`
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                  {...getFieldProps("quantity")}
                  error={Boolean(touched.quantity && errors.quantity)}
                  helperText={touched.quantity && errors.quantity}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <FormInputLable requried>Quality</FormInputLable>
                <StyledTextField
                  select
                  placeholder="Select or add  item quality"
                  fullWidth
                  {...getFieldProps("quality")}
                  error={Boolean(touched.quality && errors.quality)}
                  helperText={touched.quality && errors.quality}
                  onChange={formik.handleChange("quality")}
                  defaultValue={[]}
                  SelectProps={{
                    multiple: false,
                  }}
                >
                  {qualities?.map((item: any) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.title}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </Grid>
            </Grid>
          </PaperBox>

          <PaperBox>
            <Box mb={12}>
              <FormHeading>Notes & Files</FormHeading>
            </Box>
            <Grid
              container
              rowSpacing={12}
              columnSpacing={{ sm: 0, md: 20 }}
              md={10}
              sm={12}
            >
              <Grid item md={6} sm={12}>
                <FormInputLable>Notes</FormInputLable>
                <StyledTextFieldMulti
                  placeholder="Write something..."
                  fullWidth
                  multiline
                  {...getFieldProps("notes")}
                  error={Boolean(touched.notes && errors.notes)}
                  helperText={touched.notes && errors.notes}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <Grid item md={12} sm={12} mb={16}>
                  <FormInputLable>Order Date</FormInputLable>
                  <StyledDatePicker
                    placeholder="Enter order delivery time frame"
                    {...getFieldProps("orderDate")}
                    value={formik.values.orderDate}
                    error={Boolean(touched.orderDate && errors.orderDate)}
                    helperText={touched.orderDate && errors.orderDate}
                    onChange={(newValue: Date | null) =>
                      formik.setFieldValue("orderDate", newValue)
                    }
                  />
                </Grid>
                <Grid item md={12} sm={12}>
                  <FormInputLable>Files</FormInputLable>
                  <FileUploader
                    type={FileUploaderType.Multiple}
                    maxFiles={5}
                    files={files}
                    setFiles={setFiles}
                  />
                </Grid>
              </Grid>
            </Grid>
          </PaperBox>

          <Actions width="100%">
            <Box>
              <GrayButton
                variant="outlined"
                fullWidth
                disabled={isCreateLoading}
                onClick={() => setIsCancelModalOpen(true)}
              >
                Cancel
              </GrayButton>
            </Box>
            <Box marginLeft={12}>
              <StyledLoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={isCreateLoading}
                onClick={() => {
                  if (isValid) {
                    setIsSubmitModalOpen(true);
                  } else {
                    handleSubmit();
                  }
                }}
              >
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color={colors.gray[850]}
                >
                  Save and Send
                </Typography>
              </StyledLoadingButton>
            </Box>
          </Actions>
        </Form>
      </FormikProvider>

      <OrderNewActionsModal
        type={OrderNewActionsModalType.Cancel}
        {...orderNewActionsValues}
        open={isCancelModalOpen}
        loading={isCreateLoading}
        onCacel={() => setIsCancelModalOpen(false)}
        onHandle={() => navigate("/Orders/Orders-list")}
        onDismiss={() => setIsCancelModalOpen(false)}
      />
      <OrderNewActionsModal
        type={OrderNewActionsModalType.Submit}
        {...orderNewActionsValues}
        open={isSubmitModalOpen}
        loading={isCreateLoading}
        onCacel={() => setIsSubmitModalOpen(false)}
        onHandle={handleSubmit}
        onDismiss={() => setIsSubmitModalOpen(false)}
      />
    </>
  );
};

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  & > div {
    width: 184px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    & > div {
      width: 142px;
    }
  }
`;

export default OrderNewForm;
