import { Button, styled } from "@mui/material";
import { PickersActionBarProps } from "@mui/x-date-pickers/PickersActionBar";
import { FC } from "react";

export const CustomActionBar: FC<PickersActionBarProps> = (props) => {
  const { onClear, onCancel, onSetToday } = props;

  return (
    <Container>
      <Button variant="text" size="small" color="warning" onClick={onClear}>
        Clear
      </Button>
      <Button
        variant="text"
        size="small"
        color="secondary"
        onClick={onSetToday}
      >
        Today
      </Button>
      <Button variant="text" size="small" color="error" onClick={onCancel}>
        Cancel
      </Button>
    </Container>
  );
};

const Container = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 12px;
`;
