import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import roleServices from "./roleServices";
import {
  deleteValue,
  getData,
  getIdValue,
  patchValue,
  roleValue,
} from "./types";

const initialState = {
  isError: false,
  isSingleError: false,
  isDeleteError: false,
  isPermissionsError: false,
  isSuccess: false,
  isSingleSuccess: false,
  isDeleteSuccess: false,
  isPermissionsSuccess: false,
  isLoading: true,
  isSingleLoading: true,
  isDeleteLoading: true,
  isPermissionsLoading: true,
  message: "",
  deleteMessage: "",
  singleMessage: "",
  permissionsMessage: "",
  roles: [],
  rolesMeta: [],
  permissions: [],
  singleRole: [],
};

export const getPermissions = createAsyncThunk(
  "permissions",
  async (_, thunkAPI) => {
    try {
      return await roleServices.getPermissions();
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getRoles = createAsyncThunk(
  "roles",
  async (data: getData, thunkAPI) => {
    try {
      return roleServices.getRoles(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getRolesId = createAsyncThunk(
  "roles/id",
  async (data: getIdValue, thunkAPI) => {
    try {
      return roleServices.getRolesId(data.id);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const postRoles = createAsyncThunk(
  "roles/post",
  async (data: roleValue, thunkAPI) => {
    try {
      return await roleServices.postRoles(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const patchRoles = createAsyncThunk(
  "roles/patch",
  async (data: patchValue, thunkAPI) => {
    try {
      return await roleServices.patchRoles(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteRoles = createAsyncThunk(
  "roles/delete",
  async (data: deleteValue, thunkAPI) => {
    try {
      return await roleServices.deleteRoles(data.id);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isDeleteLoading = false;
      state.isSingleLoading = false;
      state.isPermissionsLoading = false;
      state.isSuccess = false;
      state.isDeleteSuccess = false;
      state.isSingleSuccess = false;
      state.isPermissionsSuccess = false;
      state.isError = false;
      state.isDeleteError = false;
      state.isSingleError = false;
      state.isPermissionsError = false;
      state.message = "";
      state.deleteMessage = "";
      state.singleMessage = "";
      state.permissionsMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPermissions.pending, (state) => {
        state.isPermissionsLoading = true;
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.isPermissionsLoading = false;
        state.isPermissionsSuccess = true;
        state.permissions = action.payload.data.results;
      })
      .addCase(getPermissions.rejected, (state, action) => {
        state.isPermissionsLoading = false;
        state.isPermissionsError = true;
        state.permissionsMessage = `${action.payload}`;
      })

      .addCase(getRoles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.roles = action.payload.data.results;
        state.rolesMeta = action.payload.data.meta;
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(postRoles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postRoles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(postRoles.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(patchRoles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patchRoles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(patchRoles.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(deleteRoles.pending, (state) => {
        state.isDeleteLoading = true;
      })
      .addCase(deleteRoles.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteSuccess = true;
        state.deleteMessage = `${action.payload.data.message}`;
      })
      .addCase(deleteRoles.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.deleteMessage = `${action.payload}`;
      })

      .addCase(getRolesId.pending, (state) => {
        state.isSingleLoading = true;
      })
      .addCase(getRolesId.fulfilled, (state, action) => {
        state.isSingleLoading = false;
        state.isSingleSuccess = true;
        state.singleRole = action.payload.data.results;
      })
      .addCase(getRolesId.rejected, (state, action) => {
        state.isSingleLoading = false;
        state.isSingleError = true;
        state.singleMessage = `${action.payload}`;
      });
  },
});

export const { reset } = roleSlice.actions;
export default roleSlice.reducer;
