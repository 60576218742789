/* eslint-disable react/no-unescaped-entities */
import { FC, useEffect, useState } from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import colors from "src/theme/colors";
import { useLocation, useNavigate } from "react-router-dom";
import FormHeading from "src/components/FormHeading";
import { StyledButton } from "src/components/Button";
import InfoItems from "src/components/InfoItems";
import {
  FileUploaderViewer,
  FileUploaderViewerType,
} from "src/components/FileUploader/FileUploaderViewer/FileUploaderViewer";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { AppDispatch, RootState } from "src/store";
import { useDispatch, useSelector } from "react-redux";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import { deleteCustomer, deleteContact, reset } from "../redux/CustomersSlice";
import ConfirmModal from "src/components/Modal";
import CustomersInfoContact, {
  ContactEditEventProps,
} from "./CustomersInfoContact";
import { EditContactModal } from "./modals/EditContactModal";
import { NewContactModal } from "./modals/NewContactModal";
import { useSnackbar } from "notistack";
import { UploadedFileType } from "src/components/FileUploader/LinearFileUploader/LinearUploadZone";
import { PaperBox } from "src/components/Paper/Paper";

export interface Contacts {
  address: string | null;
  id: string;
  mobile: string | null;
  name: string | null;
  phone: string | null;
  title: string | null;
}

interface Props {
  isDeleteLoading?: boolean;
  data: {
    id: string;
    full_name: string;
    code: string;
    company_name: string;
    mobile_number: string;
    phone_number: string;
    email: string;
    address: string;
    postal_code: string;
    notes: string;
    documents: UploadedFileType[];
    contacts: Contacts[];
  };
}

const CustomersInfo: FC<Props> = (props) => {
  const {
    id,
    full_name,
    code,
    company_name,
    mobile_number,
    phone_number,
    email,
    address,
    postal_code,
    notes,
    documents,
    contacts,
  } = props.data;

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isNewContactModalOpen, setIsNewContactModalOpen] = useState(false);
  const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false);
  const [isDeleteContactModalOpen, setIsDeleteContactModalOpen] =
    useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Contacts>();
  const [selectedContactId, setSelectedContactId] = useState<string>("");

  const moveToEdit = () => {
    navigate("/customers/customers-edit", {
      state: { data: props.data, forwardedFromInfo: true },
    });
  };

  const onDelete = (customerId: number) => {
    dispatch(deleteCustomer(customerId)).then(() => {
      setIsDeleteModalOpen(false);
    });
  };

  const onDeleteContact = (contactId: string) => {
    const deleteValue = {
      contactId: Number(contactId),
      customerId: Number(id),
    };
    dispatch(deleteContact(deleteValue));
  };

  return (
    <>
      <Container>
        <PaperBox>
          <Box mb={12}>
            <FormHeading>Customer Information</FormHeading>
          </Box>
          <Grid
            container
            rowSpacing={12}
            columnSpacing={{ sm: 0, md: 20 }}
            md={10}
            sm={12}
          >
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems
                  title="Full name"
                  subTilte={full_name ?? "Not Found!"}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems
                  title="Customer Code"
                  subTilte={code ?? "Not Found!"}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems
                  title="Company Name"
                  subTilte={company_name ?? "Not Found!"}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems
                  title="Mobile"
                  subTilte={mobile_number ?? "Not Found!"}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems
                  title="Phone"
                  subTilte={phone_number ?? "Not Found!"}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems
                  title="Email Address"
                  subTilte={email ?? "Not Found!"}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems title="Address" subTilte={address ?? "Not Found!"} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems
                  title="Postal Code"
                  subTilte={postal_code ?? "Not Found!"}
                />
              </Box>
            </Grid>
          </Grid>

          <ContactsContainer
            onClick={() => setShowContacts(!showContacts)}
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={4}
            pt={8}
            sx={{ cursor: "pointer" }}
          >
            <ShowMoreBtn
              mb={showContacts ? 10 : 0}
              // sx={showContacts ? { marginBottom: "24px" } : {}}
            >
              <Box>
                <Typography
                  fontWeight="700"
                  fontSize="14"
                  color={colors.primary[800]}
                  borderBottom={`1px dashed ${colors.primary[800]}`}
                >
                  {showContacts ? "Show Less" : "Show More"}
                </Typography>
              </Box>
              <Box pt={3}>
                {showContacts ? (
                  <ArrowUp2
                    size="14"
                    variant="Bold"
                    color={colors.primary[800]}
                  />
                ) : (
                  <ArrowDown2
                    size="14"
                    variant="Bold"
                    color={colors.primary[800]}
                  />
                )}
              </Box>
            </ShowMoreBtn>
            {showContacts ? (
              <ContactsItemsBox>
                <CustomersInfoContact
                  Contacts={contacts}
                  Id={id}
                  onEditContact={(itemDate: Contacts) => {
                    setSelectedContact(itemDate);
                    setIsEditContactModalOpen(true);
                  }}
                  onDeleteContact={(id: string) => {
                    setSelectedContactId(id);
                    setIsDeleteContactModalOpen(true);
                  }}
                  onCreateNewContact={() => setIsNewContactModalOpen(true)}
                />
              </ContactsItemsBox>
            ) : null}
          </ContactsContainer>
        </PaperBox>

        <PaperBox>
          <Box mb={12}>
            <FormHeading>Notes & Documents</FormHeading>
          </Box>
          <Grid
            container
            rowSpacing={12}
            columnSpacing={{ sm: 0, md: 20 }}
            md={10}
            sm={12}
          >
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems title="Notes" subTilte={notes ?? "Not Found!"} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <FileViewerWrapper>
                <FileUploaderViewer
                  type={FileUploaderViewerType.Multiple}
                  files={documents}
                />
              </FileViewerWrapper>
            </Grid>
          </Grid>
        </PaperBox>

        <Actions width="100%">
          {/* <Box>
            <StyledButton
              variant="outlined"
              color="error"
              fullWidth
              disabled={props?.isDeleteLoading}
              onClick={() => setIsDeleteModalOpen(true)}
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.error[500]}
              >
                Remove Customer
              </Typography>
            </StyledButton>
          </Box> */}
          <Box marginLeft={12}>
            <StyledButton
              variant="contained"
              color="secondary"
              fullWidth
              disabled={props?.isDeleteLoading}
              onClick={moveToEdit}
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.base.white}
              >
                Edit Customer
              </Typography>
            </StyledButton>
          </Box>
        </Actions>
      </Container>

      <NewContactModal
        open={isNewContactModalOpen}
        customerId={id}
        onDismiss={() => setIsNewContactModalOpen(false)}
      />
      <EditContactModal
        open={isEditContactModalOpen}
        customerId={id}
        contactData={selectedContact!}
        onDismiss={() => setIsEditContactModalOpen(false)}
      />

      <ConfirmModal
        title="Remove Contact"
        iconName="ModalRemove"
        open={isDeleteContactModalOpen}
        isLoading={false}
        onDismiss={() => setIsDeleteContactModalOpen(false)}
        onConfirm={() => onDeleteContact(selectedContactId)}
        confrimText="Remove"
        cancelText="Cancel"
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to remove this Contact?
        </Typography>
      </ConfirmModal>

      <ConfirmModal
        title={"Remove Customer"}
        iconName={"ModalRemove"}
        open={isDeleteModalOpen}
        isLoading={props?.isDeleteLoading}
        onDismiss={() => setIsDeleteModalOpen(false)}
        onConfirm={() => onDelete(Number(id))}
        confrimText="Remove"
        cancelText="Cancel"
        maxWidth={450}
      >
        <Typography
          textAlign="center"
          fontSize={14}
          fontWeight={400}
          color={colors.gray[850]}
        >
          Are you sure to remove <b>{full_name}</b> from your customers? by
          clicking on <b>“Remove Customer”</b> , all contacts related to this
          customer will also be removed.
        </Typography>
      </ConfirmModal>
    </>
  );
};

export default CustomersInfo;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

const NotesTitleBar = styled("div")`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 24px;
  color: ${colors.gray[850]};
  font-size: 24px;
  font-weight: 500;
`;

const ContactsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0;
`;

const ContactsItemsBox = styled(Box)`
  width: 860px;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

const ShowMoreBtn = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  & > div {
    width: 184px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    & > div {
      width: 142px;
    }
  }
`;

const FileViewerWrapper = styled(Box)`
  width: 170px;
`;
