import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PageTemplate } from "src/layout/components/PageTemplate";
import {
  serachProducts,
  reset as resetProductItems,
} from "src/features/products/redux/ProductsSlice";
import { AppDispatch, RootState } from "src/store";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { ProductItemsTable } from "../components/productItems/ProductItemsTable";

interface LocationStateProps {
  warehouseId: string;
  warehouseTitle: string;
  productId: string;
  productTitle: string;
}

const ProductItems: FC = (props) => {
  const location = useLocation();
  const state = location.state as LocationStateProps;
  const { warehouseId, warehouseTitle, productId, productTitle } = state;
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const {
    searchedProducts,
    searchedProductsMeta,
    isSearchLoading,
    isSearchError,
    isSearchSuccess,
    searchProductsMessage,
  } = useSelector((state: RootState) => state.products);

  useEffect(() => {
    if (isSearchError) {
      enqueueSnackbar(searchProductsMessage, { variant: "error" });
      setIsLoading(false);
    }
    if (isSearchSuccess) {
      setIsLoading(false);
    }
    return () => {
      dispatch(resetProductItems());
    };
  }, [isSearchLoading, isSearchError, isSearchSuccess, searchProductsMessage]);

  useEffect(() => {
    const getProductItemsParams = {
      page: page,
      warehouseId: warehouseId,
      productId: productId,
    };
    dispatch(serachProducts(getProductItemsParams));

    return () => {
      dispatch(resetProductItems());
    };
  }, [page]);

  return (
    <PageTemplate
      crumbs={[
        {
          label: "Warehouses List",
          href: "/warehouses/warehouse-list",
          depth: -2,
        },
        {
          label: warehouseTitle,
          href: "/warehouses/warehouse-information",
          depth: -1,
        },
        {
          label: productTitle,
        },
      ]}
    >
      <ProductItemsTable
        isLoading={isLoading}
        warehouseId={warehouseId}
        productId={productId}
        productItems={searchedProducts}
        rowsCount={(searchedProductsMeta as any)?.pagination?.total_items}
        page={page}
        searchValue={searchValue}
        setNextPage={(page) => setPage(page)}
        setSearchValue={(value) => setSearchValue(value)}
      />
    </PageTemplate>
  );
};

export default ProductItems;
