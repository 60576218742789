import { styled } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { Box, IconButton, Typography, css } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";
import StyledTable from "src/components/Table";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import { GridColDef } from "@mui/x-data-grid";
import { Eye } from "iconsax-react";
import {
  deleteSaleOrderShipmentCarrier,
  deleteSaleOrderShipmentCarrierProduct,
  getSaleOrderShipmentCarrierProducts,
  addSaleOrderShipmentCarrierProduct,
  getSaleOrderShipmentCarriers,
  reset as resetSaleOrderCarriers,
  receiveCarrierProduct,
} from "src/features/saleOrders/redux/CarriersSlice";
import { StyledLoadingButton } from "src/components/LoadingButton";
import ConfirmModal from "src/components/Modal";
import {
  serachProducts,
  reset as resetProducts,
} from "src/features/products/redux/ProductsSlice";
import { RecieveSingleProductModal } from "../modals/RecieveSingleProductModal";
import { RecieveAllProductModal } from "../modals/RecieveAllProductModal";

enum StatusBadgeTypes {
  Pending = "Pending",
  Received = "Received",
}

interface SaleOrderRecievedCarrierProductsListProps {
  id: number;
  warehouseId: number;
  carrierName: string;
}

export const SaleOrderRecievedCarrierProductsList: FC<
  SaleOrderRecievedCarrierProductsListProps
> = (props) => {
  const { id, warehouseId, carrierName } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("loading");
  const [products, setProductsData] = useState([] as any);
  const [productsMeta, setProductsMeta] = useState([] as any);
  const [trucksLoadind, setTrucksLoadind] = useState(true);
  const [searchedProductData, setSearchedProductData] = useState(null as any);

  const [isDeletetModalOpen, setIsDeletetModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const [isReceiveSingleProductModalOpen, setIsReceiveSingleProductModalOpen] =
    useState(false);
  const [isRecieveAllProductsModalOpen, setIsRecieveAllProductsModalOpen] =
    useState(false);

  const columns: GridColDef[] = [
    {
      field: "placement",
      headerName: "Placement",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "product",
      headerName: "Product Name",
      flex: 1,
      minWidth: 140,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.product?.title}
        </Box>
      ),
    },
    {
      field: "weight",
      headerName: "Weight",
      flex: 1,
      minWidth: 120,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.variants && params.row?.variants.length > 0
            ? params.row?.variants.find((v: any) => v.variant === "Weight") !=
              null
              ? `${
                  params.row?.variants.find((v: any) => v.variant === "Weight")
                    .value * 1000
                } Kg`
              : 0
            : 0}
        </Box>
      ),
    },
    {
      field: "barcode",
      headerName: "Barcode",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        if (params.row?.unloaded_at) {
          return (
            <BadgeBox type={StatusBadgeTypes.Received}>
              <BadgeIcon>
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/Check.svg"}
                  alt="status"
                />
              </BadgeIcon>
              Received
            </BadgeBox>
          );
        } else {
          return (
            <BadgeBox type={StatusBadgeTypes.Pending}>
              <BadgeIcon>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/icons/saleOrderShipment/PendingBadge.svg"
                  }
                  alt="status"
                />
              </BadgeIcon>
              Pending
            </BadgeBox>
          );
        }
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.2,
      minWidth: 120,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          flexDirection="row"
        >
          <IconButton>
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Remove.svg"}
              alt="delete"
              onClick={() => {
                setSelectedId(Number(params?.id));
                setIsDeletetModalOpen(true);
              }}
            />
          </IconButton>
        </Box>
      ),
    },
  ];

  const {
    shipmentCarrierProducts,
    shipmentCarrierProductsMeta,
    isGetShipmentCarrierProductsError,
    isDeleteShipmentCarrierProductError,
    isReceiveProductError,
    isAddShipmentCarrierProductError,
    isGetShipmentCarrierProductsSuccess,
    isDeleteShipmentCarrierProductSuccess,
    isAddShipmentCarrierProductSuccess,
    isReceiveProductSuccess,
    isGetShipmentCarrierProductsLoading,
    isDeleteShipmentCarrierProductLoading,
    isAddShipmentCarrierProductLoading,
    isReceiveProductLoading,
    getShipmentCarrierProductsMessage,
    deleteShipmentCarrierProductMessage,
    addShipmentCarrierProductMessage,
    receiveProductMessage,
  } = useSelector((state: RootState) => state.carriers);

  const {
    isSearchLoading,
    isSearchSuccess,
    searchedProducts,
    message: searchedProductMessage,
    isError: isSearchError,
  } = useSelector((state: RootState) => state.products);

  useEffect(() => {
    if (isGetShipmentCarrierProductsError) {
      enqueueSnackbar(getShipmentCarrierProductsMessage, { variant: "error" });
    }
    if (isGetShipmentCarrierProductsSuccess) {
      setProductsData(shipmentCarrierProducts);
      setProductsMeta(shipmentCarrierProductsMeta);
      setTrucksLoadind(false);
    }
    return () => {
      dispatch(resetSaleOrderCarriers());
    };
  }, [
    isGetShipmentCarrierProductsError,
    isGetShipmentCarrierProductsSuccess,
    getShipmentCarrierProductsMessage,
    dispatch,
  ]);

  useEffect(() => {
    if (isSearchError) {
      enqueueSnackbar(searchedProductMessage, { variant: "error" });
    }
    if (isSearchSuccess) {
      setSearchedProductData(searchedProducts);
    }
    return () => {
      dispatch(resetProducts());
    };
  }, [isSearchError, isSearchSuccess, searchedProductMessage, dispatch]);

  useEffect(() => {
    if (isReceiveProductError) {
      enqueueSnackbar(receiveProductMessage, {
        variant: "error",
      });
    }
    if (isReceiveProductSuccess) {
      enqueueSnackbar(receiveProductMessage, {
        variant: "success",
      });
      dispatch(
        getSaleOrderShipmentCarrierProducts({
          carrierId: Number(id),
          page: page,
        })
      ).then(() => {
        setIsReceiveSingleProductModalOpen(false);
        setIsRecieveAllProductsModalOpen(false);
      });
    }
    return () => {
      dispatch(resetSaleOrderCarriers());
    };
  }, [isReceiveProductError, isReceiveProductSuccess, receiveProductMessage]);

  useEffect(() => {
    if (isDeleteShipmentCarrierProductError) {
      enqueueSnackbar(deleteShipmentCarrierProductMessage, {
        variant: "error",
      });
    }
    if (isDeleteShipmentCarrierProductSuccess) {
      enqueueSnackbar(deleteShipmentCarrierProductMessage, {
        variant: "success",
      });
      dispatch(
        getSaleOrderShipmentCarrierProducts({
          carrierId: Number(id),
          page: products.length <= 1 ? (page > 1 ? page - 1 : 1) : page,
        })
      ).then(() => setIsDeletetModalOpen(false));

      if (products.length <= 1) {
        setPage(page > 1 ? page - 1 : 1);
      }
    }
    return () => {
      dispatch(resetSaleOrderCarriers());
    };
  }, [
    isDeleteShipmentCarrierProductError,
    isDeleteShipmentCarrierProductSuccess,
    deleteShipmentCarrierProductMessage,
  ]);

  useEffect(() => {
    const infoValue = {
      carrierId: Number(id),
      page: products.length <= 1 ? (page > 1 ? page - 1 : 1) : page,
    };
    dispatch(getSaleOrderShipmentCarrierProducts(infoValue));
  }, [page, filter]);

  const onDelete = (selected: any) => {
    const deleteValues = {
      carrierId: Number(id),
      productId: Number(selected),
    };
    dispatch(deleteSaleOrderShipmentCarrierProduct(deleteValues));
  };

  const onRecieveProduct = (barcode: string) => {
    const AddValues = {
      carrierId: Number(id),
      barcode: barcode ? barcode : "",
    };
    dispatch(receiveCarrierProduct(AddValues));
  };

  const onRecieveAllProducts = () => {
    const AddValues = {
      carrierId: Number(id),
      barcode: "",
    };
    dispatch(receiveCarrierProduct(AddValues));
  };

  return (
    <>
      <StyledHeader>
        <Header>
          <HeaderLabel>Products List: </HeaderLabel>
          {productsMeta?.pagination?.total_items > 0 &&
          !isGetShipmentCarrierProductsLoading ? (
            <Infos>
              <SuccessInfo>
                Total Number{" "}
                <InfoBold>
                  {productsMeta?.pagination?.total_items ?? 0}
                </InfoBold>{" "}
                Pcs
              </SuccessInfo>
              <ErrorInfo>
                Total Weight{" "}
                <InfoBold>{productsMeta?.total_weight * 1000 ?? 0}</InfoBold> Kg
              </ErrorInfo>
            </Infos>
          ) : null}
        </Header>
        <Actions>
          <SendButton>
            <StyledLoadingButton
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => setIsReceiveSingleProductModalOpen(true)}
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.base.white}
              >
                Receive A Product
              </Typography>
            </StyledLoadingButton>
          </SendButton>
          <SendButton>
            <StyledLoadingButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => setIsRecieveAllProductsModalOpen(true)}
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.base.black}
              >
                Receive All Products
              </Typography>
            </StyledLoadingButton>
          </SendButton>
        </Actions>
      </StyledHeader>
      <ListBoundary
        isEmpty={products.length === 0 || !products}
        isDataLoading={trucksLoadind}
        fallbackImageURL="/assets/images/emptyStates/CarrierProducts-List EmptyState.svg"
        description="No product added yet!"
      >
        <StyledTable
          disableColumnMenu
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableVirtualization
          disableSelectionOnClick
          loading={false}
          columns={columns}
          experimentalFeatures={{ newEditingApi: true }}
          rows={products}
          onPageChange={(e) => setPage(e + 1)}
          page={page - 1}
          paginationMode="server"
          rowCount={productsMeta?.pagination?.total_items}
        />
      </ListBoundary>

      <ConfirmModal
        title={"Remove Product"}
        iconName={"ModalRemove"}
        open={isDeletetModalOpen}
        isLoading={
          isGetShipmentCarrierProductsLoading ||
          isDeleteShipmentCarrierProductLoading
        }
        onDismiss={() => setIsDeletetModalOpen(false)}
        onConfirm={() => onDelete(selectedId)}
        confrimText={"Remove"}
        cancelText={"Cancel"}
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to remove this Product ?
        </Typography>
      </ConfirmModal>

      <RecieveSingleProductModal
        open={isReceiveSingleProductModalOpen}
        loading={isReceiveProductLoading}
        carrierName={carrierName}
        onRecieveProduct={(barcode) => onRecieveProduct(barcode)}
        onDismiss={() => setIsReceiveSingleProductModalOpen(false)}
      />
      <RecieveAllProductModal
        open={isRecieveAllProductsModalOpen}
        loading={isReceiveProductLoading}
        carrierName={carrierName}
        onRecieveProduct={onRecieveAllProducts}
        onDismiss={() => setIsRecieveAllProductsModalOpen(false)}
      />
    </>
  );
};

interface StyledProps {
  type?: StatusBadgeTypes;
}

const StyledHeader = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SendButton = styled(Box)`
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    margin-top: 24px;
  }
`;

const Header = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 32px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const HeaderLabel = styled("span")`
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  white-space: nowrap;
  color: ${colors.midnight[850]};
`;

const Infos = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
`;

const SuccessInfo = styled(Box)`
  background-color: ${colors.secondary[10]};
  color: ${colors.secondary[800]};
  border-radius: 50px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
`;

const ErrorInfo = styled(Box)`
  background-color: ${colors.error[10]};
  color: ${colors.error[600]};
  border-radius: 50px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
`;

const InfoBold = styled(Typography)`
  margin: 0 4px;
  font-size: 14px;
  font-weight: 700;
`;

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  & > div {
    width: 224px;
  }
  ${(props) => props.theme.breakpoints.down("xl")} {
    width: 100%;
    margin-top: 32px;
    flex-direction: column;
    align-items: flex-start;
    & > div {
      width: 100%;
    }
  }
`;

const BadgeBox = styled(Box)<StyledProps>(({ type }) => {
  let color = colors.primary[800];
  let bgColor = colors.primary[10];

  switch (type) {
    case StatusBadgeTypes.Pending:
      color = colors.primary[800];
      bgColor = colors.primary[10];
      break;
    case StatusBadgeTypes.Received:
      color = colors.success[700];
      bgColor = colors.success[50];
      break;

    default:
      break;
  }

  return css`
    padding: 2px 15px 2px 12px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    line-height: 26px;
    font-weight: 400;
    font-size: 13px;
    background-color: ${bgColor};
    color: ${color};
  `;
});

const BadgeIcon = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
