import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import ItemEdit from "../pages/ItemEdit";
import ItemInfo from "../pages/ItemInfo";
import ItemNew from "../pages/ItemNew";
import ItemTable from "../pages/ItemTable";

export const ItemsRoutes = () => {
  const { t } = useTranslation();
  return (
    <Routes>
      <Route path="/materials-list" element={<ItemTable />} />
      <Route path="/material-new" element={<ItemNew />} />
      <Route path="/material-edit" element={<ItemEdit />} />
      <Route path="/material-information" element={<ItemInfo />} />
    </Routes>
  );
};
