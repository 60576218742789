import { styled } from "@mui/material/styles";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { CircularProgress } from "@mui/material";

export const StyledLoadingButton = (props: LoadingButtonProps) => {
  return (
    <CustomLoadingButton
      loadingIndicator={<CircularProgress />}
      {...props}
      useVariantHeight={props.size != null}
    />
  );
};

interface StyledProps {
  useVariantHeight?: boolean;
}

const CustomLoadingButton = styled(LoadingButton)<StyledProps>`
  height: ${({ useVariantHeight }) => (useVariantHeight ? "auto" : "48px")};
  border-radius: 5px;
`;
