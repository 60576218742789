import { Add } from "@mui/icons-material";
import {
  Box,
  Grid,
  styled,
  MenuItem,
  Typography,
  Chip,
  FormHelperText,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { CloudPlus } from "iconsax-react";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "src/components/Button";
import { CanselButton } from "src/components/CanselButton";
import {
  FileUploader,
  FileUploaderType,
} from "src/components/FileUploader/FileUploader";
import { UploadedFileType } from "src/components/FileUploader/UploadZone/UploadZone";
import FormHeading from "src/components/FormHeading";
import FormInputLable from "src/components/FormInputLabel";
import { GrayButton } from "src/components/GrayButton";
import { StyledTextField } from "src/components/Input";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { StyledTextFieldMulti } from "src/components/MultilineInput";
import { PaperBox } from "src/components/Paper/Paper";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import * as Yup from "yup";
import { getWarehousePlacements } from "../redux/PlacementSlice";
import { postWarehouse, reset } from "../redux/WarehouseSlice";

interface Props {
  items: any;
}

const WharehouseNewForm = (props: Props) => {
  const navigate = useNavigate();

  const [items, setItems] = useState(props.items);
  const [placementTitle, setPlacementTitle] = useState<string>("");
  const [files, setFiles] = useState<UploadedFileType[]>([]);

  //formik---------------------------------
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const ItemSchema = Yup.object().shape({
    title: Yup.string().required(),
    code: Yup.string().required(),
    materials: Yup.array().min(1),
    placements: Yup.array().min(1),
    phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    address: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      code: "",
      materials: [],
      placements: [],
      phone: "",
      address: "",
    },
    validationSchema: ItemSchema,

    onSubmit: async (values: any) => {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("code", `WH-${values.code}`);
      formData.append("phone", values.phone);
      formData.append("address", values.address);
      values.materials?.forEach((material: string) =>
        formData.append(`materials[]`, material)
      );
      values.placements?.forEach((placement: string) =>
        formData.append(`placements[]`, placement)
      );
      if (files.length > 0) {
        files.forEach((file, index) =>
          formData.append(`files[${index}]`, file as any, file.name)
        );
      }

      dispatch(postWarehouse(formData));
    },
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    setFieldValue,
    getFieldProps,
  } = formik;

  //redux ---------------------------------
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const { isCreateLoading, isError, isSuccess, message } = useSelector(
    (state: RootState) => state.warehouse
  );

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      enqueueSnackbar(message, { variant: "success" });
      navigate("/warehouses/warehouse-list");
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, isSuccess, message, navigate, dispatch]);

  useEffect(() => {
    setItems(props.items);
  }, [props]);

  const onAddPlacement = () => {
    const placementsCopy = [...values.placements] as string[];

    if (placementsCopy.includes(placementTitle)) {
      enqueueSnackbar(`you can't add duplicated placements!`, {
        variant: "warning",
      });
    } else {
      const newPlacements = [...placementsCopy, placementTitle];
      setFieldValue("placements", newPlacements);
    }
  };

  const onDeletePlacement = (title: string) => {
    const placementsCopy = [...values.placements];
    const newPlacements = placementsCopy.filter(
      (placement) => placement !== title
    );
    setFieldValue("placements", newPlacements);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <PaperBox>
          <Box mb={12}>
            <FormHeading>Warehouse Information</FormHeading>
          </Box>
          <Grid
            container
            rowSpacing={12}
            columnSpacing={{ sm: 0, md: 20 }}
            md={10}
            sm={12}
          >
            <Grid item md={6} sm={12}>
              <FormInputLable requried>Warehouse Name</FormInputLable>
              <StyledTextField
                placeholder="Enter Warehouse name"
                fullWidth
                {...getFieldProps("title")}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <FormInputLable requried>Warehouse Code</FormInputLable>
              <StyledTextField
                placeholder="Enter Warehouse Code"
                fullWidth
                {...getFieldProps("code")}
                error={Boolean(touched.code && errors.code)}
                helperText={touched.code && errors.code}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <Grid container rowSpacing={14} columnSpacing={{ sm: 0, md: 20 }}>
                <Grid item md={12} sm={12}>
                  <FormInputLable requried>Default Materials</FormInputLable>
                  <StyledTextField
                    select
                    fullWidth
                    {...getFieldProps("materials")}
                    placeholder="Select warehouse default items"
                    error={Boolean(touched.materials && errors.materials)}
                    helperText={touched.materials && errors.materials}
                    onChange={formik.handleChange("materials")}
                    defaultValue={[]}
                    SelectProps={{
                      multiple: true,
                    }}
                  >
                    {items?.map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </StyledTextField>
                </Grid>
                <Grid item md={12} sm={12}>
                  <FormInputLable>Phone Number</FormInputLable>
                  <StyledTextField
                    placeholder="Enter Warehouse Phone Number"
                    fullWidth
                    {...getFieldProps("phone")}
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} sm={12}>
              <FormInputLable>Address</FormInputLable>
              <StyledTextFieldMulti
                placeholder="Enter Warehouse Address"
                fullWidth
                multiline={true}
                {...getFieldProps("address")}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={12}
            columnSpacing={{ sm: 0, md: 20 }}
            mt={0}
            md={10}
            sm={12}
          >
            <Grid item md={6} sm={12}>
              <FormInputLable>Files</FormInputLable>
              <FileUploader
                type={FileUploaderType.Multiple}
                maxFiles={5}
                files={files}
                setFiles={setFiles}
              />
            </Grid>
          </Grid>
        </PaperBox>

        <PaperBox>
          <Box mb={12}>
            <FormHeading>Placements</FormHeading>
          </Box>
          <Grid
            container
            rowSpacing={12}
            columnSpacing={{ sm: 0, md: 20 }}
            md={10}
            sm={12}
          >
            <Grid item md={7} xs={12}>
              <FormInputLable requried>Placements</FormInputLable>
              <PlacementField>
                <Box width="calc(100% - 72px)">
                  <StyledTextField
                    placeholder="Placement Title"
                    fullWidth
                    value={placementTitle}
                    onChange={(e) => setPlacementTitle(e.target.value)}
                  />
                </Box>
                <AddBox onClick={onAddPlacement}>
                  <Add sx={{ color: "#fff" }} />
                </AddBox>
              </PlacementField>
              <PlacementsBox>
                {values.placements?.map((placement) => (
                  <Chip
                    key={placement}
                    label={placement}
                    color="secondary"
                    onDelete={() => onDeletePlacement(placement)}
                  />
                ))}
              </PlacementsBox>
              {touched.placements && errors.placements && (
                <FormHelperText
                  style={{
                    color: colors.error[700],
                    fontSize: 13,
                    fontWeight: 400,
                  }}
                >
                  placements field must have at least 1 items
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </PaperBox>

        <Actions width="100%">
          <Box>
            <GrayButton
              variant="outlined"
              fullWidth
              disabled={isCreateLoading}
              onClick={() => navigate("/Warehouses/Warehouse-list")}
            >
              Cancel
            </GrayButton>
          </Box>
          <Box marginLeft={12}>
            <StyledLoadingButton
              loading={isCreateLoading}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.gray[850]}
              >
                Save and Send
              </Typography>
            </StyledLoadingButton>
          </Box>
        </Actions>
      </Form>
    </FormikProvider>
  );
};

const PlacementField = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const AddBox = styled(Box)`
  cursor: pointer;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #1797c2;
`;

const PlacementsBox = styled(Box)`
  width: 100%;
  min-height: 64px;
  border: 1px solid #c2cbd7;
  border-radius: 5px;
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 16px;
  gap: 8px;
`;

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  & > div {
    width: 184px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    & > div {
      width: 142px;
    }
  }
`;

export default WharehouseNewForm;
