import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loading from "src/components/Loading";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { AppDispatch, RootState } from "src/store";
import ItemEditForm from "../components/ItemEditForm";
import { getItemsId, reset as resetItems } from "../redux/ItemSlice";
import { getQualities } from "../redux/qualitySlice";
import { getUnits } from "../redux/unitSlice";

const ItemEdit = () => {
  const location = useLocation();
  const state = location.state as any;
  const [page, setPage] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const [unitData, setUnitData] = useState([] as any);
  const [qualitiesData, setQualitiesData] = useState([] as any);

  const [materialData, setMaterialData] = useState([] as any);
  const [materialDataLoading, setMaterialDataLoading] = useState(true);

  const { qualities } = useSelector((state: RootState) => state.qualities);

  const {
    singleItem: singleMaterialData,
    isSingleLoading: isMaterialDataLoading,
    isError: isMaterialDataError,
    isSuccess: isMaterialDataSuccess,
    message: materialDataMessage,
  } = useSelector((state: RootState) => state.items);

  const { units, isError, isSuccess, message, isLoading } = useSelector(
    (state: RootState) => state.units
  );

  useEffect(() => {
    if (isMaterialDataError) {
      enqueueSnackbar(materialDataMessage, { variant: "error" });
    }
    if (isMaterialDataSuccess) {
      setMaterialData(singleMaterialData);
      setMaterialDataLoading(false);
    }
    return () => {
      dispatch(resetItems());
    };
  }, [
    isMaterialDataError,
    isMaterialDataSuccess,
    materialDataMessage,
    dispatch,
  ]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      setUnitData(units);
      setQualitiesData(qualities);
    }
    return () => {
      dispatch(resetItems());
    };
  }, [isError, isLoading, isSuccess, message]);

  useEffect(() => {
    dispatch(
      getItemsId({
        id: Number(state?.data?.id),
      })
    );
    dispatch(
      getQualities({
        page: page,
      })
    ).then(() =>
      dispatch(
        getUnits({
          page: page,
        })
      )
    );

    return () => {
      setMaterialDataLoading(true);
    };
  }, []);

  return (
    <PageTemplate
      isTransparent
      crumbs={
        state.forwardedFromInfo
          ? [
              {
                label: "materials",
                href: "/materials/materials-list",
                depth: -2,
              },
              {
                label: "material info",
                href: "/materials/material-information",
                depth: -1,
              },
              {
                label: "edit material",
              },
            ]
          : [
              {
                label: "materials",
                href: "/materials/materials-list",
                depth: -1,
              },
              {
                label: "edit material",
              },
            ]
      }
    >
      {isLoading || materialDataLoading ? (
        <Loading />
      ) : (
        <ItemEditForm
          units={unitData}
          qualities={qualitiesData}
          data={materialData}
        />
      )}
    </PageTemplate>
  );
};

export default ItemEdit;
