import { styled } from "@mui/material/styles";
import { FC } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { StyledTextField } from "../Input";
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material";
import colors from "src/theme/colors";
import { CustomActionBar } from "./CustomActionBar/CustomActionBar";

interface DatePickerProps {
  label?: string;
  placeholder?: string;
  icon?: JSX.Element | React.ReactElement;
  value: Date | null;
  error?: boolean;
  disabled?: boolean;
  helperText?: string | false | undefined;
  showActions?: boolean;
  onChange: (
    value: Date | null,
    keyboardInputValue?: string | undefined
  ) => void;
}

const PickIcon = () => (
  <Icon>
    <img
      src={process.env.PUBLIC_URL + "/assets/icons/PickerCalender.svg"}
      alt="status"
    />
  </Icon>
);

export const StyledDatePicker: FC<DatePickerProps> = (props) => {
  const {
    label,
    placeholder,
    value,
    icon,
    error,
    disabled,
    helperText,
    showActions,
    onChange,
  } = props;

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MuiThemeProvider theme={customTheme}>
          <MuiDatePicker
            label={label}
            value={value}
            disabled={disabled}
            onChange={(newValue: Date | null) => {
              onChange(newValue);
            }}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                placeholder={placeholder}
                fullWidth
                error={error}
                disabled={disabled}
                helperText={helperText}
              />
            )}
            PaperProps={{
              sx: {
                "& .MuiDialogActions-root": {
                  justifyContent: "space-around",
                },
                "& .MuiPickersDay-root": {
                  borderRadius: "8px",
                },
                "& .MuiPickersDay-root.MuiPickersDay-today": {
                  color: `${colors.secondary[500]} !important`,
                  bacgroundColor: `${colors.base.white} !important`,
                  borderColor: colors.secondary[500],
                },
                "& .MuiPickersDay-root:not(.Mui-selected)": {
                  color: colors.gray[900],
                },
                "& .MuiPickersDay-root.Mui-selected": {
                  color: colors.base.white,
                  backgroundColor: colors.secondary[700],
                },
              },
            }}
            components={
              showActions
                ? {
                    ActionBar: CustomActionBar,
                    OpenPickerIcon: PickIcon,
                  }
                : { OpenPickerIcon: PickIcon }
            }
            componentsProps={
              showActions
                ? {
                    actionBar: {
                      actions: ["clear", "today", "cancel"],
                    },
                  }
                : {}
            }
          />
        </MuiThemeProvider>
      </LocalizationProvider>
    </Container>
  );
};

const Container = styled("div")`
  width: 100%;
`;

const Icon = styled("div")``;

export const customTheme = createTheme({
  palette: {
    primary: {
      main: colors.secondary[700],
      light: colors.secondary[500],
      dark: colors.secondary[700],
    },
    secondary: {
      main: colors.secondary[700],
    },
  },
});
