import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import StockTakingTable from "../pages/StockTakingTable";
import StockTakingInfo from "../pages/StockTakingInfo";

export const StocktakingsRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/stocktakings-list" element={<StockTakingTable />} />
        <Route path="/stocktaking-information" element={<StockTakingInfo />} />
      </Routes>
    </>
  );
};
