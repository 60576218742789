import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import WarehouseEdit from "../pages/WarehouseEdit";
import WarehouseInfo from "../pages/WarehouseInfo";
import WarehouseNew from "../pages/WareHouseNew";
import WarehouseTable from "../pages/WarehouseTable";
import InventoriesSearch from "../pages/InventoriesSearch";
import ProductItems from "../pages/ProductItems";

export const WarehouseRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/warehouse-list" element={<WarehouseTable />} />
        <Route path="/warehouse-new" element={<WarehouseNew />} />
        <Route path="/warehouse-edit" element={<WarehouseEdit />} />
        <Route path="/warehouse-information" element={<WarehouseInfo />} />
        <Route path="/stocktakings-list" element={<WarehouseTable />} />
        <Route path="/inventories-search" element={<InventoriesSearch />} />
        <Route
          path="/warehouse-information/product-items"
          element={<ProductItems />}
        />
      </Routes>
    </>
  );
};
