import { Box, Grid, styled, Typography } from "@mui/material";
import colors from "src/theme/colors";

type Props = {
  title: string;
  subTilte: string;
  description1?: string;
  description2?: string;
};

const InfoItems = (props: Props) => {
  return (
    <Wrapper>
      <Typography
        color={colors.gray[600]}
        fontSize="14px"
        fontWeight="500"
        pb={1}
      >
        {props.title}
      </Typography>
      <Typography
        color={colors.gray[850]}
        fontSize="15px"
        fontWeight="500"
        pb={1}
        lineHeight="30px"
        width="100%"
        whiteSpace="normal"
        sx={{ wordBreak: "break-word" }}
      >
        {props.subTilte}
      </Typography>
      {props.description1 ? (
        <Typography
          color={colors.gray[600]}
          fontSize="13px"
          fontWeight="400"
          lineHeight="26px"
          whiteSpace="normal"
          sx={{ wordBreak: "break-word" }}
        >
          {props.description1}
        </Typography>
      ) : null}
      {props.description2 ? (
        <Typography
          color={colors.gray[600]}
          fontSize="13px"
          fontWeight="400"
          lineHeight="26px"
          whiteSpace="normal"
          sx={{ wordBreak: "break-word" }}
          pt={1}
        >
          {props.description2}
        </Typography>
      ) : null}
    </Wrapper>
  );
};

export default InfoItems;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 2px;
`;
