import { Grid } from "@mui/material";
import { PageTemplate } from "src/layout/components/PageTemplate";
import EmployeeChart from "../components/EmployeeChart";
import InfoCard from "../components/InfoCard";
import RecentVendor from "../components/RecentVendor";
import TotalPurchase from "../components/TotalPurchase";

export const Dashboard = () => {
  return (
    <PageTemplate crumbs={[{ label: "dashboard" }]}>
      <Grid container spacing={"40px"}>
        <Grid item md={4} sm={12} xs={12}>
          <InfoCard title="In Stock" number="10.000 MT" color={"#0045E0"} />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <InfoCard
            title="Total Purchase"
            number="30.000 MT"
            color={"#FBB000"}
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <InfoCard title="Total Employees" number="52" color={"#FF5436"} />
        </Grid>
        <Grid item md={8} sm={12} xs={12}>
          <TotalPurchase />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <RecentVendor />
        </Grid>
        <Grid item md={8} sm={12} xs={12}>
          <EmployeeChart />
        </Grid>
      </Grid>
    </PageTemplate>
  );
};
