import { axios } from "../../../config/Axios";

//Warehouse get
const getWarehouses = async (data: any) => {
  const response = await axios.get(
    `/warehouses?page=${data.page}&${data.limit ? `limit=${data.limit}` : ""}`
  );
  return response;
};
//Warehouse get by id
const getWarehousesId = async (data: number) => {
  const response = await axios.get(`/warehouses/${data}`);
  return response;
};
//Warehouse submit
const postWarehouse = async (data: any) => {
  const response = await axios.post("/warehouses", data);
  return response;
};
//Warehouse submit
const patchWarehouse = async (data: any) => {
  const response = await axios.patch(`/warehouses/${data.id}`, data);
  return response;
};
//Warehouse submit
const deleteWarehouse = async (data: any) => {
  const response = await axios.delete(`/warehouses`, { data: data });
  return response;
};
//Warehouse delete
const deleteSingleWarehouse = async (data: any) => {
  const response = await axios.delete(`/warehouses/${data.warehouseId}`);
  return response;
};
//Trucks get
const getTrucks = async (data: any) => {
  const response = await axios.get(
    `/warehouses/${data.warehouse_id}/trucks?${
      data?.filter ? `filter=${data?.filter}` : ""
    }&${data?.page ? `page=${data?.page}` : ""}&${
      data?.from ? `from=${data?.from}` : ""
    }&${data?.to ? `to=${data?.to}` : ""}&${
      data?.order_id ? `order_id=${data?.order_id}` : ""
    }&${data?.vendor_id ? `vendor_id=${data?.vendor_id}` : ""}`
  );
  return response;
};
//Trucks patch
const confirmTruck = async (data: any) => {
  const response = await axios.patch(
    `/warehouses/${data.warehouseId}/trucks/${data?.truckId}`,
    data
  );
  return response;
};
//Trucks delete
const deleteTruck = async (data: any) => {
  const response = await axios.delete(
    `/warehouses/${data.warehouseId}/trucks/${data?.truckId}`,
    data
  );
  return response;
};
// Inventories list
const getWarehouseInventories = async (data: any) => {
  const response = await axios.get(
    `/warehouses/${data.warehouseId}/inventory-items`
  );
  return response;
};

//Users get
const getWarehouseUsers = async (data: any) => {
  const response = await axios.get(`/warehouses/${data.warehouseId}/users`);
  return response;
};
//Users post
const postWarehouseUsers = async (data: any) => {
  const response = await axios.post(
    `/warehouses/${data.warehouseId}/users`,
    data
  );
  return response;
};
//Users delete
const deleteWarehouseUsers = async (data: any) => {
  const response = await axios.delete(`/warehouses/${data.warehouseId}/users`, {
    data: data,
  });
  return response;
};

//Product-items GET
const getWarehouseProductItems = async (data: any) => {
  const response = await axios.get(
    `/warehouses/${data.warehouseId}/product-items/${data.productId}
    ?page=${data.page}
    &query=${data.search ? data.search : ""}
    &${data.limit ? `limit=${data.limit}` : ""}`
  );
  return response;
};
//Product-items EXPORT
const exportWarehouseProductItems = async (data: any) => {
  const response = await axios({
    url: `/warehouses/${data.warehouseId}/product-items/${data.productId}
    ?page=${data.page}
    &query=${data.search ? data.search : ""}
    &export_excel=true`,
    method: "get",
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${Date.now()}.xlsx`);
  document.body.appendChild(link);
  link.click();

  return response;
};
//Product-items Post
const postWarehouseProductItem = async (data: any) => {
  const response = await axios.post(
    `/warehouses/${data.warehouseId}/product-items`
  );
  return response;
};
//Product-items barcode Patch
const patchWarehouseProductItemBarcode = async (data: any) => {
  const response = await axios.patch(
    `/warehouses/${data.warehouseId}/product-items/${data.barcode}/update`
  );
  return response;
};
//Product-items Delete
const deleteWarehouseProductItem = async (data: any) => {
  const response = await axios.delete(
    `/warehouses/${data.warehouseId}/productItem/${data.productitemId}`
  );
  return response;
};

//Placements GET
const getWarehousePlacements = async (data: any) => {
  const response = await axios.get(
    `/warehouses/${data.warehouseId}/placements?page=${data.page}&${
      data.limit ? `limit=${data.limit}` : ""
    }`
  );
  return response;
};
//Placements Post
const postWarehousePlacement = async (data: any) => {
  const response = await axios.post(
    `/warehouses/${data.warehouseId}/placements`
  );
  return response;
};
//Placements Delete
const deleteWarehousePlacement = async (data: any) => {
  const response = await axios.delete(
    `/warehouses/${data.warehouseId}/placements/${data.placementId}`
  );
  return response;
};

const WarehouseServices = {
  getWarehouses,
  getWarehousesId,
  postWarehouse,
  patchWarehouse,
  deleteWarehouse,
  deleteSingleWarehouse,
  getTrucks,
  confirmTruck,
  deleteTruck,
  getWarehouseInventories,
  getWarehouseUsers,
  postWarehouseUsers,
  deleteWarehouseUsers,
  getWarehouseProductItems,
  exportWarehouseProductItems,
  postWarehouseProductItem,
  patchWarehouseProductItemBarcode,
  deleteWarehouseProductItem,
  getWarehousePlacements,
  postWarehousePlacement,
  deleteWarehousePlacement,
};
export default WarehouseServices;
