import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { CustomersEditForm } from "../components/CustomersEditForm";
import { useLocation } from "react-router-dom";
import { AppDispatch, RootState } from "src/store";
import { getCustomerId, reset as resetCustomer } from "../redux/CustomersSlice";
import Loading from "src/components/Loading";

const CustomersEdit = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const { state } = location as any;

  const [customerData, setCustomerData] = useState([] as any);
  const [customerDataLoading, setCustomerDataLoading] = useState(true);

  const {
    singleCustomer: singleCustomerData,
    isLoading: isCustomerDataLoading,
    isError: isCustomerDataError,
    isSuccess: isCustomerDataSuccess,
    message: customerDataMessage,
  } = useSelector((state: RootState) => state.customers);

  useEffect(() => {
    if (isCustomerDataError) {
      enqueueSnackbar(customerDataMessage, { variant: "error" });
    }
    if (isCustomerDataSuccess) {
      setCustomerData(singleCustomerData);
      setCustomerDataLoading(false);
    }
    return () => {
      dispatch(resetCustomer());
    };
  }, [
    isCustomerDataError,
    isCustomerDataSuccess,
    customerDataMessage,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(
      getCustomerId({
        id: Number(state?.data?.id),
      })
    );

    return () => {
      setCustomerDataLoading(true);
    };
  }, []);

  return (
    <PageTemplate
      isTransparent
      crumbs={[
        {
          label: "customers",
          href: "/customers/customers-list",
          depth: -1,
        },
        {
          label: "Edit Customer",
        },
      ]}
    >
      {customerDataLoading ? (
        <Loading />
      ) : (
        <CustomersEditForm customerData={customerData} />
      )}
    </PageTemplate>
  );
};

export default CustomersEdit;
