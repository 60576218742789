import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SaleOrdersServices from "./SaleOrdersServices";

const initialState = {
  isGetDocumentsError: false,
  isAddDocumentsError: false,
  isDeleteDocumentsError: false,
  isGetShipmentCarriersError: false,
  isDeleteShipmentCarriersError: false,
  isReceiveProductError: false,
  isAddShipmentCarriersError: false,
  isGetShipmentCarrierProductsError: false,
  isAddShipmentCarrierProductError: false,
  isDeleteShipmentCarrierProductError: false,
  isAddSealNumberError: false,
  isGetSingleCarrierError: false,
  isGetDocumentsSuccess: false,
  isAddDocumentsSuccess: false,
  isDeleteDocumentsSuccess: false,
  isGetShipmentCarriersSuccess: false,
  isDeleteShipmentCarriersSuccess: false,
  isAddShipmentCarriersSuccess: false,
  isGetShipmentCarrierProductsSuccess: false,
  isAddShipmentCarrierProductSuccess: false,
  isDeleteShipmentCarrierProductSuccess: false,
  isReceiveProductSuccess: false,
  isAddSealNumberSuccess: false,
  isGetSingleCarrierSuccess: false,
  isGetDocumentsLoading: false,
  isAddDocumentsLoading: false,
  isDeleteDocumentsLoading: false,
  isGetShipmentCarriersLoading: true,
  isDeleteShipmentCarriersLoading: true,
  isAddShipmentCarriersLoading: false,
  isGetShipmentCarrierProductsLoading: true,
  isAddShipmentCarrierProductLoading: false,
  isDeleteShipmentCarrierProductLoading: true,
  isReceiveProductLoading: false,
  isAddSealNumberLoading: false,
  isGetSingleCarrierLoading: true,
  getDocumentsMessage: "",
  addDocumentsMessage: "",
  deleteDocumentsMessage: "",
  getShipmentCarriersMessage: "",
  deleteShipmentCarriersMessage: "",
  getShipmentCarrierProductsMessage: "",
  addShipmentCarrierProductMessage: "",
  deleteShipmentCarrierProductMessage: "",
  addShipmentCarriersMessage: "",
  receiveProductMessage: "",
  addSealNumberMessage: "",
  getSingleCarrierMessage: "",
  documents: [],
  addDocuments: [],
  shipmentCarriers: [],
  shipmentCarriersMeta: [],
  shipmentCarrierProducts: [],
  shipmentCarrierProductsMeta: [],
  singleCarrier: [],
};

export const getSaleOrderCarrierDocuments = createAsyncThunk(
  "Carrier/documents/get",
  async (id: number, thunkAPI) => {
    try {
      return await SaleOrdersServices.getSaleOrderCarrierDocuments(id);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const addSaleOrderCarrierDocuments = createAsyncThunk(
  "Carrier/documents/post",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.addSaleOrderCarrierDocuments(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteSaleOrderCarrierDocuments = createAsyncThunk(
  "Carrier/documents/delete",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.deleteSaleOrderCarrierDocuments(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getSaleOrderShipmentCarriers = createAsyncThunk(
  "SaleOrders/origin-carriers",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.getSaleOrderShipmentCarriers(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getSaleOrderShipmentCarrierId = createAsyncThunk(
  "SaleOrders/carrier-id",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.getSaleOrderShipmentCarrierId(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const addSaleOrderShipmentCarrier = createAsyncThunk(
  "SaleOrders/add-carriers",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.addSaleOrderShipmentCarrier(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteSaleOrderShipmentCarrier = createAsyncThunk(
  "SaleOrders/delete-carriers",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.deleteSaleOrderShipmentCarrier(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getSaleOrderShipmentCarrierProducts = createAsyncThunk(
  "SaleOrders/carrier-products",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.getSaleOrderShipmentCarrierProducts(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const addSaleOrderShipmentCarrierProduct = createAsyncThunk(
  "SaleOrders/carrier-products-add",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.addSaleOrderShipmentCarrierProduct(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteSaleOrderShipmentCarrierProduct = createAsyncThunk(
  "SaleOrders/carrier-products-delete",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.deleteSaleOrderShipmentCarrierProduct(
        data
      );
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const receiveCarrierProduct = createAsyncThunk(
  "SaleOrders/product-recieve",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.receiveCarrierProduct(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const addCarrierSealNumber = createAsyncThunk(
  "SaleOrders/sealNumber-add",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.addCarrierSealNumber(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const editCarrierSealNumber = createAsyncThunk(
  "SaleOrders/sealNumber-edit",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.editCarrierSealNumber(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const SaleOrderslice = createSlice({
  name: "SaleOrders",
  initialState,
  reducers: {
    reset: (state) => {
      state.isGetDocumentsLoading = false;
      state.isAddDocumentsLoading = false;
      state.isDeleteDocumentsLoading = false;
      state.isGetShipmentCarriersLoading = false;
      state.isDeleteShipmentCarriersLoading = false;
      state.isAddShipmentCarriersLoading = false;
      state.isGetShipmentCarrierProductsLoading = false;
      state.isAddShipmentCarrierProductLoading = false;
      state.isDeleteShipmentCarrierProductLoading = false;
      state.isReceiveProductLoading = false;
      state.isAddSealNumberLoading = false;
      state.isGetSingleCarrierLoading = false;
      state.isGetDocumentsSuccess = false;
      state.isAddDocumentsSuccess = false;
      state.isDeleteDocumentsSuccess = false;
      state.isGetShipmentCarriersSuccess = false;
      state.isDeleteShipmentCarriersSuccess = false;
      state.isAddShipmentCarriersSuccess = false;
      state.isGetShipmentCarrierProductsSuccess = false;
      state.isAddShipmentCarrierProductSuccess = false;
      state.isDeleteShipmentCarrierProductSuccess = false;
      state.isReceiveProductSuccess = false;
      state.isAddSealNumberSuccess = false;
      state.isGetSingleCarrierSuccess = false;
      state.isGetDocumentsError = false;
      state.isAddDocumentsError = false;
      state.isDeleteDocumentsError = false;
      state.isGetShipmentCarriersError = false;
      state.isDeleteShipmentCarriersError = false;
      state.isAddShipmentCarriersError = false;
      state.isGetShipmentCarrierProductsError = false;
      state.isAddShipmentCarrierProductError = false;
      state.isDeleteShipmentCarrierProductError = false;
      state.isReceiveProductError = false;
      state.isAddSealNumberError = false;
      state.isGetSingleCarrierError = false;
      state.getDocumentsMessage = "";
      state.addDocumentsMessage = "";
      state.deleteDocumentsMessage = "";
      state.getShipmentCarriersMessage = "";
      state.deleteShipmentCarriersMessage = "";
      state.addShipmentCarriersMessage = "";
      state.getShipmentCarrierProductsMessage = "";
      state.addShipmentCarrierProductMessage = "";
      state.deleteShipmentCarrierProductMessage = "";
      state.receiveProductMessage = "";
      state.addSealNumberMessage = "";
      state.getSingleCarrierMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSaleOrderCarrierDocuments.pending, (state) => {
        state.isGetDocumentsLoading = true;
      })
      .addCase(getSaleOrderCarrierDocuments.fulfilled, (state, action) => {
        state.isGetDocumentsLoading = false;
        state.isGetDocumentsSuccess = true;
        state.documents = action.payload.data.results;
        state.getDocumentsMessage = `${action.payload.data.message}`;
      })
      .addCase(getSaleOrderCarrierDocuments.rejected, (state, action) => {
        state.isGetDocumentsLoading = false;
        state.isGetDocumentsError = true;
        state.getDocumentsMessage = `${action.payload}`;
      })

      .addCase(addSaleOrderCarrierDocuments.pending, (state) => {
        state.isAddDocumentsLoading = true;
      })
      .addCase(addSaleOrderCarrierDocuments.fulfilled, (state, action) => {
        state.isAddDocumentsLoading = false;
        state.isAddDocumentsSuccess = true;
        state.addDocuments = action.payload.data.results;
        state.addDocumentsMessage = `${action.payload.data.message}`;
      })
      .addCase(addSaleOrderCarrierDocuments.rejected, (state, action) => {
        state.isAddDocumentsLoading = false;
        state.isAddDocumentsError = true;
        state.addDocumentsMessage = `${action.payload}`;
      })

      .addCase(deleteSaleOrderCarrierDocuments.pending, (state) => {
        state.isDeleteDocumentsLoading = true;
      })
      .addCase(deleteSaleOrderCarrierDocuments.fulfilled, (state, action) => {
        state.isDeleteDocumentsLoading = false;
        state.isDeleteDocumentsSuccess = true;
        state.deleteDocumentsMessage = `${action.payload.data.message}`;
      })
      .addCase(deleteSaleOrderCarrierDocuments.rejected, (state, action) => {
        state.isDeleteDocumentsLoading = false;
        state.isDeleteDocumentsError = true;
        state.deleteDocumentsMessage = `${action.payload}`;
      })

      .addCase(getSaleOrderShipmentCarriers.pending, (state) => {
        state.isGetShipmentCarriersLoading = true;
      })
      .addCase(getSaleOrderShipmentCarriers.fulfilled, (state, action) => {
        state.isGetShipmentCarriersLoading = false;
        state.isGetShipmentCarriersSuccess = true;
        state.shipmentCarriers = action.payload.data.results;
        state.shipmentCarriersMeta = action.payload.data.meta;
        state.getShipmentCarriersMessage = `${action.payload.data.message}`;
      })
      .addCase(getSaleOrderShipmentCarriers.rejected, (state, action) => {
        state.isGetShipmentCarriersLoading = false;
        state.isGetShipmentCarriersError = true;
        state.getShipmentCarriersMessage = `${action.payload}`;
      })

      .addCase(getSaleOrderShipmentCarrierId.pending, (state) => {
        state.isGetSingleCarrierLoading = true;
      })
      .addCase(getSaleOrderShipmentCarrierId.fulfilled, (state, action) => {
        state.isGetSingleCarrierLoading = false;
        state.isGetSingleCarrierSuccess = true;
        state.singleCarrier = action.payload.data.results;
        state.getSingleCarrierMessage = `${action.payload.data.message}`;
      })
      .addCase(getSaleOrderShipmentCarrierId.rejected, (state, action) => {
        state.isGetSingleCarrierLoading = false;
        state.isGetSingleCarrierError = true;
        state.getSingleCarrierMessage = `${action.payload}`;
      })

      .addCase(addSaleOrderShipmentCarrier.pending, (state) => {
        state.isAddShipmentCarriersLoading = true;
      })
      .addCase(addSaleOrderShipmentCarrier.fulfilled, (state, action) => {
        state.isAddShipmentCarriersLoading = false;
        state.isAddShipmentCarriersSuccess = true;
        state.addShipmentCarriersMessage = `${action.payload.data.message}`;
      })
      .addCase(addSaleOrderShipmentCarrier.rejected, (state, action) => {
        state.isAddShipmentCarriersLoading = false;
        state.isAddShipmentCarriersError = true;
        state.addShipmentCarriersMessage = `${action.payload}`;
      })

      .addCase(deleteSaleOrderShipmentCarrier.pending, (state) => {
        state.isDeleteShipmentCarriersLoading = true;
      })
      .addCase(deleteSaleOrderShipmentCarrier.fulfilled, (state, action) => {
        state.isDeleteShipmentCarriersLoading = false;
        state.isDeleteShipmentCarriersSuccess = true;
        state.deleteShipmentCarriersMessage = `${action.payload.data.message}`;
      })
      .addCase(deleteSaleOrderShipmentCarrier.rejected, (state, action) => {
        state.isDeleteShipmentCarriersLoading = false;
        state.isDeleteShipmentCarriersError = true;
        state.deleteShipmentCarriersMessage = `${action.payload}`;
      })

      .addCase(getSaleOrderShipmentCarrierProducts.pending, (state) => {
        state.isGetShipmentCarrierProductsLoading = true;
      })
      .addCase(
        getSaleOrderShipmentCarrierProducts.fulfilled,
        (state, action) => {
          state.isGetShipmentCarrierProductsLoading = false;
          state.isGetShipmentCarrierProductsSuccess = true;
          state.shipmentCarrierProducts = action.payload.data.results;
          state.shipmentCarrierProductsMeta = action.payload.data.meta;
          state.getShipmentCarrierProductsMessage = `${action.payload.data.message}`;
        }
      )
      .addCase(
        getSaleOrderShipmentCarrierProducts.rejected,
        (state, action) => {
          state.isGetShipmentCarrierProductsLoading = false;
          state.isGetShipmentCarrierProductsError = true;
          state.getShipmentCarrierProductsMessage = `${action.payload}`;
        }
      )

      .addCase(addSaleOrderShipmentCarrierProduct.pending, (state) => {
        state.isAddShipmentCarrierProductLoading = true;
      })
      .addCase(
        addSaleOrderShipmentCarrierProduct.fulfilled,
        (state, action) => {
          state.isAddShipmentCarrierProductLoading = false;
          state.isAddShipmentCarrierProductSuccess = true;
          state.addShipmentCarrierProductMessage = `${action.payload.data.message}`;
        }
      )
      .addCase(addSaleOrderShipmentCarrierProduct.rejected, (state, action) => {
        state.isAddShipmentCarrierProductLoading = false;
        state.isAddShipmentCarrierProductError = true;
        state.addShipmentCarrierProductMessage = `${action.payload}`;
      })

      .addCase(deleteSaleOrderShipmentCarrierProduct.pending, (state) => {
        state.isDeleteShipmentCarrierProductLoading = true;
      })
      .addCase(
        deleteSaleOrderShipmentCarrierProduct.fulfilled,
        (state, action) => {
          state.isDeleteShipmentCarrierProductLoading = false;
          state.isDeleteShipmentCarrierProductSuccess = true;
          state.deleteShipmentCarrierProductMessage = `${action.payload.data.message}`;
        }
      )
      .addCase(
        deleteSaleOrderShipmentCarrierProduct.rejected,
        (state, action) => {
          state.isDeleteShipmentCarrierProductLoading = false;
          state.isDeleteShipmentCarrierProductError = true;
          state.deleteShipmentCarrierProductMessage = `${action.payload}`;
        }
      )

      .addCase(receiveCarrierProduct.pending, (state) => {
        state.isReceiveProductLoading = true;
      })
      .addCase(receiveCarrierProduct.fulfilled, (state, action) => {
        state.isReceiveProductLoading = false;
        state.isReceiveProductSuccess = true;
        state.receiveProductMessage = `${action.payload.data.message}`;
      })
      .addCase(receiveCarrierProduct.rejected, (state, action) => {
        state.isReceiveProductLoading = false;
        state.isReceiveProductError = true;
        state.receiveProductMessage = `${action.payload}`;
      })

      .addCase(addCarrierSealNumber.pending, (state) => {
        state.isAddSealNumberLoading = true;
      })
      .addCase(addCarrierSealNumber.fulfilled, (state, action) => {
        state.isAddSealNumberLoading = false;
        state.isAddSealNumberSuccess = true;
        state.addSealNumberMessage = `${action.payload.data.message}`;
      })
      .addCase(addCarrierSealNumber.rejected, (state, action) => {
        state.isAddSealNumberLoading = false;
        state.isAddSealNumberError = true;
        state.addSealNumberMessage = `${action.payload}`;
      })
      .addCase(editCarrierSealNumber.pending, (state) => {
        state.isAddSealNumberLoading = true;
      })
      .addCase(editCarrierSealNumber.fulfilled, (state, action) => {
        state.isAddSealNumberLoading = false;
        state.isAddSealNumberSuccess = true;
        state.addSealNumberMessage = `${action.payload.data.message}`;
      })
      .addCase(editCarrierSealNumber.rejected, (state, action) => {
        state.isAddSealNumberLoading = false;
        state.isAddSealNumberError = true;
        state.addSealNumberMessage = `${action.payload}`;
      });
  },
});

export const { reset } = SaleOrderslice.actions;
export default SaleOrderslice.reducer;
