import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React, { ReactNode } from "react";
import colors from "src/theme/colors";
interface Props {
  children: ReactNode;
  iconName?: string;
}
const StyledContainer = styled("div")`
  background: #e3f6ff;
  border-radius: 30px;
  padding: 6px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const FilterHolder = (props: Props) => {
  return (
    <StyledContainer>
      {props.iconName ? (
        <img
          src={process.env.PUBLIC_URL + `/assets/icons/${props.iconName}.svg`}
        />
      ) : (
        null
      )}
      <Typography
        pl={3}
        fontWeight={700}
        fontSize="14px"
        color={colors.secondary[800]}
      >
        {props.children}
      </Typography>
    </StyledContainer>
  );
};

export default FilterHolder;
