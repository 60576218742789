import {
  Grid,
  Box,
  Avatar,
  Typography,
  Modal,
  styled,
  IconButton,
} from "@mui/material";
import ConfirmModal from "src/components/Modal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "src/components/Button";
import FormHeading from "src/components/FormHeading";
import InfoItems from "src/components/InfoItems";
import Chat from "src/features/chat/components/Chat";
import { AppDispatch } from "src/store";
import colors from "src/theme/colors";
import { deleteEmployes } from "../redux/EmployesSlice";
import {
  FileUploaderViewer,
  FileUploaderViewerType,
} from "src/components/FileUploader/FileUploaderViewer/FileUploaderViewer";
import { UploadedFileType } from "src/components/FileUploader/UploadZone/UploadZone";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { PaperBox } from "src/components/Paper/Paper";

type Props = {
  isDeleteLoading?: boolean;
  data: {
    id: string;
    id_card_number: string;
    code: string;
    phone: string;
    cellphone: string;
    email: string;
    address: string;
    warehouse: any;
    notes: string;
    documents: UploadedFileType[];
    username: string;
    avatar: string;
    name: string;
    roles: any;
  };
};

const EmployesInfoData = (props: Props) => {
  const { isDeleteLoading } = props;
  const {
    id_card_number,
    phone,
    cellphone,
    email,
    address,
    code,
    username,
    notes,
    warehouse,
    documents,
    avatar,
    name,
    roles,
    id,
  } = props.data;

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const moveToEdit = () => {
    navigate("/employees/employee-edit", {
      state: { Data: props?.data, forwardedFromInfo: true },
    });
  };

  const onDelete = () => {
    const deleteValue = {
      user_ids: [Number(id)],
    };
    dispatch(deleteEmployes(deleteValue)).then(() =>
      navigate("/employes/employes-list")
    );
  };

  const [isChatOpen, setIsChatOpen] = useState(false);
  const handelOpenChat = (open: boolean) => {
    setIsChatOpen(!open);
  };

  return (
    <>
      <Container>
        <LeftBox>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar sx={{ height: 96, width: 96 }}>
              <img src={avatar} alt={name} style={{ height: 96, width: 96 }} />
            </Avatar>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                fontSize={17}
                fontWeight={700}
                color={colors.gray[850]}
                mt={1}
              >
                {name}
              </Typography>
              <Typography
                fontSize={15}
                fontWeight={400}
                color={colors.gray[700]}
              >
                {email ?? "Not Found"}
              </Typography>
            </Box>
          </Box>

          <Box width="100%">
            <InfoItems
              title="ID Card Number"
              subTilte={id_card_number ?? "Not Found"}
            />
          </Box>
          <Box width="100%">
            <InfoItems title="Phone Number" subTilte={phone ?? "Not Found"} />
          </Box>

          <Actions width="100%">
            {/* <Box marginRight={12}>
            <StyledLoadingButton
              variant="outlined"
              color="error"
              fullWidth
              loading={isDeleteLoading}
              onClick={() => setIsDeleteModalOpen(true)}
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.error[500]}
              >
                Remove Employee
              </Typography>
            </StyledLoadingButton>
          </Box> */}
            <Box>
              <StyledButton
                variant="contained"
                color="secondary"
                fullWidth
                disabled={isDeleteLoading}
                onClick={moveToEdit}
              >
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color={colors.base.white}
                >
                  Edit Employee
                </Typography>
              </StyledButton>
            </Box>
          </Actions>
        </LeftBox>

        <RightBox>
          <Grid md={8} sm={12}>
            <PaperBox>
              <Box
                pb={6}
                display="flex"
                flexDirection={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <SubTitleBar>Personal information</SubTitleBar>
                <IconButton onClick={() => setIsChatOpen(true)}>
                  <img
                    src={process.env.PUBLIC_URL + `/assets/icons/ChatIcon.svg`}
                  />
                </IconButton>
              </Box>
              <Box
                pb={12}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Avatar sx={{ height: 96, width: 96 }}>
                  <img
                    src={avatar}
                    alt={name}
                    style={{ height: 96, width: 96 }}
                  />
                </Avatar>
              </Box>

              <Grid
                container
                columnSpacing={{ xs: 0, md: 16 }}
                rowSpacing={{ xs: 12, md: 12 }}
                xs={12}
                md={10}
                mb={14}
              >
                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <InfoItems title="Name" subTilte={name ?? "Not Found"} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <InfoItems
                      title="Role"
                      subTilte={roles[0]?.title ?? "Not Found"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <InfoItems
                      title="Mobile Number"
                      subTilte={cellphone ?? "Not Found"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <InfoItems
                      title="Email address"
                      subTilte={email ?? "Not Found"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <InfoItems
                      title="Address"
                      subTilte={address ?? "Not Found"}
                    />
                  </Box>
                </Grid>
              </Grid>
            </PaperBox>

            <PaperBox>
              <SubTitleBar>Organizational information</SubTitleBar>
              <Grid
                container
                columnSpacing={{ xs: 0, md: 16 }}
                rowSpacing={{ xs: 12, md: 12 }}
                xs={12}
                md={10}
                mb={14}
              >
                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <InfoItems
                      title="Organization Code"
                      subTilte={code ?? "Not Found"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <InfoItems
                      title="Workplace"
                      subTilte={warehouse?.title ?? "Not Found"}
                      description1={warehouse?.address ?? "Not Found"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <InfoItems
                      title="Organizational username"
                      subTilte={username ?? "Not Found"}
                    />
                  </Box>
                </Grid>
              </Grid>
            </PaperBox>

            <PaperBox>
              <SubTitleBar>Notes & Documents</SubTitleBar>
              <Grid
                container
                columnSpacing={{ xs: 0, md: 16 }}
                rowSpacing={{ xs: 12, md: 12 }}
                xs={12}
                md={10}
                mb={4}
              >
                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <InfoItems
                      title="Notes"
                      subTilte={notes ?? "Not Found"}
                      description1="An inventory list is a complete, itemized list of every product your business has in stock. "
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FileViewerWrapper>
                    <FileUploaderViewer
                      type={FileUploaderViewerType.Multiple}
                      files={documents}
                    />
                  </FileViewerWrapper>
                </Grid>
              </Grid>
            </PaperBox>
          </Grid>
        </RightBox>
      </Container>

      <Chat
        userId={id}
        name={name}
        avatar={avatar}
        open={isChatOpen}
        onDismiss={(open) => handelOpenChat(open)}
      />
      <ConfirmModal
        title="Remove Employee"
        iconName="ModalRemove"
        open={isDeleteModalOpen}
        isLoading={isDeleteLoading}
        onDismiss={() => setIsDeleteModalOpen(false)}
        onConfirm={onDelete}
        confrimText="Remove"
        cancelText="Cancel"
        maxWidth={450}
      >
        <Typography
          textAlign="center"
          fontSize={14}
          fontWeight={400}
          color={colors.gray[850]}
        >
          Are you sure you want to remove <b>{name}</b>, warehouse operator at
          kabwe from your employees?
        </Typography>
      </ConfirmModal>
    </>
  );
};

export default EmployesInfoData;

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "16px",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

const LeftBox = styled(Box)(({ theme }) => ({
  width: "300px",
  backgroundColor: "#ffffff",
  padding: "32px 24px",
  borderRadius: "5px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "24px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const RightBox = styled(Box)(({ theme }) => ({
  width: "calc(100% - 300px)",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    marginBottom: "16px",
  },
}));

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  & > div {
    width: 184px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    & > div {
      width: 142px;
    }
  }
`;

const FileViewerWrapper = styled(Box)`
  width: 170px;
`;

const SubTitleBar = styled("div")`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  color: ${colors.gray[850]};
  font-size: 21px;
  font-weight: 500;
`;
