import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "src/components/Loading";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { AppDispatch, RootState } from "src/store";
import ItemNewForm from "../components/ItemNewForm";
import { reset } from "../redux/ItemSlice";
import { getQualities } from "../redux/qualitySlice";
import { getUnits } from "../redux/unitSlice";

const ItemNew = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const [unitData, setUnitData] = useState([] as any);
  const [qualitiesData, setQualitiesData] = useState([] as any);

  const { units, isError, isSuccess, message, isLoading } = useSelector(
    (state: RootState) => state.units
  );
  const { qualities } = useSelector((state: RootState) => state.qualities);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      setUnitData(units);
      setQualitiesData(qualities);
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, isLoading, isSuccess, message]);

  useEffect(() => {
    dispatch(
      getQualities({
        page: page,
      })
    ).then(() =>
      dispatch(
        getUnits({
          page: page,
        })
      )
    );
  }, []);

  return (
    <PageTemplate
      isTransparent
      crumbs={[
        {
          label: "materials",
          href: "/materials/materials-list",
          depth: -1,
        },
        {
          label: "new material",
        },
      ]}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <ItemNewForm units={unitData} qualities={qualitiesData} />
      )}
    </PageTemplate>
  );
};

export default ItemNew;
