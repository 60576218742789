import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  employesdeleteValue,
  employesPatchValue,
  getData,
  getIdValue,
} from "./types";
import employesServices from "./EmployesServices";

const initialState = {
  isError: false,
  isDeleteError: false,
  isSingleError: false,
  isSuccess: false,
  isPatchSuccess: false,
  isSingleSuccess: false,
  isDeleteSuccess: false,
  isLoading: true,
  isSingleLoading: true,
  isCreateLoading: false,
  isDeleteLoading: true,
  message: "",
  singleMessage: "",
  deleteMessage: "",
  employes: [],
  employesMeta: [],
  singleEmployee: [],
};

export const getEmployes = createAsyncThunk(
  "employes",
  async (data: getData, thunkAPI) => {
    try {
      return await employesServices.getEmployes(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteEmployes = createAsyncThunk(
  "employes/delete",
  async (data: employesdeleteValue, thunkAPI) => {
    try {
      return await employesServices.deleteEmployee(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getEmployesId = createAsyncThunk(
  "employes/id",
  async (data: getIdValue, thunkAPI) => {
    try {
      return employesServices.getEmployesId(data.id);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const postEmployes = createAsyncThunk(
  "employes/post",
  async (data: employesPatchValue, thunkAPI) => {
    try {
      return await employesServices.postEmployes(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const patchEmployes = createAsyncThunk(
  "employes/patch",
  async (data: employesPatchValue, thunkAPI) => {
    try {
      return await employesServices.patchEmployes(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const employesSlice = createSlice({
  name: "employes",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSingleLoading = true;
      state.isDeleteLoading = false;
      state.isSuccess = false;
      state.isPatchSuccess = false;
      state.isSingleSuccess = false;
      state.isDeleteSuccess = false;
      state.isError = false;
      state.isSingleError = false;
      state.isDeleteError = false;
      state.message = "";
      state.singleMessage = "";
      state.deleteMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEmployes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.employes = action.payload.data.results;
        state.employesMeta = action.payload.data.meta;
      })
      .addCase(getEmployes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(deleteEmployes.pending, (state) => {
        state.isDeleteLoading = true;
      })
      .addCase(deleteEmployes.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteSuccess = true;
        state.deleteMessage = `${action.payload.data.message}`;
      })
      .addCase(deleteEmployes.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.deleteMessage = `${action.payload}`;
      })

      .addCase(getEmployesId.rejected, (state, action) => {
        state.isSingleLoading = false;
        state.isSingleError = true;
        state.singleMessage = `${action.payload}`;
      })
      .addCase(getEmployesId.pending, (state) => {
        state.isSingleLoading = true;
      })
      .addCase(getEmployesId.fulfilled, (state, action) => {
        state.isSingleLoading = false;
        state.isSingleSuccess = true;
        state.singleEmployee = action.payload.data.results;
      })

      .addCase(patchEmployes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patchEmployes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isPatchSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(patchEmployes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(postEmployes.pending, (state) => {
        state.isLoading = true;
        state.isCreateLoading = true;
      })
      .addCase(postEmployes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(postEmployes.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreateLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      });
  },
});

export const { reset } = employesSlice.actions;
export default employesSlice.reducer;
