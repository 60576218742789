import { Typography } from "@mui/material";
import React from "react";
import colors from "src/theme/colors";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { ArrowCircleUp2 } from "iconsax-react";
import { Bar } from "react-chartjs-2";

const TotalPurchase = () => {
  const [value, setValue] = React.useState("one");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const options = {
    scales: {
      x: {
        stacked: true,
        display: true,
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        display: true,

        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const labels = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [
          2, 3, 5, 6, 3, 2, 1, 0.5, 2, 3, 5, 6, 3, 2, 1, 0.5, 2, 3, 5, 6, 3, 2,
          1, 0.5, 2, 3, 5, 6, 3, 2, 1, 0.5,
        ],
        borderRadius: 5,
        backgroundColor: ["#1797C2", "#FBB000"],
        barBackground: "rgba(221, 224, 229, 1)",
      },
      {
        label: "Dataset 2",
        data: [
          10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10,
          10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10,
        ],
        borderRadius: 5,
        backgroundColor: ["#E4E8EF"],
        barBackground: "rgba(221, 224, 229, 1)",
      },
    ],
  };

  return (
    <Box pr={30}>
      <Box
        display={"flex"}
        alignItems={"center"}
        flexDirection="row"
        justifyContent={"space-between"}
      >
        <Typography color={colors.gray[900]} fontWeight="500" fontSize={18}>
          TotalPurchase
        </Typography>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              color: colors.gray[600],
            }}
            aria-label="secondary tabs example"
          >
            <Tab value="one" label="Yearly" />
            <Tab value="two" label="Monthly" />
            <Tab value="three" label="Daily" />
          </Tabs>
        </Box>
      </Box>
      <Box pt={11} display={"flex"} alignItems={"center"} flexDirection="row">
        <Typography fontSize={20} fontWeight={700} color={colors.gray[900]}>
          40000 MT
        </Typography>
        <Box pl={16} display={"flex"} alignItems={"center"} flexDirection="row">
          <ArrowCircleUp2 size="32" color="#707A8A" variant="Bold" />
          <Box pl={7}>
            <Typography color={colors.gray[600]} fontWeight="500" fontSize={15}>
              0,8%
            </Typography>
            <Typography color={colors.gray[600]} fontWeight="400" fontSize={12}>
              Than last Month
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box pt={20}>
        <Bar options={options} data={data} />
      </Box>
    </Box>
  );
};

export default TotalPurchase;
