/* eslint-disable @typescript-eslint/no-unused-vars */
import Cookies from "js-cookie";

const domain = window.location.hostname.replace("www", "").replace("app.", "");
console.log(`domains => ${domain}`);

const storage = {
  getToken: () => {
    return Cookies.get(`access_token`);
  },
  setToken: (token: string) => {
    Cookies.set(
      `access_token`,
      token,
      window.location.hostname !== "localhost" ? { domain } : {}
    );
  },
  clearToken: () => {
    Cookies.remove(
      `access_token`,
      window.location.hostname !== "localhost" ? { domain } : {}
    );
  },
  getRefToken: () => {
    return Cookies.get(`refresh_token`);
  },
  setRefToken: (token: string) => {
    Cookies.set(
      `refresh_token`,
      token,
      window.location.hostname !== "localhost" ? { domain } : {}
    );
  },
  clearRefToken: () => {
    Cookies.remove(
      `refresh_token`,
      window.location.hostname !== "localhost" ? { domain } : {}
    );
  },
};

export default storage;
