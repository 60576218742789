import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { getCustomerId, reset } from "../redux/CustomersSlice";
import Loading from "src/components/Loading";
import CustomersInfoData from "../components/CustomersInfoData";
import { PageTemplate } from "src/layout/components/PageTemplate";

interface CustomizedState {
  id: string;
}

const RoleInfo = () => {
  const location = useLocation();
  const state = location.state as CustomizedState;
  const { id } = state;

  const [data, setData] = useState([] as any);
  const [loading, setLoading] = useState(true);

  const {
    singleCustomer,
    isDeleteLoading,
    isError,
    isDeleteError,
    isSuccess,
    isDeleteSuccess,
    message,
    deleteMessage,
  } = useSelector((state: RootState) => state.customers);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      setData(singleCustomer);
      setLoading(false);
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, isSuccess, message, dispatch]);

  useEffect(() => {
    if (isDeleteError) {
      enqueueSnackbar(deleteMessage, { variant: "error" });
    }
    if (isDeleteSuccess) {
      enqueueSnackbar(deleteMessage, { variant: "success" });
      navigate("/customers/customers-list");
    }
    return () => {
      dispatch(reset());
    };
  }, [isDeleteError, isDeleteSuccess, deleteMessage, dispatch]);

  useEffect(() => {
    const infoValue = {
      id: Number(id),
    };
    dispatch(getCustomerId(infoValue));
  }, []);

  return (
    <PageTemplate
      isTransparent
      crumbs={[
        {
          label: "customers",
          href: "/customers/customers-list",
          depth: -1,
        },
        {
          label: "customer information",
        },
      ]}
    >
      {loading ? (
        <Loading />
      ) : (
        <CustomersInfoData isDeleteLoading={isDeleteLoading} data={data} />
      )}
    </PageTemplate>
  );
};

export default RoleInfo;
