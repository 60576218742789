import { Typography } from "@mui/material";
import { ReactNode } from "react";
import colors from "src/theme/colors";

type Props = {
  children: ReactNode;
};

const FormHeading = (props: Props) => {
  return (
    <Typography fontSize={21} fontWeight={500} color={colors.gray[850]}>
      {props.children}
    </Typography>
  );
};

export default FormHeading;
