import React from "react";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "src/components/Loading";
import {
  getItems,
  reset as resetItems,
} from "src/features/items/redux/ItemSlice";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { AppDispatch, RootState } from "src/store";
import { ProductsNewForm } from "../components/ProductsNewForm";
import { getIndexVariants } from "../redux/ProductsSlice";

const ProductsNew = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [materialsData, setMaterials] = useState([] as any);

  const {
    items,
    isError: isItemsError,
    isSuccess: isItemsSuccess,
    message: itemsMessage,
    isLoading: isItemsLoading,
  } = useSelector((state: RootState) => state.items);

  const { variants, isVariantsError, isVariantsLoading, variantsMessage } =
    useSelector((state: RootState) => state.products);

  useEffect(() => {
    if (isItemsError) {
      enqueueSnackbar(itemsMessage, { variant: "error" });
    }
    if (isItemsSuccess) {
      setMaterials(items);
    }
    return () => {
      dispatch(resetItems());
    };
  }, [isItemsError, isItemsSuccess, itemsMessage, isItemsLoading]);

  useEffect(() => {
    if (isVariantsError) {
      enqueueSnackbar(variantsMessage, { variant: "error" });
    }
  }, [isVariantsError, isVariantsLoading, variantsMessage]);

  useEffect(() => {
    dispatch(
      getItems({
        page: page,
        limit: 100,
      })
    );
    dispatch(
      getIndexVariants({
        page: page,
        limit: 100,
      })
    );
  }, []);

  return (
    <PageTemplate
      isTransparent
      crumbs={[
        {
          label: "products",
          href: "/products/products-list",
          depth: -1,
        },
        {
          label: "New Product",
        },
      ]}
    >
      {isItemsLoading ? (
        <Loading />
      ) : (
        <ProductsNewForm materials={items} variants={variants} />
      )}
    </PageTemplate>
  );
};

export default ProductsNew;
