import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import itemServices from "./ItemServices";
import { getData } from "./types";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  qualities: [],
  qualitiesMeta: [],
};

export const getQualities = createAsyncThunk(
  "qualities",
  async (data: getData, thunkAPI) => {
    try {
      return await itemServices.getQualities(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const qualitiesSlice = createSlice({
  name: "qualities",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQualities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getQualities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.qualities = action.payload.data.results;
        state.qualitiesMeta = action.payload.data.meta;
      })
      .addCase(getQualities.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});
export const { reset } = qualitiesSlice.actions;
export default qualitiesSlice.reducer;
