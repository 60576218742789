import { Route, Routes } from "react-router-dom";
import LocalInspectionList from "../pages/LocalInspectionList";
import LocalInspectionNew from "../pages/LocalInspectionNew";

export const LocalInspectionRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          path="/local-inspections-list"
          element={<LocalInspectionList />}
        />
        <Route path="/local-inspection-new" element={<LocalInspectionNew />} />
      </Routes>
    </>
  );
};
