import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { getordersId, reset } from "../redux/orderSlice";
import Loading from "src/components/Loading";
import OrderInfoData from "../componets/details/OrderInfoData";
import ReceivedTrucks from "../componets/details/ReceivedTrucks";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { PaperBox } from "src/components/Paper/Paper";

function CustomTabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

interface CustomizedState {
  id: string;
}

const OrderInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const { id } = state;

  const [reviewdata, setReviewData] = useState([] as any);
  const [reviewloading, setReviewLoading] = useState(true);
  const [value, setValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const {
    singleorder,
    isError,
    isDeleteError,
    isSuccess,
    isDeleteSuccess,
    message,
    deleteMessage,
  } = useSelector((state: RootState) => state.orders);
  const dispatch = useDispatch<AppDispatch>();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      setReviewData(singleorder);
      setReviewLoading(false);
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, isSuccess, message, dispatch]);

  useEffect(() => {
    if (isDeleteError) {
      enqueueSnackbar(deleteMessage, { variant: "error" });
    }
    if (isDeleteSuccess) {
      enqueueSnackbar(deleteMessage, { variant: "success" });
      navigate("/orders/orders-list");
    }
    return () => {
      dispatch(reset());
    };
  }, [isDeleteError, isDeleteSuccess, deleteMessage, dispatch]);

  useEffect(() => {
    const infoValue = {
      id: Number(id),
    };
    dispatch(getordersId(infoValue));
  }, []);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <PageTemplate
      isTransparent
      crumbs={[
        {
          label: "orders",
          href: "/orders/orders-list",
          depth: -1,
        },
        {
          label: "order information",
        },
      ]}
    >
      <PaperBox>
        <Tabs
          value={value}
          onChange={handleTabChange}
          sx={{
            color: "#0084A8",
            "& .MuiTab-root": {
              fontSize: "15px",
            },
            "& .Mui-selected": {
              color: "#0084A8",
            },
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#0084A8",
            },
          }}
          aria-label="basic tabs example"
        >
          <Tab label="Order Information" {...a11yProps(0)} />
          <Tab label="Information Sent By Operator" {...a11yProps(1)} />
        </Tabs>
      </PaperBox>
      <CustomTabPanel value={value} index={0}>
        {reviewloading ? <Loading /> : <OrderInfoData data={reviewdata} />}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ReceivedTrucks id={reviewdata?.warehouse?.id} />
      </CustomTabPanel>
    </PageTemplate>
  );
};
export default OrderInfo;
