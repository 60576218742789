import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loading from "src/components/Loading";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { AppDispatch, RootState } from "src/store";
import VendorEditForm from "../components/VendorEditForm";
import { getVendorsId, reset as resetVendor } from "../redux/VendorSlice";

const VendorEdit = () => {
  const location = useLocation();
  const state = location.state as any;
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [vendorData, setVendorData] = useState([] as any);
  const [vendorDataLoading, setVendorDataLoading] = useState(true);

  const {
    singleVendor: singleVendorData,
    isSingleLoading: isVendorDataLoading,
    isError: isVendorDataError,
    isSuccess: isVendorDataSuccess,
    message: vendorDataMessage,
  } = useSelector((state: RootState) => state.vendor);

  useEffect(() => {
    if (isVendorDataError) {
      enqueueSnackbar(vendorDataMessage, { variant: "error" });
    }
    if (isVendorDataSuccess) {
      setVendorData(singleVendorData);
      setVendorDataLoading(false);
    }
    return () => {
      dispatch(resetVendor());
    };
  }, [isVendorDataError, isVendorDataSuccess, vendorDataMessage, dispatch]);

  useEffect(() => {
    dispatch(
      getVendorsId({
        id: Number(state?.vendorData?.id),
      })
    );

    return () => {
      setVendorDataLoading(true);
    };
  }, []);

  return (
    <PageTemplate
      isTransparent
      crumbs={
        state.forwardedFromInfo
          ? [
              {
                label: "vendors",
                href: "/vendors/vendors-list",
                depth: -2,
              },
              {
                label: "vendor info",
                href: "/vendors/vendor-information",
                depth: -1,
              },
              {
                label: "edit vendor",
              },
            ]
          : [
              {
                label: "vendors",
                href: "/vendors/vendors-list",
                depth: -1,
              },
              {
                label: "edit vendor",
              },
            ]
      }
    >
      {vendorDataLoading ? (
        <Loading />
      ) : (
        <VendorEditForm vendorData={vendorData as any} />
      )}
    </PageTemplate>
  );
};

export default VendorEdit;
