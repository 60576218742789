import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loading from "src/components/Loading";
import { getRoles } from "src/features/role/redux/roleSlice";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { AppDispatch, RootState } from "src/store";
import EmployesEidtForm from "../components/EmployesEditForm";
import { getEmployesId, reset as resetEmployee } from "../redux/EmployesSlice";

const EmployesEdit = () => {
  const location = useLocation();
  const state = location.state as any;
  const [page, setPage] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const [roleData, setRoleData] = useState([] as any);
  const [employeeData, setEmployeeData] = useState([] as any);
  const [employeeDataLoading, setEmployeeDataLoading] = useState(true);

  const { roles, isError, isSuccess, message, isLoading } = useSelector(
    (state: RootState) => state.role
  );

  const {
    singleEmployee: singleEmployeeData,
    isSingleLoading: isEmployeeDataLoading,
    isSingleError: isEmployeeDataError,
    isSingleSuccess: isEmployeeDataSuccess,
    singleMessage: employeeDataMessage,
  } = useSelector((state: RootState) => state.employes);

  useEffect(() => {
    if (isEmployeeDataError) {
      enqueueSnackbar(employeeDataMessage, { variant: "error" });
    }
    if (isEmployeeDataSuccess) {
      setEmployeeData(singleEmployeeData);
      setEmployeeDataLoading(false);
    }
    return () => {
      dispatch(resetEmployee());
    };
  }, [
    isEmployeeDataError,
    isEmployeeDataSuccess,
    employeeDataMessage,
    dispatch,
  ]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      if (message) {
        enqueueSnackbar(message, { variant: "success" });
      } else {
        setRoleData(roles);
      }
    }
    return () => {
      dispatch(resetEmployee());
    };
  }, [isError, isLoading, isSuccess, message]);

  useEffect(() => {
    dispatch(
      getEmployesId({
        id: Number(state?.Data?.id),
      })
    );
    dispatch(
      getRoles({
        page: page,
      })
    );

    return () => {
      setEmployeeDataLoading(true);
    };
  }, []);

  return (
    <PageTemplate
      isTransparent
      crumbs={
        state.forwardedFromInfo
          ? [
              {
                label: "employees",
                href: "/employees/employees-list",
                depth: -2,
              },
              {
                label: "employee info",
                href: "/employee-information",
                depth: -1,
              },
              {
                label: "employee information",
              },
            ]
          : [
              {
                label: "employees",
                href: "/employees/employees-list",
                depth: -1,
              },
              {
                label: "employee Edit",
              },
            ]
      }
    >
      {employeeDataLoading ? (
        <Loading />
      ) : (
        <EmployesEidtForm data={employeeData} roles={roleData} />
      )}
    </PageTemplate>
  );
};

export default EmployesEdit;
