import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import colors from "src/theme/colors";
import { IconButton, styled } from "@mui/material";
import InfoItems from "src/components/InfoItems";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { GrayButton } from "src/components/GrayButton";

type Props = {
  warehouse: string;
  vendor: string;
  order: string;
  quality: string;
  open: boolean;
  isButtonLoading: boolean;
  onSave: () => void;
  onDismiss: () => void;
};

export const InspectionRequestConfirmModal = (props: Props) => {
  const {
    open,
    warehouse,
    vendor,
    order,
    quality,
    isButtonLoading,
    onSave,
    onDismiss,
  } = props;

  return (
    <div>
      <Modal open={open} onClose={onDismiss}>
        <Wrapper>
          <Content>
            <Head>
              <IconButton onClick={onDismiss}>
                <img
                  src={process.env.PUBLIC_URL + `/assets/icons/close.svg`}
                  alt={"close"}
                />
              </IconButton>
            </Head>
            <StyledInner>
              <img
                src={
                  process.env.PUBLIC_URL + `/assets/icons/InspectionBlueBig.svg`
                }
                alt="inspection"
              />
              <Box pt={10} pb={10}>
                <Typography
                  fontSize={18}
                  fontWeight={700}
                  color={colors.gray[850]}
                >
                  Local Inspection Request
                </Typography>
              </Box>
            </StyledInner>
            <Typography
              fontSize={15}
              fontWeight={400}
              color={colors.gray[850]}
              textAlign="center"
            >
              Are you sure to confirm the local inspection request related to:
            </Typography>
            <Infos>
              <InfoItems title="Warehouse" subTilte={warehouse} />
              <InfoItems title="Vendor" subTilte={vendor} />
              <InfoItems title="Purchase Order" subTilte={order} />
              <InfoItems title="Expected Quality" subTilte={quality} />
            </Infos>
            <Buttons>
              <GrayButton
                variant="contained"
                fullWidth
                loading={isButtonLoading}
                onClick={onDismiss}
              >
                Cancel
              </GrayButton>
              <StyledLoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={isButtonLoading}
                onClick={onSave}
              >
                <Typography fontSize={15} fontWeight={500}>
                  Save
                </Typography>
              </StyledLoadingButton>
            </Buttons>
          </Content>
        </Wrapper>
      </Modal>
    </div>
  );
};

const Wrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  min-width: 600px;
  height: fit-content;
  max-height: 90vh;
  overflow: auto;
  background-color: ${colors.base.white};
  border: 1px solid ${colors.gray[200]};
  border-radius: 5px;
  padding: 28px 32px;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 90%;
    min-width: auto;
  }
`;

const Content = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledInner = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Infos = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
  margin: 36px 0 16px 0;
  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

const Head = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Buttons = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  gap: 16px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column-reverse;
  }
`;
