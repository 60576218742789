import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import StyledTable from "src/components/Table";
import { GridColDef } from "@mui/x-data-grid";
import { AppDispatch, RootState } from "src/store";
import { deleteEmployes, getEmployes, reset } from "../redux/EmployesSlice";
import { IconButton, Box, Typography } from "@mui/material";
import { Add, Eye } from "iconsax-react";
import colors from "src/theme/colors";
import styled from "@emotion/styled";
import { StyledButton } from "src/components/Button";
import ConfirmModal from "src/components/Modal";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";

const EmployesTable = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [Data, setData] = useState([] as any);
  const [MetaData, setMetaData] = useState([] as any);
  const [selected, setSelected] = useState([] as any);
  const [selectedId, setSelectedId] = useState([] as any);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const {
    employes,
    employesMeta,
    isError,
    isDeleteError,
    isSuccess,
    isDeleteSuccess,
    message,
    deleteMessage,
    isLoading,
    isDeleteLoading,
  } = useSelector((state: RootState) => state.employes);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      if (message) {
        enqueueSnackbar(message, { variant: "success" });
      } else {
        setData(employes);
        setMetaData(employesMeta);
      }
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, isSuccess, message]);

  useEffect(() => {
    if (isDeleteError) {
      enqueueSnackbar(deleteMessage, { variant: "error" });
    }
    if (isDeleteSuccess) {
      enqueueSnackbar(deleteMessage, { variant: "success" });
      dispatch(
        getEmployes({
          page:
            Data.length <= 1 || selectedId.length >= Data.length
              ? page > 1
                ? page - 1
                : 1
              : page,
        })
      ).then(() => setIsDeleteModalOpen(false));

      if (Data.length <= 1 || selectedId.length >= Data.length) {
        setPage(page > 1 ? page - 1 : 1);
      }
      setSelected([]);
      setSelectedId([]);
    }
    return () => {
      dispatch(reset());
    };
  }, [isDeleteError, isDeleteSuccess, deleteMessage]);

  useEffect(() => {
    dispatch(getEmployes({ page: page }));
    setSelected([]);
    setSelectedId([]);
  }, [page]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 80 },
    {
      field: "name",
      headerName: "Full Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "roles",
      headerName: "Job Position",
      flex: 1,
      minWidth: 200,
      renderCell: (params) =>
        params.row?.roles?.map((item: any) => {
          return (
            <Box key={item.id} padding={2} color={"#1E2329"}>
              {item.title}
            </Box>
          );
        }),
    },
    {
      field: "code",
      headerName: "Organizational Code",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "username",
      headerName: "Organizational Username",
      flex: 1,
      minWidth: 280,
    },
    {
      field: "id_card_number",
      headerName: "ID Card Number",
      flex: 0.8,
      minWidth: 180,
    },
    {
      field: "cellphone",
      headerName: "Mobile Number",
      flex: 0.8,
      minWidth: 180,
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.5,
      minWidth: 140,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          flexDirection="row"
        >
          <IconButton
            onClick={() =>
              navigate("/employees/employee-information", {
                state: { id: params?.id },
              })
            }
          >
            <Eye color={colors.gray[600]} size="24" />
          </IconButton>
          <IconButton
            onClick={() =>
              navigate("/employees/employee-edit", {
                state: { Data: params.row },
              })
            }
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Edit.svg"}
              alt="edit"
            />
          </IconButton>
          {/* <IconButton>
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Remove.svg"}
              alt="delete"
              onClick={() => {
                setSelectedId([Number(params?.id)]);
                setIsDeleteModalOpen(true);
              }}
            />
          </IconButton> */}
        </Box>
      ),
    },
  ];

  const onDelete = (selected: any) => {
    const deleteValue = {
      user_ids: selected,
    };
    dispatch(deleteEmployes(deleteValue));
  };

  return (
    <PageTemplate
      crumbs={[
        {
          label: "employees list",
        },
      ]}
    >
      <ListBoundary
        isEmpty={Data.length === 0 || !Data}
        isDataLoading={isLoading}
        fallbackImageURL="/assets/images/emptyStates/Employee-List EmptyState.svg"
        description="Register Your First Employee!"
        buttonLabel="New Employee"
        onClick={() => navigate("/employees/employee-new")}
      >
        <StyledHeader>
          <StyledButton
            variant="contained"
            endIcon={<Add size="24" color={colors.gray[850]} />}
            onClick={() => navigate("/employees/employee-new")}
          >
            New Employee
          </StyledButton>
          {/* {selected.length !== 0 ? (
            <StyledButton
              variant="contained"
              endIcon={
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/removeWhite.svg"}
                  alt="delete"
                />
              }
              onClick={() => {
                setSelectedId(selected);
                setIsDeleteModalOpen(true);
              }}
              color="error"
            >
              Remove Employes
            </StyledButton>
          ) : (
            <StyledButton
              variant="contained"
              endIcon={<Add size="24" color={colors.gray[850]} />}
              onClick={() => navigate("/employees/employee-new")}
            >
              New Employee
            </StyledButton>
          )} */}
        </StyledHeader>
        <StyledTable
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableVirtualization
          disableSelectionOnClick
          loading={isLoading}
          checkboxSelection
          experimentalFeatures={{ newEditingApi: true }}
          rows={Data}
          columns={columns}
          paginationMode="server"
          onSelectionModelChange={(e) => setSelected(e)}
          onPageChange={(e) => setPage(e + 1)}
          page={page - 1}
          rowCount={MetaData?.pagination?.total_items}
        />
      </ListBoundary>
      <ConfirmModal
        title={"Remove Employes"}
        iconName={"ModalRemove"}
        open={isDeleteModalOpen}
        isLoading={isDeleteLoading || isLoading}
        onDismiss={() => setIsDeleteModalOpen(false)}
        onConfirm={() => onDelete(selectedId)}
        confrimText={"Remove"}
        cancelText={"Cancel"}
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to remove these Employes?
        </Typography>
      </ConfirmModal>
    </PageTemplate>
  );
};

const StyledHeader = styled(Box)`
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 24px;
`;

export default EmployesTable;
