import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import colors from "src/theme/colors";
import { Grid, IconButton, MenuItem, styled } from "@mui/material";
import { StyledButton } from "src/components/Button";
import InfoItems from "src/components/InfoItems";
import FormInputLable from "src/components/FormInputLabel";
import { StyledTextField } from "src/components/Input";
import Loading from "src/components/Loading";
import { ApproveResultStep } from "./AprroveResultStep";
import { RejectResultStep } from "./RejectResultStep";

type Props = {
  qualities: any;
  iconName: string;
  title: string;
  requestName: string;
  open: boolean;
  isLoading?: boolean;
  isButtonLoading: boolean;
  selected: any;
  onApprove: (quality: string) => void;
  onReject: (quality: string, dataRemove: boolean) => void;
  onDismiss: () => void;
};

export const SetQualityResultModal = (props: Props) => {
  const {
    qualities,
    iconName,
    title,
    requestName,
    selected,
    open,
    isLoading,
    isButtonLoading,
    onApprove,
    onReject,
    onDismiss,
  } = props;

  const [currentStep, setCurrentStep] = useState("default");
  const [selectedQuality, setSelectedQuality] = useState("");

  return (
    <div>
      <Modal open={open} onClose={onDismiss}>
        <Wrapper>
          <Content>
            {isLoading ? (
              <Loading />
            ) : currentStep === "default" ? (
              <>
                <Head>
                  <IconButton onClick={onDismiss}>
                    <img
                      src={process.env.PUBLIC_URL + `/assets/icons/close.svg`}
                      alt={"close"}
                    />
                  </IconButton>
                </Head>
                <StyledInner>
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/icons/${iconName}.svg`
                    }
                    alt={iconName}
                  />
                  <Box pt={10} pb={10}>
                    <Typography
                      fontSize={18}
                      fontWeight={700}
                      color={colors.gray[850]}
                    >
                      {title}
                    </Typography>
                  </Box>
                </StyledInner>
                <Typography
                  fontSize={15}
                  fontWeight={400}
                  color={colors.gray[850]}
                  textAlign="center"
                >
                  Enter the result of local inspection
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    ”{selected.title}”{" "}
                  </span>
                  related to:
                </Typography>
                <Infos>
                  {/* <InfoItems
                    title="Purchase Order"
                    subTilte={selected?.order?.code}
                  /> */}
                  <InfoItems
                    title="Warehouse"
                    subTilte={selected?.warehouse?.code ?? "Not Found"}
                  />
                  <InfoItems
                    title="Vendor"
                    subTilte={selected?.vendor?.name ?? "Not Found"}
                  />
                  <InfoItems
                    title="Expected Quality"
                    subTilte={selected?.quality?.exptected ?? "Not Found"}
                  />
                </Infos>
                <Box>
                  <FormInputLable noPrefix>Quality Result</FormInputLable>
                  <StyledTextField
                    fullWidth
                    select
                    placeholder="Select Quality Result"
                    value={selectedQuality}
                    onChange={(e) => setSelectedQuality(e.target.value)}
                  >
                    {qualities?.map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.title}
                        </MenuItem>
                      );
                    })}
                  </StyledTextField>
                </Box>
                <Buttons>
                  <StyledButton
                    variant="contained"
                    color="error"
                    fullWidth
                    disabled={!selectedQuality}
                    onClick={() => setCurrentStep("reject")}
                  >
                    <Typography
                      fontSize={15}
                      fontWeight={500}
                      color={colors.base.white}
                    >
                      Reject Inspection
                    </Typography>
                  </StyledButton>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!selectedQuality}
                    onClick={() => setCurrentStep("approve")}
                  >
                    <Typography fontSize={15} fontWeight={500}>
                      Approve Inspection
                    </Typography>
                  </StyledButton>
                </Buttons>
              </>
            ) : currentStep === "approve" ? (
              <ApproveResultStep
                requestName={requestName}
                isButtonLoading={isButtonLoading}
                selectedQuality={
                  qualities?.find((q: any) => q.id === selectedQuality)?.title
                }
                onApprove={() => onApprove(selectedQuality)}
                onGoBack={() => setCurrentStep("default")}
                onDismiss={onDismiss}
              />
            ) : currentStep === "reject" ? (
              <RejectResultStep
                requestName={requestName}
                isButtonLoading={isButtonLoading}
                selectedQuality={
                  qualities?.find((q: any) => q.id === selectedQuality)?.title
                }
                onReject={(dataRemove: boolean) =>
                  onReject(selectedQuality, dataRemove)
                }
                onGoBack={() => setCurrentStep("default")}
                onDismiss={onDismiss}
              />
            ) : null}
          </Content>
        </Wrapper>
      </Modal>
    </div>
  );
};

const Wrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  min-width: 560px;
  max-width: 560px;
  height: fit-content;
  max-height: 90vh;
  overflow: auto;
  background-color: ${colors.base.white};
  border: 1px solid ${colors.gray[200]};
  border-radius: 5px;
  padding: 28px 32px;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 90%;
    min-width: auto;
  }
`;

const Content = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledInner = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Infos = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
  margin: 36px 0 16px 0;
`;

const Head = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Buttons = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  gap: 16px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column-reverse;
  }
`;
