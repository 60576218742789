import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "src/components/Loading";
import { getRoles } from "src/features/role/redux/roleSlice";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { AppDispatch, RootState } from "src/store";
import EmployesNewForm from "../components/EmployesNewForm";
import { reset } from "../redux/EmployesSlice";

const EmployesNew = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch<AppDispatch>();

  const { enqueueSnackbar } = useSnackbar();
  const [roleData, setRoleData] = useState([] as any);
  const [roleMetaData, setRoleMetaData] = useState([] as any);

  const { roles, rolesMeta, isError, isSuccess, message, isLoading } =
    useSelector((state: RootState) => state.role);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      if (message) {
        enqueueSnackbar(message, { variant: "success" });
      } else {
        setRoleData(roles);
        setRoleMetaData(rolesMeta);
      }
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, isLoading, isSuccess, message]);

  useEffect(() => {
    dispatch(
      getRoles({
        page: page,
      })
    );
  }, []);

  return (
    <PageTemplate
      isTransparent
      crumbs={[
        {
          label: "employees",
          href: "/employees/employees-list",
          depth: -1,
        },
        {
          label: "new employee",
        },
      ]}
    >
      {isLoading && roleData.length === 0 ? (
        <Loading />
      ) : (
        <EmployesNewForm roles={roleData} />
      )}
    </PageTemplate>
  );
};

export default EmployesNew;
