import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StyledTable from "src/components/Table";
import { GridColDef } from "@mui/x-data-grid";
import { AppDispatch, RootState } from "src/store";
import { exportLogs, getLogs, reset } from "../redux/LogsSlice";
import { Box, InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";
import { StyledTextField } from "src/components/Input";
import { Search } from "@mui/icons-material";
import { GrayButton } from "src/components/GrayButton";
import moment from "moment";

const LogsTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(1);
  const [logsData, setLogsData] = useState([] as any);
  const [logsMetaData, setLogsMetaData] = useState([] as any);

  const {
    logs,
    logsMeta,
    isLoading,
    isLogsExportLoading,
    isError,
    isLogsExportError,
    isSuccess,
    message,
    logsExportMessage,
  } = useSelector((state: RootState) => state.logs);

  useEffect(() => {
    if (isLogsExportError) {
      enqueueSnackbar(logsExportMessage, { variant: "error" });
    }
  }, [isLogsExportError, isLogsExportLoading, logsExportMessage, dispatch]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      if (message) {
        enqueueSnackbar(message, { variant: "success" });
      } else {
        setLogsData(logs);
        setLogsMetaData(logsMeta);
      }
    }

    return () => {
      dispatch(reset());
    };
  }, [isError, isSuccess, message, page]);

  useEffect(() => {
    dispatch(
      getLogs({
        page: page,
      })
    );
  }, [page]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 80, filterable: false },
    {
      field: "event",
      headerName: "Event",
      flex: 1,
    },
    {
      field: "section",
      headerName: "Section",
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "Time",
      flex: 1,
      renderCell: (params) => (
        <Box padding={2} color="#1E2329">
          {moment(params.row?.created_at).format("DD-MMM-YYYY")}
        </Box>
      ),
    },
    {
      field: "causer",
      headerName: "Causer",
      flex: 1,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.causer?.name}
        </Box>
      ),
    },
    {
      field: "reason",
      headerName: "Reason",
      flex: 1,
    },
  ];

  return (
    <PageTemplate
      crumbs={[
        {
          label: "Logs list",
        },
      ]}
    >
      <ListBoundary
        isEmpty={logsData.length === 0 || !logsData}
        isDataLoading={isLoading}
        fallbackImageURL="/assets/images/emptyStates/Logs-List EmptyState.svg"
        description="There is no Log!"
      >
        <StyledHeader>
          <ButtonsBox>
            <ActionsBox>
              <ActionBtn>
                <GrayButton
                  variant="contained"
                  fullWidth
                  loading={isLogsExportLoading}
                  endIcon={
                    <img
                      src={process.env.PUBLIC_URL + "/assets/icons/Export.svg"}
                      alt="export"
                    />
                  }
                  onClick={() => {
                    dispatch(exportLogs({})).then(() => reset());
                  }}
                >
                  Export
                </GrayButton>
              </ActionBtn>
            </ActionsBox>
          </ButtonsBox>
        </StyledHeader>
        <StyledTable
          disableColumnMenu
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableVirtualization
          disableSelectionOnClick
          loading={isLoading}
          getRowHeight={() => "auto"}
          checkboxSelection
          experimentalFeatures={{ newEditingApi: true }}
          rows={logsData}
          columns={columns}
          onPageChange={(e) => setPage(e + 1)}
          page={page - 1}
          paginationMode="server"
          rowCount={logsMetaData?.pagination?.total_items}
        />
      </ListBoundary>
    </PageTemplate>
  );
};

const StyledHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 24px;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ButtonsBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

const ActionsBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 24px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
    margin-right: 16px;
  }
`;

const ActionBtn = styled(Box)`
  width: 145px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

export default LogsTable;
