import { FC, useState, useEffect } from "react";
import { Box, Modal, styled, Typography } from "@mui/material";
import FormInputLable from "src/components/FormInputLabel";
import { StyledTextField } from "src/components/Input";
import { StyledLoadingButton } from "src/components/LoadingButton";
import colors from "src/theme/colors";
import { GrayButton } from "src/components/GrayButton";

interface AddNewProductModalProps {
  open: boolean;
  dataLoading?: boolean;
  loading?: boolean;
  resultProduct: any;
  onGetProductsData: (barcode: string) => void;
  onAddProduct: (reg: string) => void;
  onDismiss: () => void;
}

export const AddNewProductModal: FC<AddNewProductModalProps> = (props) => {
  const {
    open,
    dataLoading,
    loading,
    resultProduct,
    onGetProductsData,
    onAddProduct,
    onDismiss,
  } = props;

  const [barcode, setBarcode] = useState("");
  const [isDataFired, setIsDataFired] = useState(false);

  const onGetData = () => {
    onGetProductsData(barcode);
    setIsDataFired(true);
  };

  const onAdd = () => {
    onAddProduct(barcode);
  };

  useEffect(() => {
    if (!open) {
      setBarcode("");
      setIsDataFired(false);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Wrapper>
        <Box
          sx={{
            marginBottom: "28px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              `/assets/icons/saleOrderShipment/Product.svg`
            }
          />
          <Title>Add New Product</Title>
          <SubTitle>
            To add new product first enter the product barcode and get the
            barcode information.
          </SubTitle>
        </Box>

        <FieldsContainer>
          <Box width="100%">
            <FormInputLable noPrefix>Product Barcode</FormInputLable>
            <StyledTextField
              placeholder="Enter the Product Barcode"
              fullWidth
              value={barcode}
              InputProps={{
                endAdornment: (
                  <Box sx={{ width: "120px" }}>
                    <StyledLoadingButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      loading={dataLoading || loading}
                      disabled={!barcode}
                      onClick={onGetData}
                    >
                      <Typography
                        fontSize={16}
                        fontWeight={500}
                        color={colors.base.white}
                      >
                        get data
                      </Typography>
                    </StyledLoadingButton>
                  </Box>
                ),
              }}
              onChange={(e) => setBarcode(e.target.value)}
            />
          </Box>
        </FieldsContainer>

        {isDataFired && !dataLoading ? (
          <ResultContainer>
            {!resultProduct ? (
              <ResultStatusRow>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    `/assets/icons/saleOrderShipment/FailCircle.svg`
                  }
                />
                <span>No Result</span>
              </ResultStatusRow>
            ) : (
              <ResultStatusRow>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    `/assets/icons/saleOrderShipment/CheckCircle.svg`
                  }
                />
                <span>Result</span>
              </ResultStatusRow>
            )}
            {resultProduct ? (
              <ProductBox>
                <ResultValueCell>
                  <Label>Product</Label>
                  <Value>{resultProduct?.product}</Value>
                </ResultValueCell>
                <ResultValueCell>
                  <Label>Weight</Label>
                  <Value>{resultProduct?.weight}</Value>
                </ResultValueCell>
                <ResultValueCell>
                  <Label>Placement</Label>
                  <Value>{resultProduct?.placement}</Value>
                </ResultValueCell>
              </ProductBox>
            ) : null}
          </ResultContainer>
        ) : null}

        <Actions>
          <Box>
            <GrayButton
              variant="outlined"
              fullWidth
              disabled={loading || dataLoading}
              onClick={onDismiss}
            >
              Cancel
            </GrayButton>
          </Box>
          <Box>
            <StyledLoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={loading}
              disabled={!barcode || !resultProduct}
              onClick={onAdd}
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.midnight[850]}
              >
                Confirm
              </Typography>
            </StyledLoadingButton>
          </Box>
        </Actions>
        <CloseIcon onClick={onDismiss}>
          <img src={process.env.PUBLIC_URL + `/assets/icons/${"close"}.svg`} />
        </CloseIcon>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  minWidth: 600,
  maxWidth: 600,
  height: "fit-content",
  maxHeight: "95vh",
  backgroundColor: colors.base.white,
  border: `1px solid ${colors.gray[200]}`,
  borderRadius: "5px",
  padding: "42px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  overflow: "auto",
  [theme.breakpoints.down("md")]: {
    width: "90%",
    minWidth: "auto",
    padding: "42px 24px",
  },
}));

const CloseIcon = styled(Box)`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 4;
  cursor: pointer;
`;

const Title = styled("h6")`
  margin-top: 14px;
  font-size: 18px;
  font-weight: 700;
  color: ${colors.midnight[850]};
`;

const SubTitle = styled("p")`
  text-align: center;
  margin-top: 24px;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  color: ${colors.midnight[850]};
`;

const FieldsContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ResultContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6px;
`;

const ResultStatusRow = styled("div")`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
`;

const ProductBox = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${colors.midnight[20]};
  padding: 16px;
  gap: 8px;
`;

const ResultValueCell = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: ${colors.midnight[700]};
`;

const Label = styled("span")`
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: ${colors.midnight[700]};
`;

const Value = styled("span")`
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  color: ${colors.midnight[850]};
`;

const Actions = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  gap: 24px;
  width: 100%;
  & > div {
    width: 500%;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    gap: 14px;
    & > div {
      width: 100%;
    }
  }
`;
