import { Grid, Box, Typography, styled } from "@mui/material";
import { StyledButton } from "src/components/Button";
import { useNavigate } from "react-router-dom";
import FormHeading from "src/components/FormHeading";
import InfoItems from "src/components/InfoItems";
import colors from "src/theme/colors";
import {
  FileUploaderViewer,
  FileUploaderViewerType,
} from "src/components/FileUploader/FileUploaderViewer/FileUploaderViewer";
import { UploadedFileType } from "src/components/FileUploader/UploadZone/UploadZone";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { GrayButton } from "src/components/GrayButton";
import { PaperBox } from "src/components/Paper/Paper";

type Props = {
  data: {
    id: string;
    vendor: any;
    code: string;
    warehouse: any;
    delivery_timeframe: string;
    date: string;
    material: any;
    quantity: string;
    quality: any;
    notes: string;
    documents: UploadedFileType[];
  };
};

const OrderInfoData = (props: Props) => {
  const {
    vendor,
    code,
    warehouse,
    delivery_timeframe,
    date,
    material,
    quantity,
    quality,
    notes,
    documents,
  } = props.data;

  const navigate = useNavigate();

  const componentRef = useRef<HTMLDivElement | null>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const moveToEdit = () => {
    navigate("/orders/order-edit", {
      state: { orderData: props.data, forwardedFromInfo: true },
    });
  };

  return (
    <Container>
      <PrintBox ref={componentRef}>
        <PaperBox>
          <Box>
            <SubTitleBar>Vendor Information</SubTitleBar>
          </Box>

          <Grid
            container
            rowSpacing={12}
            columnSpacing={{ xs: 0, md: 16 }}
            xs={12}
            md={10}
          >
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems title="Order Id" subTilte={code ?? "Not Found!"} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems
                  title="Vendor"
                  subTilte={vendor?.code ?? "Not Found!"}
                />
              </Box>
            </Grid>
          </Grid>
        </PaperBox>

        <PaperBox>
          <SubTitleBar>Destination Warehouse</SubTitleBar>

          <Grid
            container
            rowSpacing={12}
            columnSpacing={{ xs: 0, md: 16 }}
            xs={12}
            md={10}
          >
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems
                  title="Warehouse"
                  subTilte={warehouse?.title ?? "Not Found!"}
                  description1={
                    warehouse?.title
                      ? warehouse?.address ?? "Not Found!"
                      : "Not Found!"
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                columnSpacing={{ xs: 0 }}
                rowSpacing={{ xs: 12 }}
                xs={12}
              >
                <Grid item xs={12}>
                  <Box width="100%">
                    <InfoItems
                      title="Delivery Time Frame"
                      subTilte={delivery_timeframe ?? "Not Found!"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box width="100%">
                    <InfoItems title="date" subTilte={date ?? "Not Found!"} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </PaperBox>

        <PaperBox>
          <SubTitleBar>Material Details</SubTitleBar>

          <Grid
            container
            rowSpacing={12}
            columnSpacing={{ xs: 0, md: 16 }}
            xs={12}
            md={10}
          >
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems
                  title="Name"
                  subTilte={material?.name ?? "Not Found!"}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems
                  title="Quantity"
                  subTilte={quantity ?? "Not Found!"}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems
                  title="Quality"
                  subTilte={quality?.title ?? "Not Found!"}
                />
              </Box>
            </Grid>
          </Grid>
        </PaperBox>

        <PaperBox>
          <SubTitleBar>Notes & Documents</SubTitleBar>
          <Grid
            container
            rowSpacing={12}
            columnSpacing={{ xs: 0, md: 16 }}
            md={10}
            xs={12}
          >
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <InfoItems title="Notes" subTilte={notes ?? "Not Found!"} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <FileViewerWrapper>
                <FileUploaderViewer
                  type={FileUploaderViewerType.Multiple}
                  files={documents}
                />
              </FileViewerWrapper>
            </Grid>
          </Grid>
        </PaperBox>
      </PrintBox>

      <Actions width="100%">
        <Box>
          <StyledButton
            variant="contained"
            color="secondary"
            fullWidth
            onClick={moveToEdit}
          >
            <Typography
              fontSize={16}
              fontWeight={500}
              color={colors.base.white}
            >
              Edit Order
            </Typography>
          </StyledButton>
        </Box>
        <Box>
          <GrayButton
            variant="contained"
            fullWidth
            endIcon={
              <img
                src={process.env.PUBLIC_URL + "/assets/icons/Print.svg"}
                alt="export"
              />
            }
            sx={{ marginLeft: "12px" }}
            onClick={handlePrint}
          >
            Print
          </GrayButton>
        </Box>
      </Actions>
    </Container>
  );
};

export default OrderInfoData;

const FileViewerWrapper = styled(Box)`
  width: 170px;
`;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

const PrintBox = styled("div")`
  width: 100%;
`;

const SubTitleBar = styled("div")`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  color: ${colors.gray[850]};
  font-size: 21px;
  font-weight: 500;
`;

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 24px 0;
  & > div {
    width: 184px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    & > div {
      width: 142px;
    }
  }
`;
