import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import MainLayout from "src/layout/Main";
import RoleEdit from "../pages/RoleEdit";
import RoleInfo from "../pages/RoleInfo";
import RoleNew from "../pages/RoleNew";
import RoleTable from "../pages/RoleTable";

export const RoleRoutes = () => {
  const { t } = useTranslation();
  return (
    <>
      <Routes>
        <Route path="/roles-list" element={<RoleTable />} />
        <Route path="/new-role" element={<RoleNew />} />
        <Route path="/role-information" element={<RoleInfo />} />
        <Route path="/role-edit" element={<RoleEdit />} />
      </Routes>
    </>
  );
};
