import { useRoutes } from "react-router-dom";
import NotFound from "../features/misc/routes/NotFound";
import { Landing } from "../features/misc";
import storage from "../utils/storage";
import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";
import NotAccess from "src/features/misc/routes/NotAccess";

const notAccessRoutes = [
  {
    path: "/",
    element: <NotAccess />,
  },
  {
    path: "*",
    element: <NotAccess />,
  },
];

export const AppRoutes = () => {
  const parseJwt = (token: any) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const token = storage.getToken();
  const permissionList = parseJwt(token)?.permissions;

  const hasAccessPermission = () => {
    const generalPermission = permissionList?.find(
      (e: any) => e.key === "General"
    );
    const genPermissionValues = generalPermission?.values;

    if (
      generalPermission &&
      genPermissionValues.includes("backoffice-access")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const commonRoutes = [{ path: "/", element: <Landing /> }];

  const routes = token
    ? hasAccessPermission()
      ? protectedRoutes
      : notAccessRoutes
    : publicRoutes;

  const element = useRoutes([...routes]);

  return <>{element}</>;
};
