import { Route, Routes } from "react-router-dom";
import InternationalInspectionList from "../pages/InternationalInspectionList";
import InternationalInspectionNew from "../pages/InternationalInspectionNew";
import InternationalInspectionEdit from "../pages/InternationalInspectionEdit";
import InternationalInspectionInformation from "../pages/InternationalInspectionInformation";

export const InternationalInspectionRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          path="/international-inspections-list"
          element={<InternationalInspectionList />}
        />
        <Route
          path="/international-inspection-new"
          element={<InternationalInspectionNew />}
        />
        <Route
          path="/international-inspection-edit"
          element={<InternationalInspectionEdit />}
        />
        <Route
          path="/international-inspection-information"
          element={<InternationalInspectionInformation />}
        />
      </Routes>
    </>
  );
};
