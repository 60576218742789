import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FC, useState } from "react";
import colors from "src/theme/colors";

export enum AlertSize {
  Small = "Small",
  Medium = "Medium",
  Large = "Large",
}

interface AlertProps {
  title: string;
  size?: AlertSize;
  color?: string;
  isHidden?: boolean;
  withArrow?: boolean;
  className?: string;
  onHide: () => void;
}

export const Alert: FC<AlertProps> = (props) => {
  const { title, size, color, isHidden, withArrow, className, onHide } = props;

  const [isLocalHidden, setIsLocalHidden] = useState(false);

  const hideHandler = () => {
    setIsLocalHidden(true);
    onHide();
  };

  if (!isHidden && !isLocalHidden) {
    return (
      <Container
        size={size}
        color={color}
        className={className}
        withArrow={withArrow}
      >
        <Title>{title}</Title>
        <HideMessageBox>
          <HideMessage onClick={hideHandler}>Hide Message</HideMessage>
        </HideMessageBox>
      </Container>
    );
  } else {
    return null;
  }
};

interface StyledProps {
  size?: AlertSize;
  color?: string;
  withArrow?: boolean;
}

const Container = styled.div<StyledProps>`
  width: 100%;

  ${({ theme, size, color, withArrow }) => {
    let height: string;

    switch (size) {
      case AlertSize.Small:
        height = "45px";
        break;
      case AlertSize.Medium:
        height = "50px";
        break;
      case AlertSize.Large:
        height = "56px";
        break;

      default:
        height = "50px";
        break;
    }

    return css`
      position: relative;
      height: ${height};
      background-color: ${color ? color : colors.secondary[50]};
      padding: 0 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px;

      &::before {
        content: "";
        display: ${withArrow ? "block" : "none"};
        position: absolute;
        bottom: -10px;
        right: 10px;
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;

        border-top: 10px solid ${color ? color : colors.secondary[50]};
      }

      @media screen and (max-width: 750px) {
        padding: 12px;
        height: auto;
        flex-direction: column;
        align-items: flex-start;
      }
    `;
  }}
`;

const Title = styled.span`
  width: calc(100% - 90px);
  text-align: left;
  color: ${colors.gray[850]};
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;

  @media screen and (max-width: 750px) {
    width: 100%;
  }
`;

const HideMessageBox = styled.div`
  text-align: center;

  @media screen and (max-width: 750px) {
    width: 100%;
    text-align: right;
    margin-top: 4px;
  }
`;

const HideMessage = styled.div`
  cursor: pointer;
  color: ${colors.gray[600]};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;
