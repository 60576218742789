import { styled } from "@mui/material/styles";
import { Button, ButtonProps } from "@mui/material";

export const StyledButton = (props: ButtonProps) => {
  return <CustomButton {...props} useVariantHeight={props.size != null} />;
};

interface StyledProps {
  useVariantHeight?: boolean;
}

const CustomButton = styled(Button)<StyledProps>`
  height: ${({ useVariantHeight }) => (useVariantHeight ? "auto" : "48px")};
  border-radius: 5px;
`;
