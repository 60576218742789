import styled from "@emotion/styled";
import { ArrowDropDown } from "@mui/icons-material";
import { Menu, MenuItem, Typography, useTheme } from "@mui/material";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "src/components/Button";
import Loading from "src/components/Loading";
import colors from "src/theme/colors";
import { InventoryCard, InventoryCardProps } from "./InventoryCard";

interface InventoryCardsBoxPops {
  loading: boolean;
  warehouseId: string;
  warehouseTitle: string;
  inventories: InventoryCardProps[];
}

export const InventoryCardsBox: FC<InventoryCardsBoxPops> = (props) => {
  const { loading, warehouseId, warehouseTitle, inventories } = props;

  const navigate = useNavigate();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  console.log(loading, inventories);
  return (
    <Container>
      <Titlebar>
        <Title>Inventory Items</Title>
        <Actions>
          <StyledButton
            endIcon={<ArrowDropDown />}
            color="inherit"
            variant="outlined"
            sx={{ width: "160px" }}
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableElevation
            onClick={handleClick}
          >
            <Typography fontSize={16} fontWeight={500} color={colors.gray[800]}>
              Actions
            </Typography>
          </StyledButton>
          <StyledMenu
            theme={theme}
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{ boxShadow: theme.shadows[10] }}
            PaperProps={{
              style: {
                width: "auto",
                padding: "8px",
                boxShadow: theme.shadows[10],
              },
            }}
          >
            <StyledMenuItem key={1} onClick={() => navigate("")}>
              International Inspection Request
            </StyledMenuItem>
            <StyledMenuItem
              key={2}
              onClick={() =>
                navigate("/stocktakings/stocktakings-list", {
                  state: { isRedirectedToCreate: true },
                })
              }
            >
              New Stocktaking
            </StyledMenuItem>
            <StyledMenuItem
              key={3}
              onClick={() =>
                navigate("/warehouses/inventories-search", {
                  state: { warehouseId, warehouseTitle },
                })
              }
              sx={{ border: "none" }}
            >
              Search Inventory
            </StyledMenuItem>
          </StyledMenu>
        </Actions>
      </Titlebar>
      <Content>
        {loading && inventories == null ? (
          <Center>
            <Loading />
          </Center>
        ) : inventories?.length === 0 ? (
          <Center>Nothing Found</Center>
        ) : (
          inventories?.map((inventory: InventoryCardProps) => (
            <StyledInventoryCard
              key={inventory.id}
              {...inventory}
              isProduct={inventory.type === "ProductItem"}
              onClick={() =>
                navigate("/Warehouses/warehouse-information/product-items", {
                  state: {
                    warehouseId,
                    warehouseTitle,
                    productId: inventory.id,
                    productTitle: inventory.title,
                  },
                })
              }
            />
          ))
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  border-radius: 4px;
  background-color: ${colors.base.white};
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 14px;
`;

const Titlebar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h6`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: ${colors.gray[850]};
`;

const Actions = styled.div``;

const StyledMenu = styled(Menu)``;

const StyledMenuItem = styled(MenuItem)`
  padding: 8px;
  border-bottom: 1px solid ${colors.gray[100]};
  color: ${colors.gray[850]};
  font-size: 16px;
  &:last-child {
    border: none;
  }
`;

const StyledInventoryCard = styled(InventoryCard)`
  margin-bottom: 16px;
`;

const Center = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
