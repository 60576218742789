import { axios } from "../../../config/Axios";
import { employesPatchValue, getData } from "./types";

//Employee get
const getEmployes = async (data?: getData) => {
  const response = await axios.get(
    `/users?page=${data?.page}&${data?.limit ? `limit=${data?.limit}` : ""}&${
      data?.unAssigned === true ? `assigned=0` : ""
    }`
  );
  return response;
};

//Employee delete
const deleteEmployee = async (Data: any) => {
  const response = await axios.delete(`/users`, { data: Data });
  return response;
};
//Employee get by id
const getEmployesId = async (id: number) => {
  const response = await axios.get(`/users/${id}`);
  return response;
};
//Employee Edit
const patchEmployes = async (data: employesPatchValue) => {
  const response = await axios.patch(`/users/${data.id}`, data);
  return response;
};
const postEmployes = async (data: employesPatchValue) => {
  const response = await axios.post(`/users`, data);
  return response;
};

const EmployesServices = {
  getEmployes,
  deleteEmployee,
  getEmployesId,
  patchEmployes,
  postEmployes,
};
export default EmployesServices;
