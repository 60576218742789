import {
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
  gridRowCountSelector,
} from "@mui/x-data-grid";
import { Box, Pagination } from "@mui/material";
import { styled } from "@mui/material/styles";
import colors from "src/theme/colors";

export default function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Box justifyContent="center" alignItems="center" display="flex" flex={1}>
      <StyledPagination
        count={pageCount}
        page={page + 1}
        color="secondary"
        variant="text"
        shape="rounded"
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    </Box>
  );
}

const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root": {
    backgroundColor: colors.gray[100],
    color: colors.gray[700],
    width: "40px",
    height: "40px",
    margin: "0 8px",
  },
  "& .MuiPaginationItem-root.Mui-selected": {
    backgroundColor: colors.secondary[600],
    color: "#ffffff",
  },
});
