import styled from "@emotion/styled";
import { Box, Typography, styled as styledMui } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import moment from "moment";
import { useEffect, useState } from "react";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";
import StyledTable from "src/components/Table";
import colors from "src/theme/colors";
import { GrayButton } from "src/components/GrayButton";
import { StyledLoadingButton } from "src/components/LoadingButton";
import {
  getTrucks,
  reset as resetTrucks,
} from "src/features/warehouses/redux/TruckSlice";
import { InspectionRequestConfirmModal } from "./modals/InspectionRequestConfirmModal";
import { useNavigate } from "react-router-dom";
import {
  addLocalInspection,
  reset as resetLocalInspections,
} from "../redux/LocalInspectionSlice";
import { PaperBox } from "src/components/Paper/Paper";

interface Props {
  title: string;
  values: any;
  warehouse?: string;
  vendor?: string;
  order?: string;
  dateRange?: string;
  onGoBack: () => void;
}

export const TrucksList = (props: Props) => {
  const { title, values, warehouse, vendor, order, dateRange, onGoBack } =
    props;

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState([] as any);
  const [trucksData, setTrucksData] = useState([] as any);
  const [trucksMeta, setTrucksMeta] = useState([] as any);

  const [confirmRequestModal, setIsConfirmRequestModal] = useState(false);

  const {
    Trucks,
    TrucksMeta,
    isLoading: isTrucksLoading,
    isError: isTrucksError,
    isSuccess: isTrucksSuccess,
    message: trucksMessage,
  } = useSelector((state: RootState) => state.trucks);
  const {
    isAddRequestLoading,
    isAddRequestError,
    isAddRequestSuccess,
    addRequestMessage,
  } = useSelector((state: RootState) => state.localInspections);

  useEffect(() => {
    if (isTrucksError) {
      enqueueSnackbar(trucksMessage, { variant: "error" });
    }
    if (isTrucksSuccess) {
      setTrucksData(Trucks);
      setTrucksMeta(TrucksMeta);
    }
    return () => {
      dispatch(resetTrucks());
    };
  }, [isTrucksError, isTrucksSuccess, trucksMessage, dispatch]);

  useEffect(() => {
    if (isAddRequestError) {
      enqueueSnackbar(addRequestMessage, { variant: "error" });
    }
    if (isAddRequestSuccess) {
      enqueueSnackbar(addRequestMessage, { variant: "success" });
      navigate("/local-inspections/local-inspections-list");
    }
    return () => {
      dispatch(resetLocalInspections());
    };
  }, [addRequestMessage, isAddRequestError, isAddRequestSuccess, dispatch]);

  const columns: GridColDef[] = [
    {
      field: "numberplate",
      headerName: "Truck Number",
      flex: 1,
      minWidth: 140,
      filterable: false,
    },
    {
      field: "received-date",
      headerName: "Received Date",
      flex: 1,
      minWidth: 140,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color="#1E2329">
          {moment(params.row?.relocation_date_time).format("DD-MMM h:mma")}
        </Box>
      ),
    },
    {
      field: "vendor",
      headerName: "Vendor",
      flex: 0.6,
      minWidth: 100,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.vendor?.code}
        </Box>
      ),
    },
    {
      field: "full_weight",
      headerName: "Full Weight(Kg)",
      flex: 1,
      minWidth: 140,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.full_weight ? params.row?.full_weight * 1000 : 0}
        </Box>
      ),
    },
    {
      field: "empty_weight",
      headerName: "Empty Weight(Kg)",
      flex: 1,
      minWidth: 160,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.empty_weight ? params.row?.empty_weight * 1000 : 0}
        </Box>
      ),
    },
    {
      field: "net_weight",
      headerName: "Net Weight(Kg)",
      flex: 1,
      minWidth: 140,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.net_weight ? params.row?.net_weight * 1000 : 0}
        </Box>
      ),
    },
    {
      field: "driver_license_number",
      headerName: "Driver License",
      flex: 1,
      minWidth: 140,
      filterable: false,
    },
    {
      field: "order",
      headerName: "Purchase Order",
      flex: 1,
      minWidth: 140,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.order?.code}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const postData = {
      ...values,
      page: page,
      filter: "ready_for_inspection",
    };

    dispatch(getTrucks(postData));
  }, [page, values]);

  const onAddTrucks = () => {
    const requestData = {
      title: title,
      truck_ids: selected,
    };

    dispatch(addLocalInspection(requestData));
    // );
  };

  return (
    <>
      <PaperBox>
        <Typography
          width="100%"
          color={colors.gray[850]}
          fontWeight="700"
          fontSize="24px"
          mb={12}
        >
          Trucks List
        </Typography>
        <StyledHeader>
          <FiltersRow>
            {selected && selected?.length > 0 ? (
              <ResultsCount>
                Total Selected: {selected?.length ?? 0}/
                {trucksMeta?.pagination?.total_items}
              </ResultsCount>
            ) : (
              <div />
            )}
            <Filters>
              <FiltersLabel>Filters:</FiltersLabel>
              {warehouse ? (
                <Filter>
                  <BadgeIcon>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/WarehouseBlueMini.svg"
                      }
                    />
                  </BadgeIcon>
                  {warehouse}
                </Filter>
              ) : null}
              {dateRange ? (
                <Filter>
                  <BadgeIcon>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/CalenderBlueMini.svg"
                      }
                    />
                  </BadgeIcon>
                  {dateRange}
                </Filter>
              ) : null}
            </Filters>
          </FiltersRow>
        </StyledHeader>
        <ListBoundary
          isEmpty={trucksData.length === 0 || !trucksData}
          isDataLoading={isTrucksLoading}
          fallbackImageURL="/assets/images/emptyStates/Trucks-List EmptyState.svg"
          description="Wait for the truck to arrive at the warehouse."
        >
          <StyledTable
            disableColumnMenu
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableVirtualization
            disableSelectionOnClick
            loading={false}
            columns={columns}
            experimentalFeatures={{ newEditingApi: true }}
            rows={trucksData}
            checkboxSelection
            onSelectionModelChange={(e) => {
              setSelected(e);
            }}
            onPageChange={(e) => setPage(e + 1)}
            page={page - 1}
            paginationMode="server"
            rowCount={trucksMeta?.pagination?.total_items}
          />
        </ListBoundary>
      </PaperBox>

      <Actions width="100%">
        <Box>
          <GrayButton variant="outlined" fullWidth onClick={onGoBack}>
            Back
          </GrayButton>
        </Box>
        <Box marginLeft={12}>
          <StyledLoadingButton
            variant="contained"
            color="primary"
            fullWidth
            disabled={selected.length === 0}
            onClick={() => setIsConfirmRequestModal(true)}
          >
            <Typography fontSize={16} fontWeight={500} color={colors.gray[850]}>
              Confirm Request
            </Typography>
          </StyledLoadingButton>
        </Box>
      </Actions>

      <InspectionRequestConfirmModal
        open={confirmRequestModal}
        warehouse={warehouse!}
        vendor={
          vendor && vendor.length > 0 ? vendor : trucksData[0]?.vendor?.name
        }
        quality={trucksData[0]?.expected_quality}
        order={order!}
        isButtonLoading={isAddRequestLoading}
        onSave={onAddTrucks}
        onDismiss={() => setIsConfirmRequestModal(false)}
      />
    </>
  );
};

const StyledHeader = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const FiltersRow = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Filters = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const FiltersLabel = styled("span")`
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  white-space: nowrap;
  color: ${colors.gray[850]};
`;

const ResultsCount = styled("h6")`
  text-align: center;
  font-size: 14px;
  line-height: 28px;
  border-radius: 30px;
  padding: 6px 16px;
  white-space: nowrap;
  background-color: ${colors.secondary[50]};
  color: ${colors.secondary[800]};
`;

const Filter = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 28px;
  border-radius: 30px;
  padding: 6px 16px;
  white-space: nowrap;
  background-color: ${colors.secondary[50]};
  color: ${colors.secondary[800]};
  margin-left: 16px;
  &:last-child {
    margin-right: 0;
  }
`;

const BadgeIcon = styled("div")`
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Actions = styledMui(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  & > div {
    width: 180px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    & > div {
      width: 100%;
    }
  }
`;
