import { Box, Grid, MenuItem, styled, Typography } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CanselButton } from "src/components/CanselButton";
import {
  FileUploader,
  FileUploaderType,
} from "src/components/FileUploader/FileUploader";
import { UploadedFileType } from "src/components/FileUploader/UploadZone/UploadZone";
import FormHeading from "src/components/FormHeading";
import FormInputLable from "src/components/FormInputLabel";
import { GrayButton } from "src/components/GrayButton";
import { StyledTextField } from "src/components/Input";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { StyledTextFieldMulti } from "src/components/MultilineInput";
import { PaperBox } from "src/components/Paper/Paper";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import * as Yup from "yup";
import { postItems, reset } from "../redux/ItemSlice";

interface Props {
  units: any;
  qualities: any;
}

const ItemNewForm = (props: Props) => {
  const navigate = useNavigate();
  const [files, setFiles] = useState<UploadedFileType[]>([]);

  //formik---------------------------------
  const ItemSchema = Yup.object().shape({
    name: Yup.string().required(),
    unit_id: Yup.number().required(),
    qualities: Yup.array().min(1),
    code: Yup.string(),
    notes: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      unit_id: "",
      qualities: [],
      notes: "",
      code: "",
    },
    validationSchema: ItemSchema,

    onSubmit: async (values: any) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("unit_id", values.unit_id);
      values.qualities.forEach((quality: string) =>
        formData.append(`qualities[]`, quality)
      );
      formData.append("notes", values.notes);
      formData.append("code", `MA-${values.code}`);
      if (files.length > 0) {
        formData.append(`image`, files[0] as any, files[0].name);
      }

      dispatch(postItems(formData));
    },
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    setFieldValue,
    getFieldProps,
  } = formik;

  //redux ---------------------------------
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const { isCreateLoading, isError, isSuccess, message } = useSelector(
    (state: RootState) => state.items
  );

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      enqueueSnackbar(message, { variant: "success" });
      navigate("/materials/materials-list");
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, isSuccess, message, navigate, dispatch]);

  const [qualities, setqualities] = useState(props.qualities);
  const [units, setunits] = useState(props.qualities);

  useEffect(() => {
    setqualities(props.qualities);
    setunits(props.units);
  }, [props]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <PaperBox>
          <Box mb={12}>
            <FormHeading>Material Information</FormHeading>
          </Box>
          <Grid
            container
            rowSpacing={12}
            columnSpacing={{ sm: 0, md: 20 }}
            md={10}
            sm={12}
          >
            <Grid item md={6} sm={12}>
              <FormInputLable requried>Material Name</FormInputLable>
              <StyledTextField
                placeholder="Enter material name"
                fullWidth
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <FormInputLable>Material Code</FormInputLable>
              <StyledTextField
                placeholder="Enter Material Code"
                fullWidth
                {...getFieldProps("code")}
                error={Boolean(touched.code && errors.code)}
                helperText={touched.code && errors.code}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <FormInputLable requried>Unit</FormInputLable>
              <StyledTextField
                select
                fullWidth
                {...getFieldProps("unit_id")}
                placeholder="Select Item Unit"
                error={Boolean(touched.unit_id && errors.unit_id)}
                helperText={touched.unit_id && errors.unit_id}
                onChange={formik.handleChange("unit_id")}
              >
                {units?.map((item: any) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </StyledTextField>
            </Grid>
            <Grid item md={12} sm={12}>
              <FormInputLable requried>Quality</FormInputLable>
              <StyledTextField
                select
                id="qualities"
                {...getFieldProps("qualities")}
                placeholder="Select item unit"
                error={Boolean(touched.qualities && errors.qualities)}
                helperText={touched.qualities && errors.qualities}
                onChange={formik.handleChange("qualities")}
                fullWidth
                defaultValue={[]}
                SelectProps={{
                  multiple: true,
                }}
              >
                {qualities?.map((item: any) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </StyledTextField>
            </Grid>
          </Grid>
        </PaperBox>

        <PaperBox>
          <Box mb={12}>
            <FormHeading>Notes & Files</FormHeading>
          </Box>
          <Grid
            container
            rowSpacing={12}
            columnSpacing={{ sm: 0, md: 20 }}
            md={10}
            sm={12}
          >
            <Grid item md={6} sm={12}>
              <FormInputLable>Note</FormInputLable>
              <StyledTextFieldMulti
                placeholder="Some Notes..."
                fullWidth
                multiline={true}
                {...getFieldProps("notes")}
                error={Boolean(touched.notes && errors.notes)}
                helperText={touched.notes && errors.notes}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <FormInputLable>Image</FormInputLable>
              <Box sx={{ height: "170px" }}>
                <FileUploader
                  type={FileUploaderType.Single}
                  files={files}
                  setFiles={setFiles}
                />
              </Box>
            </Grid>
          </Grid>
        </PaperBox>

        <Actions width="100%">
          <Box>
            <GrayButton
              variant="outlined"
              fullWidth
              disabled={isCreateLoading}
              onClick={() => navigate("/materials/materials-list")}
            >
              Cancel
            </GrayButton>
          </Box>
          <Box marginLeft={12}>
            <StyledLoadingButton
              loading={isCreateLoading}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.gray[850]}
              >
                Save Information
              </Typography>
            </StyledLoadingButton>
          </Box>
        </Actions>
      </Form>
    </FormikProvider>
  );
};

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  & > div {
    width: 184px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    & > div {
      width: 142px;
    }
  }
`;

export default ItemNewForm;
