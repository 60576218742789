import { Box, styled } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { FC, useEffect, useState } from "react";
import { GrayButton } from "src/components/GrayButton";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";
import StyledTable from "src/components/Table";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import { useSnackbar } from "notistack";
import { StyledButton } from "src/components/Button";
import { useNavigate } from "react-router-dom";
import {
  exportSerachProducts,
  reset as resetProductItems,
} from "src/features/products/redux/ProductsSlice";
import ConfirmModal from "src/components/Modal";

interface ProductItemsTableProps {
  isLoading: boolean;
  warehouseId: string;
  productId: string;
  productItems: any[];
  rowsCount: number;
  page: number;
  searchValue: string;
  setNextPage: (page: number) => void;
  setSearchValue: (page: string) => void;
}

export const ProductItemsTable: FC<ProductItemsTableProps> = (props) => {
  const {
    isLoading,
    warehouseId,
    productId,
    productItems,
    rowsCount,
    page,
    setNextPage,
  } = props;

  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const {
    isSearchLoading,
    isProductsExportLoading,
    isSearchError,
    searchProductsMessage,
  } = useSelector((state: RootState) => state.products);

  useEffect(() => {
    if (isSearchError) {
      enqueueSnackbar(searchProductsMessage, { variant: "error" });
    }
  }, [isSearchError, searchProductsMessage, isSearchLoading]);

  const columns: GridColDef[] = [
    {
      field: "placement",
      headerName: "Placewment",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "weight",
      headerName: "Weight",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "internal_barcode",
      headerName: "Barcode",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "inter_national_inspection_title",
      headerName: "Request Title",
      flex: 1,
      minWidth: 200,
    },
    // {
    //   field: "Actions",
    //   headerName: "Actions",
    //   flex: 0.4,
    //   minWidth: 80,
    //   sortable: false,
    //   filterable: false,
    //   hideable: false,
    //   renderCell: (params) => (
    //     <IconButton>
    //       <img
    //         src={process.env.PUBLIC_URL + "/assets/icons/Remove.svg"}
    //         alt="delete"
    //         onClick={() => {
    //           setSelectedId(Number(params?.id));
    //           setIsDeleteModalOpen(true);
    //         }}
    //       />
    //     </IconButton>
    //   ),
    // },
  ];

  return (
    <>
      <Container>
        <ListBoundary
          isEmpty={productItems.length === 0 || !productItems}
          isDataLoading={isLoading}
          fallbackImageURL="/assets/images/emptyStates/SearchedProducts-List EmptyState.svg"
          description="No Result Found ..."
        >
          <StyledHeader>
            <ButtonsBox>
              <ActionsBox>
                <ActionBtn>
                  <GrayButton
                    variant="contained"
                    fullWidth
                    loading={isProductsExportLoading}
                    endIcon={
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/icons/Export.svg"
                        }
                        alt="export"
                      />
                    }
                    onClick={() => {
                      dispatch(
                        exportSerachProducts({
                          page: page,
                          warehouseId: warehouseId,
                          productId: productId,
                        })
                      ).then(() => resetProductItems());
                    }}
                  >
                    Export
                  </GrayButton>
                </ActionBtn>
              </ActionsBox>
              {/* <StyledButton
                variant="contained"
                color="secondary"
                fullWidth={isBelowSmSize}
                endIcon={<Add size="24" color={colors.gray[850]} />}
                onClick={() => navigate("/products/product-new")}
              >
                Add New Item
              </StyledButton> */}
              <StyledButton
                variant="contained"
                onClick={() =>
                  navigate(
                    "/international-inspections/international-inspection-new"
                  )
                }
              >
                International Inspection Request
              </StyledButton>
            </ButtonsBox>
          </StyledHeader>

          <StyledTable
            disableColumnMenu
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableVirtualization
            disableSelectionOnClick
            loading={isLoading}
            getRowHeight={() => 56}
            experimentalFeatures={{ newEditingApi: true }}
            rows={productItems}
            columns={columns}
            onPageChange={(e) => setNextPage(e + 1)}
            page={page - 1}
            paginationMode="server"
            rowCount={rowsCount}
          />
        </ListBoundary>
      </Container>
    </>
  );
};

const Container = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 18px;
`;

const StyledHeader = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 24px;
  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ButtonsBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    & > button {
      width: 100%;
    }
  }
`;

const ActionsBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 24px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
    margin-right: 16px;
  }
`;

const ActionBtn = styled(Box)`
  width: 145px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;
