import { CloudUploadOutlined } from "@mui/icons-material";
import { Box, Typography, Modal } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import { FC, useState } from "react";
import colors from "src/theme/colors";

import { FileUploadZone, UploadedFileType } from "./UploadZone/UploadZone";

export enum FileUploaderType {
  Multiple,
  Single,
}

interface FileUploaderProps {
  type: FileUploaderType;
  files: UploadedFileType[];
  acceptedTypes?: any;
  maxFiles?: number;
  deleteEndPoint?: string;
  setFiles: React.Dispatch<React.SetStateAction<UploadedFileType[]>>;
}

export const FileUploader: FC<FileUploaderProps> = (props) => {
  const { type, files, maxFiles, acceptedTypes, deleteEndPoint, setFiles } =
    props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (type === FileUploaderType.Multiple) {
    return (
      <>
        <ModalToggler onClick={() => setIsModalOpen(true)}>
          <UploadIcon>
            <CloudUploadOutlined color="inherit" />
          </UploadIcon>
          <Title>Show / Upload Files</Title>
        </ModalToggler>
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MultiWrapper>
            <CloseIcon onClick={() => setIsModalOpen(false)}>
              <img
                src={process.env.PUBLIC_URL + `/assets/icons/${"close"}.svg`}
              />
            </CloseIcon>
            <MultiUploader>
              <FileUploadZone
                type={type}
                maxFiles={maxFiles}
                files={files}
                acceptedTypes={acceptedTypes}
                deleteEndPoint={deleteEndPoint}
                setFiles={setFiles}
              />
            </MultiUploader>
          </MultiWrapper>
        </Modal>
      </>
    );
  } else {
    return (
      <Container>
        <FileUploadZone
          type={type}
          maxFiles={1}
          files={files}
          acceptedTypes={acceptedTypes}
          deleteEndPoint={deleteEndPoint}
          setFiles={setFiles}
        />
      </Container>
    );
  }
};

const Container = muiStyled(Box)({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 4,
});

const MultiWrapper = muiStyled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  minWidth: "340px",
  backgroundColor: colors.base.white,
  border: `1px solid ${colors.gray[200]}`,
  borderRadius: "5px",
  padding: "42px 64px 58px 64px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
});

const ModalToggler = muiStyled(Box)({
  width: "230px",
  height: "48px",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "5px",
  border: `1px solid ${colors.gray[200]}`,
});

const UploadIcon = muiStyled(Box)({
  fontSize: "21px",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "10px",
  color: colors.gray[750],
});

const MultiUploader = muiStyled(Box)({
  width: "320px",
  height: "320px",
});

const Title = muiStyled(Typography)({
  fontSize: "15px",
  fontWeight: 600,
  lineHeight: "20px",
  textAlign: "center",
  color: colors.gray[750],
});

const CloseIcon = muiStyled(Box)({
  position: "absolute",
  top: "12px",
  right: "12px",
  zIndex: 4,
  cursor: "pointer",
});
