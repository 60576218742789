export default {
  10: "#F9FCFF",
  20: "#F5F8FD",
  25: "#F8F8F8",
  50: "#F0F2F7",
  100: "#EAECEF",
  200: "#C2CBD7",
  300: "#A9B6C6",
  400: "#B7BDC5",
  450: "#95A4B7",
  500: "#8193AA",
  600: "#707A8A",
  700: "#515B68",
  750: "#474D57",
  800: "#3d4550",
  850: "#1E2329",
  900: "#181A20",
};
