import { Menu, MenuItem, IconButton, Box, styled } from "@mui/material";
import { FC, MouseEvent, useState } from "react";
import { Add, Eye } from "iconsax-react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import colors from "src/theme/colors";
import { StyledButton } from "src/components/Button";

interface StocktakingDetailsCardProps {
  title: string;
  warehouse: string;
  startDate: string;
  status: string;
  onEditStocktaking: () => void;
  onFinishStocktaking: () => void;
  onDeleteStocktaking: () => void;
  onShowEmployeesStatus: () => void;
}

export const StocktakingDetailsCard: FC<StocktakingDetailsCardProps> = (
  props
) => {
  const {
    title,
    warehouse,
    startDate,
    status,
    onEditStocktaking,
    onFinishStocktaking,
    onDeleteStocktaking,
    onShowEmployeesStatus,
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <StartBox>
        <TitleBox>
          <img
            src={
              process.env.PUBLIC_URL +
              "/assets/icons/stocktakingReport/Stocktaking.svg"
            }
          />
          <Title>{title}</Title>
        </TitleBox>
        <InfoBox>
          <Text>
            <Icon>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/icons/stocktakingReport/Warehouse.svg"
                }
              />
            </Icon>
            {warehouse}
          </Text>
          <Text>
            <Icon>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/icons/stocktakingReport/Calender.svg"
                }
              />
            </Icon>
            {startDate}
          </Text>
          <BtnText onClick={onShowEmployeesStatus}>Employees Status</BtnText>
        </InfoBox>
      </StartBox>
      <EndBox>
        {status === "finished" ? (
          <Box
            padding="4px 12px"
            borderRadius={40}
            bgcolor={colors.success[50]}
            color={"#1E2329"}
            display="flex"
            alignItems="center"
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Check.svg"}
              alt="Check"
            />{" "}
            <Box ml={2}>Completed</Box>
          </Box>
        ) : (
          <StyledButton variant="contained" onClick={onFinishStocktaking}>
            Complete Stocktaking
          </StyledButton>
        )}
        <MenuIcon>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </MenuIcon>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "22ch",
            },
          }}
        >
          <MenuItem key={1} onClick={onEditStocktaking} sx={{ p: 6 }}>
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Edit.svg"}
              alt="edit"
            />
            <Box ml={4}>Edit Stocktaking</Box>
          </MenuItem>
          {/* {status !== "inProgress" ? (
            <MenuItem key={2} onClick={onDeleteStocktaking} sx={{ p: 6 }}>
              <img
                src={process.env.PUBLIC_URL + "/assets/icons/Remove.svg"}
                alt="delete"
              />
              <Box ml={4}>Remove Stocktaking</Box>
            </MenuItem>
          ) : null} */}
        </Menu>
      </EndBox>
    </Container>
  );
};

const Container = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 32px;
  border-radius: 4px;
  margin-bottom: 16px;
  background-color: ${colors.base.white};

  ${(props) => props.theme.breakpoints.down("xl")} {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const StartBox = styled("div")`
  width: calc(100% - 140px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 48px;

  ${(props) => props.theme.breakpoints.down("xl")} {
    width: 100%;
    flex-wrap: wrap;
  }
  ${(props) => props.theme.breakpoints.down("lg")} {
    flex-wrap: wrap;
    gap: 32px;
  }
`;

const TitleBox = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* margin-right: 58px; */
`;

const Title = styled("h4")`
  font-size: 18px;
  line-height: 24px;
  margin-left: 8px;
  color: ${colors.gray[850]};
`;

const InfoBox = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    flex-wrap: wrap;
  }
`;

const EndBox = styled("div")`
  width: 400px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 32px;
  ${(props) => props.theme.breakpoints.down("xl")} {
    width: 100%;
    margin-bottom: 24px;
  }
`;

const Text = styled("p")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray[700]};
`;

const BtnText = styled("span")`
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: ${colors.primary[800]};
`;

const Icon = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const MenuIcon = styled("div")`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-color: ${colors.gray[200]};
`;
