import { axios } from "../../../config/Axios";
import { getData } from "./types";

//order get
const getOrders = async (data: any) => {
  const response = await axios.get(
    `/orders?page=${data.page}&${data?.limit ? `limit=${data?.limit}` : ""}&${
      data?.vendor_id ? `vendor_id=${data?.vendor_id}` : ""
    }`
  );
  return response;
};
//order get by id
const getOrdersId = async (orderId: number) => {
  const response = await axios.get(`/orders/${orderId}`);
  return response;
};
//order submit
const postOrder = async (Data: any) => {
  const response = await axios.post("/orders", Data);
  return response;
};
//order submit
const patchOrder = async (Data: any) => {
  const response = await axios.patch(`/orders/${Data.id}`, Data);
  return response;
};
//order submit
const deleteOrder = async (Data: any) => {
  const response = await axios.delete(`/orders`, { data: Data });
  return response;
};
const orderServices = {
  getOrders,
  getOrdersId,
  postOrder,
  patchOrder,
  deleteOrder,
};
export default orderServices;
