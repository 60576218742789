import { FC } from "react";
import {
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormHeading from "src/components/FormHeading";
import FormInputLable from "src/components/FormInputLabel";
import { StyledTextField } from "src/components/Input";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { StyledTextFieldMulti } from "src/components/MultilineInput";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import * as Yup from "yup";
import { patchorder, reset } from "../redux/orderSlice";
import { StyledDatePicker } from "src/components/DatePicker/DatePicker";
import {
  FileUploader,
  FileUploaderType,
} from "src/components/FileUploader/FileUploader";
import { UploadedFileType } from "src/components/FileUploader/UploadZone/UploadZone";
import {
  useUploadNewFile,
  UseUploadNewFilesType,
} from "src/components/FileUploader/useUploadNewFile";
import { PaperBox } from "src/components/Paper/Paper";
import { GrayButton } from "src/components/GrayButton";

interface fieldsProps {
  [key: string]: string;
}

interface Props {
  vendors: any;
  warehouses: any;
  materials: any;
  qualities: any;
  orderData: {
    code: string;
    date: string;
    delivery_timeframe: string;
    documents?: UploadedFileType[];
    id: number;
    material: fieldsProps;
    notes: string;
    operator: fieldsProps;
    quality: fieldsProps;
    quantity: string;
    vendor: fieldsProps;
    warehouse: fieldsProps;
  };
}

const OrderEditForm: FC<Props> = (props) => {
  const navigate = useNavigate();
  const [uploadFilesLoading, uploadNewFiles] = useUploadNewFile(
    UseUploadNewFilesType.Multiple
  );
  const [files, setFiles] = useState<UploadedFileType[]>([]);
  const [qualities, setQualities] = useState(
    props.materials ? props.materials[0]?.qualities : []
  );

  //formik---------------------------------
  const ItemSchema = Yup.object().shape({
    vendor: Yup.number().required(),
    code: Yup.string().required(),
    warehouse: Yup.number().required(),
    deliveryTime: Yup.string(),
    material: Yup.number().required(),
    quantity: Yup.string().required(),
    quality: Yup.number().required().nullable(),
    notes: Yup.string(),
    orderDate: Yup.date().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      vendor: props?.orderData?.vendor?.id,
      code:
        props?.orderData?.code && props?.orderData?.code.startsWith("PO-")
          ? props?.orderData?.code.substring(3)
          : props?.orderData?.code,
      warehouse: props?.orderData?.warehouse?.id,
      deliveryTime: props?.orderData?.delivery_timeframe ?? "",
      material: props?.orderData?.material?.id,
      quantity: props?.orderData?.quantity,
      quality: props?.orderData?.quality?.id,
      notes: props?.orderData?.notes ?? "",
      orderDate: props?.orderData?.date,
    },
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: ItemSchema,

    onSubmit: async (values: any) => {
      const patchValues = {
        id: props?.orderData?.id,
        vendor_id: values.vendor,
        code: `PO-${values.code}`,
        warehouse_id: values.warehouse,
        delivery_timeframe: values.deliveryTime,
        material_id: values.material,
        quantity: values.quantity,
        quality_id: values.quality,
        notes: values.notes,
        date: values.orderDate
          ? values.orderDate === props?.orderData?.date
            ? values.orderDate
            : values.orderDate.$d.toISOString().slice(0, 10)
          : "",
      };

      uploadNewFiles(
        () => dispatch(patchorder(patchValues)),
        `orders/${props?.orderData?.id}`,
        files
      );
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  //redux ---------------------------------
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const { isCreateLoading, isError, isPatchSuccess, message } = useSelector(
    (state: RootState) => state.orders
  );

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isPatchSuccess) {
      enqueueSnackbar(message, { variant: "success" });
      // navigate("/orders/orders-list");
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, isPatchSuccess, message, dispatch]);

  useEffect(() => {
    const documents = props?.orderData?.documents ?? [];
    setFiles(documents as any);
  }, [props]);

  useEffect(() => {
    if (values?.material) {
      const selectedMaterialObject = props?.materials.find(
        (mt: any) => mt.id === values?.material
      );
      setQualities(selectedMaterialObject?.qualities ?? []);
    }
  }, [values?.material]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <PaperBox>
            <Box mb={12}>
              <FormHeading>Warehouse Information</FormHeading>
            </Box>
            <Grid
              container
              rowSpacing={12}
              columnSpacing={{ sm: 0, md: 20 }}
              md={10}
              sm={12}
            >
              <Grid item md={6} sm={12}>
                <FormInputLable requried>Vendor</FormInputLable>
                <StyledTextField
                  select
                  placeholder="Select a Vendor"
                  fullWidth
                  {...getFieldProps("vendor")}
                  error={Boolean(touched.vendor && errors.vendor)}
                  helperText={touched.vendor && errors.vendor}
                  onChange={formik.handleChange("vendor")}
                  SelectProps={{
                    multiple: false,
                  }}
                >
                  {props?.vendors?.map((item: any) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.code}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </Grid>
              <Grid item md={6} sm={12}>
                <FormInputLable requried>Order ID</FormInputLable>
                <StyledTextField
                  placeholder="Enter a Code"
                  fullWidth
                  {...getFieldProps("code")}
                  error={Boolean(touched.code && errors.code)}
                  helperText={touched.code && errors.code}
                />
              </Grid>
            </Grid>
          </PaperBox>

          <PaperBox>
            <Box mb={12}>
              <FormHeading>Destination Warehouse</FormHeading>
            </Box>
            <Grid
              container
              rowSpacing={12}
              columnSpacing={{ sm: 0, md: 20 }}
              md={10}
              sm={12}
            >
              <Grid item md={6} sm={12}>
                <FormInputLable requried>Destination Warehouse</FormInputLable>
                <StyledTextField
                  select
                  fullWidth
                  {...getFieldProps("warehouse")}
                  placeholder="Select a warehouse"
                  error={Boolean(touched.warehouse && errors.warehouse)}
                  helperText={touched.warehouse && errors.warehouse}
                  onChange={formik.handleChange("warehouse")}
                  SelectProps={{
                    multiple: false,
                  }}
                >
                  {props?.warehouses?.map((item: any) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.title}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </Grid>
              <Grid item md={6} sm={12}>
                <FormInputLable>Delivery Time Frame</FormInputLable>
                <StyledTextField
                  placeholder="Enter order delivery time frame"
                  fullWidth
                  {...getFieldProps("deliveryTime")}
                  error={Boolean(touched.deliveryTime && errors.deliveryTime)}
                  helperText={touched.deliveryTime && errors.deliveryTime}
                />
              </Grid>
            </Grid>
          </PaperBox>

          <PaperBox>
            <Box mb={12}>
              <FormHeading>Material Details</FormHeading>
            </Box>
            <Grid
              container
              rowSpacing={12}
              columnSpacing={{ sm: 0, md: 20 }}
              md={10}
              sm={12}
            >
              <Grid item md={6} sm={12}>
                <FormInputLable requried>Default Material</FormInputLable>
                <StyledTextField
                  select
                  fullWidth
                  placeholder="Select the Material"
                  error={Boolean(touched.material && errors.material)}
                  // helperText={touched.material && errors.material}
                  {...getFieldProps("material")}
                  onChange={formik.handleChange("material")}
                  defaultValue={[]}
                  SelectProps={{
                    multiple: false,
                  }}
                >
                  {props?.materials?.map((item: any) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </Grid>
              <Grid item md={6} sm={12}>
                <FormInputLable requried>Quantity</FormInputLable>
                <StyledTextField
                  placeholder="Enter item Quantity"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          src={
                            process.env.PUBLIC_URL + `/assets/icons/MTIcon.svg`
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                  {...getFieldProps("quantity")}
                  error={Boolean(touched.quantity && errors.quantity)}
                  helperText={touched.quantity && errors.quantity}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <FormInputLable requried>Quality</FormInputLable>
                <StyledTextField
                  select
                  placeholder="Select or add  item quality"
                  fullWidth
                  {...getFieldProps("quality")}
                  error={Boolean(touched.quality && errors.quality)}
                  helperText={touched.quality && errors.quality}
                  onChange={formik.handleChange("quality")}
                  SelectProps={{
                    multiple: false,
                  }}
                >
                  {qualities?.map((item: any) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.title}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </Grid>
            </Grid>
          </PaperBox>

          <PaperBox>
            <Box mb={12}>
              <FormHeading>Notes & Files</FormHeading>
            </Box>
            <Grid
              container
              rowSpacing={12}
              columnSpacing={{ sm: 0, md: 20 }}
              md={10}
              sm={12}
            >
              <Grid item md={6} sm={12}>
                <FormInputLable>Notes</FormInputLable>
                <StyledTextFieldMulti
                  placeholder="Write something..."
                  fullWidth
                  multiline
                  {...getFieldProps("notes")}
                  error={Boolean(touched.notes && errors.notes)}
                  helperText={touched.notes && errors.notes}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <Grid item md={12} sm={12} mb={16}>
                  <FormInputLable>Order Date</FormInputLable>
                  <StyledDatePicker
                    placeholder="Enter order delivery time frame"
                    {...getFieldProps("orderDate")}
                    error={Boolean(touched.orderDate && errors.orderDate)}
                    helperText={touched.orderDate && errors.orderDate}
                    onChange={(newValue: Date | null) =>
                      formik.setFieldValue("orderDate", newValue)
                    }
                  />
                </Grid>
                <Grid item md={12} sm={12}>
                  <FormInputLable>Files</FormInputLable>
                  <FileUploader
                    type={FileUploaderType.Multiple}
                    maxFiles={5}
                    files={files}
                    deleteEndPoint={`orders/${props?.orderData?.id}`}
                    setFiles={setFiles}
                  />
                </Grid>
              </Grid>
            </Grid>
          </PaperBox>

          <Actions width="100%">
            <Box>
              <GrayButton
                variant="outlined"
                fullWidth
                disabled={isCreateLoading}
                onClick={() => navigate("/Orders/Orders-list")}
              >
                Cancel
              </GrayButton>
            </Box>
            <Box marginLeft={12}>
              <StyledLoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={isCreateLoading || uploadFilesLoading}
                type="submit"
              >
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color={colors.gray[850]}
                >
                  Save Information
                </Typography>
              </StyledLoadingButton>
            </Box>
          </Actions>
        </Form>
      </FormikProvider>
    </>
  );
};

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  & > div {
    width: 184px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    & > div {
      width: 142px;
    }
  }
`;

export default OrderEditForm;
