import { axios } from "../../../config/Axios";
import { getData, patchValue, roleValue } from "./types";
//permissions get
const getPermissions = async () => {
  const response = await axios.get("/permissions");
  return response;
};
//roles get
const getRoles = async (data: getData) => {
  const response = await axios.get(`/roles?page=${data.page}`);
  return response;
};

//roles post
const postRoles = async (roleData: roleValue) => {
  const response = await axios.post("/roles", roleData);
  return response;
};

//roles patch
const patchRoles = async (roleData: patchValue) => {
  const response = await axios.patch(`/roles/${roleData.id}`, roleData);
  return response;
};
//roles delete
const deleteRoles = async (roleId: number) => {
  const response = await axios.delete(`/roles/${roleId}`);
  return response;
};
//roles get
const getRolesId = async (roleId: number) => {
  const response = await axios.get(`/roles/${roleId}`);
  return response;
};

const roleService = {
  getPermissions,
  getRolesId,
  getRoles,
  postRoles,
  patchRoles,
  deleteRoles,
};
export default roleService;
