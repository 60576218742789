import { FC } from "react";
import { Avatar, Box, Modal, styled, Typography } from "@mui/material";
import colors from "src/theme/colors";

interface EmployeesStatusModalProps {
  open: boolean;
  employees: any;
  onDismiss: () => void;
}

export const EmployeesStatusModal: FC<EmployeesStatusModalProps> = (props) => {
  const { open, employees, onDismiss } = props;

  const statusRenderer = (status: boolean) => {
    if (status === false) {
      return (
        <BlueStatus>
          <img
            src={process.env.PUBLIC_URL + "/assets/icons/LoadingBlue.svg"}
            alt="loading"
          />
          <Box ml={4}>In Progress</Box>
        </BlueStatus>
      );
    } else {
      return (
        <GreenStatus>
          <img
            src={process.env.PUBLIC_URL + "/assets/icons/Check.svg"}
            alt="Check"
          />
          <Box ml={4}>Completed</Box>
        </GreenStatus>
      );
    }
  };

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Wrapper>
        <Box
          sx={{
            marginBottom: "28px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={process.env.PUBLIC_URL + `/assets/icons/newCrudBlue.svg`} />
          <Title>Stocktaking product counting status</Title>
        </Box>
        {employees ? (
          <Content>
            <Row>
              <RowTitle>Employees</RowTitle>
              <RowMinTitle1>Counted</RowMinTitle1>
              <RowMinTitle2>New Items</RowMinTitle2>
            </Row>
            <Cards>
              {employees.map((employee: any) => (
                <EmployeeItem key={employee.id}>
                  <AvatarBox>
                    <Avatar
                      alt={employee.name}
                      src={employee.avatar}
                      sx={{
                        bgcolor: colors.gray[20],
                        width: "56px",
                        height: "56px",
                        marginRight: "16px",
                      }}
                    >
                      {employee.name}
                    </Avatar>
                    <AvatarTitles>
                      <AvatarTitle>{employee.name}</AvatarTitle>
                      {statusRenderer(employee.status)}
                    </AvatarTitles>
                  </AvatarBox>
                  <Counteds>{employee.counted}</Counteds>
                  <News>{employee.new_items}</News>
                </EmployeeItem>
              ))}
            </Cards>
          </Content>
        ) : (
          <Typography mt={24} fontSize="18px">
            Nothing Found..!
          </Typography>
        )}
        <CloseIcon onClick={onDismiss}>
          <img src={process.env.PUBLIC_URL + `/assets/icons/${"close"}.svg`} />
        </CloseIcon>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "700px",
  minWidth: 460,
  maxWidth: 700,
  height: "fit-content",
  maxHeight: "95vh",
  backgroundColor: colors.base.white,
  border: `1px solid ${colors.gray[200]}`,
  borderRadius: "5px",
  padding: "54px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  overflow: "auto",
  [theme.breakpoints.down("md")]: {
    width: 630,
    minWidth: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    width: 350,
    minWidth: "auto",
  },
}));

const Title = styled("h6")`
  margin-top: 14px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: ${colors.gray[850]};
`;

const CloseIcon = styled(Box)`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 4;
  cursor: pointer;
`;

const Content = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Row = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 54px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    display: none;
  }
`;

const RowTitle = styled("span")`
  font-size: 18px;
  font-weight: 700;
  color: ${colors.gray[850]};
  margin-right: 170px;
`;

const RowMinTitle1 = styled("span")`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.gray[850]};
  margin-right: 80px;
`;

const RowMinTitle2 = styled("span")`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.gray[850]};
`;

const Cards = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
`;

const EmployeeItem = styled(Box)`
  width: 100%;
  padding: 24px;
  border: 1px solid ${colors.gray[100]};
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
  }
`;

const AvatarBox = styled(Box)`
  width: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

const AvatarTitles = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const AvatarTitle = styled("span")`
  text-align: left;
  font-size: 15px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 4px;
  color: ${colors.gray[850]};
`;

const BlueStatus = styled("span")`
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  color: ${colors.secondary[800]};
  background-color: ${colors.secondary[50]};
  padding: 4px 12px;
  display: flex;
  align-items: center;
  border-radius: 40px;
`;

const GreenStatus = styled("span")`
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  color: ${colors.success[700]};
  background-color: ${colors.success[50]};
  padding: 4px 12px;
  display: flex;
  align-items: center;
  border-radius: 40px;
`;

const Counteds = styled("span")`
  width: 184px;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  color: ${colors.gray[850]};
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

const News = styled("span")`
  width: 108px;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  color: ${colors.gray[850]};
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;
