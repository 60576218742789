import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import StyledTable from "src/components/Table";
import { GridColDef } from "@mui/x-data-grid";
import { AppDispatch, RootState } from "src/store";
import {
  approveLocalInspections,
  deleteLocalInspection,
  getLocalInspections,
  rejectLocalInspections,
  reset as resetLocals,
  reset as resetLocalInspections,
  exportLocalInspections,
} from "../redux/LocalInspectionSlice";
import {
  IconButton,
  Box,
  Typography,
  Button,
  InputAdornment,
} from "@mui/material";
import { Add, Eye } from "iconsax-react";
import colors from "src/theme/colors";
import { styled } from "@mui/material/styles";
import { StyledButton } from "src/components/Button";
import ConfirmModal from "src/components/Modal";
import { SetQualityResultModal } from "../components/modals/SetResultModal/SetQualityResultModal";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";
import { GrayButton } from "src/components/GrayButton";
import { StyledTextField } from "src/components/Input";
import { GppBadOutlined, GppGoodOutlined, Search } from "@mui/icons-material";
import moment from "moment";
import {
  getQualities,
  reset as resetQualities,
} from "src/features/items/redux/qualitySlice";

const LocalInspectionTabel = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [searchValue, setSearchValue] = useState("");
  const [Data, setData] = useState([] as any);
  const [MetaData, setMetaData] = useState([] as any);
  const [selected, setSelected] = useState([] as any);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState([0]);

  const [isSetResultModalOpen, setIsSetResultModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState([] as any);

  const {
    qualities,
    isLoading: isQualitiesLoading,
    isError: isQualitiesError,
    isSuccess: isQualitiesSuccess,
    message: QualitiesMessage,
  } = useSelector((state: RootState) => state.qualities);

  const {
    LocalInspections,
    LocalInspectionsMeta,
    isError,
    isDeleteError,
    isExportError,
    isSuccess,
    isDeleteSuccess,
    isExportSuccess,
    message,
    deleteMessage,
    exportMessage,
    isLoading,
    isDeleteLoading,
    isExportLoading,
  } = useSelector((state: RootState) => state.localInspections);

  useEffect(() => {
    if (isQualitiesError) {
      enqueueSnackbar(QualitiesMessage, { variant: "error" });
    }
    return () => {
      dispatch(resetQualities());
    };
  }, [isQualitiesError, isQualitiesSuccess, QualitiesMessage, dispatch]);

  useEffect(() => {
    if (isExportError) {
      enqueueSnackbar(exportMessage, { variant: "error" });
    }
  }, [isExportError, isExportSuccess, exportMessage, dispatch]);

  useEffect(() => {
    const data = { limit: 100, page: 1 };
    dispatch(getQualities(data));
  }, []);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      if (message) {
        enqueueSnackbar(message, { variant: "success" });
      } else {
        setData(LocalInspections);
        setMetaData(LocalInspectionsMeta);
      }
    }
    return () => {
      dispatch(resetLocalInspections());
    };
  }, [isError, isSuccess, message, page]);

  useEffect(() => {
    if (isDeleteError) {
      enqueueSnackbar(deleteMessage, { variant: "error" });
    }
    if (isDeleteSuccess) {
      enqueueSnackbar(deleteMessage, { variant: "success" });
      dispatch(
        getLocalInspections({
          page:
            Data.length <= 1 || selectedId.length >= Data.length
              ? page > 1
                ? page - 1
                : 1
              : page,
        })
      ).then(() => setIsDeleteModalOpen(false));

      if (Data.length <= 1 || selectedId.length >= Data.length) {
        setPage(page > 1 ? page - 1 : 1);
      }
      setSelected([]);
      setSelectedId([]);
    }
    return () => {
      dispatch(resetLocalInspections());
    };
  }, [isDeleteError, isDeleteSuccess, deleteMessage]);

  useEffect(() => {
    dispatch(
      getLocalInspections({
        page: page,
        search: searchValue,
      })
    );
    setSelected([]);
    setSelectedId([]);
  }, [page, searchValue]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
      filterable: false,
    },
    {
      field: "title",
      headerName: "Inspection Title",
      flex: 1,
      minWidth: 240,
    },
    {
      field: "date",
      headerName: "Request Date",
      flex: 1,
      minWidth: 160,
      renderCell: (params) => (
        <Box padding={2} color="#1E2329">
          {moment(params.row?.relocation_date_time).format("DD-MMM-YYYY")}
        </Box>
      ),
    },
    {
      field: "warehouse",
      headerName: "Warehouse",
      flex: 1,
      minWidth: 280,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.warehouse?.title}
        </Box>
      ),
    },
    {
      field: "trucks_count",
      headerName: "Number Of Trucks",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "total_net_weight",
      headerName: "Total Net Weight",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "status",
      headerName: "status",
      flex: 0.4,
      minWidth: 160,
      renderCell: (params) =>
        params.row?.status === "approved" ? (
          <BadgeBox>
            <BadgeIcon>
              <GppGoodOutlined />
            </BadgeIcon>
            {params.row?.quality?.results}
          </BadgeBox>
        ) : params.row?.status === "rejected" ? (
          <BadgeBox isRejected>
            <BadgeIcon>
              <GppBadOutlined />
            </BadgeIcon>
            {params.row?.quality?.results}
          </BadgeBox>
        ) : (
          <Box sx={{ width: "110px" }}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              fullWidth
              onClick={() => {
                setSelectedItem(params.row);
                setIsSetResultModalOpen(true);
              }}
            >
              set Result
            </Button>
          </Box>
        ),
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.2,
      minWidth: 100,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          flexDirection="row"
        >
          {/* <IconButton>
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Remove.svg"}
              alt="delete"
              onClick={() => {
                setSelectedId([Number(params?.id)]);
                setIsDeleteModalOpen(true);
              }}
            />
          </IconButton> */}
        </Box>
      ),
    },
  ];

  const onDelete = (selected: any) => {
    const deleteValue = {
      warehouse_ids: selected,
    };
    dispatch(deleteLocalInspection(deleteValue));
  };

  const onApproveResult = (quality: string) => {
    dispatch(
      approveLocalInspections({
        id: Number(selectedItem?.id),
        quality_id: Number(quality),
      })
    ).then(() => {
      dispatch(
        getLocalInspections({
          page: page,
        })
      );
      setIsSetResultModalOpen(false);
    });
  };

  const onRejectResult = (quality: string, dataRemove: boolean) => {
    dispatch(
      rejectLocalInspections({
        id: Number(selectedItem?.id),
        quality_id: Number(quality),
      })
    ).then(() => {
      dispatch(
        getLocalInspections({
          page: page,
        })
      );
      setIsSetResultModalOpen(false);
    });
  };

  return (
    <PageTemplate
      crumbs={[
        {
          label: "local inspections list",
        },
      ]}
    >
      <StyledHeader>
        <SearchBox>
          <StyledTextField
            placeholder="Search"
            fullWidth
            height="48px"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </SearchBox>
        <ButtonsBox>
          <ActionsBox>
            <ActionBtn>
              <GrayButton
                variant="contained"
                fullWidth
                loading={isExportLoading}
                endIcon={
                  <img
                    src={process.env.PUBLIC_URL + "/assets/icons/Export.svg"}
                    alt="export"
                  />
                }
                onClick={() => {
                  dispatch(exportLocalInspections({})).then(() =>
                    resetLocals()
                  );
                }}
              >
                Export
              </GrayButton>
            </ActionBtn>
          </ActionsBox>
          <StyledButton
            variant="contained"
            endIcon={<Add size="24" color={colors.gray[850]} />}
            onClick={() => navigate("/local-inspections/local-inspection-new")}
          >
            New Local Inspection
          </StyledButton>
          {/* {selected.length !== 0 ? (
            <StyledButton
              variant="contained"
              endIcon={
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/removeWhite.svg"}
                  alt="delete"
                />
              }
              onClick={() => {
                setSelectedId(selected);
                setIsDeleteModalOpen(true);
              }}
              color="error"
            >
              Remove Local Inspections
            </StyledButton>
          ) : (
            <StyledButton
              variant="contained"
              endIcon={<Add size="24" color={colors.gray[850]} />}
              onClick={() =>
                navigate("/local-inspections/local-inspection-new")
              }
            >
              New Local Inspection
            </StyledButton>
          )} */}
        </ButtonsBox>
      </StyledHeader>
      <ListBoundary
        isEmpty={Data.length === 0 || !Data}
        isDataLoading={isLoading}
        fallbackImageURL="/assets/images/emptyStates/LocalInspections-List EmptyState.svg"
        description="Register Your First Local Inspection Request!"
        buttonLabel="New Inspection Request"
        onClick={() => navigate("/local-inspections/local-inspection-new")}
      >
        <StyledTable
          disableColumnMenu
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableVirtualization
          disableSelectionOnClick
          loading={isLoading}
          checkboxSelection
          experimentalFeatures={{ newEditingApi: true }}
          rows={Data}
          columns={columns}
          onSelectionModelChange={(e) => setSelected(e)}
          onPageChange={(e) => setPage(e + 1)}
          page={page - 1}
          paginationMode="server"
          rowCount={MetaData?.pagination?.total_items}
        />
      </ListBoundary>
      <ConfirmModal
        title={"Remove Local Inspections"}
        iconName={"ModalRemove"}
        open={isDeleteModalOpen}
        isLoading={isDeleteLoading || isLoading}
        onDismiss={() => setIsDeleteModalOpen(false)}
        onConfirm={() => onDelete(selectedId)}
        confrimText={"Remove"}
        cancelText={"Cancel"}
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to remove these Local Inspections?
        </Typography>
      </ConfirmModal>
      {isSetResultModalOpen ? (
        <SetQualityResultModal
          open={isSetResultModalOpen}
          qualities={qualities}
          isLoading={isQualitiesLoading}
          isButtonLoading={isLoading}
          selected={selectedItem}
          requestName={selectedItem?.title}
          title={"Local Inspection Result"}
          iconName={"Inspection"}
          onDismiss={() => setIsSetResultModalOpen(false)}
          onApprove={(quality: string) => onApproveResult(quality)}
          onReject={(quality: string, dataRemove: boolean) =>
            onRejectResult(quality, dataRemove)
          }
        />
      ) : null}
    </PageTemplate>
  );
};

interface StyledProps {
  isRejected?: boolean;
}

const StyledHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  ${(props) => props.theme.breakpoints.down("xl")} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SearchBox = styled(Box)`
  width: 372px;
  ${(props) => props.theme.breakpoints.down("xl")} {
    margin-bottom: 24px;
    width: 315px;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    margin-bottom: 18px;
  }
`;

const ButtonsBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("xl")} {
    width: 100%;
    justify-content: space-between;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

const ActionsBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 24px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

const ActionBtn = styled(Box)`
  width: 145px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

const BadgeBox = styled(Box)<StyledProps>(({ isRejected }) => ({
  padding: "2px 12px",
  borderRadius: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "4px",
  lineHeight: "26px",
  fontWeight: "400",
  fontSize: "13px",
  backgroundColor: isRejected ? colors.error[10] : colors.success[50],
  color: isRejected ? colors.error[500] : colors.success[700],
}));

const BadgeIcon = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default LocalInspectionTabel;
