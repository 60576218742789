import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import WarehouseServices from "./WarehouseServices";

const initialState = {
  isError: false,
  isExportError: false,
  isConfirmError: false,
  isDeleteError: false,
  isSuccess: false,
  isExportSuccess: false,
  isConfirmSuccess: false,
  isDeleteSuccess: false,
  isLoading: true,
  isExportLoading: true,
  isConfirmLoading: false,
  isDeleteLoading: false,
  message: "",
  exportMessage: "",
  confirmMessage: "",
  deleteMessage: "",
  Trucks: [],
  TrucksMeta: [],
  singleWarehouse: [],
};

export const getTrucks = createAsyncThunk(
  "Trucks",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.getTrucks(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const confirmTruck = createAsyncThunk(
  "Trucks/patch",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.confirmTruck(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteTruck = createAsyncThunk(
  "Trucks/delete",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.deleteTruck(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Truckslice = createSlice({
  name: "Warehouse",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isExportLoading = false;
      state.isConfirmLoading = false;
      state.isDeleteLoading = false;
      state.isSuccess = false;
      state.isExportSuccess = false;
      state.isConfirmSuccess = false;
      state.isDeleteSuccess = false;
      state.isError = false;
      state.isExportError = false;
      state.isConfirmError = false;
      state.isDeleteError = false;
      state.message = "";
      state.exportMessage = "";
      state.confirmMessage = "";
      state.deleteMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrucks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTrucks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.Trucks = action.payload.data.results;
        state.TrucksMeta = action.payload.data.meta;
      })
      .addCase(getTrucks.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(confirmTruck.pending, (state) => {
        state.isConfirmLoading = true;
      })
      .addCase(confirmTruck.fulfilled, (state, action) => {
        state.isConfirmLoading = false;
        state.isConfirmSuccess = true;
        state.confirmMessage = `${action.payload.data.message}`;
      })
      .addCase(confirmTruck.rejected, (state, action) => {
        state.isConfirmLoading = false;
        state.isConfirmError = true;
        state.confirmMessage = `${action.payload}`;
      })

      .addCase(deleteTruck.pending, (state) => {
        state.isDeleteLoading = true;
      })
      .addCase(deleteTruck.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteSuccess = true;
        state.deleteMessage = `${action.payload.data.message}`;
      })
      .addCase(deleteTruck.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.deleteMessage = `${action.payload}`;
      });
  },
});

export const { reset } = Truckslice.actions;
export default Truckslice.reducer;
