import styled from "@emotion/styled";
import { styled as styledMui } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { PageTemplate } from "src/layout/components/PageTemplate";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import StocktakingProducts from "../components/StocktakingProducts";
import {
  reset as resetStockTakings,
  getStockTakingId,
  deleteStockTaking,
  stopStockTaking,
} from "../redux/StockTakingSlice";
import ConfirmModal from "src/components/Modal";
import { Box, Typography } from "@mui/material";
import { StocktakingDetailsCard } from "../components/infos/StocktakingDetailsCard";
import { StocktakingStatusCounters } from "../components/infos/StocktakingStatusCounters";
import { StocktakingStatusChart } from "../components/infos/StocktakingStatusChart";
import { getProducts } from "src/features/products/redux/ProductsSlice";
import { EmployeesStatusModal } from "../components/modals/EmployeesStatusModal";
import moment from "moment";

interface CustomizedState {
  data: any;
}

const StocktakingInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state as CustomizedState;
  const { data: stocktakingInfo } = state;

  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [productsListFilterData, setProductsListFilterData] = useState(
    [] as any
  );
  const [isUserStatusModalOpen, setIsUserStatusModalOpen] = useState(false);
  const [isFinishModalOpen, setIsFinishModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [singleStocktakingData, setSingleStocktakingData] = useState([] as any);
  const [selectedFilter, setSelectedFilter] = useState("0");

  //redux -----------------------------------
  const {
    isSingleLoading,
    isDeleteLoading,
    singleStockTaking,
    isError,
    isSuccess,
    message,
  } = useSelector((state: RootState) => state.stockTaking);

  const {
    products,
    isLoading: isProductsLoading,
    isError: isProductsError,
    isSuccess: isProductsSuccess,
    message: productsMessage,
  } = useSelector((state: RootState) => state.products);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      setSingleStocktakingData(singleStockTaking);
    }
  }, [isError, isSuccess, message, isSingleLoading, dispatch]);

  useEffect(() => {
    if (isProductsError) {
      enqueueSnackbar(productsMessage, { variant: "error" });
    }
    if (isProductsSuccess) {
      const productsData = products?.map((p: any) => ({
        id: p.id,
        title: p.title,
      }));
      productsData.unshift({
        id: "0",
        title: "All",
      });

      setProductsListFilterData(productsData);
    }
  }, [isProductsError, isProductsSuccess, productsMessage, dispatch]);

  useEffect(() => {
    const data = {
      warehouse: Number(stocktakingInfo?.warehouse?.id),
      stockTaking: Number(stocktakingInfo?.id),
      product: selectedFilter === "0" ? "" : Number(selectedFilter),
    };
    dispatch(getStockTakingId(data));
  }, [selectedFilter]);

  useEffect(() => {
    dispatch(
      getProducts({
        page: 1,
        limit: 100,
      })
    );
  }, []);

  const onFinishStocktaking = () => {
    const data = {
      warehouse: Number(stocktakingInfo?.warehouse?.id),
      stockTaking: Number(stocktakingInfo?.id),
    };
    dispatch(stopStockTaking(data))
      .then(() => dispatch(getStockTakingId(data)))
      .then(() => setIsFinishModalOpen(false));
  };

  const onDeleteStocktaking = () => {
    const deleteValues = {
      warehouse: Number(stocktakingInfo?.warehouse?.id),
      stockTaking: Number(stocktakingInfo?.id),
    };
    dispatch(deleteStockTaking(deleteValues)).then(() => navigate(-1));
  };

  return (
    <>
      <PageTemplate
        isTransparent
        crumbs={[
          {
            label: "stocktakings",
            href: "/stocktakings/stocktakings-list",
            depth: -1,
          },
          {
            label: stocktakingInfo?.title,
          },
        ]}
      >
        <StocktakingDetailsCard
          title={stocktakingInfo?.title}
          warehouse={stocktakingInfo?.warehouse?.title}
          startDate={moment(stocktakingInfo?.start_date).format("DD-MMM-YYYY")}
          status={stocktakingInfo?.status}
          onEditStocktaking={() =>
            navigate("/stocktakings/stocktakings-list", {
              state: { isRedirectedToEdit: true, rowData: stocktakingInfo },
            })
          }
          onFinishStocktaking={() => setIsFinishModalOpen(true)}
          // onDeleteStocktaking={() => setIsDeleteModalOpen(true)}
          onDeleteStocktaking={() => undefined}
          onShowEmployeesStatus={() => setIsUserStatusModalOpen(true)}
        />
        <CardsRow>
          <CountersPaper>
            <StocktakingStatusCounters
              isLoading={isSingleLoading || isProductsLoading}
              counts={singleStocktakingData?.result}
              productsList={productsListFilterData}
              selectedFilter={selectedFilter}
              setSelectedFilter={(value: any) => setSelectedFilter(value)}
            />
          </CountersPaper>
          <ChartPaper>
            <StocktakingStatusChart
              isLoading={isSingleLoading || isProductsLoading}
              counts={singleStocktakingData?.result}
            />
          </ChartPaper>
        </CardsRow>
        <Paper>
          <StocktakingProducts
            warehouseId={stocktakingInfo?.warehouse.id}
            id={stocktakingInfo?.id}
          />
        </Paper>
      </PageTemplate>

      <EmployeesStatusModal
        open={isUserStatusModalOpen}
        employees={stocktakingInfo?.employee}
        onDismiss={() => setIsUserStatusModalOpen(false)}
      />
      <ConfirmModal
        title={"Finish Stocktaking"}
        iconName={"ModalRemove"}
        open={isFinishModalOpen}
        isLoading={isDeleteLoading}
        onDismiss={() => setIsFinishModalOpen(false)}
        onConfirm={onFinishStocktaking}
        confrimText={"Finish"}
        cancelText={"Cancel"}
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to Finish this Stocktaking?
        </Typography>
      </ConfirmModal>
      <ConfirmModal
        title={"Remove Stocktaking"}
        iconName={"ModalRemove"}
        open={isDeleteModalOpen}
        isLoading={isDeleteLoading}
        onDismiss={() => setIsDeleteModalOpen(false)}
        onConfirm={onDeleteStocktaking}
        confrimText={"Remove"}
        cancelText={"Cancel"}
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to Remove this Stocktaking?
        </Typography>
      </ConfirmModal>
    </>
  );
};

export default StocktakingInfo;

const Paper = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 4px;
  background-color: ${colors.base.white};
`;

const CardsRow = styledMui("div")(({ theme }) => ({
  width: "100%",
  marginBottom: "16px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
    "& > div": {
      width: "100%",
      "&:first-child": {
        marginBottom: "16px",
      },
    },
  },
}));

const CountersPaper = styled("div")`
  width: 70%;
  overflow: auto;
`;

const ChartPaper = styled("div")`
  width: 29%;
  overflow: auto;
`;
