import React, { useState } from "react";
import Box from "@mui/material/Box";
import Sidebar from "./components/SideBar";
import { AppDispatch, RootState } from "src/store";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "./redux/layoutStore";

const MainLayout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isSidebarOpen } = useSelector((state: RootState) => state.layout);

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar
        isOpenSidebar={isSidebarOpen}
        onCloseSidebar={() => dispatch(toggleSidebar())}
      />
    </Box>
  );
};

export default MainLayout;
