import { axios } from "../../../config/Axios";
import {
  contactdeleteValue,
  ContactPatchProps,
  contactValue,
  getData,
  vendordeleteValue,
  vendorPatchValue,
  vendorValue,
} from "./types";

//vendor get
const getVendors = async (data: any) => {
  const response = await axios.get(
    `/vendors?page=${data.page}&${data.limit ? `limit=${data.limit}` : ""}`
  );
  return response;
};
//vendor get by id
const getVendorsId = async (vendorId: number) => {
  const response = await axios.get(`/vendors/${vendorId}`);
  return response;
};
//vendor submit
const postVendor = async (vendorData: vendorValue) => {
  const response = await axios.post("/vendors", vendorData);
  return response;
};
//vendor submit
const patchVendor = async (vendorData: vendorPatchValue) => {
  const response = await axios.patch(`/vendors/${vendorData.id}`, vendorData);
  return response;
};
//vendor submit
const deleteVendor = async (vendorData: any) => {
  const response = await axios.delete(`/vendors`, { data: vendorData });
  return response;
};
//contact submit
const postContact = async (contactData: contactValue) => {
  const response = await axios.post(
    `/vendors/${contactData.vendorId}/contacts`,
    contactData
  );
  return response;
};
//contact get by id
const getContactId = async (vendorId: number) => {
  const response = await axios.get(`/vendors/${vendorId}/contacts`);
  return response;
};
const deleteContact = async (contactDeleteData: contactdeleteValue) => {
  const response = await axios.delete(
    `/vendors/${contactDeleteData.vendorId}/contacts/${contactDeleteData.contactId}`
  );
  return response;
};
const patchContact = async (contactPatchData: ContactPatchProps) => {
  const response = await axios.patch(
    `/vendors/${contactPatchData.vendorId}/contacts/${contactPatchData.contactId}`,
    contactPatchData
  );
  return response;
};

const vendorServices = {
  getVendors,
  getVendorsId,
  postVendor,
  patchVendor,
  deleteVendor,
  getContactId,
  postContact,
  deleteContact,
  patchContact,
};
export default vendorServices;
