import { FC } from "react";
import { Box, Modal, styled } from "@mui/material";
import colors from "src/theme/colors";
import Loading from "src/components/Loading";
import { StockTakingNewForm } from "../StockTakingNewForm";

interface NewStockTakingModalProps {
  open: boolean;
  warehouses: any;
  employes: any;
  products: any;
  onSuccess: () => void;
  onDismiss: () => void;
}

export const NewStockTakingModal: FC<NewStockTakingModalProps> = (props) => {
  const { open, warehouses, employes, products, onSuccess, onDismiss } = props;

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Wrapper>
        <StockTakingNewForm
          warehouses={warehouses}
          employes={employes}
          products={products}
          onSuccess={onSuccess}
          onClose={onDismiss}
        />
        <CloseIcon onClick={onDismiss}>
          <img src={process.env.PUBLIC_URL + `/assets/icons/${"close"}.svg`} />
        </CloseIcon>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  minWidth: 460,
  maxWidth: 800,
  height: "fit-content",
  maxHeight: "95vh",
  backgroundColor: colors.base.white,
  border: `1px solid ${colors.gray[200]}`,
  borderRadius: "5px",
  padding: "42px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  overflow: "auto",
  [theme.breakpoints.down("md")]: {
    width: "90%",
    minWidth: "auto",
  },
}));

const LoadingWrapper = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CloseIcon = styled(Box)`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 4;
  cursor: pointer;
`;
