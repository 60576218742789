export default {
  50: "#E1F4EE",
  100: "#B5E3D3",
  200: "#86D1B7",
  300: "#56BE9B",
  400: "#33AF86",
  500: "#18A074",
  600: "#149268",
  700: "#0E8259",
  800: "#02724C",
  900: "#005631",
};
