import styled from "@emotion/styled";
import { Box, Typography, styled as styledMui } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import { useEffect, useState } from "react";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";
import StyledTable from "src/components/Table";
import colors from "src/theme/colors";
import { GrayButton } from "src/components/GrayButton";
import { StyledLoadingButton } from "src/components/LoadingButton";

import { InspectionRequestConfirmModal } from "./modals/InspectionRequestConfirmModal";
import { useNavigate } from "react-router-dom";
import {
  patchInternationalInspection,
  exportInternationalProductsExcel,
  exportInternationalProductsPDF,
  getInternationalInspectionProductItems,
  reset as resetInternationalInspections,
} from "../redux/InternationalInspectionSlice";
import { PaperBox } from "src/components/Paper/Paper";

interface Props {
  title: string;
  id: string;
  values: any;
  warehouse?: string;
  product?: string;
  placementId?: any;
  placement?: string;
  dateRange?: string;
  onGoBack: () => void;
}

export const InternationalEditProductsList = (props: Props) => {
  const {
    title,
    id,
    values,
    warehouse,
    product,
    placementId,
    placement,
    dateRange,
    onGoBack,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState([] as any);
  const [productsData, setProductsData] = useState([] as any);
  const [productsMeta, setProductsMeta] = useState([] as any);

  const [confirmRequestModal, setIsConfirmRequestModal] = useState(false);

  const {
    isUpdateRequestLoading,
    isUpdateRequestError,
    isUpdateRequestSuccess,
    updateRequestMessage,
    productItems,
    productItemsMeta,
    productItemsMessage,
    isProductItemsSuccess,
    isProductItemsLoading,
    isProductItemsError,
    isProductItemsExportExcelLoading,
    isProductItemsExportExcelError,
    productItemsExportExcelMessage,
  } = useSelector((state: RootState) => state.internationalInspections);

  useEffect(() => {
    if (isProductItemsExportExcelError) {
      enqueueSnackbar(productItemsExportExcelMessage, { variant: "error" });
    }
  }, [
    isProductItemsExportExcelError,
    productItemsExportExcelMessage,
    isProductItemsExportExcelLoading,
  ]);

  useEffect(() => {
    if (isProductItemsError) {
      enqueueSnackbar(productItemsMessage, { variant: "error" });
    }
    if (isProductItemsSuccess) {
      const selectedItemsList =
        productItems?.filter((item: any) => item.selected === true) ?? [];
      const selectedItemsIds =
        selectedItemsList?.map((item: any) => item.id) ?? [];
      setProductsData(productItems);
      setProductsMeta(productItemsMeta);
      setSelected(selectedItemsIds);
    }
  }, [
    isProductItemsError,
    isProductItemsSuccess,
    productItemsMessage,
    isProductItemsLoading,
    dispatch,
  ]);

  // useEffect(() => {
  //   if (productItems) {
  //     const selectedItemsList =
  //       productItems?.filter((item: any) => item.selected === true) ?? [];
  //     const selectedItemsIds =
  //       selectedItemsList?.map((item: any) => item.id) ?? [];
  //     setSelected(selectedItemsIds);
  //   }
  // }, [page]);

  useEffect(() => {
    if (isUpdateRequestError) {
      enqueueSnackbar(updateRequestMessage, { variant: "error" });
    }
    if (isUpdateRequestSuccess) {
      enqueueSnackbar(updateRequestMessage, { variant: "success" });
      navigate("/international-inspections/international-inspections-list");
    }
    return () => {
      dispatch(resetInternationalInspections());
    };
  }, [
    updateRequestMessage,
    isUpdateRequestError,
    isUpdateRequestSuccess,
    dispatch,
  ]);

  const columns: GridColDef[] = [
    {
      field: "product",
      headerName: "Product",
      flex: 1,
      minWidth: 140,
      filterable: false,
    },
    {
      field: "barcode",
      headerName: "Internal Barcode",
      flex: 1,
      minWidth: 140,
      filterable: false,
    },
    {
      field: "weight",
      headerName: "Weight",
      flex: 0.6,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "placement",
      headerName: "Placement",
      flex: 1,
      minWidth: 140,
      filterable: false,
    },
  ];

  useEffect(() => {
    const postData = {
      id: id,
      page: page,
    };

    dispatch(getInternationalInspectionProductItems(postData));
  }, [page]);

  const onUpdateRequest = () => {
    const requestData = {
      id: Number(id),
      title: title,
      warehouse_id: values.warehouseId,
      product_id: values.product,
      placements: placementId,
      product_items: selected,
    };

    dispatch(patchInternationalInspection(requestData));
  };

  return (
    <>
      <PaperBox>
        <MainHead>
          <Typography
            width="100%"
            color={colors.gray[850]}
            fontWeight="700"
            fontSize="24px"
            mb={12}
          >
            Products List
          </Typography>
          <HeadActionsBox>
            <ActionBtn>
              <GrayButton
                variant="contained"
                fullWidth
                loading={isProductItemsExportExcelLoading}
                endIcon={
                  <img
                    src={process.env.PUBLIC_URL + "/assets/icons/Export.svg"}
                    alt="export"
                  />
                }
                onClick={() =>
                  dispatch(exportInternationalProductsExcel(Number(id))).then(
                    () => resetInternationalInspections()
                  )
                }
              >
                Export Excel
              </GrayButton>
            </ActionBtn>
          </HeadActionsBox>
        </MainHead>
        <StyledHeader>
          <FiltersRow>
            {selected && selected?.length > 0 ? (
              <ResultsCount>
                Total Selected: {selected?.length ?? 0}/
                {(productItemsMeta as any)?.pagination?.total_items}
              </ResultsCount>
            ) : (
              <div />
            )}
            <Filters>
              <FiltersLabel>Filters:</FiltersLabel>
              {warehouse ? (
                <Filter>
                  <BadgeIcon>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/WarehouseBlueMini.svg"
                      }
                    />
                  </BadgeIcon>
                  {warehouse}
                </Filter>
              ) : null}
              {product ? (
                <Filter>
                  <BadgeIcon>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/ProductBlueMini.svg"
                      }
                    />
                  </BadgeIcon>
                  {product}
                </Filter>
              ) : null}
              {placement ? (
                <Filter>
                  <BadgeIcon>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/PlacementBlueMini.svg"
                      }
                    />
                  </BadgeIcon>
                  {placement}
                </Filter>
              ) : null}
              {dateRange ? (
                <Filter>
                  <BadgeIcon>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/CalenderBlueMini.svg"
                      }
                    />
                  </BadgeIcon>
                  {dateRange}
                </Filter>
              ) : null}
            </Filters>
          </FiltersRow>
        </StyledHeader>
        <ListBoundary
          isEmpty={productItems.length === 0 || !productItems}
          isDataLoading={isProductItemsLoading}
          fallbackImageURL="/assets/images/emptyStates/SearchedProducts-List EmptyState.svg"
          description="No Result Found ..."
        >
          <StyledTable
            disableColumnMenu
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableVirtualization
            disableSelectionOnClick
            loading={false}
            columns={columns}
            experimentalFeatures={{ newEditingApi: true }}
            rows={productItems}
            checkboxSelection
            onSelectionModelChange={(e) => {
              setSelected(e);
            }}
            selectionModel={selected}
            onPageChange={(e) => setPage(e + 1)}
            page={page - 1}
            paginationMode="server"
            rowCount={(productItemsMeta as any)?.pagination?.total_items}
          />
        </ListBoundary>
      </PaperBox>

      <Actions mt={8} width="100%">
        <Box>
          <GrayButton variant="outlined" fullWidth onClick={onGoBack}>
            Back
          </GrayButton>
        </Box>
        <Box marginLeft={12}>
          <StyledLoadingButton
            variant="contained"
            color="primary"
            fullWidth
            disabled={selected.length === 0}
            onClick={() => setIsConfirmRequestModal(true)}
          >
            <Typography fontSize={16} fontWeight={500} color={colors.gray[850]}>
              Edit Request
            </Typography>
          </StyledLoadingButton>
        </Box>
      </Actions>

      <InspectionRequestConfirmModal
        open={confirmRequestModal}
        title={title!}
        product={product!}
        warehouse={warehouse!}
        isButtonLoading={isUpdateRequestLoading}
        onSave={onUpdateRequest}
        onDismiss={() => setIsConfirmRequestModal(false)}
      />
    </>
  );
};

const MainHead = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const StyledHeader = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const FiltersRow = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Filters = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const FiltersLabel = styled("span")`
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  white-space: nowrap;
  color: ${colors.gray[850]};
`;

const ResultsCount = styled("h6")`
  text-align: center;
  font-size: 14px;
  line-height: 28px;
  border-radius: 30px;
  padding: 6px 16px;
  white-space: nowrap;
  background-color: ${colors.secondary[50]};
  color: ${colors.secondary[800]};
`;

const Filter = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 28px;
  border-radius: 30px;
  padding: 6px 16px;
  white-space: nowrap;
  background-color: ${colors.secondary[50]};
  color: ${colors.secondary[800]};
  margin-left: 16px;
  &:last-child {
    margin-right: 0;
  }
`;

const BadgeIcon = styled("div")`
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Actions = styledMui(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
  & > div {
    width: 180px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    & > div {
      width: 100%;
    }
  }
`;

const HeadActionsBox = styledMui(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  // margin-right: 24px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
    margin-right: 16px;
  }
`;

const ActionBtn = styledMui(Box)`
  width: 165px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;
