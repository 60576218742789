import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { ReactNode } from "react";
import colors from "src/theme/colors";

type Props = {
  children: ReactNode;
};

const TableChip = (props: Props) => {
  return (
    <StyledContainer>
      <Typography fontSize={12} fontWeight={700} color={colors.secondary[800]}>
        {props.children}
      </Typography>
    </StyledContainer>
  );
};

export default TableChip;

const StyledContainer = styled(Box)`
  background: ${colors.secondary[50]};
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 7px 12px;
`;
