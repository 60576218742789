import { Box, Typography, Modal } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import { FC, useState, useEffect } from "react";
import FormInputLable from "src/components/FormInputLabel";
import { GrayButton } from "src/components/GrayButton";
import { StyledTextField } from "src/components/Input";
import { StyledLoadingButton } from "src/components/LoadingButton";
import colors from "src/theme/colors";

interface SealNumberAddCardProps {
  title: string;
  defaultValue: string;
  index: number;
  loading?: boolean;
  isModalOpen: boolean;
  onAddSealNumber: (addData: any) => void;
  onOpenModal: () => void;
  onCloseModal: () => void;
}

export const SealNumberAddCard: FC<SealNumberAddCardProps> = (props) => {
  const {
    title,
    defaultValue,
    index,
    loading,
    isModalOpen,
    onAddSealNumber,
    onOpenModal,
    onCloseModal,
  } = props;

  const [sealNumber, setSealNumber] = useState("");

  // useEffect(() => {
  //   if (!isModalOpen) {
  //     setSealNumber("");
  //   }
  // }, [isModalOpen]);

  useEffect(() => {
    if (!isModalOpen && defaultValue) {
      setSealNumber(defaultValue);
    }
  }, [isModalOpen, defaultValue]);

  const onAdd = () => {
    onAddSealNumber(sealNumber);
  };

  const onDismiss = () => {
    onCloseModal();
    setSealNumber("");
  };

  return (
    <>
      <Toggler>
        <BigTitle>
          {index}. {title}
        </BigTitle>
        <Infos>
          <UploadAdder onClick={onOpenModal}>
            {defaultValue ? (
              <DefValue>
                {defaultValue}
                <img
                  src={
                    process.env.PUBLIC_URL +
                    `/assets/icons/saleOrderShipment/EditBlack.svg`
                  }
                />
              </DefValue>
            ) : (
              "Add Seal Number"
            )}
          </UploadAdder>
        </Infos>
      </Toggler>

      <Modal
        open={isModalOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Wrapper>
          <Box
            sx={{
              marginBottom: "28px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={
                process.env.PUBLIC_URL +
                `/assets/icons/saleOrderShipment/ContainerAdd.svg`
              }
            />
            <Title>{defaultValue ? "Edit" : "Add"} Container Seal Number</Title>
          </Box>

          <FieldsContainer>
            <Box width="100%">
              <FormInputLable noPrefix>Container Number</FormInputLable>
              <StyledTextField
                placeholder="Enter Container number"
                fullWidth
                value={sealNumber}
                defaultValue={defaultValue}
                onChange={(e) => setSealNumber(e.target.value)}
              />
            </Box>
          </FieldsContainer>

          <Actions>
            <Box>
              <GrayButton
                variant="outlined"
                fullWidth
                disabled={loading}
                onClick={onDismiss}
              >
                Cancel
              </GrayButton>
            </Box>
            <Box>
              <StyledLoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={loading}
                disabled={!sealNumber}
                onClick={onAdd}
              >
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color={colors.midnight[850]}
                >
                  Confirm
                </Typography>
              </StyledLoadingButton>
            </Box>
          </Actions>
          <CloseIcon onClick={onDismiss}>
            <img
              src={process.env.PUBLIC_URL + `/assets/icons/${"close"}.svg`}
            />
          </CloseIcon>
        </Wrapper>
      </Modal>
    </>
  );
};

const Toggler = muiStyled(Box)(({ theme }) => ({
  width: "100%",
  height: "44px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  zIndex: 4,

  [theme.breakpoints.down("md")]: {
    height: "auto",
  },
}));

const Infos = muiStyled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
});

const BigTitle = muiStyled(Typography)(({ theme }) => ({
  fontSize: "15px",
  fontWeight: 600,
  lineHeight: "20px",
  textAlign: "left",
  marginRight: "12px",
  color: colors.gray[750],

  [theme.breakpoints.down("md")]: {
    fontSize: "13px",
    fontWeight: 400,
  },
}));

const UploadAdder = muiStyled(Typography)(({ theme }) => ({
  cursor: "pointer",
  fontSize: "13px",
  fontWeight: 700,
  lineHeight: "26px",
  textAlign: "left",
  marginRight: "8px",
  textDecoration: "underline",
  color: colors.midnight[750],
}));

const Wrapper = muiStyled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  minWidth: 600,
  maxWidth: 600,
  height: "fit-content",
  maxHeight: "95vh",
  backgroundColor: colors.base.white,
  border: `1px solid ${colors.gray[200]}`,
  borderRadius: "5px",
  padding: "42px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  overflow: "auto",
  [theme.breakpoints.down("md")]: {
    width: "90%",
    minWidth: "auto",
    padding: "42px 24px",
  },
}));

const CloseIcon = muiStyled(Box)`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 4;
  cursor: pointer;
`;

const DefValue = muiStyled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px
`;

const Title = muiStyled("h6")`
  margin-top: 14px;
  font-size: 18px;
  font-weight: 700;
  color: ${colors.midnight[850]};
`;

const FieldsContainer = muiStyled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Actions = muiStyled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  gap: 24px;
  width: 100%;
  & > div {
    width: 500%;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    gap: 14px;
    & > div {
      width: 100%;
    }
  }
`;
