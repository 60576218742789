import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import CustomersEdit from "../pages/CustomersEdit";
import CustomersInfo from "../pages/CustomersInfo";
import CustomersNew from "../pages/CustomersNew";
import CustomersList from "../pages/CustomersList";

export const CustomersRoutes = () => {
  const { t } = useTranslation();
  return (
    <Routes>
      <Route path="/customers-list" element={<CustomersList />} />
      <Route path="/customers-new" element={<CustomersNew />} />
      <Route path="/customers-edit" element={<CustomersEdit />} />
      <Route path="/customers-information" element={<CustomersInfo />} />
    </Routes>
  );
};
