/* eslint-disable react/no-unescaped-entities */
import { FC } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import { Form, FormikProvider } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormHeading from "src/components/FormHeading";
import FormInputLable from "src/components/FormInputLabel";
import { StyledTextField } from "src/components/Input";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import { StyledDatePicker } from "src/components/DatePicker/DatePicker";
import { GrayButton } from "src/components/GrayButton";
import { useNavigate } from "react-router-dom";
import { PaperBox } from "src/components/Paper/Paper";

interface Props {
  warehouses: any;
  products: any;
  placements: any;
  formik: any;
}

export const InternationalInspectionEditForm: FC<Props> = (props) => {
  const { warehouses, products, placements, formik } = props;
  const navigate = useNavigate();

  const { errors, touched, values, handleSubmit, resetForm, getFieldProps } =
    formik;

  const onReset = () => {
    resetForm();
    navigate("/international-inspections/international-inspections-list");
  };

  return (
    <Container>
      <FormikProvider value={formik}>
        <FormContainer>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <PaperBox>
              <PaperContainer>
                <Box mb={12}>
                  <FormHeading>Request Title</FormHeading>
                </Box>
                <SingleRow width="100%">
                  <Box>
                    <FormInputLable requried>Title</FormInputLable>
                    <StyledTextField
                      placeholder="Enter the inspection request title"
                      fullWidth
                      {...getFieldProps("title")}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                    />
                  </Box>
                </SingleRow>
              </PaperContainer>
            </PaperBox>

            <PaperBox>
              <PaperContainer>
                <Box mb={12}>
                  <FormHeading>Input Filters</FormHeading>
                </Box>
                <FieldsContainer>
                  <SingleRow width="100%">
                    <Box mb={12}>
                      <FormInputLable requried>Warehouse</FormInputLable>
                      <StyledTextField
                        select
                        fullWidth
                        {...getFieldProps("warehouse")}
                        placeholder="Select a warehouse"
                        error={Boolean(touched.warehouse && errors.warehouse)}
                        helperText={touched.warehouse && errors.warehouse}
                        onChange={formik.handleChange("warehouse")}
                        defaultValue={""}
                        SelectProps={{
                          multiple: false,
                        }}
                      >
                        {warehouses?.map((item: any) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.title}
                            </MenuItem>
                          );
                        })}
                      </StyledTextField>
                    </Box>
                  </SingleRow>

                  <Row width="100%">
                    <Box
                      mb={12}
                      mr={{
                        md: 20,
                        sm: 0,
                      }}
                    >
                      <FormInputLable requried>Product</FormInputLable>
                      <StyledTextField
                        select
                        fullWidth
                        {...getFieldProps("product")}
                        placeholder="Select a product"
                        error={Boolean(touched.product && errors.product)}
                        helperText={touched.product && errors.product}
                        onChange={formik.handleChange("product")}
                        defaultValue={""}
                        SelectProps={{
                          multiple: false,
                        }}
                      >
                        {products?.map((item: any) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.title}
                            </MenuItem>
                          );
                        })}
                      </StyledTextField>
                    </Box>
                    <Box
                      mb={12}
                      // sx={placements?.length === 0 ? { opacity: 0.5 } : {}}
                    >
                      <FormInputLable noPrefix>Placement</FormInputLable>
                      <StyledTextField
                        select
                        // disabled={placements?.length === 0}
                        fullWidth
                        {...getFieldProps("placement")}
                        placeholder="Select a placement"
                        error={Boolean(touched.placement && errors.placement)}
                        helperText={touched.placement && errors.placement}
                        onChange={formik.handleChange("placement")}
                        // defaultValue={"0"}
                        SelectProps={{
                          multiple: false,
                        }}
                      >
                        {placements?.map((item: any) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.title}
                            </MenuItem>
                          );
                        })}
                      </StyledTextField>
                    </Box>
                  </Row>

                  <Row width="100%">
                    <Box
                      mb={{
                        md: 0,
                        sm: 12,
                      }}
                      mr={{
                        md: 20,
                        sm: 0,
                      }}
                    >
                      <FormInputLable noPrefix>Start Date</FormInputLable>
                      <StyledDatePicker
                        placeholder="Enter or select date"
                        {...getFieldProps("startDate")}
                        // fullWidth
                        value={formik.values.startDate}
                        error={Boolean(touched.startDate && errors.startDate)}
                        helperText={touched.startDate && errors.startDate}
                        onChange={(newValue: Date | null) =>
                          formik.setFieldValue("startDate", newValue)
                        }
                      />
                    </Box>
                    <Box>
                      <FormInputLable noPrefix>End Date</FormInputLable>
                      <StyledDatePicker
                        placeholder="Enter or select date"
                        {...getFieldProps("endDate")}
                        // fullWidth
                        value={formik.values.endDate}
                        error={Boolean(touched.endDate && errors.endDate)}
                        helperText={touched.endDate && errors.endDate}
                        onChange={(newValue: Date | null) =>
                          formik.setFieldValue("endDate", newValue)
                        }
                      />
                    </Box>
                  </Row>
                </FieldsContainer>
              </PaperContainer>
            </PaperBox>

            <Actions width="100%">
              <Box>
                <GrayButton
                  variant="outlined"
                  fullWidth
                  disabled={false}
                  onClick={onReset}
                >
                  Cancel
                </GrayButton>
              </Box>
              <Box marginLeft={12}>
                <StyledLoadingButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  loading={false}
                  type="submit"
                >
                  <Typography
                    fontSize={16}
                    fontWeight={500}
                    color={colors.gray[850]}
                  >
                    Next
                  </Typography>
                </StyledLoadingButton>
              </Box>
            </Actions>
          </Form>
        </FormContainer>
      </FormikProvider>
    </Container>
  );
};

const Container = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 40px;
  border-bottom: 1px solid ${colors.gray[100]};
`;

const FormContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > form {
    width: 100%;
  }
`;

const PaperContainer = styled("div")`
  width: 860px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

const FieldsContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SingleRow = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > div {
    width: calc(50% - 20px);
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    flex-wrap: wrap;
    & > div {
      width: 100%;
    }
  }
`;

const Row = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  & > div {
    width: 50%;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    flex-wrap: wrap;
    & > div {
      width: 100%;
    }
  }
`;

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  & > div {
    width: 152px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    & > div {
      width: 122px;
    }
  }
`;
