import colors from "src/theme/colors";

export const baseStyles = {
  position: "relative",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderWidth: 2,
  borderRadius: 5,
  borderColor: colors.gray[200],
  borderStyle: "dashed",
  backgroundColor: "transparent",
  color: colors.gray[700],
  outline: "none",
  transition: "border .24s ease-in-out",
} as const;

export const defaultTypeBaseStyle = {
  ...baseStyles,
  height: "100%",
  padding: "20px",
} as const;

export const inputTypeBaseStyle = {
  ...baseStyles,
  height: "56px",
  padding: "0 1px 0 14px",
} as const;

export const activeStyle = {
  borderColor: colors.gray[200],
} as const;

export const acceptStyle = {
  borderColor: colors.gray[200],
} as const;

export const rejectStyle = {
  borderColor: colors.error[200],
} as const;
