import { ArrowDropDown } from "@mui/icons-material";
import { Menu, MenuItem, Typography, useTheme, styled } from "@mui/material";
import { ReactComponent as ColorizedInStockSvg } from "../../../../../public/assets/icons/stocktakingReport/ColorizedInStock.svg";
import { ReactComponent as ColorizedCountedSvg } from "../../../../../public/assets/icons/stocktakingReport/ColorizedCounted.svg";
import { ReactComponent as ColorizedUnCountedSvg } from "../../../../../public/assets/icons/stocktakingReport/ColorizedUnCounted.svg";
import { ReactComponent as ColorizedNewItemsSvg } from "../../../../../public/assets/icons/stocktakingReport/ColorizedNewItems.svg";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "src/components/Button";
import { StyledTextField } from "src/components/Input";
import Loading from "src/components/Loading";
import colors from "src/theme/colors";

interface StocktakingStatusCountersPops {
  isLoading: boolean;
  counts: any;
  productsList: any;
  selectedFilter: string;
  setSelectedFilter: (val: any) => void;
}

export const StocktakingStatusCounters: FC<StocktakingStatusCountersPops> = (
  props
) => {
  const { isLoading, counts, productsList, selectedFilter, setSelectedFilter } =
    props;

  const statusIconRenderer = (status: string) => {
    switch (status) {
      case "stock":
        return <ColorizedInStockSvg />;
      case "counted":
        return <ColorizedCountedSvg />;
      case "uncounted":
        return <ColorizedUnCountedSvg />;
      case "new":
        return <ColorizedNewItemsSvg />;

      default:
        return <ColorizedInStockSvg />;
    }
  };
  console.log(isLoading, counts);
  return (
    <Container>
      <Titlebar>
        <Title>Status</Title>
        <Filters>
          <StyledTextField
            select
            fullWidth
            size="small"
            placeholder="Filter"
            value={selectedFilter}
            onChange={(e) => setSelectedFilter(e.target.value)}
            defaultValue={"0"}
            SelectProps={{
              multiple: false,
            }}
          >
            {productsList?.map((item: any) => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.title}
                </MenuItem>
              );
            })}
          </StyledTextField>
        </Filters>
      </Titlebar>
      <Content>
        {isLoading ? (
          <Center>
            <Loading />
          </Center>
        ) : !counts ? (
          <Center>Not Found</Center>
        ) : (
          <Counters>
            {Object.keys(counts)?.map((key, index) => {
              return (
                <StatusBox key={key} status={key}>
                  {statusIconRenderer(key)}
                  <Count>{counts[key] as string}</Count>
                  <SubCount>{key}</SubCount>
                </StatusBox>
              );
            })}
          </Counters>
        )}
      </Content>
    </Container>
  );
};

interface StyledProps {
  status: string;
}

const Container = styled("div")`
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  border-radius: 4px;
  background-color: ${colors.base.white};

  ${(props) => props.theme.breakpoints.down("xl")} {
    min-height: 350px;
  }
  ${(props) => props.theme.breakpoints.down("lg")} {
    min-height: 400px;
  }
`;

const Content = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 14px;
`;

const Counters = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
`;

const Titlebar = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled("h6")`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: ${colors.gray[850]};
`;

const Filters = styled("div")`
  width: 160px;
`;

const Center = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StatusBox = styled("div")<StyledProps>`
  width: calc((100% / 4) - 18px);
  height: 290px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 52px 24px;
  border-radius: 5px;
  ${({ status }) => {
    let bgColor: string;
    switch (status) {
      case "stock":
        bgColor = "#F1FBFF";
        break;
      case "counted":
        bgColor = colors.secondary[50];
        break;
      case "uncounted":
        bgColor = colors.error[50];
        break;
      case "new":
        bgColor = colors.primary[50];
        break;

      default:
        bgColor = "#F1FBFF";
        break;
    }
    return {
      backgroundColor: bgColor,
    };
  }}

  ${(props) => props.theme.breakpoints.down("xl")} {
    height: 240px;
    padding: 24px 24px;
  }
  ${(props) => props.theme.breakpoints.down("lg")} {
    height: 290px;
    padding: 52px 24px;
  }
  @media screen and (max-width: 750px) {
    width: calc((100% / 2) - 18px);
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

const Count = styled("span")`
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
  margin-top: 32px;
  margin-bottom: 18px;
  color: ${colors.gray[850]};
`;

const SubCount = styled("span")`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: ${colors.gray[850]};
`;
