import { FC } from "react";
import styled from "@emotion/styled";
import colors from "src/theme/colors";
import { UploadedFileType } from "../../UploadZone/UploadZone";

interface MultiFilesViewerProps {
  currentSlide: UploadedFileType | null;
  uploadedSlides: number;
  isPrevDisabled: boolean;
  isNextDisabled: boolean;
  onPrev: () => void;
  onNext: () => void;
}

export const MultiFilesViewer: FC<MultiFilesViewerProps> = (props) => {
  const {
    currentSlide,
    uploadedSlides,
    isPrevDisabled,
    isNextDisabled,
    onPrev,
    onNext,
  } = props;

  return (
    <>
      {currentSlide != null ? (
        <Container>
          <ArrowLeft
            isDisabled={isPrevDisabled}
            isHidden={currentSlide == null || uploadedSlides <= 1}
            onClick={!isPrevDisabled ? onPrev : undefined}
          >
            <img src={process.env.PUBLIC_URL + "/assets/icons/NavArrow.svg"} />
          </ArrowLeft>

          <Slides>
            {currentSlide.mime.startsWith("image/") ? (
              <ImageSlide src={currentSlide.url} />
            ) : (
              <NameSlide>{currentSlide.name}</NameSlide>
            )}
          </Slides>

          <ArrowRight
            isDisabled={isNextDisabled}
            isHidden={currentSlide == null || uploadedSlides <= 1}
            onClick={!isNextDisabled ? onNext : undefined}
          >
            <img src={process.env.PUBLIC_URL + "/assets/icons/NavArrow.svg"} />
          </ArrowRight>
        </Container>
      ) : null}
    </>
  );
};

interface StyledProps {
  isHidden?: boolean;
  isDisabled?: boolean;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

const Slides = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
`;

const ImageSlide = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: ${colors.base.white};
  user-select: none;
`;

const NameSlide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.base.white};
  color: ${colors.secondary[700]};
  font-weight: bold;
  user-select: none;
`;

const ArrowLeft = styled.div<StyledProps>`
  cursor: ${({ isDisabled }) => (isDisabled ? "no-drop" : "pointer")};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};
  width: 32px;
  height: 32px;
  border-radius: 5px;
  border: 1px solid ${colors.gray[300]};
  display: ${({ isHidden }) => (isHidden ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  user-select: none;
  margin-right: 10px;
`;

const ArrowRight = styled.div<StyledProps>`
  cursor: ${({ isDisabled }) => (isDisabled ? "no-drop" : "pointer")};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};
  width: 32px;
  height: 32px;
  border-radius: 5px;
  border: 1px solid ${colors.gray[300]};
  display: ${({ isHidden }) => (isHidden ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  user-select: none;
  margin-left: 10px;
  & > img {
    transform: rotate(180deg);
  }
`;
