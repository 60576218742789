import { axios } from "../../../config/Axios";
//permissions get
const getChat = async (data: any) => {
    const response = await axios.get(`/chat/${data.userId}?limit=100`)
    return response;
};
const postChat = async (data: any) => {
    const response = await axios.post(`/chat/${data.userId}`, data)
    return response;
};
const roleService = {
    getChat,
    postChat,
};
export default roleService;
