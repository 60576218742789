import type { FC } from "react";
import { RadialBarChart, RadialBar, PolarAngleAxis } from "recharts";
import colors from "src/theme/colors";

const circleSize = 320;

const style = {
  margin: "auto",
};

interface TotalOrderChart {
  total: number;
  value: number;
  data: { name: string; value: number }[];
}

export const TotalOrderChart: FC<TotalOrderChart> = (props) => {
  const { total, value, data } = props;

  return (
    <RadialBarChart
      width={circleSize}
      height={175}
      cx={circleSize / 2}
      cy={circleSize / 2}
      innerRadius={140}
      outerRadius={190}
      barSize={40}
      data={data}
      startAngle={180}
      endAngle={0}
      style={style}
    >
      <PolarAngleAxis
        type="number"
        domain={[0, total]}
        angleAxisId={0}
        tick={false}
      />
      <RadialBar
        background={{ fill: "#D4DCE5" }}
        cornerRadius={6}
        dataKey="value"
        fill={colors.secondary[500]}
      />
      <text
        x={circleSize / 2}
        y={125}
        textAnchor="middle"
        dominantBaseline="middle"
        fontWeight="500"
        fontSize="16px"
        fill={colors.midnight[600]}
      >
        Total Order
      </text>
      {total ? (
        <text
          x={circleSize / 2}
          y={155}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          <tspan
            textAnchor="middle"
            fontWeight="700"
            fontSize="28px"
            fill={colors.midnight[850]}
          >
            {total}
          </tspan>
          <tspan
            textAnchor="middle"
            fontWeight="700"
            fontSize="18px"
            dx="2px"
            fill={colors.midnight[850]}
          >
            MT
          </tspan>
        </text>
      ) : (
        <text
          x={circleSize / 2}
          y={155}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          0
        </text>
      )}
    </RadialBarChart>
  );
};
