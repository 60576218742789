import { axios } from "../../../config/Axios";

//SaleOrders get
const getSaleOrders = async (data: any) => {
  const response = await axios.get(
    `/sale-orders?page=${data.page}${
      data?.search ? `&title=${data?.search}` : ""
    }`
  );
  return response;
};
//SaleOrders EXPORT
const exportSaleOrders = async () => {
  const response = await axios({
    url: `/sale-orders?export_excel=true`,
    method: "get",
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${Date.now()}.xlsx`);
  document.body.appendChild(link);
  link.click();

  return response;
};
//SaleOrders get by id
const getSaleOrderId = async (data: any) => {
  const response = await axios.get(`/sale-orders/${data.id}`);
  return response;
};
//SaleOrders update
const updateSaleOrder = async (data: any) => {
  const response = await axios.patch(`/sale-orders/${data.id}`, data);
  return response;
};
//SaleOrders get by id by shipment
const getSaleOrderIdByShipment = async (data: any) => {
  const response = await axios.get(
    `/sale-orders/${data.id}${
      data.shipmentId ? `?shipment_id=${data.shipmentId}` : ""
    }`
  );
  return response;
};
//SaleOrders get by id by first shipment
const getSaleOrderIdByFirstShipment = async (data: any) => {
  const response = await axios.get(
    `/sale-orders/${data.id}${
      data.shipmentId ? `?shipment_id=${data.shipmentId}` : ""
    }`
  );
  return response;
};
//SaleOrders create
const addSaleOrder = async (data: any) => {
  const response = await axios.post("/sale-orders", data);
  return response;
};
//SaleOrders patch
const patchSaleOrder = async (data: any) => {
  const response = await axios.patch(`/sale-orders/${data.id}`, data);
  return response;
};
//SaleOrders delete
const deleteSaleOrder = async (id: number) => {
  const response = await axios.delete(`/sale-orders/${id}`);
  return response;
};
//SaleOrders documents GET
const getSaleOrderDocuments = async (id: number) => {
  const response = await axios.get(`/sale-orders/${id}/documents`);
  return response;
};
//SaleOrders documents GET by tag
const getSaleOrderDocumentsByTag = async (id: number) => {
  const response = await axios.get(`/sale-orders/${id}/documents-by-tag`);
  return response;
};
//SaleOrders carrier documents GET
const getSaleOrderCarrierDocuments = async (id: number) => {
  const response = await axios.get(`/sale-orders/carrier/${id}/documents`);
  return response;
};
//SaleOrders documents POST
const addSaleOrderDocuments = async (data: any) => {
  const response = await axios.post(
    `/sale-orders/${data?.id}/documents`,
    data?.data
  );
  return response;
};
//SaleOrders carrier documents POST
const addSaleOrderCarrierDocuments = async (data: any) => {
  const response = await axios.post(
    `/sale-orders/carrier/${data?.id}/documents`,
    data?.data
  );
  return response;
};
//SaleOrders documents DELETE
const deleteSaleOrderDocuments = async (data: any) => {
  const response = await axios.delete(
    `/sale-orders/${data?.id}/documents/${data?.documentID}`
  );
  return response;
};
//SaleOrders documents downloadble
const changeSaleOrderDocumentDownloadble = async (data: any) => {
  const response = await axios.patch(
    `/sale-orders/${data?.id}/documents/${data?.document}`,
    { downloadable: data?.downloadable }
  );
  return response;
};
//SaleOrders carrier documents DELETE
const deleteSaleOrderCarrierDocuments = async (data: any) => {
  const response = await axios.delete(
    `/sale-orders/carrier/${data?.id}/documents/${data?.documentID}`
  );
  return response;
};
//SaleOrders complete
const setSaleOrderAsCompleted = async (data: any) => {
  const response = await axios.post(`/sale-orders/${data?.id}/complete`);
  return response;
};

//SaleOrders Shipments
const getSaleOrderShipments = async (data: any) => {
  const response = await axios.get(`/sale-orders/${data?.id}/shipments`);
  return response;
};
//SaleOrders Details by shipment
const getSaleOrderDetailsByShipment = async (data: any) => {
  const response = await axios.get(
    `/sale-orders/${data?.id}/${data.shipmentId}`
  );
  return response;
};
//SaleOrders shipment carriers
const getSaleOrderShipmentCarriers = async (data: any) => {
  const response = await axios.get(
    `/sale-orders/${data?.saleOrderId}/${data.shipmentId}/carriers?${
      data.filter ? `status=${data.filter}` : ""
    }`
  );
  return response;
};
//SaleOrders shipment carrier - GET
const getSaleOrderShipmentCarrierId = async (data: any) => {
  const response = await axios.get(`/sale-orders/carrier/${data?.id}`);
  return response;
};
//SaleOrders shipment carriers - Add
const addSaleOrderShipmentCarrier = async (data: any) => {
  const response = await axios.post(
    `/sale-orders/${data?.saleOrderId}/${data.shipmentId}/carriers`,
    {
      truck_reg_number: data.truckRegNumber,
    }
  );
  return response;
};
//SaleOrders shipment carriers - DELETE
const deleteSaleOrderShipmentCarrier = async (data: any) => {
  const response = await axios.delete(
    `/sale-orders/${data?.saleOrderId}/${data.shipmentId}/carriers/${data.carrierId}`
  );
  return response;
};

//SaleOrders shipment carrier products - GET
const getSaleOrderShipmentCarrierProducts = async (data: any) => {
  const response = await axios.get(
    `/sale-orders/carrier/${data?.carrierId}/products`
  );
  return response;
};
//SaleOrders shipment carrier products - ADD
const addSaleOrderShipmentCarrierProduct = async (data: any) => {
  const response = await axios.post(
    `/sale-orders/carrier/${data?.carrierId}/products`,
    { barcode: data?.barcode }
  );
  return response;
};
//SaleOrders shipment carrier products - DELETE
const deleteSaleOrderShipmentCarrierProduct = async (data: any) => {
  const response = await axios.delete(
    `/sale-orders/carrier/${data?.carrierId}/products/${data?.productId}`
  );
  return response;
};

//SaleOrders report containers - GET
const getSaleOrderContainers = async (data: any) => {
  const response = await axios.get(
    `/sale-orders/${data?.saleOrderId}/report/containers`
  );
  return response;
};
//SaleOrders report containers - EDIT
const editSaleOrderContainerNumber = async (data: any) => {
  const response = await axios.patch(
    `/sale-orders/${data?.saleOrderId}/${data.shipmentId}/carriers/${data.carrierId}`,
    { title: data.title }
  );
  return response;
};
// EXPORT searched Products
const exportSaleOrderContainers = async (data: any) => {
  const response = await axios({
    url: `/sale-orders/${data?.saleOrderId}/report/containers`,
    method: "get",
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${Date.now()}.xlsx`);
  document.body.appendChild(link);
  link.click();

  return response;
};
//SaleOrders carrier products - Unpload
const receiveCarrierProduct = async (data: any) => {
  const response = await axios.post(
    `/sale-orders/carrier/${data?.carrierId}/unload`,
    { barcode: data.barcode ?? "" }
  );
  return response;
};
//SaleOrders carrier add seal number - POST
const addCarrierSealNumber = async (data: any) => {
  const response = await axios.post(
    `/sale-orders/${data?.saleOrderId}/${data?.shipmentId}/carrier/${data?.carrierId}/seal-number`,
    { seal_number: data.seal_number ?? "" }
  );
  return response;
};
//SaleOrders carrier edit seal number - PATCH
const editCarrierSealNumber = async (data: any) => {
  const response = await axios.patch(
    `/sale-orders/${data?.saleOrderId}/${data?.shipmentId}/carrier/${data?.carrierId}/seal-number`,
    { seal_number: data.seal_number ?? "" }
  );
  return response;
};

const SaleOrderservices = {
  getSaleOrders,
  exportSaleOrders,
  getSaleOrderId,
  updateSaleOrder,
  getSaleOrderIdByShipment,
  getSaleOrderIdByFirstShipment,
  addSaleOrder,
  patchSaleOrder,
  deleteSaleOrder,
  getSaleOrderDocuments,
  getSaleOrderDocumentsByTag,
  addSaleOrderDocuments,
  deleteSaleOrderDocuments,
  changeSaleOrderDocumentDownloadble,
  setSaleOrderAsCompleted,
  getSaleOrderShipments,
  getSaleOrderDetailsByShipment,
  getSaleOrderShipmentCarriers,
  getSaleOrderShipmentCarrierId,
  addSaleOrderShipmentCarrier,
  deleteSaleOrderShipmentCarrier,
  getSaleOrderCarrierDocuments,
  addSaleOrderCarrierDocuments,
  deleteSaleOrderCarrierDocuments,
  getSaleOrderShipmentCarrierProducts,
  addSaleOrderShipmentCarrierProduct,
  deleteSaleOrderShipmentCarrierProduct,
  getSaleOrderContainers,
  editSaleOrderContainerNumber,
  exportSaleOrderContainers,
  receiveCarrierProduct,
  addCarrierSealNumber,
  editCarrierSealNumber,
};
export default SaleOrderservices;
