import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import StyledTable from "src/components/Table";
import { GridColDef } from "@mui/x-data-grid";
import { AppDispatch, RootState } from "src/store";
import {
  IconButton,
  Box,
  Typography,
  Button,
  InputAdornment,
} from "@mui/material";
import { Add, Eye } from "iconsax-react";
import colors from "src/theme/colors";
import { styled } from "@mui/material/styles";
import { StyledButton } from "src/components/Button";
import ConfirmModal from "src/components/Modal";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";
import { GrayButton } from "src/components/GrayButton";
import { StyledTextField } from "src/components/Input";
import { Search } from "@mui/icons-material";
import {
  deleteCustomer,
  exportCustomers,
  getCustomers,
  reset as resetCustomers,
} from "../redux/CustomersSlice";

const CustomersList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [searchValue, setSearchValue] = useState("");
  const [Data, setData] = useState([] as any);
  const [MetaData, setMetaData] = useState([] as any);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const {
    customers,
    customersMeta,
    isError,
    isExportError,
    isDeleteError,
    isSuccess,
    isExportSuccess,
    isDeleteSuccess,
    message,
    exportMessage,
    deleteMessage,
    isLoading,
    isDeleteLoading,
    isExportLoading,
  } = useSelector((state: RootState) => state.customers);

  useEffect(() => {
    if (isExportError) {
      enqueueSnackbar(exportMessage, { variant: "error" });
    }
  }, [isExportError, isExportSuccess, exportMessage, dispatch]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      if (message) {
        enqueueSnackbar(message, { variant: "success" });
      } else {
        setData(customers);
        setMetaData(customersMeta);
      }
    }
    return () => {
      dispatch(resetCustomers());
    };
  }, [isError, isSuccess, message, page]);

  useEffect(() => {
    if (isDeleteError) {
      enqueueSnackbar(deleteMessage, { variant: "error" });
    }
    if (isDeleteSuccess) {
      enqueueSnackbar(deleteMessage, { variant: "success" });
      dispatch(
        getCustomers({
          page: Data.length <= 1 ? (page > 1 ? page - 1 : 1) : page,
        })
      ).then(() => setIsDeleteModalOpen(false));

      if (Data.length <= 1) {
        setPage(page > 1 ? page - 1 : 1);
      }
    }
    return () => {
      dispatch(resetCustomers());
    };
  }, [isDeleteError, isDeleteSuccess, deleteMessage]);

  useEffect(() => {
    dispatch(
      getCustomers({
        page: page,
        search: searchValue,
      })
    );
  }, [page, searchValue]);

  const onDelete = (selected: any) => {
    dispatch(deleteCustomer(selected));
  };

  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: "Customer Code",
      flex: 1,
      minWidth: 240,
    },
    {
      field: "full_name",
      headerName: "Full Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "mobile_number",
      headerName: "Mobile Number",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "company_name",
      headerName: "Company Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "email",
      headerName: "Email Address",
      flex: 1,
      minWidth: 220,
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.2,
      minWidth: 140,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          flexDirection="row"
        >
          <IconButton
            onClick={() => {
              navigate("/customers/customers-information", {
                state: { id: params?.id },
              });
            }}
          >
            <Eye color={colors.gray[600]} size="24" />
          </IconButton>
          <IconButton
            onClick={() => {
              navigate("/customers/customers-edit", {
                state: {
                  data: params.row,
                },
              });
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Edit.svg"}
              alt="edit"
            />
          </IconButton>
          {/* <IconButton>
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/Remove.svg"}
              alt="delete"
              onClick={() => {
                setSelectedId(Number(params?.id));
                setIsDeleteModalOpen(true);
              }}
            />
          </IconButton> */}
        </Box>
      ),
    },
  ];

  return (
    <PageTemplate
      crumbs={[
        {
          label: "customers list",
        },
      ]}
    >
      <StyledHeader>
        <SearchBox>
          <StyledTextField
            placeholder="Search"
            fullWidth
            height="48px"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </SearchBox>
        <ButtonsBox>
          <ActionsBox>
            <ActionBtn>
              <GrayButton
                variant="contained"
                fullWidth
                loading={isExportLoading}
                endIcon={
                  <img
                    src={process.env.PUBLIC_URL + "/assets/icons/Export.svg"}
                    alt="export"
                  />
                }
                onClick={() => {
                  dispatch(exportCustomers({})).then(() => resetCustomers());
                }}
              >
                Export
              </GrayButton>
            </ActionBtn>
          </ActionsBox>
          <StyledButton
            variant="contained"
            endIcon={<Add size="24" color={colors.gray[850]} />}
            onClick={() => navigate("/customers/customers-new")}
          >
            New Customer
          </StyledButton>
        </ButtonsBox>
      </StyledHeader>

      <ListBoundary
        isEmpty={Data.length === 0 || !Data}
        isDataLoading={isLoading}
        fallbackImageURL="/assets/images/emptyStates/Customers-List EmptyState.svg"
        description="Add Your First Customer!"
        buttonLabel="New Customer"
        onClick={() => navigate("/customers/customers-new")}
      >
        <StyledTable
          disableColumnMenu
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableVirtualization
          disableSelectionOnClick
          loading={isLoading}
          experimentalFeatures={{ newEditingApi: true }}
          rows={Data}
          columns={columns}
          onPageChange={(e) => setPage(e + 1)}
          page={page - 1}
          paginationMode="server"
          rowCount={MetaData?.pagination?.total_items}
        />
      </ListBoundary>

      <ConfirmModal
        title={"Remove Customer"}
        iconName={"ModalRemove"}
        open={isDeleteModalOpen}
        isLoading={isLoading || isDeleteLoading}
        onDismiss={() => setIsDeleteModalOpen(false)}
        onConfirm={() => onDelete(selectedId)}
        confrimText={"Remove"}
        cancelText={"Cancel"}
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to remove this customer?
        </Typography>
      </ConfirmModal>
    </PageTemplate>
  );
};

interface StyledProps {
  isRejected?: boolean;
}

const StyledHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  ${(props) => props.theme.breakpoints.down("xl")} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SearchBox = styled(Box)`
  width: 372px;
  ${(props) => props.theme.breakpoints.down("xl")} {
    margin-bottom: 24px;
    width: 315px;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    margin-bottom: 18px;
  }
`;

const ButtonsBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("xl")} {
    width: 100%;
    justify-content: space-between;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

const ActionsBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 24px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

const ActionBtn = styled(Box)`
  width: 145px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

export default CustomersList;
