import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { FC, useState } from "react";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { StyledDatePicker } from "./DatePicker/DatePicker";
import { FileUploader, FileUploaderType } from "./FileUploader/FileUploader";
import { UploadedFileType } from "./FileUploader/UploadZone/UploadZone";

export const ComponentsPage: FC = () => {
  const [singleFile, setSingleFile] = useState<UploadedFileType[]>([]);
  const [multipleFiles, setMultipleFiles] = useState<UploadedFileType[]>([]);
  const [dateValue, setDateValue] = useState<Date | null>(null);
  const [date2Value, setDate2Value] = useState<Date | null>(null);

  return (
    <PageTemplate>
      <Box sx={{ p: 12 }}>
        <ComponentContainer>
          <Typography mb={6}>Single file uploader</Typography>
          <Box sx={{ width: "224px", height: "200px" }}>
            <FileUploader
              type={FileUploaderType.Single}
              files={singleFile}
              setFiles={setSingleFile}
            />
          </Box>
        </ComponentContainer>
        <ComponentContainer>
          <Typography mb={6}>Multiple file uploader</Typography>
          <Box>
            <FileUploader
              type={FileUploaderType.Multiple}
              maxFiles={5}
              files={multipleFiles}
              setFiles={setMultipleFiles}
            />
          </Box>
        </ComponentContainer>
        <ComponentContainer>
          <Typography mb={6}>date picker with actions</Typography>
          <Box sx={{ width: "450px" }}>
            <StyledDatePicker
              label="with actions"
              placeholder="2022/12/12"
              showActions
              value={dateValue}
              onChange={(newValue: Date | null) => setDateValue(newValue)}
            />
          </Box>
        </ComponentContainer>
        <ComponentContainer>
          <Typography mb={6}>date picker without actions</Typography>
          <Box sx={{ width: "450px" }}>
            <StyledDatePicker
              label="without actions"
              placeholder="2022/12/12"
              value={date2Value}
              onChange={(newValue: Date | null) => setDate2Value(newValue)}
            />
          </Box>
        </ComponentContainer>
      </Box>
    </PageTemplate>
  );
};

const ComponentContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 72px;
`;
