import {
  Box,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { GridColDef } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "src/components/Button";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";
import StyledTable from "src/components/Table";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import {
  getStockTakingProducts,
  exportStockTakingProducts,
  reset,
} from "../redux/StockTakingSlice";
import { GrayButton } from "src/components/GrayButton";

const filters = [
  {
    name: "all",
    label: "All",
  },
  {
    name: "counted",
    label: "Counted",
  },
  {
    name: "unCounted",
    label: "UnCounted",
  },
  {
    name: "new",
    label: "New Products",
  },
];

interface Props {
  warehouseId: string;
  id: string;
}

const StocktakingProducts = (props: Props) => {
  const { warehouseId, id } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([] as any);
  const [meta, setMeta] = useState([] as any);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(filters[0].name);

  //redux -----------------------------------
  const {
    products,
    productsMeta,
    isProductsLoading,
    isProductsExportLoading,
    isProductsError,
    isProductsExportError,
    isProductsSuccess,
    productsMessage,
    productsExportMessage,
  } = useSelector((state: RootState) => state.stockTaking);

  useEffect(() => {
    if (isProductsError) {
      enqueueSnackbar(productsMessage, { variant: "error" });
    }
    if (isProductsSuccess) {
      setData(products);
      setMeta(productsMeta);
      setLoading(false);
    }
    return () => {
      // dispatch(reset());
      // setLoading(true);
    };
  }, [isProductsError, isProductsSuccess, productsMessage, dispatch]);

  useEffect(() => {
    if (isProductsExportError) {
      enqueueSnackbar(productsExportMessage, { variant: "error" });
    }
  }, [
    isProductsExportError,
    isProductsExportLoading,
    productsExportMessage,
    dispatch,
  ]);

  useEffect(() => {
    const getValues = {
      page: page,
      filter: filter !== "all" ? filter : null,
      warehouse: Number(warehouseId),
      stockTaking: Number(id),
    };
    dispatch(getStockTakingProducts(getValues));
  }, [page, filter]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  const columns: GridColDef[] = [
    {
      field: "product_name",
      headerName: "Product Name",
      flex: 1,
      minWidth: 140,
      filterable: false,
    },
    {
      field: "internal_barcode",
      headerName: "Internal Barcode",
      flex: 1,
      minWidth: 140,
      filterable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 140,
      filterable: false,
    },
    {
      field: "placement",
      headerName: "Placement",
      flex: 1,
      minWidth: 140,
      filterable: false,
    },
  ];

  return (
    <>
      <MainHeader pb={8}>
        <StyledHeader>
          <Typography color={colors.gray[850]} fontWeight="600" fontSize="24px">
            Products list
          </Typography>
          <FiltersHead
            pl={{
              sm: 0,
              md: 30,
            }}
          >
            {filters.map((item) => (
              <Filter
                key={item.name}
                isActive={filter === item.name}
                onClick={() => {
                  if (filter !== item.name) {
                    setFilter(item.name);
                    setPage(1);
                  }
                }}
              >
                {item.label}
              </Filter>
            ))}
          </FiltersHead>
        </StyledHeader>
        <ExportButton>
          {data && data.length > 0 ? (
            <GrayButton
              variant="contained"
              fullWidth
              loading={isProductsExportLoading}
              endIcon={
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/Export.svg"}
                  alt="export"
                />
              }
              onClick={() => {
                dispatch(
                  exportStockTakingProducts({
                    warehouse: Number(warehouseId),
                    stockTaking: Number(id),
                  })
                ).then(() => reset());
              }}
            >
              Export
            </GrayButton>
          ) : null}
        </ExportButton>
      </MainHeader>

      <ListBoundary
        isEmpty={products.length === 0 || !products}
        isDataLoading={isProductsLoading || loading}
        fallbackImageURL="/assets/images/emptyStates/Products-List EmptyState.svg"
        description="Nothing Found!..."
      >
        <StyledTable
          disableColumnMenu
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableVirtualization
          disableSelectionOnClick
          loading={isProductsLoading || loading}
          columns={columns}
          experimentalFeatures={{ newEditingApi: true }}
          rows={products}
          onPageChange={(e) => setPage(e + 1)}
          page={page - 1}
          paginationMode="server"
          getRowId={(products) => products.internal_barcode}
          rowCount={meta?.pagination?.total_items}
        />
      </ListBoundary>
    </>
  );
};

interface StyledProps {
  isActive?: boolean;
}

const MainHeader = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.theme.breakpoints.down("xl")} {
    flex-direction: column;
    align-items: normal;
  }
`;

const StyledHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.theme.breakpoints.down("xl")} {
    justify-content: flex-start;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    margin-bottom: 16px;
    flex-direction: column;
    align-items: flex-start;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FiltersHead = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  ${(props) => props.theme.breakpoints.down("md")} {
    margin-top: 16px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

const Filter = styled(Box)<StyledProps>(({ theme, isActive }) => ({
  color: isActive ? colors.secondary[800] : colors.gray[700],
  backgroundColor: isActive ? colors.gray[20] : "transparent",
  cursor: !isActive ? "pointer" : "default",
  fontWeight: isActive ? 500 : 400,
  padding: "8px 24px",
  whiteSpace: "nowrap",
  borderRadius: "4px",
  fontSize: "15px",
  lineHeight: "30px",
  // [theme.breakpoints.down("sm")]: {
  //   width: "100%",
  // },
}));

const ExportButton = styled(Box)`
  width: 184px;
  ${(props) => props.theme.breakpoints.down("xl")} {
    margin-top: 24px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

export default StocktakingProducts;
