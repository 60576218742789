import { Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import colors from 'src/theme/colors'

type Props = {
    children: ReactNode
    color?: string
}

const FormSubHeading = (props: Props) => {
    return (
        <Typography
            fontSize={16}
            fontWeight={500}
            color={props.color ? props.color : colors.gray[850]}
        >
            {props.children}
        </Typography>
    )
}

export default FormSubHeading