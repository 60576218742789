/* eslint-disable react/no-unescaped-entities */
import { FC, useEffect, useState } from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import colors from "src/theme/colors";
import { GrayButton } from "src/components/GrayButton";
import { useLocation, useNavigate } from "react-router-dom";
import FormHeading from "src/components/FormHeading";
import { StyledButton } from "src/components/Button";
import InfoItems from "src/components/InfoItems";
import {
  FileUploaderViewer,
  FileUploaderViewerSingleFileType,
  FileUploaderViewerType,
} from "src/components/FileUploader/FileUploaderViewer/FileUploaderViewer";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { AppDispatch, RootState } from "src/store";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduct, reset } from "../redux/ProductsSlice";
import ConfirmModal from "src/components/Modal";
import { useSnackbar } from "notistack";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { PaperBox } from "src/components/Paper/Paper";

interface LocationState {
  data: any;
}

const ProductsInfo: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const { enqueueSnackbar } = useSnackbar();
  const state = location.state as LocationState;
  const { data } = state;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { isDeleteLoading, isDeleteSuccess, isDeleteError, deleteMessage } =
    useSelector((state: RootState) => state.products);

  useEffect(() => {
    if (isDeleteError) {
      enqueueSnackbar(deleteMessage, { variant: "error" });
    }
    if (isDeleteSuccess) {
      enqueueSnackbar(deleteMessage, { variant: "success" });
      navigate("/products/products-list");
    }
    return () => {
      dispatch(reset());
    };
  }, [isDeleteError, isDeleteSuccess, deleteMessage]);

  const onDelete = (id: number[]) => {
    dispatch(deleteProduct(id)).then(() => {
      setIsDeleteModalOpen(false);
    });
  };

  return (
    <>
      <PageTemplate
        isTransparent
        crumbs={[
          {
            label: "products",
            href: "/products/products-list",
            depth: -1,
          },
          {
            label: "product information",
          },
        ]}
      >
        <Container>
          <PaperBox>
            <Box pb={12}>
              <FormHeading>Product Information</FormHeading>
            </Box>
            <Grid
              container
              rowSpacing={12}
              columnSpacing={{ xs: 0, md: 16 }}
              xs={12}
              md={10}
              mb={14}
            >
              <Grid item xs={12} md={6}>
                <Box width="100%">
                  <InfoItems
                    title="Product Title"
                    subTilte={data?.title ?? "Not Found!"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box width="100%">
                  <InfoItems
                    title="Material"
                    subTilte={data?.material?.name ?? "Not Found!"}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={12}
              columnSpacing={{ xs: 0, md: 16 }}
              md={10}
              xs={12}
            >
              {data?.countable || data?.is_package || data?.is_product ? (
                <Grid item xs={12} md={6}>
                  <ProductsChecks>
                    {data?.countable ? <Badge>Countable</Badge> : null}
                    {data?.is_package ? <Badge>Packageable</Badge> : null}
                    {data?.is_product ? (
                      <Badge>Creatable in the production process</Badge>
                    ) : null}
                  </ProductsChecks>
                </Grid>
              ) : null}
              <Grid item xs={12} md={6}>
                <FileViewerWrapper>
                  <FileUploaderViewer
                    type={FileUploaderViewerType.Single}
                    singleFileType={FileUploaderViewerSingleFileType.Image}
                    file={data?.image}
                  />
                </FileViewerWrapper>
              </Grid>
            </Grid>
          </PaperBox>

          <PaperBox>
            <VariantsTitleBar>Variants</VariantsTitleBar>
            <VariantsList
              container
              rowSpacing={12}
              columnSpacing={{ xs: 0, md: 16 }}
              md={10}
              xs={12}
            >
              {data?.variants && data?.variants.length > 0 ? (
                data?.variants.map((variant: any) => (
                  <Grid key={variant.id} item xs={12} md={6}>
                    <Box width="100%">
                      <InfoItems
                        title={variant.title}
                        subTilte={variant?.fixed_value}
                      />
                    </Box>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} md={6}>
                  <Empty>no variant added</Empty>
                </Grid>
              )}
            </VariantsList>
          </PaperBox>

          <Actions width="100%">
            {/* <Box>
              <StyledLoadingButton
                variant="outlined"
                color="error"
                fullWidth
                loading={isDeleteLoading}
                onClick={() => setIsDeleteModalOpen(true)}
              >
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color={colors.error[500]}
                >
                  Remove Product
                </Typography>
              </StyledLoadingButton>
            </Box> */}
            <Box marginLeft={12}>
              <StyledButton
                variant="contained"
                color="secondary"
                fullWidth
                disabled={isDeleteLoading}
                onClick={() =>
                  navigate("/products/product-edit", {
                    state: { data: data },
                  })
                }
              >
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color={colors.base.white}
                >
                  Edit Product
                </Typography>
              </StyledButton>
            </Box>
          </Actions>
        </Container>
      </PageTemplate>

      <ConfirmModal
        title={"Remove Product"}
        iconName={"ModalRemove"}
        open={isDeleteModalOpen}
        isLoading={isDeleteLoading}
        onDismiss={() => setIsDeleteModalOpen(false)}
        onConfirm={() => onDelete([Number(data?.id)])}
        confrimText="Remove"
        cancelText="Cancel"
      >
        <Typography fontSize={14} fontWeight={400} color={colors.gray[450]}>
          Are you sure you want to remove this Product?
        </Typography>
      </ConfirmModal>
    </>
  );
};

export default ProductsInfo;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

const ProductsChecks = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
`;

const VariantsTitleBar = styled("div")`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 22px;
  color: ${colors.gray[850]};
  font-size: 21px;
  font-weight: 500;
`;

const VariantsList = styled(Grid)`
  width: 100%;
`;

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  & > div {
    width: 184px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    & > div {
      width: 142px;
    }
  }
`;

const Badge = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 28px;
  border-radius: 30px;
  padding: 2px 12px;
  white-space: nowrap;
  background-color: ${colors.secondary[50]};
  color: ${colors.secondary[800]};
`;

const Empty = styled("span")`
  text-align: left;
  font-size: 14px;
  line-height: 28px;
  white-space: nowrap;
  color: ${colors.gray[750]};
`;

const FileViewerWrapper = styled(Box)`
  width: 170px;
  height: 170px;
`;
