import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import NotFound from "src/features/misc/routes/NotFound";
import MainLayout from "src/layout/Main";
import Loading from "src/components/Loading";
//routes
import { Dashboard } from "src/features/misc";
import { ComponentsPage } from "src/components";
import { RoleRoutes } from "src/features/role";
import { VendorRoutes } from "src/features/vendors";
import { EmployesRoutes } from "src/features/employes";
import { ItemsRoutes } from "src/features/items";
import { OrdersRoutes } from "src/features/orders";
import { SaleOrdersRoutes } from "src/features/saleOrders";
import { WarehouseRoutes } from "src/features/warehouses";
import { StocktakingsRoutes } from "src/features/stocktakings";
import { LocalInspectionRoutes } from "src/features/localInspection";
import { InternationalInspectionRoutes } from "src/features/internationalInspection";
import { ProductsRoutes } from "src/features/products/routes";
import { CustomersRoutes } from "src/features/customers";
import { LogsRoutes } from "src/features/logs";

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <MainLayout />
      <Outlet />
    </Suspense>
  );
};

export const protectedRoutes = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
      {
        path: "/components",
        element: <ComponentsPage />,
      },
      {
        path: "/roles-management/*",
        element: <RoleRoutes />,
      },
      {
        path: "/vendors/*",
        element: <VendorRoutes />,
      },
      {
        path: "/customers/*",
        element: <CustomersRoutes />,
      },
      {
        path: "/employees/*",
        element: <EmployesRoutes />,
      },
      {
        path: "/materials/*",
        element: <ItemsRoutes />,
      },
      {
        path: "/orders/*",
        element: <OrdersRoutes />,
      },
      {
        path: "/saleOrders/*",
        element: <SaleOrdersRoutes />,
      },
      {
        path: "/warehouses/*",
        element: <WarehouseRoutes />,
      },
      {
        path: "/stocktakings/*",
        element: <StocktakingsRoutes />,
      },
      {
        path: "/local-inspections/*",
        element: <LocalInspectionRoutes />,
      },
      {
        path: "/international-inspections/*",
        element: <InternationalInspectionRoutes />,
      },
      {
        path: "/products/*",
        element: <ProductsRoutes />,
      },
      {
        path: "/logs/*",
        element: <LogsRoutes />,
      },
    ],
  },
];
