import React from "react";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { CustomersNewForm } from "../components/CustomersNewForm";

const CustomersNew = () => {
  return (
    <PageTemplate
      isTransparent
      crumbs={[
        {
          label: "customers",
          href: "/customers/customers-list",
          depth: -1,
        },
        {
          label: "New Customer",
        },
      ]}
    >
      <CustomersNewForm />
    </PageTemplate>
  );
};

export default CustomersNew;
