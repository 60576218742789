import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import colors from "src/theme/colors";
import { IconButton, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import { GridColDef } from "@mui/x-data-grid";
import { getTrucks, reset } from "../../redux/TruckSlice";
import StyledTable from "src/components/Table";
import { useSnackbar } from "notistack";
import FilterHolder from "src/components/FilterHolder";
import { StyledButton } from "src/components/Button";
import { ListBoundary } from "src/components/ListBoundary/ListBoundary";

type Props = {
  iconName: string;
  title: string;
  open: boolean;
  cancelText: string;
  confrimText: string;
  onConfirm: (values: any) => void;
  onDismiss: (open: boolean) => void;
  filterValue: any;
  isLoading?: boolean;
  warehouseId: string;
};

export default function TruckSelectModal(props: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const {
    iconName,
    title,
    open,
    onConfirm,
    onDismiss,
    confrimText,
    cancelText,
    warehouseId,
    filterValue,
  } = props;

  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    setShow(open);
  }, [open]);

  const columns: GridColDef[] = [
    {
      field: "numberplate",
      headerName: "Truck Number",
      flex: 1,
      minWidth: 140,
      filterable: false,
    },
    {
      field: "date",
      headerName: "Received Date",
      flex: 1,
      minWidth: 140,
      filterable: false,
    },
    {
      field: "vendor",
      headerName: "Vendor",
      flex: 0.6,
      minWidth: 100,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.vendor?.code}
        </Box>
      ),
    },
    {
      field: "full_weight",
      headerName: "Full Weight(Kg)",
      flex: 1,
      minWidth: 140,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.full_weight ? params.row?.full_weight * 1000 : 0}
        </Box>
      ),
    },
    {
      field: "empty_weight",
      headerName: "Empty Weight(Kg)",
      flex: 1,
      minWidth: 140,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.empty_weight ? params.row?.empty_weight * 1000 : 0}
        </Box>
      ),
    },
    {
      field: "net_weight",
      headerName: "Net Weight(Kg)",
      flex: 1,
      minWidth: 140,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.net_weight ? params.row?.net_weight * 1000 : 0}
        </Box>
      ),
    },
    {
      field: "driver_license_number",
      headerName: "Driver License",
      flex: 1,
      minWidth: 140,
      filterable: false,
    },
    {
      field: "order",
      headerName: "Purchase Order",
      flex: 1,
      minWidth: 140,
      filterable: false,
      renderCell: (params) => (
        <Box padding={2} color={"#1E2329"}>
          {params.row?.order?.code}
        </Box>
      ),
    },
  ];

  const [data, setData] = React.useState([] as any);
  const [meta, setMeta] = React.useState([] as any);
  const [page, setPage] = React.useState(1);
  const [selectionModel, setSelectionModel] = React.useState([]);

  const { Trucks, TrucksMeta, isLoading, isError, isSuccess, message } =
    useSelector((state: RootState) => state.trucks);

  React.useEffect(() => {
    const infoValue = {
      warehouse_id: Number(warehouseId),
      page: page,
      order_id: filterValue?.order?.id,
      vendor_id: filterValue?.vendor?.id,
      fitler: "confirmed",
      from: filterValue?.from,
      to: filterValue?.to,
    };
    dispatch(getTrucks(infoValue));
  }, [page, filterValue]);

  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      setData(Trucks);
      setMeta(TrucksMeta);
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, isSuccess, message, dispatch]);

  return (
    <div>
      <Modal open={show} onClose={() => onDismiss(show)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: "80%",
            minHeight: "60%",
            bgcolor: colors.base.white,
            padding: "16px 24px 40px",
            border: `1px solid ${colors.gray[200]}`,
            borderRadius: "5px",
            overflow: "scroll",
            height: "100%",
            display: "block",
          }}
        >
          <StyledOuter>
            <IconButton onClick={() => onDismiss(show)}>
              <img
                src={process.env.PUBLIC_URL + `/assets/icons/close.svg`}
                alt={"close"}
              />
            </IconButton>
          </StyledOuter>
          <StyledInner>
            <img
              src={process.env.PUBLIC_URL + `/assets/icons/${iconName}.svg`}
              alt={iconName}
            />
            <Box pt={4} pb={5}>
              <Typography
                fontSize={18}
                fontWeight={700}
                color={colors.gray[850]}
              >
                {title}
              </Typography>
            </Box>
          </StyledInner>
          <Box
            display={"flex"}
            flexDirection="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <FilterHolder>
              Total Selected: {selectionModel.length} /{" "}
              {meta?.pagination?.total_items}
            </FilterHolder>
            <StyledOuter>
              <Typography
                fontSize={"14px"}
                fontWeight={700}
                color={colors.gray[750]}
              >
                Filters:
              </Typography>
              <FilterHolder iconName="FilterVendor">
                {filterValue.vendor?.code}
              </FilterHolder>
              <FilterHolder iconName="FilterOrder">
                {filterValue.order?.code}
              </FilterHolder>
            </StyledOuter>
          </Box>
          <Box pt={4}>
            <ListBoundary
              miniPage
              isEmpty={data.length === 0 || !data}
              isDataLoading={isLoading}
              fallbackImageURL="/assets/images/emptyStates/Trucks-List EmptyState.svg"
              description="NOTHING FOUND"
            >
              <StyledTable
                disableColumnMenu
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableVirtualization
                checkboxSelection
                keepNonExistentRowsSelected
                onSelectionModelChange={(newSelectionModel: any) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
                loading={data.length === 0 ? false : isLoading}
                getRowHeight={() => "auto"}
                columns={columns}
                experimentalFeatures={{ newEditingApi: true }}
                rows={data}
                onPageChange={(e) => setPage(e + 1)}
                page={page - 1}
                paginationMode="server"
                rowCount={meta?.pagination?.total_items}
              />
            </ListBoundary>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            gap={5}
            pt={15}
            justifyContent="flex-end"
          >
            <StyledButton
              color="inherit"
              variant="outlined"
              fullWidth
              sx={{ width: "150px" }}
              onClick={() => onDismiss(show)}
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.gray[800]}
              >
                {cancelText}
              </Typography>
            </StyledButton>
            <StyledButton
              color="primary"
              variant="contained"
              fullWidth
              sx={{ width: "150px" }}
              onClick={() => onConfirm(selectionModel)}
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={colors.gray[800]}

                //  onClick={() => alert("ss")}
              >
                Next
              </Typography>
            </StyledButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

const StyledInner = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledOuter = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;
