import { FC } from "react";
import styled from "@emotion/styled";
import colors from "src/theme/colors";
import { Button, Chip, CircularProgress, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { UploadedFileType } from "../UploadZone/UploadZone";
import { downloadURLFile } from "src/utils/downloadURLFile";
import { useSnackbar } from "notistack";

interface UploaderSlidesCarouselProps {
  currentSlide: UploadedFileType;
  currentSlideNumber: number;
  uploadedSlides: number;
  files?: UploadedFileType[] | [];
  max?: number;
  addLoading?: boolean;
  deleteLoading?: boolean;
  isPrevDisabled: boolean;
  isNextDisabled: boolean;
  onBrowse: () => void;
  onPrev: () => void;
  onNext: () => void;
  onDeleteSlide: () => void;
}

export const UploaderSlidesCarousel: FC<UploaderSlidesCarouselProps> = (
  props
) => {
  const {
    currentSlide,
    currentSlideNumber,
    files,
    uploadedSlides,
    max,
    addLoading,
    deleteLoading,
    isPrevDisabled,
    isNextDisabled,
    onBrowse,
    onPrev,
    onNext,
    onDeleteSlide,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      {currentSlide != null ? (
        <Container>
          <ArrowLeft
            isDisabled={isPrevDisabled}
            isHidden={currentSlide == null || uploadedSlides <= 1}
            onClick={!isPrevDisabled ? onPrev : undefined}
          >
            <img src={process.env.PUBLIC_URL + "/assets/icons/NavArrow.svg"} />
          </ArrowLeft>
          <ArrowRight
            isDisabled={isNextDisabled}
            isHidden={currentSlide == null || uploadedSlides <= 1}
            onClick={!isNextDisabled ? onNext : undefined}
          >
            <img src={process.env.PUBLIC_URL + "/assets/icons/NavArrow.svg"} />
          </ArrowRight>

          <Slides>
            {currentSlide.mime.startsWith("image/") ? (
              <ImageSlide src={currentSlide.url} />
            ) : (
              <NameSlide>{currentSlide.name}</NameSlide>
            )}
          </Slides>
        </Container>
      ) : null}

      <BottomBar>
        <ActionsBar>
          {files && files.length > 0 ? (
            <DownloadBtn
              onLoading={deleteLoading || addLoading}
              onClick={() =>
                !deleteLoading && !addLoading
                  ? downloadURLFile(
                      files && files[currentSlideNumber]
                        ? files[currentSlideNumber]?.url
                        : "",
                      files && files[currentSlideNumber]
                        ? files[currentSlideNumber]?.name
                        : "",
                      () =>
                        enqueueSnackbar("Failed to download File", {
                          variant: "error",
                        })
                    )
                  : undefined
              }
            >
              <IconButton sx={{ width: "38px" }}>
                <img
                  src={
                    process.env.PUBLIC_URL + `/assets/icons/DownloadBlue.svg`
                  }
                />
              </IconButton>
            </DownloadBtn>
          ) : null}

          {deleteLoading || addLoading ? (
            <CircularProgress color="secondary" size={21} />
          ) : (
            <IconButton
              color={uploadedSlides === 0 ? "default" : "error"}
              onClick={uploadedSlides === 0 ? undefined : onDeleteSlide}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </ActionsBar>

        <Chip
          label={
            currentSlide != null
              ? `${currentSlideNumber} - ${uploadedSlides}/${max}`
              : `0/${max}`
          }
          sx={{ color: colors.gray[600] }}
        />

        <Button
          variant="contained"
          color="info"
          disabled={uploadedSlides >= max! || addLoading}
          onClick={onBrowse}
        >
          Browse File
        </Button>
      </BottomBar>
    </>
  );
};

interface StyledProps {
  isHidden?: boolean;
  isDisabled?: boolean;
  onLoading?: boolean;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
`;

const Slides = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
`;

const ImageSlide = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: ${colors.base.white};
  user-select: none;
`;

const NameSlide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.base.white};
  color: ${colors.secondary[700]};
  font-weight: bold;
  user-select: none;
`;

const ArrowLeft = styled.div<StyledProps>`
  cursor: ${({ isDisabled }) => (isDisabled ? "no-drop" : "pointer")};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};
  position: absolute;
  top: 50%;
  left: -48px;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  border-radius: 5px;
  border: 1px solid ${colors.gray[300]};
  display: ${({ isHidden }) => (isHidden ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  user-select: none;
`;

const ArrowRight = styled.div<StyledProps>`
  cursor: ${({ isDisabled }) => (isDisabled ? "no-drop" : "pointer")};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};
  position: absolute;
  top: 50%;
  right: -48px;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  border-radius: 5px;
  border: 1px solid ${colors.gray[300]};
  display: ${({ isHidden }) => (isHidden ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  user-select: none;
  & > img {
    transform: rotate(180deg);
  }
`;

const BottomBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  position: absolute;
  bottom: -52px;
  left: 0;
`;

const ActionsBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
`;

const DownloadBtn = styled.div<StyledProps>`
  z-index: 4;
  ${({ onLoading }) => ({
    opacity: onLoading ? 0.5 : 1,
    cursor: onLoading ? "no-drop" : "pointer",
  })}
`;
