import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loading from "src/components/Loading";
import { getItems, reset } from "src/features/items/redux/ItemSlice";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { AppDispatch, RootState } from "src/store";
import WharehouseEditForm from "../components/WareHouseEditForm";
import {
  getWarehousesId,
  reset as resetWarehouseData,
} from "../redux/WarehouseSlice";

const WharahouseEdit = () => {
  const location = useLocation();
  const state = location.state as any;
  const [page, setPage] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const [Data, setData] = useState([] as any);
  const { items, isError, isSuccess, message, isLoading } = useSelector(
    (state: RootState) => state.items
  );
  const [warehouseData, setWarehouseData] = useState([] as any);
  const [warehouseDataLoading, setWarehouseDataLoading] = useState([] as any);

  const {
    singleWarehouse: singleWarehouseData,
    isSingleLoading: isWarehouseDataLoading,
    isError: isWarehouseDataError,
    isSuccess: isWarehouseDataSuccess,
    message: WarehouseDataMessage,
  } = useSelector((state: RootState) => state.warehouse);

  useEffect(() => {
    if (isWarehouseDataError) {
      enqueueSnackbar(WarehouseDataMessage, { variant: "error" });
    }
    if (isWarehouseDataSuccess) {
      setWarehouseData(singleWarehouseData);
      setWarehouseDataLoading(false);
    }
    return () => {
      dispatch(resetWarehouseData());
    };
  }, [
    isWarehouseDataError,
    isWarehouseDataLoading,
    isWarehouseDataSuccess,
    WarehouseDataMessage,
  ]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      setData(items);
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, isLoading, isSuccess, message]);

  useEffect(() => {
    dispatch(
      getWarehousesId({
        id: Number(state?.data?.id),
      })
    );
    dispatch(
      getItems({
        page: page,
        limit: 100,
      })
    );

    return () => {
      setWarehouseDataLoading(true);
    };
  }, []);

  return (
    <PageTemplate
      isTransparent
      crumbs={[
        {
          label: "warehouses",
          href: "/warehouses/warehouse-list",
          depth: -1,
        },
        {
          label: "edit warehouse",
        },
      ]}
    >
      {isLoading || warehouseDataLoading ? (
        <Loading />
      ) : (
        <WharehouseEditForm items={items} data={warehouseData} />
      )}
    </PageTemplate>
  );
};

export default WharahouseEdit;
