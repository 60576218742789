import { Box, Modal, styled, Typography } from "@mui/material";
import { FC } from "react";
import { GrayButton } from "src/components/GrayButton";
import { StyledLoadingButton } from "src/components/LoadingButton";
import colors from "src/theme/colors";

export enum OrderNewActionsModalType {
  Submit,
  Cancel,
}

interface OrderNewActionsModalProps {
  type: OrderNewActionsModalType;
  orderId: string;
  materialName: string;
  orderQuantity: string;
  warehouseName: string;
  open: boolean;
  loading?: boolean;
  onCacel: () => void;
  onHandle: () => void;
  onDismiss: (open: boolean) => void;
}

export const OrderNewActionsModal: FC<OrderNewActionsModalProps> = (props) => {
  const {
    type,
    orderId,
    materialName,
    orderQuantity,
    warehouseName,
    open,
    loading,
    onCacel,
    onHandle,
    onDismiss,
  } = props;

  return (
    <Modal
      open={open}
      onClose={() => onDismiss(open)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Wrapper>
        <img
          src={
            process.env.PUBLIC_URL +
            `/assets/icons/${
              type === OrderNewActionsModalType.Cancel ? "Stop" : "Send"
            }.svg`
          }
        />
        <Typography
          fontSize={18}
          fontWeight="700"
          color={colors.gray[850]}
          align="center"
          mt={4}
          mb={8}
        >
          {type === OrderNewActionsModalType.Submit
            ? "Apply Purchase Order"
            : "Stop Purchase Order"}
        </Typography>
        <Typography
          fontSize={14}
          fontWeight="400"
          color={colors.gray[850]}
          align="center"
          lineHeight="30px"
        >
          {type === OrderNewActionsModalType.Cancel ? (
            "Are you sure you want to stop purchase order operation?  if you agree your data will be lost too."
          ) : (
            <>
              Are you sure of applying the purchase order {orderId}{" "}
              <Bold>{materialName}</Bold> <Bold>{orderQuantity} MT</Bold> for{" "}
              {warehouseName} Warehouse?
            </>
          )}
        </Typography>

        <Actions>
          <CancelButtonBox>
            <GrayButton
              variant="outlined"
              size="large"
              fullWidth
              disabled={loading}
              onClick={() => onCacel()}
            >
              Cancel
            </GrayButton>
          </CancelButtonBox>
          <SubmitButtonBox>
            <StyledLoadingButton
              variant="contained"
              fullWidth
              loading={loading}
              color={
                type === OrderNewActionsModalType.Cancel ? "error" : "primary"
              }
              onClick={() => onHandle()}
            >
              {type === OrderNewActionsModalType.Cancel
                ? "Stop Operation"
                : "Apply Order"}
            </StyledLoadingButton>
          </SubmitButtonBox>
        </Actions>

        <CloseIcon onClick={() => onCacel()}>
          <img src={process.env.PUBLIC_URL + `/assets/icons/${"close"}.svg`} />
        </CloseIcon>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  minWidth: 300,
  height: "fit-content",
  backgroundColor: colors.base.white,
  border: `1px solid ${colors.gray[200]}`,
  borderRadius: "5px",
  padding: "42px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
});

const Actions = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  ${(props) => props.theme.breakpoints.down("lg")} {
    flex-direction: column;
  }
`;

const Bold = styled("b")`
  font-size: 15px;
  font-weight: 700;
`;

const CancelButtonBox = styled(Box)`
  width: 184px;
  margin-right: 24px;
  ${(props) => props.theme.breakpoints.down("lg")} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
  }
`;

const SubmitButtonBox = styled(Box)`
  width: 184px;
  ${(props) => props.theme.breakpoints.down("lg")} {
    width: 100%;
  }
`;

const CloseIcon = styled(Box)`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 4;
  cursor: pointer;
`;
