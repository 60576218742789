
import { Typography ,Box} from '@mui/material'
import { display, styled } from '@mui/system'
import React from 'react'
import colors from 'src/theme/colors'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
    title: string;
    number:string;
    color:string
}
const StyledContainer = styled("div")`
    background: #FCFCFC;
    border-radius: 12px;
    padding:13px;
    display: flex;
    flex-direction: row;
    align-items: center;
`

const InfoCard = (props: Props) => {
    const data = {
        labels:[],
    
        datasets: [
          {
            label: '# of Votes',
            data: [20,80],
            backgroundColor: [
              
              '#F2F6FC',
              `${props?.color}`,
            ],
           
            borderWidth: 0,
          },
        ],
    };
    const options = {
        responsive: true,
        maintainAspectRatio: true,
    };
  return (
    <StyledContainer>
        <Box sx={{maxWidth:80,maxHeight:80}}>        
          <Doughnut data={data} options={options} />
        </Box>
        <Box pl={6}>
            <Typography
                fontSize={14}
                fontWeight={500}
                color={colors.gray[600]}
            >
                {props.title}
            </Typography>
            <Typography
                fontSize={24}
                fontWeight={700}
                color={colors.gray[900]}
            >
                {props.number}
            </Typography>
        </Box>
    </StyledContainer>
  )
}

export default InfoCard