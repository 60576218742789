import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import MainLayout from "src/layout/Main";
import VendorEdit from "../pages/VendorEdit";
import VendorInfo from "../pages/VendorInfo";
import VendorNew from "../pages/VendorNew";
import VendorTable from "../pages/VendorTable";

export const VendorRoutes = () => {
  const { t } = useTranslation();
  return (
    <>
      <Routes>
        <Route path="/vendors-list" element={<VendorTable />} />
        <Route path="/vendor-new" element={<VendorNew />} />
        <Route path="/vendor-edit" element={<VendorEdit />} />
        <Route path="/vendor-information" element={<VendorInfo />} />
      </Routes>
    </>
  );
};
