import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import itemServices from "./ItemServices";
import { getData } from "./types";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: true,
  isDeleteLoading: false,
  message: "",
  units: [],
  unitsMeta: [],
};

export const getUnits = createAsyncThunk(
  "units",
  async (data: getData, thunkAPI) => {
    try {
      return await itemServices.getUnits(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const unitSlice = createSlice({
  name: "units",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnits.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnits.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.units = action.payload.data.results;
        state.unitsMeta = action.payload.data.meta;
      })
      .addCase(getUnits.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});
export const { reset } = unitSlice.actions;
export default unitSlice.reducer;
