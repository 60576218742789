import { FC, useState, useEffect } from "react";
import { Box, styled, useMediaQuery } from "@mui/material";
import { ShippingDetailsStepper } from "./Stepper/ShippingDetailsStepper";
import { SaleShippingOriginWarehouseInfoPage } from "./originWarehouse/SaleShippingOriginWarehouseInfoPage";
import { AppDispatch, RootState } from "src/store";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  getSaleOrdersId,
  getSaleOrderShipments,
  reset as resetSaleOrders,
  getSaleOrderIdByShipment,
  getSaleOrderIdByFirstShipment,
} from "../../redux/SaleOrdersSlice";
import Loading from "src/components/Loading";
import { SaleShippingPortOperationInfoPage } from "./portOperation/SaleShippingPortOperationInfoPage";

interface SaleOrderShippingDetailProps {
  data: any;
}

export const SaleOrderShippingDetail: FC<SaleOrderShippingDetailProps> = (
  props
) => {
  const { data } = props;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const isBelowSmSize = useMediaQuery("(max-width:600px)");

  const [shipmentDetailsData, setShipmentDetailsData] = useState<any>(null);
  const [firstShipmentDetailsData, setFirstShipmentDetailsData] =
    useState<any>(null);
  const [steps, setSteps] = useState<any>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({
    0: false,
    1: false,
    2: false,
  });
  const [isGetShippingsLoading, setIsGetShippingsLoading] = useState(true);
  const [isSingleLoading, setIsSingleLoading] = useState(true);
  const [isFirstSingleLoading, setIsFirstSingleLoading] = useState(true);

  const {
    shipments,
    singleByShipmentSaleOrder,
    singleByFirstShipmentSaleOrder,
    isGetShipmentsError,
    isSingleByShipmentError,
    isSingleByFirstShipmentError,
    // isGetShipmentsLoading,
    isSingleByShipmentLoading,
    isSingleByFirstShipmentLoading,
    isGetShipmentsSuccess,
    isSingleByShipmentSuccess,
    isSingleByFirstShipmentSuccess,
    getShipmentsMessage,
    singleByShipmentMessage,
    singleByFirstShipmentMessage,
  } = useSelector((state: RootState) => state.saleOrders);

  useEffect(() => {
    if (isGetShipmentsError) {
      enqueueSnackbar(getShipmentsMessage, { variant: "error" });
    }
    if (isGetShipmentsSuccess) {
      const stepsData = shipments.map((shipment: any, index: number) => ({
        id: shipment.id,
        index: index,
        title:
          shipment.origin_warehouse.type === "normal"
            ? "Origin Warehouse"
            : "Port Operation",
        type: shipment.origin_warehouse.type,
      }));
      setSteps(stepsData);
      setIsGetShippingsLoading(false);
    }

    return () => {
      dispatch(resetSaleOrders());
    };
  }, [isGetShipmentsError, isGetShipmentsSuccess, getShipmentsMessage]);

  useEffect(() => {
    if (isSingleByShipmentError) {
      enqueueSnackbar(singleByShipmentMessage, { variant: "error" });
    }
    if (isSingleByShipmentSuccess) {
      setIsSingleLoading(false);
      setShipmentDetailsData(singleByShipmentSaleOrder);
    }

    return () => {
      dispatch(resetSaleOrders());
    };
  }, [
    isSingleByShipmentError,
    isSingleByShipmentSuccess,
    singleByShipmentMessage,
  ]);

  useEffect(() => {
    if (isSingleByFirstShipmentError) {
      enqueueSnackbar(singleByFirstShipmentMessage, { variant: "error" });
    }
    if (isSingleByFirstShipmentSuccess) {
      setIsFirstSingleLoading(false);
      setFirstShipmentDetailsData(singleByFirstShipmentSaleOrder);
    }

    return () => {
      dispatch(resetSaleOrders());
    };
  }, [
    isSingleByFirstShipmentError,
    isSingleByFirstShipmentSuccess,
    singleByFirstShipmentMessage,
  ]);

  useEffect(() => {
    if (data) {
      dispatch(
        getSaleOrderShipments({
          id: Number(data.id),
        })
      );
    }
  }, [data]);

  useEffect(() => {
    if (data.id && steps?.length > 0) {
      dispatch(
        getSaleOrderIdByShipment({
          id: Number(data.id),
          shipmentId: Number(steps[activeStep]?.id),
        })
      );
    }
  }, [data, steps, activeStep]);

  useEffect(() => {
    if (data.id && steps?.length > 0) {
      dispatch(
        getSaleOrderIdByFirstShipment({
          id: Number(data.id),
          shipmentId: Number(steps[0]?.id),
        })
      );
    }
  }, [data, steps, activeStep]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    // const newActiveStep =
    // isLastStep() && !allStepsCompleted()
    // ? // It's the last step, but not all steps have been completed,
    // find the first step that has been completed
    // steps.findIndex((step, i) => !(i in completed))
    // : activeStep + 1;
    setActiveStep(activeStep + 1);
  };

  const handleStep = (step: number) => () => {
    setIsSingleLoading(true);
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const shippingStepRenderer = () => {
    if (data && steps?.length > 0) {
      if (!isSingleLoading && !isFirstSingleLoading) {
        switch (shipmentDetailsData?.details?.ui_type) {
          case "normal":
            return (
              <SaleShippingOriginWarehouseInfoPage
                details={shipmentDetailsData?.details}
                product={shipmentDetailsData?.product}
                saleOrderId={data?.id}
                warehouseId={shipmentDetailsData?.origin_warehouse?.id}
                saleOrderName={data?.name}
                shipmentId={steps[activeStep]?.id}
              />
            );
          case "port":
            return (
              <SaleShippingPortOperationInfoPage
                details={firstShipmentDetailsData?.details}
                product={shipmentDetailsData?.product}
                saleOrderId={data?.id}
                warehouseId={shipmentDetailsData?.origin_warehouse?.id}
                saleOrderName={data?.name}
                shipmentId={steps[activeStep]?.id}
                originShipmentId={steps[0]?.id}
              />
            );

          default:
            return (
              <SaleShippingOriginWarehouseInfoPage
                details={shipmentDetailsData?.details}
                product={shipmentDetailsData?.product}
                saleOrderId={data?.id}
                warehouseId={shipmentDetailsData?.origin_warehouse?.id}
                saleOrderName={data?.name}
                shipmentId={steps[activeStep]?.id}
              />
            );
        }
      } else {
        return <Loading />;
      }
    } else {
      return <>Shipment Steps Data Not Found</>;
    }
  };

  if (isGetShippingsLoading) {
    return <Loading />;
  } else {
    return (
      <Container>
        <ShippingDetailsStepper
          steps={steps.map((step: any) => step.title)}
          activeStep={activeStep}
          completed={completed}
          handleStep={(index) => handleStep(index)}
        />
        {shippingStepRenderer()}
      </Container>
    );
  }
};

const Container = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});
