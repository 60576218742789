import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getIdValue } from "./types";
import orderServices from "./orderServices";

const initialState = {
  isError: false,
  isDeleteError: false,
  isCreateError: false,
  isSuccess: false,
  isPatchSuccess: false,
  isDeleteSuccess: false,
  isCreateSuccess: false,
  isLoading: true,
  isSingleLoading: true,
  isCreateLoading: false,
  isDeleteLoading: false,
  message: "",
  deleteMessage: "",
  createMessage: "",
  orders: [],
  ordersMeta: [],
  singleorder: [],
};

export const getorders = createAsyncThunk(
  "orders",
  async (data: any, thunkAPI) => {
    try {
      return await orderServices.getOrders(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getordersId = createAsyncThunk(
  "orders/id",
  async (data: getIdValue, thunkAPI) => {
    try {
      return orderServices.getOrdersId(data.id);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const postorder = createAsyncThunk(
  "orders/post",
  async (data: any, thunkAPI) => {
    try {
      return await orderServices.postOrder(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const patchorder = createAsyncThunk(
  "orders/patch",
  async (data: any, thunkAPI) => {
    try {
      return await orderServices.patchOrder(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteorder = createAsyncThunk(
  "orders/delete",
  async (data: any, thunkAPI) => {
    try {
      return await orderServices.deleteOrder(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSingleLoading = true;
      state.isDeleteLoading = false;
      state.isSuccess = false;
      state.isPatchSuccess = false;
      state.isDeleteSuccess = false;
      state.isError = false;
      state.isDeleteError = false;
      state.deleteMessage = "";
      state.isCreateError = false;
      state.isCreateSuccess = false;
      state.isCreateLoading = false;
      state.createMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getorders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getorders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.orders = action.payload.data.results;
        state.ordersMeta = action.payload.data.meta;
      })
      .addCase(getorders.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })
      .addCase(getordersId.pending, (state) => {
        state.isSingleLoading = true;
      })
      .addCase(getordersId.fulfilled, (state, action) => {
        state.isSingleLoading = false;
        state.isSuccess = true;
        state.singleorder = action.payload.data.results;
      })
      .addCase(getordersId.rejected, (state, action) => {
        state.isSingleLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(postorder.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(postorder.fulfilled, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateSuccess = true;
        state.createMessage = `${action.payload.data.message}`;
      })
      .addCase(postorder.rejected, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateError = true;
        state.createMessage = `${action.payload}`;
      })

      .addCase(patchorder.pending, (state) => {
        state.isLoading = true;
        state.isCreateLoading = true;
      })
      .addCase(patchorder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCreateLoading = false;
        state.isPatchSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(patchorder.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreateLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(deleteorder.pending, (state) => {
        state.isDeleteLoading = true;
      })
      .addCase(deleteorder.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteSuccess = true;
        state.deleteMessage = `${action.payload.data.message}`;
      })
      .addCase(deleteorder.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.deleteMessage = `${action.payload}`;
      });
  },
});

export const { reset } = orderSlice.actions;
export default orderSlice.reducer;
