import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SaleOrdersServices from "./SaleOrdersServices";

const initialState = {
  isError: false,
  isSingleError: false,
  isUpdateError: false,
  isSingleByShipmentError: false,
  isSingleByFirstShipmentError: false,
  isExportError: false,
  isCreateError: false,
  isPatchError: false,
  isDeleteError: false,
  isGetDocumentsError: false,
  isGetDocumentsByTagError: false,
  isAddDocumentsError: false,
  isDeleteDocumentsError: false,
  isChangeDocumentDownloadbleError: false,
  isSetCompletedError: false,
  isGetShipmentsError: false,
  isGetShipmentDetailsError: false,
  isGetContainersError: false,
  isExportContainersError: false,
  isEditContainerNumberError: false,
  isSuccess: false,
  isSingleSuccess: false,
  isUpdateSuccess: false,
  isSingleByShipmentSuccess: false,
  isSingleByFirstShipmentSuccess: false,
  isExportSuccess: false,
  isDeleteSuccess: false,
  isCreateSuccess: false,
  isPatchSuccess: false,
  isGetDocumentsSuccess: false,
  isGetDocumentsByTagSuccess: false,
  isAddDocumentsSuccess: false,
  isDeleteDocumentsSuccess: false,
  isChangeDocumentDownloadbleSuccess: false,
  isSetCompletedSuccess: false,
  isGetShipmentsSuccess: false,
  isGetShipmentDetailsSuccess: false,
  isGetContainersSuccess: false,
  isExportContainersSuccess: false,
  isEditContainerNumberSuccess: false,
  isLoading: true,
  isSingleLoading: true,
  isUpdateLoading: false,
  isSingleByShipmentLoading: true,
  isSingleByFirstShipmentLoading: true,
  isCreateLoading: false,
  isDeleteLoading: false,
  isPatchLoading: false,
  isExportLoading: false,
  isGetDocumentsLoading: false,
  isGetDocumentsByTagLoading: false,
  isAddDocumentsLoading: false,
  isDeleteDocumentsLoading: false,
  isChangeDocumentDownloadbleLoading: false,
  isSetCompletedLoading: false,
  isGetShipmentsLoading: true,
  isGetShipmentDetailsLoading: true,
  isGetContainersLoading: false,
  isExportContainersLoading: false,
  isEditContainerNumberLoading: false,
  message: "",
  singleMessage: "",
  singleByShipmentMessage: "",
  exportMessage: "",
  createMessage: "",
  patchMessage: "",
  deleteMessage: "",
  getDocumentsMessage: "",
  getDocumentsByTagMessage: "",
  addDocumentsMessage: "",
  deleteDocumentsMessage: "",
  setCompletedMessage: "",
  getShipmentsMessage: "",
  getShipmentDetailsMessage: "",
  getContainersMessage: "",
  exportContainersMessage: "",
  editContainerNumberMessage: "",
  changeDocumentDownloadbleMessage: "",
  singleByFirstShipmentMessage: "",
  updateMessage: "",
  saleOrders: [],
  saleOrdersMeta: [],
  singleSaleOrder: [],
  singleByShipmentSaleOrder: [],
  singleByFirstShipmentSaleOrder: [],
  documents: [],
  documentsByTag: [],
  addDocuments: [],
  shipments: [],
  shipmentDetails: [],
  containers: [],
  containersMeta: [],
};

export const getSaleOrders = createAsyncThunk(
  "SaleOrders",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.getSaleOrders(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const exportSaleOrders = createAsyncThunk(
  "SaleOrders/export",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.exportSaleOrders();
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getSaleOrdersId = createAsyncThunk(
  "SaleOrders/id",
  async (data: any, thunkAPI) => {
    try {
      return SaleOrdersServices.getSaleOrderId(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateSaleOrder = createAsyncThunk(
  "SaleOrders/update",
  async (data: any, thunkAPI) => {
    try {
      return SaleOrdersServices.updateSaleOrder(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getSaleOrderIdByShipment = createAsyncThunk(
  "SaleOrders/id-byshipment",
  async (data: any, thunkAPI) => {
    try {
      return SaleOrdersServices.getSaleOrderIdByShipment(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const addSaleOrder = createAsyncThunk(
  "SaleOrders/post",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.addSaleOrder(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const patchSaleOrder = createAsyncThunk(
  "SaleOrders/patch",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.patchSaleOrder(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteSaleOrder = createAsyncThunk(
  "SaleOrders/delete",
  async (id: number, thunkAPI) => {
    try {
      return await SaleOrdersServices.deleteSaleOrder(id);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getSaleOrderDocuments = createAsyncThunk(
  "SaleOrders/documents/get",
  async (id: number, thunkAPI) => {
    try {
      return await SaleOrdersServices.getSaleOrderDocuments(id);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getSaleOrderDocumentsByTag = createAsyncThunk(
  "SaleOrders/documents/get-by-tag",
  async (id: number, thunkAPI) => {
    try {
      return await SaleOrdersServices.getSaleOrderDocumentsByTag(id);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const addSaleOrderDocuments = createAsyncThunk(
  "SaleOrders/documents/add",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.addSaleOrderDocuments(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteSaleOrderDocuments = createAsyncThunk(
  "SaleOrders/documents/delete",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.deleteSaleOrderDocuments(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const changeSaleOrderDocumentDownloadble = createAsyncThunk(
  "SaleOrders/documents/downloadble",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.changeSaleOrderDocumentDownloadble(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const setSaleOrderAsCompleted = createAsyncThunk(
  "SaleOrders/set-complete",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.setSaleOrderAsCompleted(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getSaleOrderShipments = createAsyncThunk(
  "SaleOrders/shipments",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.getSaleOrderShipments(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getSaleOrderDetailsByShipment = createAsyncThunk(
  "SaleOrders/shipment-details",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.getSaleOrderDetailsByShipment(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getSaleOrderIdByFirstShipment = createAsyncThunk(
  "SaleOrders/first-shipment-details",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.getSaleOrderIdByFirstShipment(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getSaleOrderShipmentCarriers = createAsyncThunk(
  "SaleOrders/origin-carriers",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.getSaleOrderShipmentCarriers(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteSaleOrderShipmentCarrier = createAsyncThunk(
  "SaleOrders/delete-carriers",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.deleteSaleOrderShipmentCarrier(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSaleOrderContainers = createAsyncThunk(
  "SaleOrders/containers",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.getSaleOrderContainers(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const editSaleOrderContainerNumber = createAsyncThunk(
  "SaleOrders/container-edit",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.editSaleOrderContainerNumber(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const exportSaleOrderContainers = createAsyncThunk(
  "SaleOrders/containers-export",
  async (data: any, thunkAPI) => {
    try {
      return await SaleOrdersServices.exportSaleOrderContainers(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const SaleOrderslice = createSlice({
  name: "SaleOrders",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSingleLoading = false;
      state.isUpdateLoading = false;
      state.isSingleByShipmentLoading = false;
      state.isSingleByFirstShipmentLoading = false;
      state.isCreateLoading = false;
      state.isDeleteLoading = false;
      state.isPatchLoading = false;
      state.isExportLoading = false;
      state.isGetDocumentsLoading = false;
      state.isGetDocumentsByTagLoading = false;
      state.isAddDocumentsLoading = false;
      state.isDeleteDocumentsLoading = false;
      state.isChangeDocumentDownloadbleLoading = false;
      state.isSetCompletedLoading = false;
      state.isGetShipmentsLoading = false;
      state.isGetShipmentDetailsLoading = false;
      state.isGetContainersLoading = false;
      state.isExportContainersLoading = false;
      state.isEditContainerNumberLoading = false;
      state.isSuccess = false;
      state.isSingleSuccess = false;
      state.isUpdateSuccess = false;
      state.isSingleByShipmentSuccess = false;
      state.isSingleByFirstShipmentSuccess = false;
      state.isCreateSuccess = false;
      state.isExportSuccess = false;
      state.isDeleteSuccess = false;
      state.isPatchSuccess = false;
      state.isGetDocumentsSuccess = false;
      state.isGetDocumentsByTagSuccess = false;
      state.isAddDocumentsSuccess = false;
      state.isDeleteDocumentsSuccess = false;
      state.isChangeDocumentDownloadbleSuccess = false;
      state.isSetCompletedSuccess = false;
      state.isGetShipmentsSuccess = false;
      state.isGetShipmentDetailsSuccess = false;
      state.isGetContainersSuccess = false;
      state.isExportContainersSuccess = false;
      state.isEditContainerNumberSuccess = false;
      state.isError = false;
      state.isSingleError = false;
      state.isUpdateError = false;
      state.isSingleByShipmentError = false;
      state.isSingleByFirstShipmentError = false;
      state.isExportError = false;
      state.isCreateError = false;
      state.isDeleteError = false;
      state.isPatchError = false;
      state.isGetDocumentsError = false;
      state.isGetDocumentsByTagError = false;
      state.isAddDocumentsError = false;
      state.isDeleteDocumentsError = false;
      state.isChangeDocumentDownloadbleError = false;
      state.isSetCompletedError = false;
      state.isGetShipmentsError = false;
      state.isGetShipmentDetailsError = false;
      state.isGetContainersError = false;
      state.isExportContainersError = false;
      state.isEditContainerNumberError = false;
      state.message = "";
      state.singleMessage = "";
      state.updateMessage = "";
      state.singleByShipmentMessage = "";
      state.singleByFirstShipmentMessage = "";
      state.exportMessage = "";
      state.createMessage = "";
      state.patchMessage = "";
      state.deleteMessage = "";
      state.getDocumentsMessage = "";
      state.getDocumentsByTagMessage = "";
      state.addDocumentsMessage = "";
      state.deleteDocumentsMessage = "";
      state.changeDocumentDownloadbleMessage = "";
      state.getShipmentsMessage = "";
      state.getShipmentDetailsMessage = "";
      state.setCompletedMessage = "";
      state.getContainersMessage = "";
      state.exportContainersMessage = "";
      state.editContainerNumberMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSaleOrders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSaleOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.saleOrders = action.payload.data.results;
        state.saleOrdersMeta = action.payload.data.meta;
      })
      .addCase(getSaleOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(exportSaleOrders.pending, (state) => {
        state.isExportLoading = true;
      })
      .addCase(exportSaleOrders.fulfilled, (state, action) => {
        state.isExportLoading = false;
        state.isExportSuccess = true;
      })
      .addCase(exportSaleOrders.rejected, (state, action) => {
        state.isExportLoading = false;
        state.isExportError = true;
        state.exportMessage = `${action.payload}`;
      })

      .addCase(getSaleOrdersId.pending, (state) => {
        state.isSingleLoading = true;
      })
      .addCase(getSaleOrdersId.fulfilled, (state, action) => {
        state.isSingleLoading = false;
        state.isSingleSuccess = true;
        state.singleSaleOrder = action.payload.data.results;
      })
      .addCase(getSaleOrdersId.rejected, (state, action) => {
        state.isSingleLoading = false;
        state.isError = true;
        state.singleMessage = `${action.payload}`;
      })

      .addCase(updateSaleOrder.pending, (state) => {
        state.isUpdateLoading = true;
      })
      .addCase(updateSaleOrder.fulfilled, (state, action) => {
        state.isUpdateLoading = false;
        state.isUpdateSuccess = true;
        state.updateMessage = `${action.payload}`;
      })
      .addCase(updateSaleOrder.rejected, (state, action) => {
        state.isUpdateLoading = false;
        state.isUpdateError = true;
        state.updateMessage = `${action.payload}`;
      })

      .addCase(getSaleOrderIdByShipment.pending, (state) => {
        state.isSingleByShipmentLoading = true;
      })
      .addCase(getSaleOrderIdByShipment.fulfilled, (state, action) => {
        state.isSingleByShipmentLoading = false;
        state.isSingleByShipmentSuccess = true;
        state.singleByShipmentSaleOrder = action.payload.data.results;
      })
      .addCase(getSaleOrderIdByShipment.rejected, (state, action) => {
        state.isSingleByShipmentLoading = false;
        state.isSingleByShipmentError = true;
        state.singleByShipmentMessage = `${action.payload}`;
      })

      .addCase(getSaleOrderIdByFirstShipment.pending, (state) => {
        state.isSingleByFirstShipmentLoading = true;
      })
      .addCase(getSaleOrderIdByFirstShipment.fulfilled, (state, action) => {
        state.isSingleByFirstShipmentLoading = false;
        state.isSingleByFirstShipmentSuccess = true;
        state.singleByFirstShipmentSaleOrder = action.payload.data.results;
      })
      .addCase(getSaleOrderIdByFirstShipment.rejected, (state, action) => {
        state.isSingleByFirstShipmentLoading = false;
        state.isSingleByFirstShipmentError = true;
        state.singleByFirstShipmentMessage = `${action.payload}`;
      })

      .addCase(addSaleOrder.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(addSaleOrder.fulfilled, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateSuccess = true;
        state.createMessage = `${action.payload.data.message}`;
      })
      .addCase(addSaleOrder.rejected, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateError = true;
        state.createMessage = `${action.payload}`;
      })

      .addCase(patchSaleOrder.pending, (state) => {
        state.isPatchLoading = true;
      })
      .addCase(patchSaleOrder.fulfilled, (state, action) => {
        state.isPatchLoading = false;
        state.isPatchSuccess = true;
        state.patchMessage = `${action.payload.data.message}`;
      })
      .addCase(patchSaleOrder.rejected, (state, action) => {
        state.isPatchLoading = false;
        state.isPatchError = true;
        state.patchMessage = `${action.payload}`;
      })

      .addCase(deleteSaleOrder.pending, (state) => {
        state.isDeleteLoading = true;
      })
      .addCase(deleteSaleOrder.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteSuccess = true;
        state.deleteMessage = `${action.payload.data.message}`;
      })
      .addCase(deleteSaleOrder.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.deleteMessage = `${action.payload}`;
      })

      .addCase(getSaleOrderDocuments.pending, (state) => {
        state.isGetDocumentsLoading = true;
      })
      .addCase(getSaleOrderDocuments.fulfilled, (state, action) => {
        state.isGetDocumentsLoading = false;
        state.isGetDocumentsSuccess = true;
        state.documents = action.payload.data.results;
        state.getDocumentsMessage = `${action.payload.data.message}`;
      })
      .addCase(getSaleOrderDocuments.rejected, (state, action) => {
        state.isGetDocumentsLoading = false;
        state.isGetDocumentsError = true;
        state.getDocumentsMessage = `${action.payload}`;
      })

      .addCase(getSaleOrderDocumentsByTag.pending, (state) => {
        state.isGetDocumentsByTagLoading = true;
      })
      .addCase(getSaleOrderDocumentsByTag.fulfilled, (state, action) => {
        state.isGetDocumentsByTagLoading = false;
        state.isGetDocumentsByTagSuccess = true;
        state.documentsByTag = action.payload.data.results;
        state.getDocumentsByTagMessage = `${action.payload.data.message}`;
      })
      .addCase(getSaleOrderDocumentsByTag.rejected, (state, action) => {
        state.isGetDocumentsByTagLoading = false;
        state.isGetDocumentsByTagError = true;
        state.getDocumentsByTagMessage = `${action.payload}`;
      })

      .addCase(addSaleOrderDocuments.pending, (state) => {
        state.isAddDocumentsLoading = true;
      })
      .addCase(addSaleOrderDocuments.fulfilled, (state, action) => {
        state.isAddDocumentsLoading = false;
        state.isAddDocumentsSuccess = true;
        state.addDocuments = action.payload.data.results;
        state.addDocumentsMessage = `${action.payload.data.message}`;
      })
      .addCase(addSaleOrderDocuments.rejected, (state, action) => {
        state.isAddDocumentsLoading = false;
        state.isAddDocumentsError = true;
        state.addDocumentsMessage = `${action.payload}`;
      })

      .addCase(deleteSaleOrderDocuments.pending, (state) => {
        state.isDeleteDocumentsLoading = true;
      })
      .addCase(deleteSaleOrderDocuments.fulfilled, (state, action) => {
        state.isDeleteDocumentsLoading = false;
        state.isDeleteDocumentsSuccess = true;
        state.deleteDocumentsMessage = `${action.payload.data.message}`;
      })
      .addCase(deleteSaleOrderDocuments.rejected, (state, action) => {
        state.isDeleteDocumentsLoading = false;
        state.isDeleteDocumentsError = true;
        state.deleteDocumentsMessage = `${action.payload}`;
      })

      .addCase(changeSaleOrderDocumentDownloadble.pending, (state) => {
        state.isChangeDocumentDownloadbleLoading = true;
      })
      .addCase(
        changeSaleOrderDocumentDownloadble.fulfilled,
        (state, action) => {
          state.isChangeDocumentDownloadbleLoading = false;
          state.isChangeDocumentDownloadbleSuccess = true;
          state.changeDocumentDownloadbleMessage = `${action.payload.data.message}`;
        }
      )
      .addCase(changeSaleOrderDocumentDownloadble.rejected, (state, action) => {
        state.isChangeDocumentDownloadbleLoading = false;
        state.isChangeDocumentDownloadbleError = true;
        state.changeDocumentDownloadbleMessage = `${action.payload}`;
      })

      .addCase(setSaleOrderAsCompleted.pending, (state) => {
        state.isSetCompletedLoading = true;
      })
      .addCase(setSaleOrderAsCompleted.fulfilled, (state, action) => {
        state.isSetCompletedLoading = false;
        state.isSetCompletedSuccess = true;
        state.setCompletedMessage = `${action.payload.data.message}`;
      })
      .addCase(setSaleOrderAsCompleted.rejected, (state, action) => {
        state.isSetCompletedLoading = false;
        state.isSetCompletedError = true;
        state.setCompletedMessage = `${action.payload}`;
      })

      .addCase(getSaleOrderShipments.pending, (state) => {
        state.isGetShipmentsLoading = true;
      })
      .addCase(getSaleOrderShipments.fulfilled, (state, action) => {
        state.isGetShipmentsLoading = false;
        state.isGetShipmentsSuccess = true;
        state.shipments = action.payload.data.results;
        state.getShipmentsMessage = `${action.payload.data.message}`;
      })
      .addCase(getSaleOrderShipments.rejected, (state, action) => {
        state.isGetShipmentsLoading = false;
        state.isGetShipmentsError = true;
        state.getShipmentsMessage = `${action.payload}`;
      })

      .addCase(getSaleOrderDetailsByShipment.pending, (state) => {
        state.isGetShipmentDetailsLoading = true;
      })
      .addCase(getSaleOrderDetailsByShipment.fulfilled, (state, action) => {
        state.isGetShipmentDetailsLoading = false;
        state.isGetShipmentDetailsSuccess = true;
        state.shipmentDetails = action.payload.data.results;
        state.getShipmentDetailsMessage = `${action.payload.data.message}`;
      })
      .addCase(getSaleOrderDetailsByShipment.rejected, (state, action) => {
        state.isGetShipmentDetailsLoading = false;
        state.isGetShipmentDetailsError = true;
        state.getShipmentDetailsMessage = `${action.payload}`;
      })

      .addCase(getSaleOrderContainers.pending, (state) => {
        state.isGetContainersLoading = true;
      })
      .addCase(getSaleOrderContainers.fulfilled, (state, action) => {
        state.isGetContainersLoading = false;
        state.isGetContainersSuccess = true;
        state.containers = action.payload.data.results;
        state.containersMeta = action.payload.data.meta;
        state.getContainersMessage = `${action.payload.data.message}`;
      })
      .addCase(getSaleOrderContainers.rejected, (state, action) => {
        state.isGetContainersLoading = false;
        state.isGetContainersError = true;
        state.getContainersMessage = `${action.payload}`;
      })

      .addCase(editSaleOrderContainerNumber.pending, (state) => {
        state.isEditContainerNumberLoading = true;
      })
      .addCase(editSaleOrderContainerNumber.fulfilled, (state, action) => {
        state.isEditContainerNumberLoading = false;
        state.isEditContainerNumberSuccess = true;
        state.editContainerNumberMessage = `${action.payload.data.message}`;
      })
      .addCase(editSaleOrderContainerNumber.rejected, (state, action) => {
        state.isEditContainerNumberLoading = false;
        state.isEditContainerNumberError = true;
        state.editContainerNumberMessage = `${action.payload}`;
      })

      .addCase(exportSaleOrderContainers.pending, (state) => {
        state.isExportContainersLoading = true;
      })
      .addCase(exportSaleOrderContainers.fulfilled, (state, action) => {
        state.isExportContainersLoading = false;
        state.isExportContainersSuccess = true;
        state.exportContainersMessage = `${action.payload.data.message}`;
      })
      .addCase(exportSaleOrderContainers.rejected, (state, action) => {
        state.isExportContainersLoading = false;
        state.isExportContainersError = true;
        state.exportContainersMessage = `${action.payload}`;
      });
  },
});

export const { reset } = SaleOrderslice.actions;
export default SaleOrderslice.reducer;
