import { styled } from "@mui/material/styles";
import { FormHelperText, TextField, TextFieldProps } from "@mui/material";
import colors from "../../theme/colors";
const CustomTextField = styled(TextField)`
  background: ${colors.base.white};
  border-radius: 5px;
  color: ${colors.gray[850]}!important;
`;
export const StyledTextFieldMulti = (props: TextFieldProps) => {
  return (
    <CustomTextField
      {...props}
      inputProps={{
        style: {
          height: "140px",
          padding: " 0px 14px",
        },
        sx: {
          color: colors.gray[850],
          "&::placeholder": {
            color: colors.gray[500],
            fontWeight: "400",
          },
        },
      }}
    >
      <FormHelperText
        style={{ color: colors.error[700], fontSize: 13, fontWeight: 400 }}
      >
        {props.helperText}
      </FormHelperText>
    </CustomTextField>
  );
};
