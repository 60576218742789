import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import WarehouseServices from "./WarehouseServices";

const initialState = {
  isError: false,
  isInventoriesError: false,
  isPlacementsError: false,
  isSuccess: false,
  isPatchSuccess: false,
  isInventoriesSuccess: false,
  isPlacementsSuccess: false,
  isLoading: true,
  isInventoriesLoading: true,
  isPlacementsLoading: false,
  isSingleLoading: true,
  isCreateLoading: false,
  isDeleteLoading: false,
  message: "",
  InventoriesMessage: "",
  placementsMessage: "",
  inventories: [],
  Warehouses: [],
  WarehousesMeta: [],
  singleWarehouse: [],
  placements: [],
};

export const getWarehouses = createAsyncThunk(
  "Warehouses",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.getWarehouses(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getWarehousesId = createAsyncThunk(
  "Warehouses/id",
  async (data: any, thunkAPI) => {
    try {
      return WarehouseServices.getWarehousesId(data.id);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const postWarehouse = createAsyncThunk(
  "Warehouses/post",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.postWarehouse(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const patchWarehouse = createAsyncThunk(
  "Warehouses/patch",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.patchWarehouse(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteWarehouse = createAsyncThunk(
  "Warehouses/delete",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.deleteWarehouse(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteSingleWarehouse = createAsyncThunk(
  "Warehouses/deleteSingle",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.deleteSingleWarehouse(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getWarehouseInventories = createAsyncThunk(
  "Warehouses/inventories",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.getWarehouseInventories(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getWarehousePlacements = createAsyncThunk(
  "Warehouses/placements",
  async (data: any, thunkAPI) => {
    try {
      return await WarehouseServices.getWarehousePlacements(data);
    } catch (error: any) {
      const message =
        error?.response?.status === 500
          ? "Internal Server Error"
          : error?.response.data?.results?.errors[0]?.message ||
            error?.response.data?.meta?.errors[0]?.message ||
            error?.response.data.message ||
            error?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const WarehouseSlice = createSlice({
  name: "Warehouse",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSingleLoading = true;
      state.isInventoriesLoading = false;
      state.isPlacementsLoading = false;
      state.isCreateLoading = false;
      state.isSuccess = false;
      state.isPatchSuccess = false;
      state.isPlacementsSuccess = false;
      state.isInventoriesSuccess = false;
      state.isError = false;
      state.isInventoriesError = false;
      state.isPlacementsError = false;
      state.message = "";
      state.placementsMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWarehouses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWarehouses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.Warehouses = action.payload.data.results;
        state.WarehousesMeta = action.payload.data.meta;
      })
      .addCase(getWarehouses.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })
      .addCase(getWarehousesId.pending, (state) => {
        state.isSingleLoading = true;
      })
      .addCase(getWarehousesId.fulfilled, (state, action) => {
        state.singleWarehouse = action.payload.data.results;
        state.isSingleLoading = false;
        state.isSuccess = true;
      })
      .addCase(getWarehousesId.rejected, (state, action) => {
        state.isSingleLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(postWarehouse.pending, (state) => {
        state.isLoading = true;
        state.isCreateLoading = true;
      })
      .addCase(postWarehouse.fulfilled, (state, action) => {
        state.isLoading = false;
        (state.isCreateLoading = false), (state.isSuccess = true);
        state.message = `${action.payload.data.message}`;
      })
      .addCase(postWarehouse.rejected, (state, action) => {
        state.isLoading = false;
        (state.isCreateLoading = false), (state.isError = true);
        state.message = `${action.payload}`;
      })

      .addCase(patchWarehouse.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(patchWarehouse.fulfilled, (state, action) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isPatchSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(patchWarehouse.rejected, (state, action) => {
        state.isCreateLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(deleteWarehouse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteWarehouse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(deleteWarehouse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })
      .addCase(deleteSingleWarehouse.pending, (state) => {
        state.isDeleteLoading = true;
      })
      .addCase(deleteSingleWarehouse.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isSuccess = true;
        state.message = `${action.payload.data.message}`;
      })
      .addCase(deleteSingleWarehouse.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isError = true;
        state.message = `${action.payload}`;
      })

      .addCase(getWarehouseInventories.pending, (state) => {
        state.isInventoriesLoading = true;
      })
      .addCase(getWarehouseInventories.fulfilled, (state, action) => {
        state.InventoriesMessage = `${action.payload.data.message}`;
        state.inventories = action.payload.data.results;
        state.isInventoriesLoading = false;
        state.isInventoriesSuccess = true;
      })
      .addCase(getWarehouseInventories.rejected, (state, action) => {
        state.InventoriesMessage = `${action.payload}`;
        state.isInventoriesLoading = false;
        state.isInventoriesError = true;
      })

      .addCase(getWarehousePlacements.pending, (state) => {
        state.isPlacementsLoading = true;
      })
      .addCase(getWarehousePlacements.fulfilled, (state, action) => {
        state.placementsMessage = `${action.payload.data.message}`;
        state.placements = action.payload.data.results;
        state.isPlacementsLoading = false;
        state.isPlacementsSuccess = true;
      })
      .addCase(getWarehousePlacements.rejected, (state, action) => {
        state.placementsMessage = `${action.payload}`;
        state.isPlacementsLoading = false;
        state.isPlacementsError = true;
      });
  },
});

export const { reset } = WarehouseSlice.actions;
export default WarehouseSlice.reducer;
