import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageTemplate } from "src/layout/components/PageTemplate";
import { SaleOrdersEditForm } from "../components/SaleOrdersEditForm";
import { AppDispatch, RootState } from "src/store";
import {
  getCustomers,
  reset as resetCustomers,
} from "src/features/customers/redux/CustomersSlice";
import {
  getorders,
  reset as resetOrders,
} from "src/features/orders/redux/orderSlice";
import {
  getWarehouses,
  reset as resetWarehouses,
} from "src/features/warehouses/redux/WarehouseSlice";
import {
  getProducts,
  reset as resetProducts,
} from "src/features/products/redux/ProductsSlice";
import Loading from "src/components/Loading";
import {
  getSaleOrdersId,
  reset as resetSaleOrders,
} from "../redux/SaleOrdersSlice";
import { useLocation, useNavigate } from "react-router-dom";

const SaleOrdersEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const { state } = location as any;
  const [page, setPage] = useState(1);

  const {
    singleSaleOrder,
    isError: isSaleOrdersError,
    isSuccess: isSaleOrdersSuccess,
    message: saleOrdersMessage,
    isLoading: isSaleOrdersLoading,
  } = useSelector((state: RootState) => state.saleOrders);

  const {
    customers,
    isError: isCustomersError,
    isSuccess: isCustomersSuccess,
    message: customersMessage,
    isLoading: isCustomersLoading,
  } = useSelector((state: RootState) => state.customers);

  const {
    orders,
    isError: isOrdersError,
    isSuccess: isOrdersSuccess,
    message: ordersMessage,
    isLoading: isOrdersLoading,
  } = useSelector((state: RootState) => state.orders);

  const {
    Warehouses,
    isError: isWarehousesError,
    isSuccess: isWarehousesSuccess,
    message: warehousesMessage,
    isLoading: isWarehousesLoading,
  } = useSelector((state: RootState) => state.warehouse);

  const {
    products,
    isError: isProductsError,
    isSuccess: isProductsSuccess,
    message: productsMessage,
    isLoading: isProductsLoading,
  } = useSelector((state: RootState) => state.products);

  useEffect(() => {
    if (isSaleOrdersError) {
      enqueueSnackbar(saleOrdersMessage, { variant: "error" });
      navigate("/saleOrders/saleOrders-list");
    }
    return () => {
      dispatch(resetSaleOrders());
    };
  }, [isSaleOrdersError, isSaleOrdersSuccess, isSaleOrdersLoading]);

  useEffect(() => {
    if (isCustomersError) {
      enqueueSnackbar(customersMessage, { variant: "error" });
    }
    return () => {
      dispatch(resetCustomers());
    };
  }, [isCustomersError, isCustomersSuccess, isCustomersLoading]);

  useEffect(() => {
    if (isOrdersError) {
      enqueueSnackbar(ordersMessage, { variant: "error" });
    }
    return () => {
      dispatch(resetOrders());
    };
  }, [isOrdersError, isOrdersSuccess, isOrdersLoading]);

  useEffect(() => {
    if (isWarehousesError) {
      enqueueSnackbar(warehousesMessage, { variant: "error" });
    }
    return () => {
      dispatch(resetWarehouses());
    };
  }, [isWarehousesError, isWarehousesSuccess, isWarehousesLoading]);

  useEffect(() => {
    if (isProductsError) {
      enqueueSnackbar(productsMessage, { variant: "error" });
    }
    return () => {
      dispatch(resetProducts());
    };
  }, [isProductsError, isProductsSuccess, isProductsLoading]);

  useEffect(() => {
    dispatch(
      getSaleOrdersId({
        id: Number(state?.id),
      })
    );
    dispatch(
      getCustomers({
        page: page,
        limit: 100,
      })
    );
    dispatch(
      getorders({
        page: page,
        limit: 100,
      })
    );
    dispatch(
      getWarehouses({
        page: page,
        limit: 100,
      })
    );
    dispatch(
      getProducts({
        page: page,
        limit: 100,
      })
    );
  }, []);

  return (
    <PageTemplate
      isTransparent
      crumbs={[
        {
          label: "saleOrders",
          href: "/saleOrders/saleOrders-list",
          depth: -1,
        },
        {
          label: "Edit Sale Order",
        },
      ]}
    >
      {isSaleOrdersLoading ||
      isCustomersLoading ||
      isOrdersLoading ||
      isWarehousesLoading ||
      isProductsLoading ? (
        <Loading />
      ) : (
        <SaleOrdersEditForm
          saleOrderData={singleSaleOrder as any}
          customersData={customers}
          ordersData={orders}
          warehousesData={Warehouses}
          productsData={products}
        />
      )}
    </PageTemplate>
  );
};

export default SaleOrdersEdit;
