import { axios } from "src/config/Axios";
import fileDownload from "js-file-download";

export function downloadURLFile(
  url: string,
  filename: string,
  onCatch: () => void
) {
  return axios
    .get(url, {
      responseType: "blob",
    })
    .then((res) => {
      if (res.data) {
        fileDownload(res.data, filename);
      } else {
        onCatch();
      }
    })
    .catch(() => onCatch());
}
