export enum Permissions {
  USERS = "Users",
  ORDERS = "VendorOrders",
  SALEORDERS = "SaleOrders",
  VENDORS = "Vendors",
  ROLES = "Roles",
  TRUCKS = "Trucks",
  WAREHOUSES = "Warehouses",
  MATERIALS = "Materials",
  LOCALINSPECTIONS = "LocalInspections",
  INTERNATIONALSPECTIONS = "InternationalInspections",
  PRODUCTS = "Products",
  STOCKTAKING = "StockTaking",
  CUSTOMERS = "Customers",
  LOGS = "Logs",
}
