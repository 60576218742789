/* eslint-disable react/no-unescaped-entities */
import { FC } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import { Field, FieldArray, Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormInputLable from "src/components/FormInputLabel";
import { StyledTextField } from "src/components/Input";
import { StyledLoadingButton } from "src/components/LoadingButton";
import { AppDispatch, RootState } from "src/store";
import colors from "src/theme/colors";
import * as Yup from "yup";
import { patchProduct, reset as resetProducts } from "../redux/ProductsSlice";
import { GrayButton } from "src/components/GrayButton";
import { useNavigate } from "react-router-dom";
import {
  FileUploader,
  FileUploaderType,
} from "src/components/FileUploader/FileUploader";
import { UploadedFileType } from "src/components/FileUploader/UploadZone/UploadZone";
import FormHeading from "src/components/FormHeading";
import { StyledButton } from "src/components/Button";
import { AddNewVariantModal } from "./modals/AddNewVariantModal";
import { PaperBox } from "src/components/Paper/Paper";

interface Props {
  data: any;
  materials: any;
  variants: any;
}

export const ProductsEditForm: FC<Props> = (props) => {
  const navigate = useNavigate();

  const [files, setFiles] = useState<UploadedFileType[]>([]);
  const [isAddNewVariantModalOpen, setIsAddNewVariantModalOpen] =
    useState(false);

  //formik---------------------------------
  const ItemSchema = Yup.object().shape({
    title: Yup.string().required(),
    material: Yup.number(),
    isCountable: Yup.boolean(),
    isPackageable: Yup.boolean(),
    isCreatableInProduction: Yup.boolean(),
    variants: Yup.array().of(
      Yup.object().shape({
        id: Yup.number(),
        name: Yup.string(),
        initial_value: Yup.string(),
        has_initial_value: Yup.boolean(),
        initial_value_required: Yup.boolean(),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      title: props?.data?.title,
      material: props?.data?.material?.id ?? "",
      isCountable: props?.data?.countable,
      isPackageable: props?.data?.is_package,
      isCreatableInProduction: props?.data?.is_product,
      variants: props?.data?.variants
        ? props?.data?.variants.map((variant: any) => ({
            id: variant.id,
            name: variant.title,
            initial_value: variant.fixed_value,
            has_initial_value: variant.fixed,
            initial_value_required: variant.initial_value_required,
          }))
        : [],
    },
    validationSchema: ItemSchema,

    onSubmit: async (values: any) => {
      const formData = new FormData();
      formData.append("_method", "PATCH");
      formData.append("title", values.title);
      if (values.material) {
        formData.append("material_id", values.material);
      }
      formData.append(
        "is_package",
        values.isPackageable ? (1 as any) : (0 as any)
      );
      formData.append(
        "countable",
        values.isCountable ? (1 as any) : (0 as any)
      );
      formData.append(
        "production",
        values.isCreatableInProduction ? (1 as any) : (0 as any)
      );

      const oldFiles = files.filter((file) => file.id != null);
      const newFiles = files.filter((file) => file.id == null);
      if (oldFiles.length === 0 || oldFiles == null) {
        if (newFiles.length > 0) {
          formData.append(`image`, newFiles[0] as any, newFiles[0].name);
        } else {
          formData.append(`image`, "" as any);
        }
      }

      if (values.variants && values.variants.length > 0) {
        values.variants.forEach((variant: any, index: number) => {
          const updatedVariant = {
            id: variant.id,
            initial_value: variant.initial_value,
            has_initial_value: variant.has_initial_value ? 1 : 0,
            initial_value_required: variant.initial_value_required ? 1 : 0,
          };
          Object.keys(updatedVariant).forEach((key) =>
            formData.append(
              `variants[${index}][${key}]`,
              (updatedVariant as any)[key] as any
            )
          );
        });
      }

      dispatch(
        patchProduct({
          id: Number(props?.data?.id),
          data: formData,
        })
      );
    },
  });

  const {
    errors,
    touched,
    values,
    resetForm,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  //redux ---------------------------------
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const { isPatchLoading, isError, isSuccess, message } = useSelector(
    (state: RootState) => state.products
  );
  //redux ---------------------------------

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(message, { variant: "error" });
    }
    if (isSuccess) {
      enqueueSnackbar(message, { variant: "success" });
      // navigate("/products/products-list");
    }
    return () => {
      dispatch(resetProducts());
    };
  }, [isError, isSuccess, message, dispatch]);

  const [materialsData, setMaterialsData] = useState(props.materials ?? []);

  useEffect(() => {
    setMaterialsData(props.materials ?? []);
  }, [props]);

  useEffect(() => {
    const image = {
      id: props.data?.image,
      url: props.data?.image,
    };

    setFiles(props.data?.image ? ([image] as any) : []);
  }, []);

  const onCancel = () => {
    navigate("/products/products-list");
    resetForm();
  };

  const onAddVariant = (data: any) => {
    const variantsCopy = values.variants ? [...values.variants] : [];
    const newVariants = variantsCopy ? [...variantsCopy, data] : [data];

    setFieldValue("variants", newVariants);
    setIsAddNewVariantModalOpen(false);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <FormContainer>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <PaperBox>
              <Box pb={12}>
                <FormHeading>Product Information</FormHeading>
              </Box>
              <Grid
                container
                rowSpacing={12}
                columnSpacing={{ sm: 0, md: 20 }}
                xs={12}
                md={10}
                mb={16}
              >
                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <FormInputLable requried>Product Title</FormInputLable>
                    <StyledTextField
                      placeholder="Enter stock keeping units title"
                      fullWidth
                      {...getFieldProps("title")}
                      error={Boolean(touched.title && errors.title)}
                      helperText={
                        (touched.title as any) && (errors.title as any)
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box width="100%">
                    <FormInputLable noPrefix>Material</FormInputLable>
                    <StyledTextField
                      select
                      fullWidth
                      placeholder="Select a material"
                      error={Boolean(touched.material && errors.material)}
                      helperText={
                        (touched.material as any) && (errors.material as any)
                      }
                      {...getFieldProps("material")}
                      onChange={formik.handleChange("material")}
                      defaultValue={[]}
                      SelectProps={{
                        multiple: false,
                      }}
                    >
                      {materialsData?.map((item: any) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </StyledTextField>
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                columnSpacing={{ xs: 0, md: 16 }}
                rowSpacing={{ xs: 12, md: 0 }}
                md={10}
                xs={12}
              >
                <Grid item xs={12} md={6}>
                  <ProductsChecksBox>
                    <CheckField>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="secondary"
                            checked={values?.isCountable}
                          />
                        }
                        label="Countable"
                        name="isCountable"
                        onChange={formik.handleChange}
                      />
                    </CheckField>
                    <CheckField>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="secondary"
                            checked={values?.isPackageable}
                          />
                        }
                        label="Packageable"
                        name="isPackageable"
                        onChange={formik.handleChange}
                      />
                    </CheckField>
                    <CheckField>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="secondary"
                            checked={values?.isCreatableInProduction}
                          />
                        }
                        label="Creatable in the production process"
                        name="isCreatableInProduction"
                        onChange={formik.handleChange}
                      />
                    </CheckField>
                  </ProductsChecksBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ width: "186px", height: "146px" }}>
                    <FileUploader
                      type={FileUploaderType.Single}
                      files={files}
                      setFiles={setFiles}
                    />
                  </Box>
                </Grid>
              </Grid>
            </PaperBox>

            <PaperBox>
              <VariantsTitleBar>
                Variants
                <Box width="128px" marginLeft={16}>
                  <StyledButton
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => setIsAddNewVariantModalOpen(true)}
                  >
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      color={colors.base.white}
                    >
                      Add New Variant
                    </Typography>
                  </StyledButton>
                </Box>
              </VariantsTitleBar>
              <FieldArray
                name="variants"
                render={(arrayHelpers) => (
                  <VariantsList
                    container
                    rowSpacing={12}
                    columnSpacing={{ sm: 0, md: 20 }}
                    md={10}
                    xs={12}
                  >
                    {values.variants && values.variants.length > 0 ? (
                      values.variants.map((variant: any, index: number) => (
                        <Grid item xs={12} md={6} key={index}>
                          <Box width="100%">
                            <VariantInputHead>
                              <FormInputLable
                                requried={
                                  variant.initial_value_required ?? false
                                }
                                noPrefix={!variant.initial_value_required}
                                noPB
                              >
                                {variant.name}
                              </FormInputLable>
                              <IconButton
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/icons/RemoveSlim.svg"
                                  }
                                  alt="delete"
                                />
                              </IconButton>
                            </VariantInputHead>
                            <StyledTextField
                              fullWidth
                              {...getFieldProps(
                                `variants[${index}].initial_value`
                              )}
                            />
                          </Box>
                        </Grid>
                      ))
                    ) : (
                      <Grid item md={6} sm={12} mt={12}>
                        <EmptyVariantsBox>
                          <Image>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/emptyStates/Variants-List EmptyState.svg"
                              }
                            />
                          </Image>
                          <Desc>
                            There is no varient added yet,{" "}
                            <LinkBtn
                              onClick={() => setIsAddNewVariantModalOpen(true)}
                            >
                              Build first now!
                            </LinkBtn>
                          </Desc>
                        </EmptyVariantsBox>
                      </Grid>
                    )}
                  </VariantsList>
                )}
              />
            </PaperBox>

            <Actions width="100%">
              <Box>
                <GrayButton
                  variant="outlined"
                  fullWidth
                  disabled={isPatchLoading}
                  onClick={onCancel}
                >
                  Cancel
                </GrayButton>
              </Box>
              <Box marginLeft={12}>
                <StyledLoadingButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  loading={isPatchLoading}
                  type="submit"
                >
                  <Typography
                    fontSize={16}
                    fontWeight={500}
                    color={colors.gray[850]}
                  >
                    Confirm
                  </Typography>
                </StyledLoadingButton>
              </Box>
            </Actions>
          </Form>
        </FormContainer>
      </FormikProvider>

      <AddNewVariantModal
        variants={props?.variants}
        open={isAddNewVariantModalOpen}
        onAddVariant={onAddVariant}
        onDismiss={() => setIsAddNewVariantModalOpen(false)}
      />
    </>
  );
};

const FormContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }

  & > form {
    width: 100%;
  }
`;

const ProductsChecksBox = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
`;

const CheckField = styled("div")`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const VariantsTitleBar = styled("div")`
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 22px;
  border-bottom: 1px solid ${colors.gray[100]};
  color: ${colors.gray[850]};
  font-size: 21px;
  font-weight: 500;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

const VariantsList = styled(Grid)`
  width: 100%;
`;

const VariantInputHead = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  & > div {
    width: 184px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    & > div {
      width: 142px;
    }
  }
`;

const EmptyVariantsBox = styled("div")`
  width: 280px;
  height: 190px;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Image = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Desc = styled(Typography)({
  color: colors.gray[600],
  display: "inline-flex",
  marginTop: "6px",
  whiteSpace: "nowrap",
});

const LinkBtn = styled(Typography)({
  cursor: "pointer",
  color: colors.primary[800],
  marginLeft: "4px",
});
