import styled from "@emotion/styled";
import { Avatar } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import colors from "src/theme/colors";

export interface InventoryCardProps {
  id?: string;
  type: string;
  title: string;
  image?: string;
  weight: number;
  unit: string;
  count?: string;
  isProduct?: boolean;
  className?: string;
  onClick?: () => void;
}

export const InventoryCard: FC<InventoryCardProps> = (props) => {
  const {
    title,
    image,
    type,
    weight,
    unit,
    count,
    isProduct,
    className,
    onClick,
  } = props;

  return (
    <Container
      className={className}
      onClick={isProduct ? onClick : undefined}
      style={isProduct ? { cursor: "pointer" } : {}}
    >
      <TitleBox>
        <Avatar
          alt={title}
          src={image}
          sx={{
            bgcolor: colors.gray[20],
            width: "64px",
            height: "64px",
            marginRight: "16px",
          }}
          variant="square"
        >
          {title}
        </Avatar>
        <Titless>
          <Title>{title}</Title>
          <Type>{type}</Type>
        </Titless>
      </TitleBox>
      <Stock isProduct={isProduct != null}>
        {weight != null && count != null ? (
          <StockValue>
            <Value>{weight}</Value> <Prefix>{unit}</Prefix>
            <Dash>-</Dash>
            <Value>{count}</Value> <Prefix>Pcs</Prefix>
          </StockValue>
        ) : weight != null && count == null ? (
          <StockValue>
            <Value light>
              {weight} {unit}
            </Value>
          </StockValue>
        ) : (
          <StockValue>
            <Value>{count}</Value> <Prefix>Pcs</Prefix>
          </StockValue>
        )}
        <StockLabel>Stock</StockLabel>
      </Stock>
      {isProduct ? <Badge>{isProduct}</Badge> : null}
    </Container>
  );
};

interface StyledProps {
  isProduct?: boolean;
  light?: boolean;
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 104px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 20px 24px;
  border: 1px solid #f0f2f7;
  box-shadow: 0px 2px 20px 0px #0000000d;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Titless = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const Title = styled.span`
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 4px;
  margin-left: 4px;
  color: ${colors.gray[850]};
`;

const Type = styled.span`
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  padding: 2px 12px;
  border-radius: 30px;
  color: ${colors.secondary[800]};
  background-color: ${colors.secondary[50]};
`;

const Stock = styled.span<StyledProps>`
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  color: ${colors.gray[800]};
  ${({ isProduct }) => ({
    marginTop: isProduct ? "32px" : 0,
  })}
`;

const StockValue = styled.span<StyledProps>`
  font-size: 13px;
  font-weight: 700;
  line-height: 26px;
  margin-left: 8px;
  color: ${colors.gray[600]};
`;

const StockLabel = styled.span<StyledProps>`
  font-size: 13px;
  font-weight: 700;
  line-height: 26px;
  margin-left: 8px;
  color: ${colors.gray[600]};
`;

const Value = styled.span<StyledProps>`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  ${({ light }) => ({
    color: light ? colors.gray[600] : colors.gray[750],
  })}
`;

const Prefix = styled.span<StyledProps>`
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  color: ${colors.gray[750]};
`;

const Dash = styled.span<StyledProps>`
  font-size: 18px;
  margin: 0 4px;
  color: ${colors.gray[750]};
`;

const Badge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  padding: 8px 16px;
  color: ${colors.primary[800]};
  background-color: ${colors.primary[10]};
`;
