import styled from "@emotion/styled";
import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MouseEvent, useState } from "react";
import { FC } from "react";
import colors from "src/theme/colors";

export interface EmployeeCardProps {
  id?: string;
  name: string;
  avatar?: string;
  roles: any[];
}

interface EmployeeCardComponentProps {
  cardData: EmployeeCardProps;
  className?: string;
  onRemoveEmployee: () => void;
  onRedirectChat: () => void;
}

export const EmployeeCard: FC<EmployeeCardComponentProps> = (props) => {
  const { cardData, className, onRemoveEmployee, onRedirectChat } = props;
  const { name, avatar, roles } = cardData;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container className={className}>
      <TitleBox>
        <Avatar
          alt={name}
          src={avatar}
          sx={{
            bgcolor: colors.gray[20],
            width: "56px",
            height: "56px",
            marginRight: "16px",
          }}
        >
          {name}
        </Avatar>
        <Titless>
          <Title>{name}</Title>
          <SubTitle>{roles ? roles[0]?.title : ""}</SubTitle>
        </Titless>
      </TitleBox>
      <Actions>
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: "auto",
              },
            }}
          >
            {/* <MenuItem
              key={1}
              onClick={onRemoveEmployee}
              sx={{ p: 6, color: colors.error[500] }}
            >
              <img
                src={process.env.PUBLIC_URL + "/assets/icons/Remove.svg"}
                alt="delete"
              />{" "}
              Remove Employee
            </MenuItem> */}
          </Menu>
        </div>
        <ChatButton onClick={onRedirectChat}>
          <img
            src={process.env.PUBLIC_URL + "/assets/icons/GreenChat.svg"}
            alt="chat"
          />
        </ChatButton>
      </Actions>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 104px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  border-radius: 5px;
  border: 1px solid #eaecef;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Titless = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const Title = styled.span`
  font-size: 15px;
  font-weight: 700;
  line-height: 30px;
  color: ${colors.gray[850]};
`;

const SubTitle = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: ${colors.gray[700]};
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

const ChatButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  cursor: pointer;
`;
