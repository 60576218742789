import { Menu, MenuItem, Typography, useTheme, styled } from "@mui/material";
import { FC } from "react";
import Loading from "src/components/Loading";
import colors from "src/theme/colors";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface StocktakingStatusChartPops {
  isLoading: boolean;
  counts: any;
}

export const StocktakingStatusChart: FC<StocktakingStatusChartPops> = (
  props
) => {
  const { isLoading, counts } = props;

  const data = [
    {
      name: "In Stock",
      stock: counts?.stock,
    },
    {
      name: "Stocktaking",
      counted: counts?.counted,
      uncounted: counts?.uncounted,
      new: counts?.new,
    },
  ];

  return (
    <Container>
      <Titlebar>
        <Title>Chart</Title>
      </Titlebar>
      <Content>
        {isLoading ? (
          <Center>
            <Loading />
          </Center>
        ) : (
          <ChartWrapper>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                height={300}
                data={data}
                margin={{
                  top: 20,
                }}
              >
                <CartesianGrid stroke="#E5E5EF" strokeDasharray="3 3" />
                <XAxis dataKey="name" stroke="#A1A8B2" axisLine={true} />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tick={{ fill: "#A1A8B2" }}
                />
                <Tooltip />
                <Bar dataKey="stock" stackId="a" fill="#8DDDFD" />
                <Bar dataKey="counted" stackId="a" fill="#6DD2B1" />
                <Bar dataKey="uncounted" stackId="a" fill="#FF5436" />
                <Bar dataKey="new" stackId="a" fill="#FFDD8A" />
              </BarChart>
            </ResponsiveContainer>
          </ChartWrapper>
        )}
      </Content>
    </Container>
  );
};

const Container = styled("div")`
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  border-radius: 4px;
  background-color: ${colors.base.white};

  ${(props) => props.theme.breakpoints.down("xl")} {
    min-height: 350px;
  }
  ${(props) => props.theme.breakpoints.down("lg")} {
    min-height: 400px;
  }
`;

const Content = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 14px;
`;

const ChartWrapper = styled("div")`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Titlebar = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled("h6")`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: ${colors.gray[850]};
`;

const Center = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
